import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { IFilterParams } from 'src/typings/filter';
import { getFilterParamData } from 'src/store/filter/filter-params/selectors';
import { useTranslations } from 'src/hooks/translataions';

interface ISelectedParams {
  name: string;
  value: string;
  data: string | string[];
}

export const useGetFilterParams = (
  countHideParams?: number,
) => {
  const { getTranslations } = useTranslations();
  const [selectedParams, setSelectedParams] = useState<ISelectedParams[]>([]);
  const [selectedHideParams, setSelectedHideParams] = useState<ISelectedParams[]>([]);
  const filterParams: IFilterParams = useSelector(getFilterParamData);

  useEffect(() => {
    const result: ISelectedParams[] = [];

    Object.keys(filterParams).forEach((key) => {
      if (Boolean(filterParams[key]?.data?.length)) {
        const displayedValue = _.isString(filterParams[key].data)
          ? filterParams[key].data
          : filterParams[key].data.join('-');

        result.push({
          name: key,
          value: `${getTranslations(key)} ${displayedValue}`,
          data: filterParams[key].data,
        });
      }
    });

    setSelectedParams(result);
  }, [getTranslations, filterParams]);

  useEffect(() => {
    if (Boolean(countHideParams)) {
      const result: ISelectedParams[] = [];

      Object.keys(filterParams).slice(countHideParams, 99).forEach((key) => {
        if (Boolean(filterParams[key]?.data?.length)) {
          const displayedValue = _.isString(filterParams[key].data)
            ? filterParams[key].data
            : filterParams[key].data.join('-');

          result.push({
            name: key,
            value: `${key} ${displayedValue}`,
            data: filterParams[key].data,
          });
        }
      });

      setSelectedHideParams(result);
    }
  }, [filterParams, countHideParams]);

  return {
    filterParams,
    selectedParams,
    selectedHideParams,
  };
};
