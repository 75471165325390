import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  sidebarWrapper: (props: any) => ({
    width: '260px',
    overflowY: 'auto',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 10,
      background: props.filterBackgroundColor,
    },
  }),
  popoverContent: {
    width: '100%',
    padding: '10px',
  },
  wrapperLayoutStyles: {
    '&:hover': {
      top: '0px !important',
    },
  },
  moreInfoLink: {
    color: 'white',
    textTransform: 'capitalize',
    marginTop: '15px',
  },
  wrapperOpen: {
    animation: '$wrapperOpen 400ms forwards',
    [theme.breakpoints.down('md')]: {
      animation: '$wrapperOpenPhone 400ms forwards',
    },
  },
  wrapperClose: {
    animation: '$wrapperClose 400ms forwards',
    [theme.breakpoints.down('md')]: {
      animation: '$wrapperClosePhone 400ms forwards',
    },
  },
  '@keyframes wrapperOpen': {
    '0%': { width: '0px' },
    '100%': { width: '260px' },
  },
  '@keyframes wrapperClose': {
    '0%': { width: '260px' },
    '100%': { width: '0px' },
  },
  '@keyframes wrapperOpenPhone': {
    '0%': { width: '0px' },
    '100%': { width: '100%' },
  },
  '@keyframes wrapperClosePhone': {
    '0%': { width: '100%' },
    '100%': { width: '0px' },
  },
}));
