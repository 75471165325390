import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';
import { locals } from 'src/constants/locals';

export const useIsInfrastructure = () => {
  const typeOfPropertySelector = useSelector(getSelectedPlacement);
  const [isInfrastructure, setInfrastructure] = useState<boolean>(false);

  useEffect(() => {
    setInfrastructure(typeOfPropertySelector === locals.appTranslations.appInfrastructure);
  }, [typeOfPropertySelector]);

  return {
    isInfrastructure,
  };
};
