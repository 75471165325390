import { IAppLayoutsItem } from 'src/typings/layouts';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface ILayoutsReducer {
  filteredData: IAppLayoutsItem[];
}

export const initialState: ILayoutsReducer = {
  filteredData: [],
};

export const layoutsReducer = (
  state = initialState,
  { type, payload }: IAction,
): ILayoutsReducer => {
  switch (type) {
    case ActionTypes.SET_LAYOUTS_FILTERED_DATA: {
      return {
        ...state,
        filteredData: payload,
      };
    }

    case ActionTypes.CLEAR_LAYOUTS_FILTERED_DATA: {
      return {
        ...state,
        filteredData: [],
      };
    }

    default:
      return state;
  }
};
