import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { IFilterAllData } from 'src/typings/filter';

export const useShowDifferences = (
  listOfComparePlacement: IFilterAllData[],
) => {
  const [showDifferences, setShowDifferences] = useState<boolean>(false);
  const [listOfComparePlacementFiltered, setListOfComparePlacementFiltered] = useState<IFilterAllData[] | any>([]);

  const handleToggleShowDifferences = useCallback(() => setShowDifferences(!showDifferences), [showDifferences]);

  useEffect(() => {
    if (listOfComparePlacement.length && showDifferences) {
      const keysOfCompareElement = Object.keys(listOfComparePlacement[0]);

      const updateKeysForDisplayed: string[] = [];
      const updateListOfComparePlacement: IFilterAllData[] | any = [];

      keysOfCompareElement.forEach((keysElement) => {
        const checkUniq: any[] = [];

        listOfComparePlacement.forEach((oldElement: IFilterAllData | any) => {
          if (keysElement === 'main_filter_img') {
            return;
          }

          if (_.isArray(oldElement[keysElement])) {
            checkUniq.push(oldElement[keysElement].join(','));
          } else {
            checkUniq.push(oldElement[keysElement]);
          }
        });

        if (_.uniq(checkUniq).length !== 1) {
          updateKeysForDisplayed.push(keysElement);
        }
      });

      listOfComparePlacement.forEach((oldElement: IFilterAllData | any) => {
        const filteredObj = _.pickBy(oldElement, (v, k) => {
          return updateKeysForDisplayed.includes(k);
        });

        updateListOfComparePlacement.push(filteredObj);
      });

      setListOfComparePlacementFiltered(updateListOfComparePlacement);
    } else {
      setListOfComparePlacementFiltered(listOfComparePlacement);
    }
  }, [listOfComparePlacement, showDifferences]);

  return {
    listOfComparePlacementFiltered,
    showDifferences,
    handleToggleShowDifferences,
  };
};
