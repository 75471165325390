import { ReactImageGalleryItem } from 'react-image-gallery';
import { IPanoAppartments } from 'src/containers/MainPageApartments/hooks';
import { IFilterAllData } from 'src/typings/filter';
import { ISpinnerDataPossibilityInnerData } from 'src/typings/spinner';

export interface IGalleryListOfSpinner {
  name: string;
  order: number;
  data: ISpinnerDataPossibilityInnerData;
  icon: string | null;
}

export interface IApartmentGallery {
  combineIds: string[];
  spinnerApartments: IGalleryListOfSpinner[] | [];
  panoApartments: IPanoAppartments[] | [];
  gallery: string[];
  chooseData: IFilterAllData;
}

export interface ReactImageGalleryItemWithSpinnerData extends ReactImageGalleryItem {
  spinnerData?: ISpinnerDataPossibilityInnerData | undefined;
  icon?: string | null;
  type?: string | undefined;
  entity?: string;
  entityId?: number|string;
  id?: number;
}

export interface IComponentGalleryItem {
  combineIds: string[];
  galleryItem: ReactImageGalleryItemWithSpinnerData;
  galleryImagesTransform: any;
  floorIds: string[];
  placementId: string;
}

export enum SpinnerLabel {
  SPINNER = 'SPINNER',
  complex = 'complex',
  floor = 'floor',
  house = 'house',
  placement = 'placement',
  section = 'section',
}
