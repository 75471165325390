import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';

export const usePanoramaData = () => {
  const panoramaListData = useSelector(requestSelectors.spinner.getPanoramaData);

  return {
    panoramaListData,
  };
};
