import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  apartmentWrapper: {
    flex: '1',
    height: '100%',
    padding: '20px',
    overflowY: 'scroll',
  },
  headerBlock: {
    padding: '25px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  wrapperHeader: {
    marginBottom: '30px',
  },
  apartmentPageContainer: {
    flexDirection: 'column',
    padding: '30px',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
  },
  nestedInfo: {
    marginBottom: '20px',
    fontSize: '14px',
    fontWeight: 'bold',
    '& > span': {
      color: theme.palette.primary.main,
    },
    '& > span:last-child': {
      color: theme.palette.secondary.main,
    },
  },
  apartmentContainer: {
    display: 'flex',
    '& > div:not(:first-child)': {
      paddingLeft: '40px',
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      '& > div:not(:first-child)': {
        paddingLeft: '0px',
      },
    },
  },
  apartmentAdditionalInfo: {
    minWidth: '250px',
    maxWidth: '250px',
    '& .markUpDesktop': {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      bottom: 0,
      transform: 'none',
      pointerEvents: 'none',
      touchAction: 'none',
    },
    '&:empty': {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: 'none',
      maxWidth: 'none',
      '& .markUpDesktop': {
        display: 'block',
      },
      '& .markUpMobile': {
        display: 'none',
      },
    },
  },
  textNotAvailable: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));
