/* eslint-disable require-yield */
import { put, select, takeEvery } from 'redux-saga/effects';
import { ActionTypes, Actions } from './actions';

export function* setSectionsDataSaga(actions: {
  type: string,
  payload: string[]
}) {
  const markup = actions.payload;
  if (markup && markup[0]) {
    yield put(Actions.setSelectedSection({
      markup: 'section_id',
      'markup-id': markup[0],
    }));
    yield put(Actions.setSelectedFloor(undefined));
  }
}

export function* setFloorsDataWatchSaga() {
  yield takeEvery(ActionTypes.SET_FILTERED_SECTIONS, setSectionsDataSaga);
}
