import React, { useState, useEffect, useCallback } from 'react';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

export const ZoomTable = () => {
  const [value, setValue] = useState<number>(50);

  useEffect(() => {
    const crossSize = Number(document.documentElement.style.getPropertyValue('--crosstable-size'));
    if (crossSize) {
      setValue(crossSize * 50);
    }
  }, []);

  const handleChange = useCallback((newValue: number) => {
    setValue(newValue);
    document.documentElement.style.setProperty('--crosstable-size', String(newValue / 50));
  }, [document]);

  const handleZoomOut = useCallback(() => {
    if (value > 30) { handleChange(value - 10); }
  }, [value]);

  const handleZoomIn = useCallback(() => {
    if (value < 70) { handleChange(value + 10); }
  }, [value]);

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item>
        <ZoomOutIcon
          style={{
            cursor: 'pointer',
          }}
          onClick={handleZoomOut}
        />
      </Grid>
      <Grid item xs>
        <Slider
          min={30}
          step={10}
          max={70}
          value={value}
          aria-labelledby="discrete-slider"
          marks
          onChange={(e, newValue) => {
            if (!Array.isArray(newValue)) {
              handleChange(newValue);
            }
          }}
        />
      </Grid>
      <Grid item>
        <ZoomInIcon
          style={{
            cursor: 'pointer',
          }}
          onClick={handleZoomIn}
        />
      </Grid>
    </Grid>

  );
};
