import { IFilterData } from 'src/typings/filter';
import { action } from 'src/store/common';

export const ActionTypes = {
  GET_APP_CLIENT_MENU_MODULE_RESET: '[REQUEST_APP]GET_CLIENT_MENU_MODULE_RESET',
  GET_APP_CLIENT_MENU_MODULE_REQUEST: '[REQUEST_APP]GET_CLIENT_MENU_MODULE_REQUEST',
  GET_APP_CLIENT_MENU_MODULE_REQUEST_SUCCESS: '[REQUEST_APP]GET_CLIENT_MENU_MODULE_REQUEST_SUCCESS',
  GET_APP_CLIENT_MENU_MODULE_REQUEST_FAIL: '[REQUEST_APP]GET_CLIENT_MENU_MODULE_REQUEST_FAIL',
};

export const Actions = {
  getAppClientMenuModuleRequest: action(ActionTypes.GET_APP_CLIENT_MENU_MODULE_REQUEST),
  getAppClientMenuModuleReset: action(ActionTypes.GET_APP_CLIENT_MENU_MODULE_RESET),
  getAppClientMenuModuleSuccess: action<IFilterData>(ActionTypes.GET_APP_CLIENT_MENU_MODULE_REQUEST_SUCCESS),
  getAppClientMenuModuleFail: action<Error>(ActionTypes.GET_APP_CLIENT_MENU_MODULE_REQUEST_FAIL),
};
