import React, { useRef } from 'react';

import {
  useGetFilteredData,
  useScrollIntoResultFilter, useTranslations,
} from 'src/hooks';
import { ComponentListOfLayoutApartments } from 'src/components/ListOfLayoutApartments/component';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';

export const ViewLayout: React.FC = () => {
  const styles = useStyles({});
  const scrollPosition = useRef(null);
  useScrollIntoResultFilter(scrollPosition);
  const { resultFiltering, layoutsData } = useGetFilteredData();
  const { getTranslations } = useTranslations();

  return (
    <div
      className={styles.containerWrapper}
      ref={scrollPosition}
    >
      {
        Boolean(resultFiltering.length)
          ? (
            <ComponentListOfLayoutApartments
              isLayoutTab
              parentScroll={scrollPosition}
              properties={layoutsData}
              propertyWrapperStyles={styles.itemLayoutStyles}
              propertyWrapperImageStyle={styles.itemLayoutWrapperImageStyles}
            />
          )
          : <p className={styles.textNoData}>{getTranslations(locals.appTranslations.appNoDataToDisplay)}</p>
      }
    </div>
  );
};
