import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { useTypeView2d } from 'src/components/TypeView2d/useTypeView2d';
import { useConvertToFormat } from 'src/components/dropdowns/hooks';
import { useStyles } from './styles';

export const TypeView2d: React.FC = () => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const {
    viewList,
    selectedType,
    handleOnChange,
  } = useTypeView2d();
  const { currentData } = useConvertToFormat(viewList);

  if (!viewList.length) return null;

  return (
    <Box mb={isSmallScreen ? 2 : 0}>
      {getTranslations(locals.appTranslations.appShow)}
      : &nbsp;
      <Dropdown
        inline
        text={getTranslations(selectedType)}
        options={currentData}
        className={styles.styleOfDropdown}
        value={selectedType}
        onChange={(event, data) => handleOnChange(data.value)}
      />
    </Box>
  );
};
