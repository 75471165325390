import { combineReducers } from 'redux';

import { IInfrastructureRequestReducer, infrastructureRequestReducer } from './infrastructure/reducer';
import { IFilterStateReducer, filterReducer } from './filter/reducer';
import { ITypeOfPropertyReducer, typeOfPropertyReducer } from './type-of-property/reducer';
import { ISpinnerViewStateReducer, spinnerViewReducer } from './spinner-views/reducer';
import { ITranslationReducer, translationReducer } from './translations/reducer';
import { IAppRequestReducer, appRequestReducer } from './app/reducer';
import { IAppMenuRequestReducer, appMenuRequestReducer } from './app-menu/reducer';
import { ILayoutsRequestReducer, layoutRequestReducer } from './layouts/reducer';
import { IUserRequestReducer, userRequestReducer } from './user/reducer';

export const requestsReducer = combineReducers({
  app: appRequestReducer,
  appMenu: appMenuRequestReducer,
  filter: filterReducer,
  infrastructure: infrastructureRequestReducer,
  typeOfProperty: typeOfPropertyReducer,
  spinner: spinnerViewReducer,
  layouts: layoutRequestReducer,
  user: userRequestReducer,
  translations: translationReducer,
});

export interface IRequestsReducer {
  app: IAppRequestReducer,
  appMenu: IAppMenuRequestReducer,
  spinner: ISpinnerViewStateReducer,
  filter: IFilterStateReducer;
  infrastructure: IInfrastructureRequestReducer,
  typeOfProperty: ITypeOfPropertyReducer;
  layouts: ILayoutsRequestReducer,
  user: IUserRequestReducer,
  translations: ITranslationReducer,
}
