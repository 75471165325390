import React, { useMemo } from 'react';

import { randomHash } from 'src/helpers';
import { Loader } from 'semantic-ui-react';
import { IAppPanorama } from './types';
import { useInitPanorama } from './hooks';
import { useStyles } from './styles';

export const AppPanorama: React.FC<IAppPanorama> = ({
  isFullScreen,
  srcId,
}) => {
  const styles = useStyles({});
  const randomPanoramaId: string = `testPlace-${useMemo(randomHash, [])}`;
  const { isLoaded } = useInitPanorama(
    randomPanoramaId,
    srcId,
  );

  return (
    <div
      className={`${styles.panoramaView} ${isFullScreen ? styles.panoramaViewFullSize : ''}`}
    >
      <Loader active={isLoaded} />
      <div
        id={randomPanoramaId}
        className={`${styles.panoramaViewFullSize}`}
      />
    </div>
  );
};
