import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  errorTextMessage: {
    marginTop: '10px',
    color: theme.palette.error.main,
  },
  isDoneWrapper: {
    padding: '20px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  isDoneText: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '20px',
  },
}));
