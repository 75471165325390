import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { IRenderSvg } from 'src/components/ViewSpinner/components/RenderSvg/types';
import { requestSelectors } from 'src/store/requests/selectors';

interface IMouseTrackState {
  x: number;
  y: number;
}

export const useDetectAdditionalParams = (svg: IRenderSvg | any) => {
  // Hover
  const getPopupConfig = useSelector(requestSelectors.app.getPopupConfig);
  const [isHasHoverText] = useState<boolean>(Boolean(svg.attr['hover-text']) || svg.attr.infrastructure);
  const [isEnableHoverPopup] = useState<boolean>(getPopupConfig?.enable_hover_pop_up_short_info || false);
  const [isEnableHoverTextPolygonLightUp] = useState<boolean>(getPopupConfig?.enable_hover_text_polygon_light_up || false);
  const [isHoverTextPolygon, setIsHoverPolygon] = useState<boolean>(false);
  const [trackMouse, setTrackMouse] = useState<IMouseTrackState>({
    x: 0,
    y: 0,
  });

  // Infrastructure
  const [isHasInfrastructureType] = useState<boolean>(Boolean(svg.attr.infrastructure));
  const [isInfrastructureOpen, setInfrastructureOpen] = useState<boolean>(false);
  const handleInfrastructureToggle = useCallback(() => {
    if (isHasInfrastructureType) setInfrastructureOpen(!isInfrastructureOpen);
  }, [isHasInfrastructureType, isInfrastructureOpen]);

  return {
    isInfrastructureOpen,
    isEnableHoverPopup,
    isEnableHoverTextPolygonLightUp,
    isHasHoverText,
    isHasInfrastructureType,
    isHoverTextPolygon,
    trackMouse,
    setTrackMouse,
    setIsHoverPolygon,
    handleInfrastructureToggle,
  };
};
