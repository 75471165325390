import { IComplex, IDeveloper } from './complexes';

export interface IRole {
  '@context': string;
  '@id': TypeOfRole;
  '@type': string;
  id: number;
  name: string;
}

export interface IUsers {
  '@context': string;
  '@id': string;
  '@type': string;
  email: string;
  iconUri: string|null;
  id: number;
  language: string;
  role: IRole|null;
  username: string;
  complexes: IComplex[];
  developers: IDeveloper[];
}

export enum TypeOfRole {
  superadmin = '/api/user_roles/1',
  admin = '/api/user_roles/2',
  supervisor = '/api/user_roles/3',
  manager = '/api/user_roles/4'
}
