import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEntityConfig } from 'src/store/spinner/selectors';
import { makeWindRoseData } from 'src/components/WindRose/state';
import { getEntityOptions } from 'src/api';

export const useGetCompassData = (
  galleryImagesTransform: any,
  url: string | undefined,
  floorId: string,
) => {
  const [windRose, setWindRose] = useState<Function | null>(null);
  const entityConfig = useSelector(getEntityConfig);
  useEffect(() => {
    setWindRose(makeWindRoseData);
  }, []);
  useEffect(() => {
    if (entityConfig
      && windRose && url && galleryImagesTransform
      && galleryImagesTransform[url] && galleryImagesTransform[url][floorId]) {
      const windRoseData = {
        bg_src: entityConfig.floor_compass_bg_image,
        degree: entityConfig.floor_compass_angle + galleryImagesTransform[url][floorId].angle,
        disabled: false,
        src: entityConfig.floor_compass_image,
      };

      windRose(windRoseData);
    }
  }, [entityConfig, windRose]);

  return windRose;
};
