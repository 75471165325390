import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ICurrentData, useConvertToFormat } from 'src/components/dropdowns/hooks';
import { IRangeAndSet } from 'src/typings/filter';
import { useTranslations } from 'src/hooks/translataions';
import { AppComponentCheckbox } from 'src/components/AppCheckbox';
import { useCheckboxState } from './useCheckboxState';
import { useStyles } from '../../styles';

interface IAppCheckboxFilter {
  dropdownInfo: IRangeAndSet;
}

export const AppCheckboxSingleInfrastructure: React.FC<IAppCheckboxFilter> = ({
  dropdownInfo,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const { currentData } = useConvertToFormat(dropdownInfo.data);
  const {
    filterValue,
    handleSelectSingleCheckbox,
  } = useCheckboxState(dropdownInfo.attribute);

  return (
    <FormControl component="fieldset">
      <FormGroup>
        <FormLabel
          className={styles.titleItem}
          component="legend"
        >
          {`${getTranslations(dropdownInfo.attribute)}`}
        </FormLabel>
        <div className={styles.wrapperCheckboxes}>
          {currentData
            .sort((a, b) => {
              if (a.value < b.value) { return -1; }
              if (a.value > b.value) { return 1; }
              return 0;
            })
            .map((item: ICurrentData, index: number) => {
              return (
                <FormControlLabel
                  key={item.key}
                  className={styles.checkboxItem}
                  onClick={() => handleSelectSingleCheckbox(item, dropdownInfo.attribute)}
                  control={(
                    <AppComponentCheckbox
                      isChecked={filterValue === item.value}
                      label={item.value}
                    />
              )}
                  label=""
                />
              );
            })}
        </div>
      </FormGroup>
    </FormControl>
  );
};
