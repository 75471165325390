import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';
import { IAppRequest } from 'src/typings/app';

export const useGetRequestAppData = () => {
  const appRequestData: IAppRequest | undefined = useSelector(requestSelectors.app.getAppData);

  return {
    appRequestData,
  };
};
