import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';

export function getComplexDefaultConfig(lang: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexDefaultConfig}&lang=${lang}`);
}

export function getGroupedPlacementsConfig(lang: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getGroupedPlacementsConfig}&lang=${lang}`);
}

export function getClientModuleMenu(lang: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getClientMenuModule}&lang=${lang}`);
}
