import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { showInfrastructureState } from 'src/store/app/selectors';
import { Actions } from 'src/store/app/actions';
import { requestSelectors } from 'src/store/requests/selectors';
import { useIsInfrastructure } from 'src/hooks/infrastructure';

export const useShowInfrastructure = () => {
  const dispatch = useDispatch();
  const showInfrastructure: boolean = useSelector(showInfrastructureState);
  const infrastructure = useSelector(requestSelectors.spinner.getSpinnerInfrastructure);
  const { isInfrastructure } = useIsInfrastructure();

  const handleChangeShowInfrastructure = useCallback(() => {
    dispatch(Actions.showInfrastructure(!showInfrastructure));
  }, [showInfrastructure, dispatch]);

  return {
    isInfrastructure,
    showInfrastructure,
    handleChangeShowInfrastructure,
    infrastructureList: infrastructure || [],
  };
};
