import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';

import { ButtonApp } from 'src/components/Button';
import { ControlledAutocomplete } from 'src/components/ControledAutocomplete';
import { ReactHookFormSelect } from 'src/components/ReactHookFormSelect';
import { borderColor } from 'src/constants/styles';
import { translations } from 'src/constants/translations';
import { IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import {
  getBitrixFormConfigurations,
  postBitrixFormConfigurations,
  patchBitrixFormConfigurations,
  deleteBitrixFormConfigurations,
  getBitrixFormConfigurationTimelines,
  postBitrixFormConfigurationTimelines,
  patchBitrixFormConfigurationTimelines,
  deleteBitrixFormConfigurationTimelines,
} from 'src/api';
import { FormFields } from 'src/typings/forms';
import { IDogeFields } from 'src/typings/global-config';
import { makeId } from 'src/helpers';

interface IFeedbackSettings {
  project: IProject;
}

enum TypeOfField {
  string = 'string',
  selector = 'selector'
}

export interface IFeedbackField extends IDogeFields {
  tempId: string;
  id?: string;
  fsFormFieldKeyName: string;
  bitrixEntityName: string;
  bitrixEntityFieldName: string;
  bitrixEntityFieldId: string;
  bitrixEntityFieldType: string;
  fsFieldType: TypeOfField;
  isFilter: boolean;
  bitrixEntityFieldValuesIdToFsFormValues: {
    [key: string]: string
  } | null;
}
export interface ITimelineField {
  id?: string,
  tempId: string,
  linkToEntity: string,
  fsFormFieldKeyNames: string[],
}

const defaultEntity = ['lead', 'deal', 'contact', 'company'];

const defaultStringFields = [FormFields.phone, FormFields.email, FormFields.bio, FormFields.clientIp, FormFields.utm, FormFields.googleClientId];

export const FeedbackSettings: React.FC<IFeedbackSettings> = ({ project }) => {
  const { getJWT } = useGetAuthData();
  const [fields, setFields] = useState<{
    string: IFeedbackField[],
    selector: IFeedbackField[],
  }>({
    string: [],
    selector: [],
  });
  const [fieldsTimeline, setFieldsTimeline] = useState<ITimelineField[]>([]);
  const [entityValues, setEntityValues] = useState<{
    [key: string]: any
  }>({});
  const [rerender, setRerender] = useState<boolean>(false);
  const [removeIds, setRemoveIds] = useState<{
    id: string;
    type: string;
  }[]>([]);
  const jwt = getJWT();
  const {
    register, handleSubmit, getValues, setValue, control,
  } = useForm({
    mode: 'onChange',
  });

  const fetchFields = async (tempEntityValues?: {
    [key: string]: any
  }) => {
    const resultConf = await getBitrixFormConfigurations(jwt, project.id);
    const { webhookUrl } = project;

    const { data } = resultConf;

    if (data['hydra:member'].length) {
      const strings: IFeedbackField[] = [];
      const selectors: IFeedbackField[] = [];
      data['hydra:member'].forEach((item: IFeedbackField) => {
        if (item.fsFieldType === TypeOfField.string) {
          strings.push(item);
        } else {
          selectors.push(item);
        }
      });
      setFields({
        ...fields,
        string: strings,
        selector: selectors,
      });
      const currenttEntityValues = tempEntityValues || entityValues;
      const iblockElems = selectors.filter((selector: IFeedbackField) => selector.bitrixEntityFieldType === 'iblock_element');
      const iblockItems = await Promise.all(iblockElems.map((iblock) => {
        const findItem = Object.keys(currenttEntityValues[iblock.bitrixEntityName]).find((item: any) => {
          return currenttEntityValues[iblock.bitrixEntityName][item].title === iblock.bitrixEntityFieldName
          || currenttEntityValues[iblock.bitrixEntityName][item].listLabel === iblock.bitrixEntityFieldName;
        });
        if (findItem) {
          const iblockId = currenttEntityValues[iblock.bitrixEntityName][findItem].settings.IBLOCK_ID;
          return axios.get(`${webhookUrl}lists.element.get.json?IBLOCK_TYPE_ID=lists&IBLOCK_ID=${iblockId}`);
        }
        return null;
      }));
      const tempIblockValues = { ...currenttEntityValues };
      iblockItems.forEach((item: any) => {
        tempIblockValues[`iblock${item.data.result[0].IBLOCK_ID}`] = item.data.result;
      });
      setEntityValues(tempIblockValues);
    } else {
      const temp = { ...fields };
      temp.string = defaultStringFields.map((item) => {
        return {
          tempId: makeId(5),
          fsFormFieldKeyName: item,
          bitrixEntityName: '',
          bitrixEntityFieldName: '',
          bitrixEntityFieldId: '',
          fsFieldType: TypeOfField.string,
          bitrixEntityFieldType: '',
          bitrixEntityFieldValuesIdToFsFormValues: null,
        } as IFeedbackField;
      });
      setFields(temp);
    }

    const { data: dataTimelines } = await getBitrixFormConfigurationTimelines(jwt, project.id);
    if (dataTimelines['hydra:member'].length) {
      setFieldsTimeline(dataTimelines['hydra:member']);
      dataTimelines['hydra:member'].forEach((item: ITimelineField) => {
        setValue(`timeline.${item.id}.fsFormFieldKeyNames`, item.fsFormFieldKeyNames);
      });
    }
  };

  const fetchBitrixFields = async () => {
    const { webhookUrl } = project;
    if (webhookUrl) {
      const tempEntityValues: {
        [key: string]: any[]
      } = {};
      await Promise.all(defaultEntity.map(((defaultEntityItem) => {
        return axios.get(`${webhookUrl}crm.${defaultEntityItem}.fields.json`)
          .then((response) => {
          // handle success
            const { data } = response;
            const { result } = data;
            tempEntityValues[defaultEntityItem] = result;
            return result;
          })
          .catch((error) => {
          // handle error
            console.log(error);
            return error;
          });
      })));

      await axios.get(`${webhookUrl}crm.status.list.json`)
        .then((response) => {
          // handle success
          const { data } = response;
          const { result } = data;
          tempEntityValues.status = result;
          return result;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          return error;
        });

      setEntityValues(tempEntityValues);
      fetchFields(tempEntityValues);
    }
  };

  const onSubmit = async (data:any) => {
    console.log('DATA', data);
    await Promise.all(Object.keys(data).map((type: string) => {
      return Object.keys(data[type]).map((fieldId) => {
        if (type !== 'timeline') {
          if (data[type][fieldId] && data[type][fieldId].fsFormFieldKeyName
            && data[type][fieldId].bitrixEntityName) {
            if (!data[type][fieldId].id) {
              const newArr = data[type][fieldId].bitrixEntityFieldValuesIdToFsFormValues
                ? Object.keys(data[type][fieldId].bitrixEntityFieldValuesIdToFsFormValues).reduce((acc: any, id: string) => {
                  return {
                    ...acc,
                    [id.replace('ID', '')]: data[type][fieldId].bitrixEntityFieldValuesIdToFsFormValues[id],
                  };
                }, {})
                : null;
              return postBitrixFormConfigurations(jwt, {
                ...data[type][fieldId],
                bitrixEntityFieldValuesIdToFsFormValues: newArr,
                bitrixProject: project['@id'],
              });
            }
            const newArr = data[type][fieldId].bitrixEntityFieldValuesIdToFsFormValues
              ? Object.keys(data[type][fieldId].bitrixEntityFieldValuesIdToFsFormValues).reduce((acc: any, id: string) => {
                return {
                  ...acc,
                  [id.replace('ID', '')]: data[type][fieldId].bitrixEntityFieldValuesIdToFsFormValues[id],
                };
              }, {})
              : null;
            return patchBitrixFormConfigurations(jwt, data[type][fieldId].id, {
              ...data[type][fieldId],
              bitrixEntityFieldValuesIdToFsFormValues: newArr,
            });
          }
        } else if (data[type][fieldId] && data[type][fieldId].linkToEntity
              && data[type][fieldId].fsFormFieldKeyNames) {
          if (!data[type][fieldId].id) {
            return postBitrixFormConfigurationTimelines(jwt, {
              ...data[type][fieldId],
              bitrixProject: project['@id'],
            });
          }
          return patchBitrixFormConfigurationTimelines(jwt, data[type][fieldId].id, data[type][fieldId]);
        }
        return null;
      });
    }));
    await Promise.all(removeIds.map((itemId) => {
      if (itemId.type === 'timeline') {
        return deleteBitrixFormConfigurationTimelines(jwt, itemId.id);
      }
      return deleteBitrixFormConfigurations(jwt, itemId.id);
    }));
    setRemoveIds([]);
    setFields({
      string: [],
      selector: [],
    });
    fetchFields();
  };

  useEffect(() => {
    fetchBitrixFields();
  }, [project]);

  const handleGetFieldID = async (fieldId: string, type: TypeOfField) => {
    const entityName = getValues(`${type}.${fieldId}.bitrixEntityName`);
    const entityFieldName = getValues(`${type}.${fieldId}.bitrixEntityFieldName`);
    const { webhookUrl } = project;

    if (entityValues[entityName]) {
      const findedItem = Object.keys(entityValues[entityName]).find((item: any) => {
        return entityValues[entityName][item].title === entityFieldName
        || entityValues[entityName][item].listLabel === entityFieldName;
      });

      if (findedItem) {
        if (entityValues[entityName][findedItem].type === 'iblock_element') {
          const iblockID = entityValues[entityName][findedItem].settings.IBLOCK_ID;
          if (!entityValues[`iblock${iblockID}`]) {
            const iblockItem = await axios.get(`${webhookUrl}lists.element.get.json?IBLOCK_TYPE_ID=lists&IBLOCK_ID=${iblockID}`);
            setEntityValues({
              ...entityValues,
              [`iblock${iblockID}`]: iblockItem.data.result,
            });
          }
        }
        setValue(`${type}.${fieldId}.bitrixEntityFieldId`, findedItem);
        setValue(`${type}.${fieldId}.bitrixEntityFieldType`, entityValues[entityName][findedItem].type);
        if (type === TypeOfField.selector) {
          setRerender(!rerender);
        }
      }
    }
  };

  const handleAddElement = (type: TypeOfField) => {
    const temp = [...fields[type]];
    temp.push({
      tempId: makeId(5),
      fsFormFieldKeyName: '',
      bitrixEntityName: '',
      bitrixEntityFieldName: '',
      bitrixEntityFieldId: '',
      bitrixEntityFieldType: '',
      fsFieldType: type,
      bitrixEntityFieldValuesIdToFsFormValues: null,
    } as IFeedbackField);
    setFields({
      ...fields,
      [type]: temp,
    });
  };
  const handleAddTimeline = () => {
    const temp = [...fieldsTimeline];
    temp.push({
      tempId: makeId(5),
      linkToEntity: '',
      fsFormFieldKeyNames: [],
    } as ITimelineField);
    setFieldsTimeline(temp);
  };

  const removeElement = (itemString: IFeedbackField, type: TypeOfField) => {
    const temp = [...fields[type]];
    const findedIndex = fields[type].findIndex((item: any) => item === itemString);
    temp.splice(findedIndex, 1);
    setFields({
      ...fields,
      [type]: temp,
    });
    if (itemString.id) {
      removeIds.push({
        type,
        id: itemString.id,
      });
    }
  };

  const removeTimeline = (itemString: ITimelineField) => {
    const temp = [...fieldsTimeline];
    const findedIndex = fieldsTimeline.findIndex((item: any) => item === itemString);
    temp.splice(findedIndex, 1);
    setFieldsTimeline(temp);
    if (itemString.id) {
      removeIds.push({
        type: 'timeline',
        id: itemString.id,
      });
    }
  };

  const getBitrixFieldsList = (fieldId: string, defEntityName: string) => {
    const entityName = getValues(`string.${fieldId}.bitrixEntityName`) || defEntityName;
    return entityValues[entityName] ? Object.keys(entityValues[entityName]).map((item: string) => (
      entityValues[entityName][item].listLabel || entityValues[entityName][item].title
    )) : [];
  };

  const getBitrixFieldsListSelector = (fieldId: string, defEntityName: string) => {
    const entityName = getValues(`selector.${fieldId}.bitrixEntityName`) || defEntityName;
    if (entityValues[entityName]) {
      const filtered = Object.keys(entityValues[entityName]).filter((item: string) => ((
        entityValues[entityName][item].type === 'enumeration'
        || entityValues[entityName][item].type === 'crm_status'
        || entityValues[entityName][item].type === 'iblock_element'
      )));
      return filtered.map((item: string) => (entityValues[entityName][item].listLabel || entityValues[entityName][item].title));
    }

    return [];
  };

  console.log('TIMELINE', fieldsTimeline);

  return (
    <Box py={5} px={4}>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        Настройка формы обратной связи
      </Box>
      {project.webhookUrl ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            position="relative"
            width="100%"
            p={4}
            mb={4}
          >
            <Box
              top="-10px"
              position="absolute"
              bgcolor="white"
              fontSize="14px"
            >
              Тип сущности - строка
            </Box>
            {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
            <Box
              border={`1px solid ${borderColor}`}
              borderRadius="3px"
              position="relative"
              width="100%"
              p={2}
              mb={2}
            >
              ❗ Так как поиск контакта происходит по номеру телефона (это зачастую,
              могут быть другие признаки, например имейл - тогда их тоже нужно будет добавить),
              в тип сущности СТРОКА нужно обязательно добавить номер телефона для сущности Contact.
            </Box>
            {fields.string.map((item: IFeedbackField) => (
              <Box
                key={item.id || item.tempId}
                display="flex"
                justifyContent="space-beetwen"
                alignItems="center"
                mb={2}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <ControlledAutocomplete
                      control={control}
                      name={`string.${item.id || item.tempId}.fsFormFieldKeyName`}
                      options={Object.values(FormFields)}
                      getOptionLabel={(option: any) => option}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Имя поля из формы FS"
                          variant="outlined"
                        />
                      )}
                      defaultValue={item.fsFormFieldKeyName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ControlledAutocomplete
                      control={control}
                      name={`string.${item.id || item.tempId}.bitrixEntityName`}
                      options={defaultEntity}
                      getOptionLabel={(option: any) => option}
                      onChange={(e:any) => {
                        setRerender(!rerender);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Название сущности B24"
                          variant="outlined"
                        />
                      )}
                      defaultValue={item.bitrixEntityName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ControlledAutocomplete
                      control={control}
                      defaultValue={item.bitrixEntityFieldName}
                      name={`string.${item.id || item.tempId}.bitrixEntityFieldName`}
                      options={getBitrixFieldsList(item.id || item.tempId, item.bitrixEntityName)}
                      getOptionLabel={(option: any) => option}
                      onChange={(data:any) => {
                        setValue(`string.${item.id || item.tempId}.bitrixEntityFieldName`, data);
                        handleGetFieldID(item.id || item.tempId, TypeOfField.string);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Название поля сущности B24"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="ID поля сущности B24"
                      name={`string.${item.id || item.tempId}.bitrixEntityFieldId`}
                      defaultValue={item.bitrixEntityFieldId}
                      variant="outlined"
                      inputRef={register}
                    />

                  </Grid>
                </Grid>
                <Box display="none">
                  <TextField
                    label="Тип поля"
                    name={`string.${item.id || item.tempId}.bitrixEntityFieldType`}
                    defaultValue={item.bitrixEntityFieldType}
                    variant="outlined"
                    inputRef={register}
                  />
                </Box>
                <Input
                  type="hidden"
                  name={`string.${item.id || item.tempId}.fsFieldType`}
                  defaultValue="string"
                  inputRef={register}
                />
                {item.id && (
                <Input
                  type="hidden"
                  name={`string.${item.id}.id`}
                  defaultValue={item.id}
                  inputRef={register}
                />
                )}
                <Box ml={1}>
                  <Tooltip placement="top" title="Искать дубли по этому полю?">
                    <div>
                      <Checkbox
                        defaultChecked={item.isFilter}
                        name={`string.${item.id || item.tempId}.isFilter`}
                        color="primary"
                        inputRef={register}
                      />
                    </div>
                  </Tooltip>
                </Box>
                <Box ml={1}>
                  <IconButton
                    onClick={() => removeElement(item, TypeOfField.string)}
                    aria-label="delete"
                    size="large"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
            <Box>
              <ButtonApp
                text="Добавить строку"
                color="primary"
                variant="contained"
                onClick={() => handleAddElement(TypeOfField.string)}
                size="large"
              />
            </Box>
          </Box>

          {/* TYPE SELECTOR */}
          <Box
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            position="relative"
            width="100%"
            p={4}
            mb={4}
          >
            <Box
              top="-10px"
              position="absolute"
              bgcolor="white"
              fontSize="14px"
            >
              Тип сущности - селектор
            </Box>
            {fields.selector.map((item: IFeedbackField) => {
              const fieldId = getValues(`selector.${item.id || item.tempId}.bitrixEntityFieldId`);
              const entityName = getValues(`selector.${item.id || item.tempId}.bitrixEntityName`);

              let fieldItems: any = null;
              if (entityValues[entityName] && entityValues[entityName][fieldId]) {
                switch (entityValues[entityName][fieldId].type) {
                  case 'crm_status': {
                    const entityId = fieldId.replace('_ID', '');
                    const tempItems = entityValues.status.filter((statusItem: any) => {
                      return statusItem.ENTITY_ID === entityId;
                    });
                    fieldItems = tempItems.map((statusItem: any) => {
                      return {
                        ID: statusItem.STATUS_ID,
                        VALUE: statusItem.NAME,
                      };
                    });
                    break;
                  }
                  case 'iblock_element': {
                    const iblockID = entityValues[entityName][fieldId].settings.IBLOCK_ID;
                    if (entityValues[`iblock${iblockID}`]) {
                      fieldItems = entityValues[`iblock${iblockID}`].map((blockItem: any) => {
                        return {
                          ID: blockItem.ID,
                          VALUE: blockItem.NAME,
                        };
                      });
                    }

                    break;
                  }

                  default:
                    fieldItems = (entityValues[entityName] && entityValues[entityName][fieldId])
                      ? entityValues[entityName][fieldId].items : null;
                    break;
                }
              }
              return (
                <Box
                  border={`1px solid ${borderColor}`}
                  borderRadius="3px"
                  p={2}
                  mb={2}
                  key={item.id || item.tempId}
                >
                  <Box
                    display="flex"
                    justifyContent="space-beetwen"
                    alignItems="center"
                    mb={2}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <ControlledAutocomplete
                          defaultValue={item.fsFormFieldKeyName}
                          control={control}
                          name={`selector.${item.id || item.tempId}.fsFormFieldKeyName`}
                          options={Object.values(FormFields)}
                          getOptionLabel={(option: any) => option}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Имя поля из формы FS"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ControlledAutocomplete
                          defaultValue={item.bitrixEntityName}
                          control={control}
                          name={`selector.${item.id || item.tempId}.bitrixEntityName`}
                          options={defaultEntity}
                          getOptionLabel={(option: any) => option}
                          onChange={(e: any) => {
                            setRerender(!rerender);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Название сущности B24"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ControlledAutocomplete
                          defaultValue={item.bitrixEntityFieldName}
                          control={control}
                          name={`selector.${item.id || item.tempId}.bitrixEntityFieldName`}
                          options={getBitrixFieldsListSelector(item.id || item.tempId, item.bitrixEntityName)}
                          getOptionLabel={(option: any) => option}
                          onChange={(data:any) => {
                            setValue(`selector.${item.id || item.tempId}.bitrixEntityFieldName`, data);
                            handleGetFieldID(item.id || item.tempId, TypeOfField.selector);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Название поля сущности B24"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="ID поля сущности B24"
                          name={`selector.${item.id || item.tempId}.bitrixEntityFieldId`}
                          defaultValue={item.bitrixEntityFieldId}
                          variant="outlined"
                          inputRef={register}
                        />
                      </Grid>
                    </Grid>
                    <Input
                      type="hidden"
                      name={`selector.${item.id || item.tempId}.bitrixEntityFieldType`}
                      defaultValue={item.bitrixEntityFieldType}
                      inputRef={register}
                    />
                    <Input
                      type="hidden"
                      name={`selector.${item.id || item.tempId}.fsFieldType`}
                      defaultValue="selector"
                      inputRef={register}
                    />

                    {item.id && (
                    <Input
                      type="hidden"
                      name={`selector.${item.id}.id`}
                      defaultValue={item.id}
                      inputRef={register}
                    />
                    )}
                    <Box ml={1}>
                      <IconButton
                        onClick={() => removeElement(item, TypeOfField.selector)}
                        aria-label="delete"
                        size="large"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box display="none">
                    <TextField
                      label="Тип поля"
                      name={`selector.${item.id || item.tempId}.bitrixEntityFieldType`}
                      defaultValue={item.bitrixEntityFieldType}
                      variant="outlined"
                      inputRef={register}
                    />
                  </Box>
                  {fieldItems && (
                  <Box>

                    {fieldItems.map((fieldItem: { ID: string; VALUE: string; }) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        mb={2}
                        key={fieldItem.ID}
                      >
                        <Box width="250px">
                          {`ID = ${fieldItem.ID}, VALUE = ${fieldItem.VALUE}`}
                        </Box>
                        <Box ml={4} width="300px">
                          <TextField
                            label="ID значение поля формы FS"
                            name={`selector.${item.id || item.tempId}.bitrixEntityFieldValuesIdToFsFormValues.ID${fieldItem.ID}`}
                            defaultValue={item.bitrixEntityFieldValuesIdToFsFormValues && item.bitrixEntityFieldValuesIdToFsFormValues[fieldItem.ID]}
                            variant="outlined"
                            inputRef={register}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  )}
                </Box>
              );
            })}
            <Box>
              <ButtonApp
                text="Добавить селектор"
                color="primary"
                variant="contained"
                onClick={() => handleAddElement(TypeOfField.selector)}
                size="large"
              />
            </Box>
          </Box>
          <Box
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            position="relative"
            width="100%"
            p={4}
            mb={4}
          >
            <Box
              top="-10px"
              position="absolute"
              bgcolor="white"
              fontSize="14px"
            >
              Тип сущности - Timeline
            </Box>
            {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
            <Box
              border={`1px solid ${borderColor}`}
              borderRadius="3px"
              position="relative"
              width="100%"
              p={2}
              mb={2}
            >
              ❗ Для каждой сущности (deal, lead, contact) таймлайн нужно настраивать отдельно.
            </Box>
            {fieldsTimeline.map((item: ITimelineField) => (
              <Box
                key={item.id || item.tempId}
                display="flex"
                justifyContent="space-beetwen"
                alignItems="center"
                mb={2}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label="Название поля из формы FS"
                      SelectProps={{
                        multiple: true,
                        defaultValue: item.fsFormFieldKeyNames,
                      }}
                      onChange={(e) => {
                        setValue(`timeline.${item.id || item.tempId}.fsFormFieldKeyNames`, e.target.value);
                      }}
                      // @ts-ignore
                      inputProps={register(`timeline.${item.id || item.tempId}.fsFormFieldKeyNames`)}
                    >
                      {Object.values(FormFields).map((fieldName: string) => (
                        <MenuItem key={`${item.id || item.tempId}${fieldName}`} value={fieldName}>{fieldName}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <ControlledAutocomplete
                      defaultValue={item.linkToEntity}
                      control={control}
                      name={`timeline.${item.id || item.tempId}.linkToEntity`}
                      options={defaultEntity}
                      getOptionLabel={(option: any) => option}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Название сущности B24"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Box ml={1}>
                    <IconButton onClick={() => removeTimeline(item)} aria-label="delete" size="large">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Grid>
                <Input
                  type="hidden"
                  name={`timeline.${item.id || item.tempId}.bitrixEntityFieldType`}
                  defaultValue="timeline"
                  inputRef={register}
                />
                {item.id && (
                <Input
                  type="hidden"
                  name={`timeline.${item.id}.id`}
                  defaultValue={item.id}
                  inputRef={register}
                />
                )}

              </Box>
            ))}
            <Box>
              <ButtonApp
                text="Добавить timeline"
                color="primary"
                variant="contained"
                onClick={() => handleAddTimeline()}
                size="large"
              />
            </Box>
          </Box>
          <Box>
            <ButtonApp
              text={translations.safeChanges}
              color="primary"
              variant="contained"
              type="submit"
              size="large"
            />
          </Box>
        </form>
      ) : (
        <Box>
          Введите webhook url в блоке &quot;Основные данные&ldquo;
        </Box>
      )}

    </Box>
  );
};
