import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';

import { Actions as SpinnerActions } from 'src/store/spinner/actions';
import { getSelectedFloor, getSelectedHouse, getFromComplex } from 'src/store/floors/selectors';
import { getComplexId } from 'src/store/app/selectors';
import { requestSelectors } from 'src/store/requests/selectors';
import { ISpinnerDataPossibilityInnerData } from 'src/typings/spinner';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { useGetFilteredIds } from 'src/components/ViewSpinner/components/RenderSvg/hooks';

export const useHouseSpinner = () => {
  const { filteredIds } = useGetFilteredIds();
  const selectedFloorIds = useSelector(getSelectedFloor);
  const filterAllData = useSelector(requestSelectors.spinner.getSpinnerDetailInfo)?.placement;
  const selectedHouse = useSelector(getSelectedHouse);
  const allSpinners = useSelector(requestSelectors.spinner.getSpinnerData);
  const complexId = useSelector(getComplexId);
  const fromComplex: boolean = useSelector(getFromComplex);
  const dispatch = useDispatch();
  const [spinnerData, setSpinnerData] = useState<ISpinnerDataPossibilityInnerData | undefined>();
  const [floorData, setFloorData] = useState<string[]>([]);
  const [combineFilterIds, setCombineFilteredIds] = useState<string[]>([]);

  useEffect(() => {
    if (selectedHouse) {
      if (fromComplex && complexId) {
        setSpinnerData(
          allSpinners
            ?.spinners.complex[complexId],
        );
        dispatch(SpinnerActions.setAppCurrentSpinner({ name: 'complex', 'name-id': complexId }));
      } else {
        setSpinnerData(
          allSpinners
            ?.spinners[selectedHouse?.name][selectedHouse?.['name-id']],
        );
        dispatch(SpinnerActions.setAppCurrentSpinner({ name: 'house', 'name-id': selectedHouse?.['name-id'] }));
      }
    }
  }, [allSpinners, selectedHouse]);

  useEffect(() => {
    if (selectedFloorIds !== null) {
      const filterFloor = _.pickBy(filterAllData, (obj: IFilterAllData) => {
        return obj[ListOfKeysFilterAllData.floorIds].includes(selectedFloorIds);
      });

      setFloorData(Object.keys(filterFloor));
    }
  }, [selectedFloorIds, filterAllData]);

  useEffect(() => {
    if (floorData) {
      setCombineFilteredIds([
        // ...filteredIds.placement,
        ...floorData,
      ]);
    }
  }, [floorData]);

  return {
    filteredIds,
    combineFilterIds,
    spinnerData,
    type: fromComplex ? 'complex' : 'house',
  };
};
