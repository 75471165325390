import { useEffect, useState } from 'react';

import { SectionView2dData } from 'src/store/view-2d/reducer';

export const useSplitHouseToSections = (houseData: SectionView2dData) => {
  const [sectionKey, setSectionKey] = useState<string[]>([]);

  useEffect(() => {
    setSectionKey(Object.keys(houseData));
  }, [houseData]);

  return {
    sectionKey,
  };
};
