import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { Actions } from 'src/store/app/actions';
import Box from '@mui/material/Box';
import {
  useBuildType,
} from 'src/hooks';
import { getFilterInitialData } from 'src/store/requests/filter/selector';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';
import { requestSelectors } from 'src/store/requests/selectors';
import { LayoutApartmentItem } from 'src/components/LayoutApartmentItem';
import { ButtonApp } from 'src/components/Button';
import { ApartmentConnectDeal } from 'src/components/ApartmentConnectDeal';
import { useTranslations } from 'src/hooks/translataions';
import { Actions as FloorsActions } from 'src/store/floors/actions';
import { getComplexId, getSelectedAppartment, getIsOpenAppartSidebar } from 'src/store/app/selectors';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';

export const AppartmentSidebar = () => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);
  const { isCrossTable } = useBuildType();
  const dispatch = useDispatch();
  const { getTranslations } = useTranslations();
  const complexIdState = useSelector(getComplexId);
  const filteredData = useSelector(getFilterInitialData);
  const selectedAppartment = useSelector(getSelectedAppartment);
  const typeOfProperty: any = useSelector(getSelectedPlacement);
  const isOpenAppartSidebar = useSelector(getIsOpenAppartSidebar);

  if (!selectedAppartment || !filteredData || !filteredData[typeOfProperty].filter_all_data[selectedAppartment]) {
    return null;
  }
  const { filter_all_data: filterAllData } = filteredData[typeOfProperty];

  const handleCloseSidebar = () => {
    dispatch(Actions.setIsOpenAppartSidebar(false));
    dispatch(FloorsActions.setSelectedApartmentId(undefined));
  };

  return (
    <div className={`${styles.sidebarWrapper} ${
      isOpenAppartSidebar ? styles.wrapperOpen : styles.wrapperClose
    }`}
    >
      <div className={styles.popoverContent}>
        <Box>
          <IconButton
            onClick={handleCloseSidebar}
            aria-label="close"
            size="large"
          >
            <ArrowForwardIcon fontSize="medium" />
          </IconButton>
        </Box>
        <LayoutApartmentItem
          withSizeLimits
          wrapperStyle={styles.wrapperLayoutStyles}
          property={filterAllData[selectedAppartment]}
        />
        <Link
          to={
            isCrossTable
              ? `/${complexIdState}/own/${filterAllData[selectedAppartment].id}`
              : `/own/${filterAllData[selectedAppartment].id}`
          }
          onClick={handleCloseSidebar}
        >
          <ButtonApp
            fullWidth
            text={getTranslations(locals.appTranslations.appMore)}
            variant="contained"
            className={styles.moreInfoLink}
          />
        </Link>
        {isOpenAppartSidebar && <ApartmentConnectDeal placeInfo={filterAllData[selectedAppartment]} isSmall />}
      </div>
    </div>
  );
};
