import { useEffect, useState } from 'react';

import { IRangeAndSetData } from 'src/typings/filter';
import { useTranslations } from 'src/hooks/translataions';

export interface ICurrentData {
  key: any;
  text: any;
  value: any;
}

export const useConvertToFormat = <T>(data: any) => {
  const { getTranslations } = useTranslations();
  const [currentData, setCurrentData] = useState<ICurrentData[]>(data?.map((item: IRangeAndSetData | any) => {
    return {
      key: item?.key || item,
      text: item?.text || item,
      value: item?.value || item,
    };
  }));

  useEffect(() => {
    setCurrentData(data?.map((item: IRangeAndSetData | any) => {
      return {
        key: item?.key || item,
        text: item?.text || getTranslations(item),
        value: item?.value || item,
      };
    }));
  }, [data, getTranslations]);

  return {
    currentData: currentData || [],
  };
};
