export enum FormTypes {
  text = 'text',
  phone = 'phone',
  email = 'email',
  textarea = 'textarea',
  hidden = 'hidden',
}

export enum FormFields {
  bio = 'bio',
  phone = 'phoneNumber',
  email = 'email',
  url = 'url',
  googleClientId = 'googleClientId',
  clientIp = 'clientIp',
  source = 'source',
  utm = 'utm',
  utmSource = 'utm_source',
  utmMedium = 'utm_medium',
  utmCampaign = 'utm_campaign',
  utmTerm = 'utm_term',
  utmContent = 'utm_content',
  complexId = 'complexId',
  placementAllocation = 'placementAllocation',
  additionalInfo = 'additionalInfo',
  comment = 'comment',
  hidden = 'hidden',
}
