import { useDispatch } from 'react-redux';
import { Actions } from 'src/store/spinner/actions';

export const useSetCurrentSpinner = () => {
  const dispatch = useDispatch();

  const handleSetCurrentSpinner = (name: string, nameId: any) => {
    dispatch(Actions.setCurrentSpinner({
      name,
      'name-id': nameId,
    }));
  };

  return {
    handleSetCurrentSpinner,
  };
};
