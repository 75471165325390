import { action } from 'src/store/common';

import { IRouteList } from 'src/route-list';
import { IMessage } from 'src/typings/app';
import { IFilterAllData } from 'src/typings/filter';

export const ActionTypes = {
  SET_CRUMBS: '[APP]SET_CRUMBS',
  SET_AVAILABLE_LANGUAGES: '[APP]SET_AVAILABLE_LANGUAGES',
  SET_CURRENT_LANG: '[APP]SET_CURRENT_LANG',
  IS_BOOK_NOW_OPEN: '[APP]IS_BOOK_NOW_OPEN',
  IS_MENU_OPEN: '[APP]IS_MENU_OPEN',
  JWT_TOKEN: '[APP]JWT_TOKEN',
  IS_FILTER_OPEN: '[APP]IS_FILTER_OPEN',
  IS_MOBILE_FILTER_OPEN: '[APP]IS_MOBILE_FILTER_OPEN',
  IS_SHOW_NESTED_FILTER: '[APP]IS_SHOW_NESTED_FILTER',
  IS_END_DRAGGABLE_MOBILE_MENU: '[APP]IS_END_DRAGGABLE_MOBILE_MENU',
  SHOW_INFRASTRUCTURE: '[APP]SHOW_INFRASTRUCTURE',
  SET_FAVORITE_COUNT: '[APP]SET_FAVORITE_COUNT',
  SET_SPINNER_DISABLED_FILTER: '[APP]SET_SPINNER_DISABLED_FILTER',
  SET_COMPLEX_ID: '[APP]SET_COMPLEX_ID',
  SET_AMOCRM_DATA: '[APP]SET_AMOCRM_DATA',
  SET_BINDING_APPARTMENT: '[APP]SET_BINDING_APPARTMENT',
  SELECT_APPARTMENT: '[APP]SELECT_APPARTMENT',
  IS_OPEN_APPART_SIDEBAR: '[APP]IS_OPEN_APPART_SIDEBAR',
  SET_CURRENT_ENTITY: '[APP]SET_CURRENT_ENTITY',
};

export const Actions = {
  isBookModalOpen: action<boolean>(ActionTypes.IS_BOOK_NOW_OPEN),
  isMenuOpen: action<boolean>(ActionTypes.IS_MENU_OPEN),
  jwtToken: action<string>(ActionTypes.JWT_TOKEN),
  isFilterOpen: action<boolean>(ActionTypes.IS_FILTER_OPEN),
  isMobileFilterOpen: action<boolean>(ActionTypes.IS_MOBILE_FILTER_OPEN),
  isShowNestedFilter: action<boolean>(ActionTypes.IS_SHOW_NESTED_FILTER),
  isEndDraggableMobileMenu: action<boolean>(ActionTypes.IS_END_DRAGGABLE_MOBILE_MENU),
  showInfrastructure: action<boolean>(ActionTypes.SHOW_INFRASTRUCTURE),
  setSpinnerDisabledFilter: action<boolean>(ActionTypes.SET_SPINNER_DISABLED_FILTER),
  setFavoriteCount: action<number>(ActionTypes.SET_FAVORITE_COUNT),
  setCrumbs: action<IRouteList[]>(ActionTypes.SET_CRUMBS),
  setAvailableLanguages: action<string[]>(ActionTypes.SET_AVAILABLE_LANGUAGES),
  setCurrentLang: action<string>(ActionTypes.SET_CURRENT_LANG),
  setComplexId: action<number|string|null>(ActionTypes.SET_COMPLEX_ID),
  setCrmData: action<IMessage>(ActionTypes.SET_AMOCRM_DATA),
  setBindingAppartment: action<boolean|null>(ActionTypes.SET_BINDING_APPARTMENT),
  selectAppartment: action<string|null>(ActionTypes.SELECT_APPARTMENT),
  setIsOpenAppartSidebar: action<boolean>(ActionTypes.IS_OPEN_APPART_SIDEBAR),
  setCurrentEntity: action(ActionTypes.SET_CURRENT_ENTITY),
};
