import React from 'react';

import { DropdownSingle } from 'src/components/dropdowns/DropdownSingle';
import { useConvertToFormat } from 'src/components/dropdowns/hooks';
import { IDropdownGenerator } from 'src/components/dropdowns/types';
import { useTranslations } from 'src/hooks/translataions';
import { useGetFilterParams, useSetFilterParams } from 'src/hooks';

interface IDropdownSingleFilter extends IDropdownGenerator {}

export const DropdownSingleFilter: React.FC<IDropdownSingleFilter> = ({
  dropdownInfo,
}) => {
  const { getFixedTranslations } = useTranslations(dropdownInfo.attribute);
  console.log('🚀 ~ file: index.tsx:16 ~ dropdownInfo.data:', dropdownInfo.data);
  const { currentData } = useConvertToFormat(dropdownInfo.data);
  const { filterParams } = useGetFilterParams();
  const { handleWriteToRedux } = useSetFilterParams();

  return (
    <DropdownSingle
      disabled={!currentData || currentData.length === 0 || filterParams[dropdownInfo.attribute]?.isDisabled}
      text={`${getFixedTranslations()} ${filterParams[dropdownInfo.attribute]?.data}`}
      placeholder={getFixedTranslations()}
      options={currentData}
      value={filterParams[dropdownInfo.attribute]?.data}
      onChange={(event, data) => handleWriteToRedux(dropdownInfo.attribute, data.value)}
    />
  );
};
