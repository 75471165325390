import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

interface IQueryState {
  [key: string]: any;
}

export const useGetQueryParams = (
  listParams: string[],
  withUpdateState?: boolean,
) => {
  const history = useHistory();

  const [listQueries, setListQueries] = useState<IQueryState>(listParams.reduce((acc, cur) => {
    const params = new URLSearchParams(history.location.search);

    return {
      ...acc,
      [cur]: params.get(cur),
    };
  }, {}));

  useEffect(() => {
    if (withUpdateState) {
      const params = new URLSearchParams(history.location.search);
      const newListQueries: IQueryState = {};

      listParams.forEach((query) => {
        newListQueries[query] = params.get(query);
      });

      setListQueries(newListQueries);
    }
  }, [history.location.search]);

  return {
    listQueries,
  };
};
