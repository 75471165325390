/* eslint-disable no-nested-ternary */
import React from 'react';
import Select from 'react-select';
import { borderColor, fontBlackColor } from 'src/constants/styles';

const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor,
  }),
  dropdownIndicator: (styles: any) => ({ ...styles, display: 'none' }),
  indicatorsContainer: (styles: any) => ({ ...styles, display: 'none' }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: '16px 10px',
    lineHeight: '1em',
  }),
  option: (styles: { [x: string]: any; }, {
    data, isDisabled, isFocused, isSelected,
  }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? 'grey'
          : isFocused
            ? 'grey'
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? 'green'
          : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && ('grey'),
      },
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      margin: '0 2px',
      padding: '0 2px',
    };
  },
  multiValue: (styles: any, { isDisabled }: any) => {
    return {
      ...styles,
      margin: '0 2px',
      backgroundColor: 'transparent',
    };
  },
  multiValueLabel: (styles: any, { isDisabled }: any) => {
    return {
      ...styles,
      color: isDisabled ? '#AEAEAE' : fontBlackColor,
      padding: 0,
    };
  },
  multiValueRemove: (styles: any, { isDisabled }: any) => ({
    ...styles,
    display: isDisabled ? 'none' : 'inline-block',
    ':hover': {
      backgroundColor: 'red',
      color: 'black',
    },
  }),
};

export const Multiselect: React.FC<any> = (props) => (
  <Select
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
