import React from 'react';

import { IInfrastructureTypeVideo } from '../../types';
import { useStyles } from '../../styles';

export const InfrastructureVideo: React.FC<IInfrastructureTypeVideo> = ({
  data,
}) => {
  const styles = useStyles({});

  return (
    <div className={styles.wrapperInfrastructureType}>
      <iframe
        allowFullScreen
        frameBorder={0}
        title="video"
        src={data['infrastructure.src']}
        width="100%"
        height="400px"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  );
};
