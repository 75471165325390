import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { appIcon } from 'src/assets';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  pageBg: {
    backgroundImage: `url(${appIcon.authBG})`,
    minHeight: '100%',
  },
  infoText: {
    fontWeight: 'bold',
    fontSize: '50px',
    lineHeight: '60px',
    color: '#7051EF',
  },

}));
