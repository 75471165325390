import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBuildType } from 'src/hooks';
import { requestSelectors } from 'src/store/requests/selectors';

export const useModuleJSPath = () => {
  const moduleJSPath: string[] | null | undefined = useSelector(requestSelectors.app.getModuleJSPath);

  if (!moduleJSPath) return null;

  return moduleJSPath;
};

export const useInitJSPath = () => {
  const moduleJSPath = useModuleJSPath();
  const { isCrossTable } = useBuildType();

  useEffect(() => {
    if (moduleJSPath && !isCrossTable) {
      const nodeHead = document.querySelector('head');
      if (nodeHead) {
        moduleJSPath.forEach((path) => {
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.src = path;
          nodeHead.appendChild(s);
        });
      }
    }
  }, [moduleJSPath]);
};
