import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { IComplex } from 'src/typings/complexes';
import { UploadButton } from 'src/components/UploadButton';
import { borderColor } from 'src/constants/styles';
import { useGetAuthData } from 'src/hooks';
import {
  uploadFile, patchComplexData, patchDeveloperData,
} from 'src/api';
import { useStyles } from '../styles';

interface ILogoBlock {
  handler: React.ChangeEventHandler<HTMLInputElement>;
  logoImg: string|null;
  id: string;
}

export const ComplexImages: React.FC<IComplex> = ({
  id, previewUri, logoUri, developers,
}) => {
  const styles = useStyles({});
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [preview, setPreview] = useState(previewUri);
  const [compLogo, setCompLogo] = useState(logoUri);
  const [devLogo, setDevLogo] = useState(developers[0]?.logoUri || '');

  const handlePreview = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append('preview', event.target.files[0]);

      uploadFile(formData).then((res: any) => {
        setPreview(res.data.preview);
        patchComplexData(jwt, id, {
          previewUri: res.data.preview,
        });
      });
    }
  };

  const handleCompLogo = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append('logoUri', event.target.files[0]);

      uploadFile(formData).then((res: any) => {
        setCompLogo(res.data.logoUri);
        patchComplexData(jwt, id, {
          logoUri: res.data.logoUri,
        });
      });
    }
  };
  const handleDevLogo = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append('logo', event.target.files[0]);

      uploadFile(formData).then((res: any) => {
        setDevLogo(res.data.logo);
        patchDeveloperData(jwt, developers[0].id, {
          logoUri: res.data.logo,
        });
      });
    }
  };

  const LogoBlock: React.FC<ILogoBlock> = ({ handler, logoImg, id: imageId }) => (
    <Box
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <img
        style={{
          maxHeight: '50px',
          maxWidth: '90%',
        }}
        src={logoImg || ''}
        alt=""
      />
      <Box className={styles.logoUpload}>
        <label htmlFor={`file${imageId}`}>
          <input
            accept="image/*,.jpg,.jpeg,.png,.svg,.webp"
            style={{
              display: 'none',
            }}
            id={`file${imageId}`}
            type="file"
            onChange={handler}
          />
          <UploadButton />
        </label>
      </Box>
    </Box>
  );

  return (
    <Box
      border={`1px solid ${borderColor}`}
      borderRadius="8px"
      width={440}
      overflow="hidden"
    >
      <Box
        width="100%"
        height="365px"
        position="relative"
        style={{
          backgroundImage: `url(${preview})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Box className={styles.previewUpload}>
          <label htmlFor="uploadPreview">
            <input
              accept="image/*,.jpg,.jpeg,.png,.svg,.webp"
              style={{
                display: 'none',
              }}
              id="uploadPreview"
              type="file"
              onChange={handlePreview}
            />
            <UploadButton />
          </label>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="nowrap"
        height={90}
      >
        <LogoBlock logoImg={compLogo} handler={handleCompLogo} id="complex" />
        <Box
          width="1px"
          height="44px"
          style={{
            background: borderColor,
          }}
        />
        <LogoBlock logoImg={devLogo} handler={handleDevLogo} id="develop" />
      </Box>
    </Box>
  );
};
