import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { pathList } from 'src/route-list';

export const useHeaderRoute = () => {
  const history = useHistory();

  const handleOpenFavorite = useCallback(() => {
    history.push(pathList.favorites);
  }, [history]);

  return {
    handleOpenFavorite,
  };
};
