import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectedView } from 'src/store/type-displayed-spinner/selectors';
import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { Actions } from 'src/store/app/actions';
import { getSpinnerDisabledFilterRequest } from 'src/store/requests/app/selector';
import { SpinnerLabel } from 'src/components/ApartmentGallery/types';

export const useHideFilterZone = () => {
  const dispatch = useDispatch();
  const requestDisabledFilter = useSelector(getSpinnerDisabledFilterRequest);
  const getSelectedViewId = useSelector(getSelectedView);
  const currentSpinner = useSelector(getCurrentSpinner);

  useEffect(() => {
    if (requestDisabledFilter && currentSpinner) {
      const {
        complex_spinner_disable_filter,
        house_spinner_disable_filter,
        section_spinner_disable_filter,
        floor_spinner_disable_filter,
      } = requestDisabledFilter;
      const {
        name,
      } = currentSpinner;

      if (name === SpinnerLabel.complex) {
        dispatch(Actions.setSpinnerDisabledFilter(complex_spinner_disable_filter));
      } else if (name === SpinnerLabel.house) {
        dispatch(Actions.setSpinnerDisabledFilter(house_spinner_disable_filter));
      } else if (name === SpinnerLabel.section) {
        dispatch(Actions.setSpinnerDisabledFilter(section_spinner_disable_filter));
      } else if (name === SpinnerLabel.floor) {
        dispatch(Actions.setSpinnerDisabledFilter(floor_spinner_disable_filter));
      } else {
        dispatch(Actions.setSpinnerDisabledFilter(false));
      }
    }
  }, [
    requestDisabledFilter,
    getSelectedViewId,
    currentSpinner,
    dispatch,
  ]);
};
