import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const PURPLE = '#6C59E6';
const PURPLE_DARK = '#5a4abd';

export const useStyles = makeStyles(() => createStyles({
  wrapper: {
    background: 'white',
    padding: '40px 50px 50px 30px',
    width: '500px',
    border: '1px solid #E9E9E9',
    borderRadius: '5px',
  },
  infoText: {
    fontWeight: 'bold',
    fontSize: '50px',
    lineHeight: '60px',
    color: '#7051EF',
  },
  input: {
    '& .MuiOutlinedInput-input': {
      padding: '15px 14px',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: PURPLE,
      },
      '&.Mui-focused fieldset': {
        borderColor: PURPLE,
      },
    },
  },
  link: {
    borderBottom: '1px solid #BEB4FE',
    transition: '0.2s all',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      borderColor: 'transparent',
      color: '#BEB4FE',
    },
  },
  linkDisabled: {
    color: '#A8A6B2',
    borderColor: '#A8A6B2',
    '&:hover': {
      color: '#A8A6B2',
      borderColor: '#A8A6B2',
      cursor: 'default',
    },
  },
  submit: {
    background: PURPLE,
    color: 'white',
    width: '100%',
    padding: '10px 20px',
    textAlign: 'center',
    borderRadius: '5px',
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: 300,
    textTransform: 'unset',
    '&:hover': {
      background: PURPLE_DARK,
    },
  },
  socialBtn: {
    fontSize: '16px',
    padding: '10px 20px',
    marginBottom: '15px',
  },
  error: {
    position: 'absolute',
    bottom: '-15px',
    fontSize: '11px',
    color: 'red',
  },
}));
