import React, { ReactNode } from 'react';

import { AppModal } from 'src/components/AppModal';
import { useStyles } from './styles';

interface IInfrastructureModal {
  isOpen: boolean;
  toggleClose: () => any;
  contentHeader?: ReactNode;
}

export const InfrastructureModal: React.FC<IInfrastructureModal> = ({
  contentHeader,
  isOpen,
  toggleClose,
  children,
}) => {
  const styles = useStyles({});

  return (
    <AppModal
      open={isOpen}
      toggleClose={toggleClose}
      listClassName={{
        wrapperModal: styles.wrapperModal,
        modalWindow: styles.modalWindow,
        wrapperContent: styles.wrapperContent,
      }}
      contentHeader={contentHeader || <div />}
    >
      <>
        {children}
      </>
    </AppModal>
  );
};
