import { IDogeFields } from 'src/typings/global-config';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface IPlacementType extends IDogeFields {
  id: number,
  title: {
      [key: string]: string,
  },
  moduleViewTypes: TypesOfSpinner[],
  crosstableViewTypes: TypesOfSpinner[],
  isShowInModule: boolean,
  isShowInCrosstable: boolean,
}

export interface ITypeDisplayedSpinner {
  listOfTypes: IPlacementType[],
  selectedView: string;
}

export const initialState: ITypeDisplayedSpinner = {
  listOfTypes: [],
  selectedView: '',
};

export const typeDisplayedSpinnerReducer = (
  state = initialState,
  { type, payload }: IAction,
): ITypeDisplayedSpinner => {
  switch (type) {
    case ActionTypes.SET_TYPE_DISPLAYED_SPINNER:
      return {
        ...state,
        selectedView: payload,
      };
    case ActionTypes.SET_LIST_TYPES_SUCCESS:
      return {
        ...state,
        listOfTypes: payload,
      };

    default:
      return state;
  }
};
