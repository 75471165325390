/* eslint-disable max-len */
import React from 'react';

import { useStyles } from '../styles';

export const FloorsImage = () => {
  const styles = useStyles({});

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="appStrokeStyles" d="M12 4L4 8L12 12L20 8L12 4Z" stroke="#253644" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path className="appStrokeStyles" d="M4 12L12 16L20 12" stroke="#253644" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path className="appStrokeStyles" d="M4 16L12 20L20 16" stroke="#253644" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
