import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useStyles } from './styles';

export interface ICrumbs {
  name: string;
  url?: string;
}

export interface IBreadcrumbs {
  crumbs: ICrumbs[]
}

export const Breadcrumbs: React.FC<IBreadcrumbs> = ({ crumbs }) => {
  const styles = useStyles({});
  return (
    <Box fontSize="14px" display="flex">
      {crumbs.map((crumb) => {
        if (crumb.url) {
          return (
            <Box key={crumb.name} mr={1} display="flex" alignItems="center">
              <Link
                to={crumb.url}
                className={styles.link}
              >
                {crumb.name}
              </Link>
              <Box ml={1}>/</Box>
            </Box>
          );
        }
        return (
          <Box key={crumb.name}>
            {crumb.name}
          </Box>
        );
      })}
    </Box>
  );
};
