import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperProperty: (props: any) => ({
    top: '0px',
    cursor: 'pointer',
    borderRadius: '10px',
    background: props.filterBackgroundColor,
    transition: 'all 0.2s',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        // top: '-5px',
        '-webkit-box-shadow': `0px 0px 5px 0px ${theme.palette.primary.main}`,
        '-moz-box-shadow': `0px 0px 5px 0px ${theme.palette.primary.main}`,
        'box-shadow': `0px 0px 5px 0px ${theme.palette.primary.main}`,
      },
    },
    '& > a': {
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
      },
    },
  }),
  wrapperImageProperty: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageSizeLimits: {
    height: '160px',
    maxHeight: '160px',
  },
  labelAddToFav: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px 10px 0px 0px',
  },
  wrapperApartmentInfo: {
    padding: '10px',
  },
  apartmentHeaderInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  closeButtonPopover: {
    cursor: 'pointer',
    // border: `1px solid ${theme.palette.grey['300']}`,
    // background: 'white',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    zIndex: 2,
    position: 'absolute',
    left: '0px',
    top: '0px',
    padding: '5px',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '17px',
    },
  },
  disableLink: {
    '&:hover': {
      cursor: 'normal',
      boxShadow: 'none',
    },
  },
}));
