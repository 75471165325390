import { IUsers } from 'src/typings/user';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface IUserRequestReducer {
  isLoading: boolean;
  data: IUsers|undefined;
}

export const initialState: IUserRequestReducer = {
  isLoading: true,
  data: undefined,
};

export const userRequestReducer = (
  state = initialState,
  { type, payload }: IAction,
): IUserRequestReducer => {
  switch (type) {
    case ActionTypes.GET_USER_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.GET_USER_DATA_REQUEST_SUCCESS: {
      return {
        data: { ...payload },
        isLoading: false,
      };
    }

    case ActionTypes.GET_USER_DATA_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
