/* eslint-disable max-len */
import React from 'react';

import { useStyles } from '../styles';

export const LayoutsImage = () => {
  const styles = useStyles({});

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="appStrokeStyles"
        d="M4 5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V5Z"
        stroke="#253644"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className="appStrokeStyles" d="M4 12L12 12" stroke="#253644" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path className="appStrokeStyles" d="M12 12V20" stroke="#253644" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
