import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';
import { PlacementDisplayedType } from 'src/typings/filter';
import { useGetTypePlacementById } from 'src/hooks/index';

export const useFilterConfigurator = (
  placementDisplayedType: PlacementDisplayedType,
  searchId: string | string[],
) => {
  const { placementType } = useGetTypePlacementById(searchId);
  const paddingTileSize = useSelector(requestSelectors.app.getTileImgParentPadding);
  const filterConfigurator = useSelector(requestSelectors.filter.getFilterInitialAllData);

  return {
    filterConfigurator:
      filterConfigurator
      && placementType
      && filterConfigurator?.[placementType]?.['placement-info-config']?.[placementDisplayedType],
    paddingTileSize,
  };
};
