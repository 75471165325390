import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  mobileBottomMenuWrapper: (props: any) => ({
    backgroundColor: `${props.filterBackgroundColor}e6`,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,
    '& > div': {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '7px 10px',
      overflow: 'hidden',
      '& > img': {
        marginRight: '0px',
        width: '24px',
      },
    },
  }),
}));
