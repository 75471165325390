import React, { useRef, useEffect } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import { IFloorsState } from 'src/components/ViewFloors/components/Floors/types';
import { useStyles } from './styles';
import { useFloorSlider } from './useFloorSlider';
import { useFloorScrollbar } from './useFloorScrollbar';

interface IDesktopFloorList {
  selectedFloorId: string | null;
  renderFloors: IFloorsState[];
  paramsName: string;
  handleChangeFloor: (
    name: string,
    nameId: any,
    floorNumber: string,
  ) => void;
}

export const DesktopFloorList: React.FC<IDesktopFloorList> = React.memo(({
  selectedFloorId,
  renderFloors,
  paramsName,
  handleChangeFloor,
}) => {
  const styles = useStyles({});
  const refScrollBar = useRef<HTMLDivElement>(null);
  const { handleBindGesture, handleScrollToActiveElement } = useFloorScrollbar(refScrollBar);
  const {
    filteredFloorsIds,
    availableFloors,
    nextFloor,
    previousFloor,
    setCurrentArrayIndex,
  } = useFloorSlider(
    renderFloors,
    selectedFloorId,
  );

  useEffect(() => {
    handleScrollToActiveElement();
  }, [availableFloors]);

  return (
    <div className={styles.containerDesktopFloorsList}>
      <Box
        className={`${styles.arrowStyle} ${styles.arrowStyleUp} ${styles.floorItem} ${
          !nextFloor ? styles.arrowDisable : ''
        }`}
        onClick={() => {
          if (nextFloor) {
            handleChangeFloor(paramsName, nextFloor.floorId, nextFloor.floor);
            handleScrollToActiveElement();
          }
        }}
      >
        <ArrowDropUpIcon />
      </Box>
      <div
        {...handleBindGesture()}
        ref={refScrollBar}
        className={styles.wrapperFloorList}
      >
        {
        availableFloors
          .map((floorData, index) => {
            const isSelected: boolean = selectedFloorId === floorData.floorId;
            const isContainInFilteredArray: boolean = filteredFloorsIds.includes(floorData.floorId);

            return (
              <Box
                key={floorData.floorId}
                data-floor-id={`${floorData.floorId}`}
                className={`${styles.floorItem} ${
                  isSelected ? `${styles.activeFloor} activeFloor` : ''} ${
                  // eslint-disable-next-line no-nested-ternary
                  isContainInFilteredArray
                    ? styles.containFilteredElement
                    : filteredFloorsIds.length === 0
                      ? ''
                      : styles.notContainFilteredElement
                }`}
                onClick={() => {
                  setCurrentArrayIndex(index);
                  handleChangeFloor(paramsName, floorData.floorId, floorData.floor);
                }}
              >
                <span>{floorData.floor}</span>
              </Box>
            );
          })
      }
      </div>
      <Box
        className={`${styles.arrowStyle} ${styles.arrowStyleDown} ${styles.floorItem} ${
          !previousFloor ? styles.arrowDisable : ''
        }`}
        onClick={() => {
          if (previousFloor) {
            handleChangeFloor(paramsName, previousFloor.floorId, previousFloor.floor);
            handleScrollToActiveElement();
          }
        }}
      >
        <ArrowDropDownIcon />
      </Box>
    </div>
  );
});
