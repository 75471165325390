import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IFloorsState } from 'src/components/ViewFloors/components/Floors/types';
import { getFilteredFloorsIdsData } from 'src/store/floors/selectors';

export const useFloorSlider = (
  renderFloors: IFloorsState[],
  selectedFloorId: string | null,
) => {
  const filteredFloorsIds: string[] = useSelector(getFilteredFloorsIdsData);
  const [availableFloors, setAvailableFloors] = useState<IFloorsState[]>([]);
  const [nextFloor, setNextFloor] = useState<IFloorsState | undefined>();
  const [previousFloor, setPreviousFloor] = useState<IFloorsState | undefined>();
  const [currentArrayIndex, setCurrentArrayIndex] = useState<number>(0);

  useEffect(() => {
    const generateFloors = renderFloors
      .sort((a: IFloorsState, b: IFloorsState) => parseInt(a.floor, 10) - parseInt(b.floor, 10))
      .reverse();

    setAvailableFloors(generateFloors);
  }, [renderFloors]);

  useEffect(() => {
    if (selectedFloorId) {
      availableFloors.forEach((item, index) => {
        if (item.floorId === selectedFloorId) {
          setCurrentArrayIndex(index);
        }
      });
    }
  }, [availableFloors, selectedFloorId]);

  useEffect(() => {
    const nextFloorItem = availableFloors[currentArrayIndex - 1];
    const previousFloorItem = availableFloors[currentArrayIndex + 1];

    setNextFloor(nextFloorItem);
    setPreviousFloor(previousFloorItem);
  }, [availableFloors, currentArrayIndex]);

  return {
    filteredFloorsIds,
    currentArrayIndex,
    availableFloors,
    nextFloor,
    previousFloor,
    setCurrentArrayIndex,
  };
};
