import React from 'react';
import { useSelector } from 'react-redux';
import { range } from 'lodash';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';
import { getFloorItemType } from 'src/store/view-2d/selectors';
import { useOpenFilter } from 'src/hooks/app';
import { EFloorType } from 'src/store/view-2d/reducer';
import { CrossTableContent } from 'src/components/CrosstableContent';
import { AppartmentSidebar } from 'src/components/CrosstableContent/components/AppartmentSidebar';
import { TypeView2d } from 'src/components/TypeView2d';
import { SelectFloorItemType } from 'src/components/SelectFloorItemType';
import { ZoomTable } from 'src/components/CrosstableContent/components/ZoomTable';
import {
  useTranslations, useGetComplexStatuses, useBuildType, useLanguageState,
} from 'src/hooks';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';
import { useControlCrossTable, useCrossTableState } from './hooks';

export const CrossTableContainer: React.FC = () => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const floorItemType = useSelector(getFloorItemType);
  const { isCrossTable } = useBuildType();
  const { currentAppLanguage } = useLanguageState();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const {
    refCrossTable,
    measuredRef,
  } = useControlCrossTable();
  const { isOpen } = useOpenFilter();
  const { statuses, isLoading } = useGetComplexStatuses();
  const {
    floorList,
    view2dData,
  } = useCrossTableState();

  if (view2dData === null) {
    return (
      <div className={styles.noDisplayData}>
        <p>{getTranslations(locals.appTranslations.appNoDataToDisplay)}</p>
        <p>{getTranslations(locals.appTranslations.appPleaseStartFiltering)}</p>
      </div>
    );
  }

  return (
    <div className={`${styles.crossTableContainer} ${!isOpen && styles.closedFilters}`}>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={isCrossTable ? 4 : 0}
        pr={6}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        alignItems="center"
      >
        <Box display="flex" flexDirection="column" height="55px" justifyContent="center">
          {isCrossTable && (
          <Box py={1}>
            <SelectFloorItemType />
          </Box>
          )}
          {floorItemType === EFloorType.mini && (
          <Box py={1}>
            <TypeView2d />
          </Box>
          )}
        </Box>
        {floorItemType === EFloorType.mini && (
        <Box width="150px">
          <ZoomTable />
        </Box>
        )}
      </Box>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="flex-end"
          pr={6}
          flexWrap="wrap"
          my={1}
        >
          {range(4).map((item) => (
            <Box key={item} display="flex" mr={2}>
              <Skeleton variant="rectangular" width={20} height={20} />
              <Box ml={1} width="50px">
                <Skeleton variant="text" />
              </Box>
            </Box>
          ))}

        </Box>
      )}
      {!!statuses.length && (
      <Box
        display="flex"
        justifyContent="flex-end"
        pr={6}
        flexWrap="wrap"
        mt={1}
      >

        {statuses.map((status) => (
          <Box key={status.id} display="flex" mb={1} mr={2}>
            <Box
              width="20px"
              height="20px"
              borderRadius="4px"
              mr={1}
              bgcolor={isCrossTable ? (status.crosstableColor || status.color) : status.color}
            />
            <Box>
              {isCrossTable
                ? (status.crosstable_name && status.crosstable_name[currentAppLanguage])
                : (status.module_name && status.module_name[currentAppLanguage])}
            </Box>
          </Box>
        ))}
      </Box>
      )}
      <div
        ref={measuredRef}
        className={styles.crossTable}
        // style={{ maxHeight: crossTableHeight || 'none', overflow: crossTableHeight ? 'unset' : 'hidden' }}
      >
        {
          refCrossTable
          && Boolean(floorList.length)
          && view2dData !== null
          && (
            <>
              <CrossTableContent
                view2dData={view2dData}
                floorList={floorList}
              />
            </>
          )
        }
        <AppartmentSidebar />
      </div>
    </div>
  );
};
