import { useEffect, useState } from 'react';
import _ from 'lodash';

import {
  IFilterAllData,
  IFilterPlacementInfoConfig,
  IPlacementExplications,
  ListOfKeysFilterAllData,
  PlacementDisplayedType,
} from 'src/typings/filter';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { useFilterConfigurator } from 'src/hooks/filters';

export interface ISchemaTabs {
  tabTitle: string,
  tabData: IFilterAllData
    | IPlacementExplications[]
    | any,
}

export const useDescriptionState = (data: IFilterAllData, isSingleId: boolean) => {
  const [transformState, setTransformState] = useState<ISchemaTabs[]>([]);
  const { filterConfigurator }: {
    filterConfigurator: IFilterPlacementInfoConfig | any
  } = useFilterConfigurator(
    isSingleId
      ? PlacementDisplayedType.placement
      : PlacementDisplayedType.placementGroupedInfoConfig,
    data[ListOfKeysFilterAllData.id],
  );
  const { getTranslations } = useTranslations();

  useEffect(() => {
    if (filterConfigurator) {
      const generateTabs: ISchemaTabs[] = [];

      const exceptionList: string[] = Object.keys(filterConfigurator).filter((item) => {
        if (!filterConfigurator[item].use) {
          return item;
        }

        return null;
      });

      const dataForDescription = _.omit(data || {}, [
        ...exceptionList,
        ListOfKeysFilterAllData.houseId,
        ListOfKeysFilterAllData.floorIds,
        ListOfKeysFilterAllData.section,
        ListOfKeysFilterAllData.sectionId,
        ListOfKeysFilterAllData.mainFilterImg,
        ListOfKeysFilterAllData.placementGallery,
        ListOfKeysFilterAllData.placementExplications,
        ListOfKeysFilterAllData.placementStatusShowInModule,
      ]);

      if (!_.isEmpty(dataForDescription)) {
        generateTabs.push({
          tabTitle: getTranslations(locals.appTranslations.appCharacteristics),
          tabData: dataForDescription,
        });
      }

      if (!_.isEmpty(data['placement.explications'])) {
        generateTabs.push({
          tabTitle: getTranslations(locals.appTranslations.appExplication),
          tabData: data['placement.explications'],
        });
      }

      setTransformState(generateTabs);
    }
  }, [getTranslations, filterConfigurator, data]);

  return {
    transformState,
  };
};
