import { call, put, takeEvery } from 'redux-saga/effects';
import { Actions as ActionsView2d } from 'src/store/view-2d/actions';
import { ActionTypes } from './actions';

function* getCrosstableRequestSaga() {
  yield put(ActionsView2d.clearView2d());
}

export function* getCrosstableWatchSaga() {
  yield takeEvery([
    ActionTypes.SET_COMPLEX,
  ], getCrosstableRequestSaga);
}
