/* eslint-disable max-len */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { jsPDF } from 'jspdf';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CircularProgress from '@mui/material/CircularProgress';
import { getComplexId, getCurrentLang } from 'src/store/app/selectors';
import { useBuildType } from 'src/hooks';

import { useStyles } from '../styles';
import { insertHtmlForPdf } from './insertHtmlForPdf';

function numberWithCommas(x: string) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

// @ts-ignore
export const CreatePdf = ({ id }) => {
  const styles = useStyles({});
  const { isCrossTable } = useBuildType();
  const complexIdState = useSelector(getComplexId);
  const currentLang = useSelector(getCurrentLang);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    insertHtmlForPdf();
  }, []);

  function convertToPNG(src: any, color: any) {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        let { clientWidth, clientHeight } = src;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (canvas) {
          clientWidth *= 4;
          clientHeight *= 4;

          canvas.width = clientWidth;
          canvas.height = clientHeight;
          // @ts-ignore
          context.fillStyle = color; // <- background color
          // draw background / rect on entire canvas
          // @ts-ignore
          context.fillRect(0, 0, canvas.width, canvas.height);
          // @ts-ignore
          context.drawImage(img, 0, 0, clientWidth, clientHeight);

          src.onload = resolve;

          src.src = canvas.toDataURL('image/jpeg', 1.0);
        }
      };

      img.crossOrigin = 'Anonymous';
      img.src = src.src;
    });
  }

  function getUrlExtension(url: string) {
    // @ts-ignore
    return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
  }

  function convertAllSVGToPNG(callback: any) {
    const promises: any[] = [];
    const tempPdf = document.querySelector('#tempPDF');
    if (tempPdf) {
      // @ts-ignore
      tempPdf.querySelectorAll('img').forEach((img) => promises.push(convertToPNG(img, img.closest('#pdf').style.backgroundColor)));
    }
    callback && Promise.all(promises).then(callback);
  }

  function generatePDF() {
    // @ts-ignore
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.addFont('https://dev1.flat.show/web/js/pdfAssets/fonts/DejaVuSans.ttf', 'DejaVuSans', 'normal');
    doc.addFont('https://dev1.flat.show/web/js/pdfAssets/fonts/DejaVuSans-Bold.ttf', 'DejaVuSans-Bold', 'normal');
    doc.setFont('DejaVuSans'); // set font
    doc.setFont('DejaVuSans-Bold'); // set font
    const count = document.querySelectorAll('#pdf').length;

    const tempPdfEl = document.querySelector('#tempPDF');
    if (tempPdfEl) {
      doc.html(tempPdfEl as HTMLElement, {
        callback() {
          doc.deletePage(count + 1);
          doc.save('Apartment_Passport.pdf');
          setloading(false);
          const tempPdf = document.getElementById('tempPDF');
          if (tempPdf) {
            tempPdf.innerHTML = '';
          }
        },
        margin: [0, 0, 0, 0],
        x: 0,
        y: 0,
      });
    }
  }

  function rotate(srcBase64: string, degrees: number, color: any, callback: ((arg0: string) => void) | undefined) {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      image.onload = function () {
        canvas.width = degrees % 180 === 0 ? image.width : image.height;
        canvas.height = degrees % 180 === 0 ? image.height : image.width;

        if (getUrlExtension(image.src) === 'svg') {
          canvas.width *= 4;
          canvas.height *= 4;
          image.width *= 4;
          image.height *= 4;
        }
        if (canvas.width > canvas.height) canvas.height = canvas.width;
        if (canvas.height > canvas.width) canvas.width = canvas.height;
        if (ctx) {
          ctx.fillStyle = color;
          // draw background / rect on entire canvas
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.translate(canvas.width / 2, canvas.height / 2);
          if (degrees !== 0) {
            ctx.rotate(degrees * (Math.PI / 180));
          }
          ctx.drawImage(image, canvas.width / -2 + 36, canvas.height / -2 + 36, image.width - 72, image.height - 72);
        }

        // @ts-ignore
        callback(canvas.toDataURL('image/jpeg', 1.0));
        // @ts-ignore
        resolve();
      };
      image.crossOrigin = 'Anonymous';
      image.src = srcBase64;
    });
  }

  const combine = (imgSource: string, overlay: any, color: any) => {
    return new Promise(((resolve) => {
      const img = new Image();
      img.onload = function () { resolve(img); };
      img.crossOrigin = 'Anonymous';
      img.src = imgSource;
    })).then((image: any) => {
      const clientWidth = image.naturalWidth;
      const clientHeight = image.naturalHeight;
      const canvas = document.createElement('canvas');

      canvas.width = clientWidth;
      canvas.height = clientHeight;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = color; // <- background color
        // draw background / rect on entire canvas
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, clientWidth, clientHeight);
        if (overlay.pointsArray) {
          overlay.pointsArray.forEach((point: string) => {
            const pointsArray = point.split(' ');
            ctx.moveTo(Number(pointsArray[0]), Number(pointsArray[1]));
            for (let item = 2; item < pointsArray.length - 1; item += 2) {
              ctx.lineTo(Number(pointsArray[item]), Number(pointsArray[item + 1]));
            }
          });
        } else {
          const pointsArray = overlay.points.split(' ');
          ctx.moveTo(pointsArray[0], pointsArray[1]);
          for (let item = 2; item < pointsArray.length - 1; item += 2) {
            ctx.lineTo(pointsArray[item], pointsArray[item + 1]);
          }
        }
        ctx.fillStyle = `${overlay.fill}B3`;
        ctx.fill();
        ctx.strokeStyle = overlay.stroke;
        ctx.stroke();
        ctx.closePath();
        // ctx.drawImage(svg, 0, 0, clientWidth, clientHeight);
      }

      // return the resulting image in base64 form
      return canvas.toDataURL('image/jpeg');
    });
  };

  const handlerIcon = () => {
    const href = `https://dev1.flat.show/client/module/get-placement-pdf-data?lang=${currentLang}&id=${id}&frontType=${isCrossTable ? 'crosstable' : 'module'}`;
    let compass: any;
    setloading(true);
    fetch(href)
      .then((res) => res.json())
      .then(async (data) => {
        const pdfViewTypeRes = await fetch(
          `https://dev1.flat.show/client/module/get-placement-pdf-config-view-type-field?id=${complexIdState}&frontType=${isCrossTable ? 'crosstable' : 'module'}`,
        );
        const pdfViewTypeData = await pdfViewTypeRes.json();
        const onePageData: any = {};
        const onePageTrigger = pdfViewTypeData.view_type === 'one-page';
        for (let i = 0; i < data.length; i++) { // выведет 0, затем 1, затем 2
          const item = data[i];
          Object.keys(item.placementData).forEach((fieldName) => {
            item.placementData[fieldName] = item.placementData[fieldName] ? numberWithCommas(item.placementData[fieldName]) : item.placementData[fieldName];
          });
          item.pageNumber = i + 1;
          if (item.floorWindroseData) {
            const response = await fetch(`https://dev1.flat.show/entity-options/get-spinner-config?entity=floor&id=${item.floorWindroseData.id}`);
            const res = await response.json();
            item.thirdImageURL = res._windRose.src;
            item.degree = res._windRose.degree;

            if (item.thirdImageURL) {
              await rotate(item.thirdImageURL, item.degree, item.backgroundColor, (resultBase64: any) => {
                compass = resultBase64;
              });
            }
          }
          if (item.firstImageOverlaySVGData) {
            const result = await combine(item.firstImageURL, item.firstImageOverlaySVGData, item.backgroundColor);
            item.firstImageURL = result;
          }
          if (item.secondImageOverlaySVGData) {
            const result = await combine(item.secondImageURL, item.secondImageOverlaySVGData, item.backgroundColor);
            item.secondImageURL = result;
          }
          item.thirdImageURL = compass;
          if (onePageTrigger) {
            if (i == 0) {
              onePageData.salesDepartmentFieldName = item.salesDepartmentFieldName;
              onePageData.complexAddressFieldName = item.complexAddressFieldName;
              onePageData.backgroundColor = item.backgroundColor;
              onePageData.complexAddress = item.complexAddress;
              onePageData.apartmentLayout = item.firstImageURL;
              onePageData.placementData = item.placementData;
              onePageData.clientLogoURL = item.clientLogoURL;
              onePageData.complexName = item.complexName;
              onePageData.phones = item.phones || [];
              onePageData.notes = item.notes || [];
              onePageData.compass = compass;
            } else if (i == 1 && data.length === 2 || i == 1 && data.length === 3 || i == 2 && data.length === 4) {
              onePageData.floorPlan = item.firstImageURL;
              onePageData.sectionDiagram = item.secondImageURL;
            } else if (i == 2 && data.length === 2 || i == 2 && data.length === 3 || i == 3 && data.length === 4) onePageData.renderFacade = item.firstImageURL;
          } else {
            console.log('ITEM', item);
            // @ts-ignore
            document.getElementById('tempPDF').innerHTML += tmpl('pdf-template', item);
          }
        }
        if (onePageTrigger) {
          // @ts-ignore
          document.getElementById('tempPDF').innerHTML += tmpl('pdf-template-short', onePageData);
          console.log('onePageData', onePageData);
        }
        convertAllSVGToPNG(generatePDF);
      })
      .catch((err) => { throw err; });
  };

  return (
    <div style={{
      cursor: loading ? 'auto' : 'pointer',
    }}
    >
      <div id="tempPDF-holder">
        <div id="tempPDF" />
      </div>
      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <PictureAsPdfIcon onClick={handlerIcon} className={styles.pdfIcon} />
      )}
    </div>
  );
};
