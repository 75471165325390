import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  compareWrapper: {
    flex: '1',
    height: '100%',
    padding: '20px',
    overflowY: 'auto',
  },
}));
