import {
  takeEvery, put, select, call, all,
} from 'redux-saga/effects';

import { ISpinnerData, IFirstSpinner } from 'src/typings/spinner';
import { getCurrentSpinner } from 'src/store/spinner/selectors';

import { Actions as ActionsApp } from 'src/store/app/actions';
import { Actions as ActionsFloors } from '../../floors/actions';
import { ActionTypes } from './actions';
import { Actions as ActionsSpinnerState } from '../../spinner/actions';

export function* setCurrentSpinner(actions: {
  type: string,
  payload: ISpinnerData
}) {
  const currentSpinner: IFirstSpinner = yield select(getCurrentSpinner);
  const {
    spinners,
  } = actions.payload;

  if (currentSpinner === null) {
    yield put(ActionsSpinnerState.setCurrentSpinner({
      name: spinners.firstSpinner.name,
      'name-id': spinners.firstSpinner['name-id'],
    }));
    yield put(ActionsApp.setCurrentEntity({
      name: spinners.firstSpinner.name,
      id: spinners.firstSpinner['name-id'],
    }));

    // yield put(ActionsFloors.setSelectedHouse({
    //   name: 'house',
    //   'name-id': '5',
    // }));

    // yield put(ActionsSpinnerState.setCurrentSpinner({
    //   name: 'floor',
    //   'name-id': '17',
    // }));

    // 15.07.2020 we a hurry. We didn't have any complex and we had only house spinner
    // Needed implement visibility for floors
    if (spinners.firstSpinner.name === 'house') {
      yield put(ActionsFloors.setSelectedHouse({
        name: spinners.firstSpinner.name,
        'name-id': spinners.firstSpinner['name-id'],
      }));
    }
  }
}

export function* getSpinnerWatchSaga() {
  yield takeEvery(ActionTypes.GET_SPINNER_DATA_REQUEST_SUCCESS, setCurrentSpinner);
}
