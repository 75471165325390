import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ButtonApp } from 'src/components/Button';
import { Loader } from 'src/components/Loader';
import { CustomDropdown } from 'src/components/dropdowns/CustomDropdown';
import { getComplexId } from 'src/store/app/selectors';
import { requestSelectors } from 'src/store/requests/selectors';
import { IStatus } from 'src/typings/complexes';
import { TypeOfRole } from 'src/typings/user';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { useGetAuthData, useLanguageState } from 'src/hooks';
import { translations } from 'src/constants/translations';
import {
  getPlacementStatus, getPlacementStatusByComplex, patchPlacement,
} from 'src/api';

import { useStyles } from './styles';

interface IApartmentStatus {
  placeInfo: IFilterAllData
}

export const ApartmentStatus: React.FC<IApartmentStatus> = ({
  placeInfo,
}) => {
  const styles = useStyles({});
  const { Id }: { Id: string } = useParams();
  const [newStatus, setNewStatus] = useState<IStatus|null>(null);
  const [status, setStatus] = useState<IStatus|null>(null);
  const [allStatuses, setAllStatuses] = useState<IStatus[]|null>(null);
  const user = useSelector(requestSelectors.user.getUserData);
  const { currentAppLanguage } = useLanguageState();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const complexId = useSelector(getComplexId);
  const asyncFetching = async () => {
    if (complexId) {
      const statusRes = await getPlacementStatus(jwt, placeInfo?.[ListOfKeysFilterAllData.statusId]);
      setStatus(statusRes.data);
      const allStatusesRes = await getPlacementStatusByComplex(jwt, complexId);
      setAllStatuses(allStatusesRes.data['hydra:member']);
    }
  };
  useEffect(() => {
    asyncFetching();
  }, [placeInfo, complexId]);

  const applyNewStatus = async () => {
    if (newStatus) {
      await patchPlacement(jwt, Id, {
        placementStatus: newStatus?.['@id'],
      });
      window.location.reload();
    }
  };

  if (!status || !allStatuses) {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={4}
      >
        <Loader />
      </Box>
    );
  }

  const DropButton = () => (
    <Box>
      {newStatus ? (
        <Box
          display="flex"
          alignItems="center"
          fontSize="17px"
          color="#201E2A"
        >
          <Box
            width="24px"
            height="24px"
            borderRadius="3px"
            mr={2}
            style={{ backgroundColor: newStatus?.color?.toString() || '' }}
          />
          {currentAppLanguage && newStatus?.name[currentAppLanguage]}
        </Box>
      ) : (
        <Box
          color="#9E9E9E"
          fontSize="14px"
          lineHeight="24px"
        >
          {translations.chooseStatus}
        </Box>
      )}
    </Box>
  );

  return (
    <Box mb={4}>
      <Box fontWeight="600" fontSize="28px">
        {translations.appartStatus}
      </Box>
      <Box mb={4}>
        <Box
          borderBottom="1px solid #F2F2F2"
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box color="#9E9E9E" fontSize="14px" fontWeight="300">
            {translations.status}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            fontWeight="bold"
            fontSize="17px"
          >
            <Box
              width="24px"
              height="24px"
              borderRadius="3px"
              mr={2}
              style={{ backgroundColor: status?.color?.toString() || '' }}
            />
            {currentAppLanguage && status?.name[currentAppLanguage]}
          </Box>
        </Box>
        {placeInfo[ListOfKeysFilterAllData.updateTime] && (
          <Box
            borderBottom="1px solid #F2F2F2"
            py={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box color="#9E9E9E" fontSize="14px" fontWeight="300">
              {translations.validUntil}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              fontWeight="bold"
              fontSize="17px"
            >
              {placeInfo[ListOfKeysFilterAllData.updateTime]}
            </Box>
          </Box>
        )}
        {placeInfo[ListOfKeysFilterAllData.userName] && (
          <Box
            borderBottom="1px solid #F2F2F2"
            py={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box color="#9E9E9E" fontSize="14px" fontWeight="300">
              {translations.manager}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              fontWeight="bold"
              fontSize="17px"
            >
              <Box
                width="24px"
                height="24px"
                borderRadius="999px"
                mr={2}
                style={{
                  backgroundSize: 'cover',
                  backgroundImage: `url(${placeInfo[ListOfKeysFilterAllData.userIconUri]})`,
                }}
              />
              {placeInfo[ListOfKeysFilterAllData.userName]}
            </Box>
          </Box>
        )}
      </Box>
      {(user?.role?.['@id'] !== TypeOfRole.manager || status.can_be_changed) && (
      <Box>
        <Box fontWeight="600" fontSize="28px" mb={2}>
          {translations.applyNewStatus}
        </Box>
        <Box mb={4}>
          <CustomDropdown
            isSmall
            closeOnChild
            isFuncChild
            button={<DropButton />}
          >
            {() => (
              <>
                {allStatuses.map((item: IStatus) => {
                  if (!item.can_be_set && user?.role?.['@id'] === TypeOfRole.manager) {
                    return null;
                  }
                  return (
                    <Box
                      key={item['@id']}
                      py={2}
                      px={2}
                      mx={-3}
                      display="flex"
                      alignItems="center"
                      onClick={() => setNewStatus(item)}
                      className={styles.dropItem}
                      fontSize="18px"
                    >
                      {item.crosstableColor && (
                      <Box
                        width="24px"
                        height="24px"
                        borderRadius="3px"
                        mr={2}
                        style={{ backgroundColor: item.crosstableColor.toString() || '' }}
                      />
                      )}
                      {currentAppLanguage && item.name[currentAppLanguage]}
                    </Box>
                  );
                })}
              </>
            )}
          </CustomDropdown>
        </Box>
        <ButtonApp
          color="primary"
          text={translations.apply}
          disabled={!newStatus}
          onClick={applyNewStatus}
          className={styles.applyBtn}
          isCapitalize
          variant="contained"
        />
      </Box>
      )}
    </Box>
  );
};
