import React, {
  RefObject, useRef, useEffect, useCallback,
} from 'react';
import { Action, Fab } from 'react-tiny-fab';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';

// eslint-disable-next-line import/extensions
import './fabDefault.scss';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { useGenerateFavoriteLink } from './useGenerateFavoriteLink';
import { useStyles } from './styles';

export const AppFavFAB: React.FC = () => {
  const styles = useStyles({});
  const refCopyInput: RefObject<HTMLInputElement> = useRef(null);
  const { showFAB, favShareLink } = useGenerateFavoriteLink();
  const { getTranslations } = useTranslations();

  useEffect(() => {
    if (window.afterCreateCopyFavoriteLink) {
      window.afterCreateCopyFavoriteLink(refCopyInput.current);
    }
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    if (refCopyInput.current) {
      refCopyInput.current.type = 'text';
      refCopyInput.current.select();
      refCopyInput.current.setSelectionRange(0, 99999);

      document.execCommand('copy');
      refCopyInput.current.type = 'hidden';
    }
  }, [refCopyInput.current]);

  return (
    <Fab
      mainButtonStyles={{ display: `${showFAB ? 'block' : 'none'}` }}
      text={getTranslations(locals.appTranslations.appFavorites)}
      position={{ bottom: 53, right: 10 }}
      event="click"
      icon={<ShareIcon />}
      data-gaid="copyFavoriteLink"
    >
      <Action
        text={getTranslations(locals.appTranslations.appCopyLink)}
        className={styles.actionStyles}
        onClick={handleCopyToClipboard}
      >
        <>
          <input readOnly type="hidden" value={favShareLink} ref={refCopyInput} />
          <LinkIcon className={styles.fabMainStyleBtn} />
        </>
      </Action>
    </Fab>
  );
};
