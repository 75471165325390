import { IUsers } from './user';
import { IDogeFields } from './global-config';

export interface IStage extends IDogeFields {
  bitrixCategory: string;
  bitrixDeals: string[];
  bitrixExternalStageId: string;
  bitrixExternalStageName: string;
  id: number;
  priority: number;
  placementStatuses: string[];
}

export interface IStatusOnZeroDeals extends IDogeFields {
  id: number,
  complex: IComplex,
  placementStatus: IStatus
}

export interface ICategory extends IDogeFields {
  bitrixCategoryPlacementStatusOnZeroDeals: IStatusOnZeroDeals[];
  bitrixExternalCategoryName: string;
  bitrixExternalCategoryId: string;
  complexes: IComplex[];
  bitrixStages: string[];
  id: number;
}

export interface ICategoryChild extends IDogeFields {
  bitrixExternalCategoryName: string;
  bitrixExternalCategoryId: string;
  complexes: string[];
  bitrixStages: string[];
}

export interface IProject extends IDogeFields {
  id: number | string;
  domain: string;
  webhookUrl: string;
  developer: string;
  bitrixCategories: ICategoryChild[];
}

export interface IDeveloper extends IDogeFields {
  id: number | string;
  logoUri: string;
  title: string;
  bitrixProject: string | null;
  complexes: IComplex[];
}

export interface IAmoPipeline extends IDogeFields {
  amoProject: string | null;
  amo_id: number;
  id: number;
}

export interface IComplex extends IDogeFields {
  amo_pipeline: IAmoPipeline | null;
  bitrixComplexData: null;
  developers: IDeveloper[];
  id: number | string;
  logoUri: string;
  previewUri: string;
  title: {
    [key: string]: string;
  };
  language: string;
  showInCrosstable: boolean;
  users: IUsers[];
}

export enum TypeOfStatus {
  fixed = 'fixed time',
  static = 'static'
}

export interface IStatus extends IDogeFields {
  amo_can_be_set_for_deal: boolean;
  amo_only_one_deal_placement: boolean;
  can_be_changed: boolean;
  can_be_set: boolean;
  color: string;
  crosstableColor: string | null;
  created_by: string;
  complex: string;
  crosstable_name: {
    [key: string]: string;
  };
  id: number;
  module_name: {
    [key: string]: string;
  };
  name: {
    [key: string]: string;
  };
  show_in_module: string;
  temporary_type: TypeOfStatus|null;
  update_time_interval: number|null;
}
