import React, { useCallback } from 'react';

import {
  AppCheckboxMultiBoxInfrastructure,
  AppCheckboxSingleInfrastructure,
} from 'src/components/AppCheckboxInfrastructure';
import {
  IRangeAndSet,
  ListOfDropdown,
} from 'src/typings/filter';
import { useStyles } from './styles';

interface ISelectorGenerator {
  dropdownField: IRangeAndSet;
}

export const SelectorGeneratorInfrastructure: React.FC<ISelectorGenerator> = ({
  dropdownField,
}) => {
  const styles = useStyles({});

  const handleSelectorGenerate = useCallback(() => {
    switch (dropdownField.type) {
      case ListOfDropdown.dropdownSelectList:
      case ListOfDropdown.dataList:
      case ListOfDropdown.dropdownRadioList:
        return <AppCheckboxSingleInfrastructure dropdownInfo={dropdownField} />;
      case ListOfDropdown.dropdownCheckboxList:
      case ListOfDropdown.datalistCheckboxList:
        return <AppCheckboxMultiBoxInfrastructure dropdownInfo={dropdownField} />;
      default:
        return null;
    }
  }, [dropdownField]);

  if (handleSelectorGenerate()) {
    return (
      <div className={styles.wrapperCheckbox}>
        {handleSelectorGenerate()}
      </div>
    );
  }

  return null;
};
