import React from 'react';
import {
  isArray, isNumber, isObject, isString,
} from 'lodash';
import { Box, styled } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IPlacementErrors } from '../../typings/house';

const Th = styled('th')({
  border: '1px solid #C2BFBF',
  padding: '16px 12px',
  fontWeight: 800,
  fontSize: '12px',
  textAlign: 'start',
  textTransform: 'uppercase',
});
const Td = styled('td')({
  border: '1px solid #C2BFBF',
  padding: '16px 12px',
  fontWeight: 400,
  fontSize: '14px',
  verticalAlign: 'top',
});
const Table = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
});

interface Props {
  errors: {
    [key: string]: IPlacementErrors[];
};
}

export const ErrorsBlock: React.FC<Props> = ({ errors }) => {
  return (
    <Box mt={3} py={5} px={4} bgcolor="#DE7C711A" borderRadius="4px">
      <Box display="flex" alignItems="center" mb={5}>
        <HighlightOffIcon />
        <Box
          ml={2}
          fontWeight={300}
          fontSize="18px"
          lineHeight="24px"
        >
          Errors list
        </Box>
      </Box>
      <Table>
        <thead>
          <tr>
            <Th>Unit</Th>
            <Th>Property</Th>
            <Th>Mistake</Th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(errors).map(([unit, errorList]) => {
            return errorList.map((item, index) => {
              return (
                <tr key={(item.property?.clientTranslatedName ? 'a' : item.property)
                + (isString(item.message) ? item.message : '') + item.rowNumber || ''}
                >
                  <Td>{unit === 'unnamed' || unit}</Td>
                  <Td>
                    {item.property?.clientTranslatedName || item.property}
                  </Td>
                  <Td>
                    {isString(item.message) && item.message}
                  </Td>
                </tr>
              );
            });
          })}
        </tbody>
      </Table>
    </Box>
  );
};
