import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  isFullSize: {
    width: '100%',
    padding: '10px 20px',
    textAlign: 'center',
  },
  defaultButtonStyles: {
    fontSize: '14px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 'inherit',
    },
  },
  isPrimaryStyle: {
    color: 'white !important',
  },
  isCapitalize: {
    textTransform: 'initial',
  },
  withBorderStyle: {
    color: theme.palette.secondary.main,
    border: '1px solid #E7E7E7',
    borderRadius: '10px',
  },
  onlyStartIcon: {
    '& .MuiButton-startIcon': {
      marginRight: '0px',
    },
  },
}));
