interface ICheckboxes {
  [key: string]: string;
}

export const checkboxes: ICheckboxes = {
  unlinkPlacementAtStageWithLowerPriority: `
  1. При перемещении сделки с помещением в этот стейдж происходит
  <b> отвязка </b>
  этого помещения от сделок, которые находятся на стейдже с
  <b> более низким приоритетом.</b>
  `,
  unlinkPlacementAtStageWithHigherPriority: `
  2. При перемещении сделки с помещением в этот стейдж происходит
  <b> отвязка </b>
  этого помещения от сделок, которые находятся на стейдже с
  <b> более высоким приоритетом.</b>
  `,
  unlinkPlacementAtStageWithSamePriority: `
  3. При перемещении сделки с помещением в этот стейдж происходит
  <b> отвязка </b>
  этого помещения от сделок, которые находятся на стейдже с
  <b> таким  же приоритетом.</b>
  `,
  unlinkAllPlacementsAtAllOtherStages: `
  4. При переходе/ добавлении помещения к сделке в этот стейдж происходит
  <b> отвязка </b>
  этого помещения от
  <b> всех остальных сделок</b>
  не зависимо от приоритета.
  `,
  unlinkPlacementAtThisStage: `
  5. При перемещении сделки с помещением в этот стейдж происходит
  <b> отвязка </b>
  этого помещения от текущей сделки. (Ситуация например сделка проиграна)
  `,
  blockMoveAndLinkDealsWithPlacementAtOtherStages: `
  6.
  <b>Заморозить</b>
  все сделки с этим же помещением в остальных стейджах (блокировка передвижения по воронке), с блокировкой привязки этого помещения к новым сделкам.
  `,
  blockMoveNdLinkDealsWithPlacementAtOrAftrCurrentStage: `
  7. При перемещении сделки с помещением в этот стейдж
  <b>остальным сделкам с этим же помещением разрешается </b>
  перемещаться по стейджам
  <b> до текущего стейджа</b>
  , с возможностью привязки этого помещения к новым сделкам до этого стейджа.
  `,
};
