import React from 'react';
import {
  isArray, isNumber, isObject, isString,
} from 'lodash';
import { Box, styled } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { IPlacementUpdates } from '../../typings/house';

const Th = styled('th')({
  border: '1px solid #C2BFBF',
  padding: '16px 12px',
  fontWeight: 800,
  fontSize: '12px',
  textAlign: 'start',
  textTransform: 'uppercase',
});
const Td = styled('td')({
  border: '1px solid #C2BFBF',
  padding: '16px 12px',
  fontWeight: 400,
  fontSize: '14px',
  verticalAlign: 'top',
});
const Table = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
});

interface Props {
  updates: {
    [key: string]: IPlacementUpdates[];
};
}

export const UpdatesBlock: React.FC<Props> = ({ updates }) => {
  return (
    <Box mt={3} py={5} px={4} bgcolor="#77E56A1A" borderRadius="4px">
      <Box display="flex" alignItems="center" mb={5}>
        <CachedIcon />
        <Box
          ml={2}
          fontWeight={300}
          fontSize="18px"
          lineHeight="24px"
        >
          Updates list
        </Box>
      </Box>
      <Table>
        <thead>
          <tr>
            <Th>Unit</Th>
            <Th>Property</Th>
            <Th>Old Value</Th>
            <Th>New Value</Th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(updates).map(([unit, updateList]) => {
            return (
              <tr key={unit}>
                <Td>{unit}</Td>
                <Td>
                  {updateList.map((item, index) => {
                    return !!index ? (
                      <>
                        <br />
                        {item.property?.clientTranslatedName || item.property}
                      </>
                    ) : item.property?.clientTranslatedName || item.property;
                  })}
                </Td>
                <Td>
                  {updateList.map((item, index) => {
                    if (isObject(item.oldValue) || isArray(item.oldValue)) return null;
                    return (isString(item.oldValue) || isNumber(item.oldValue)) && !!index ? (
                      <>
                        <br />
                        {item.oldValue}
                      </>
                    ) : item.oldValue;
                  })}
                </Td>
                <Td>
                  {updateList.map((item, index) => {
                    if (isObject(item.newValue) || isArray(item.newValue)) return null;
                    return (isString(item.newValue) || isNumber(item.newValue)) && !!index ? (
                      <>
                        <br />
                        {item.newValue}
                      </>
                    ) : item.newValue;
                  })}
                </Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Box>
  );
};
