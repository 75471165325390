import React, { useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { useLanguageState } from 'src/hooks';
import { useAppMenuState, useAppVisibilityBtn } from 'src/hooks/app';
import { ButtonApp } from 'src/components/Button';
import { AppHeaderLogo } from 'src/components/AppHeader/ComponentLogo';
import { useLinkState } from 'src/components/AppMenu/useLinkState';
import { Loader } from 'src/components/Loader';
import { ButtonLanguage } from 'src/components/ButtonLanguage';
import { useStyles } from './styles';

export const AppMenu: React.FC = React.memo(() => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const rootUrl = useSelector(requestSelectors.app.getRootUrl);
  const ringoClassname = useSelector(requestSelectors.app.getRingoClassname);
  const styles = useStyles(mainColor);
  const {
    countColumns,
    listOfLinksIsLoading,
    listOfPhones,
    listOfLinks,
  } = useLinkState();

  useEffect(() => {
    if (window.ringostatRestartSubstitution) {
      window.ringostatRestartSubstitution();
    }
  }, [listOfPhones, ringoClassname]);

  const { show_client_logo } = useAppVisibilityBtn();
  const { isAppMenuOpen, handleToggleOnStateChange } = useAppMenuState();
  const { currentAppLanguage } = useLanguageState();
  const defaultLang: any = useSelector(requestSelectors.app.getLang);

  const formatLink = (link: string) => {
    const host = rootUrl || window.location.origin;
    const linkParts = link.split(host);

    if (linkParts.length === 2) {
      if (currentAppLanguage !== defaultLang) {
        const splitPath = linkParts[1].split('/');
        if (splitPath[1] === 'ua') {
          splitPath.splice(0, 2);
        }
        return (`${host}/${currentAppLanguage}${splitPath.join('/')}`);
      }
      return (linkParts.join(`${host}`));
    }
    return link;
  };

  return (
    <Menu
      isOpen={isAppMenuOpen}
      outerContainerId="root"
      pageWrapId="page-wrap"
      width={countColumns}
      customCrossIcon={(
        <ButtonApp
          startIcon={<CloseIcon />}
          className={styles.closeButton}
        />
      )}
      bodyClassName={styles.menuBody}
      menuClassName={styles.menuClassName}
      itemListClassName={styles.itemListClassName}
      crossButtonClassName={styles.crossButtonClassName}
      onStateChange={(state: any) => handleToggleOnStateChange(state)}
    >
      <AppHeaderLogo isVisible={show_client_logo} customWrapperStyle={styles.wrapperLogo} />
      <ButtonLanguage buttonStyles={`${styles.closeButton} ${styles.languageButton}`} />
      {listOfLinksIsLoading && <Loader />}
      <div className={styles.wrapperMenuContent}>
        <div className={styles.menuContent}>
          {listOfLinks.map((column) => {
            return (
              <div className={styles.menuColumnWrapper} key={column.rows.toString()}>
                {column.menu_column_title && (
                  <span className={styles.itemListItemClassNameTitle}>
                    {column.menu_column_title}
                  </span>
                )}
                {column
                  .rows
                  .sort((a, b) => a.order - b.order)
                  .map((rowItem, indexRowItem) => {
                    return (
                      <a
                        key={rowItem.order || indexRowItem}
                        className={styles.itemListItemClassName}
                        href={formatLink(rowItem.link_url) || '/'}
                      >
                        {rowItem.link_title}
                      </a>
                    );
                  })}
              </div>
            );
          })}
        </div>
        <div className={styles.phoneContent}>
          <span className="ringo-phone">
            {listOfPhones && listOfPhones
              .sort((a, b) => a.order - b.order)
              .map((phoneItem) => {
                return (
                  <a
                    className={`${ringoClassname || ''}`}
                    key={phoneItem.order}
                    href={`tel:${phoneItem.phone_href}`}
                  >
                    {phoneItem.phone_text}
                  </a>
                );
              })}
          </span>
        </div>
      </div>
    </Menu>
  );
});
