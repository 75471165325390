import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';
import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';

export function getPlacementTypeFilter(lang: string) {
  const buildType = getBuildType();
  const isCrossTable = buildType === BuildEnv.crossTable;
  return httpService.get(
    `${ApiUrl.getApiUrl().placementFilter}&lang=${lang}${isCrossTable ? '&view=crosstable' : ''}`,
  );
}

export function getPlacement(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getPlacement}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getPlacementByAmo(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().getPlacement}?amo_deals=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getPlacementByBitrix(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().getPlacement}?bitrixDeals=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getPlacementByCreatio(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().getPlacement}?creatioOpportunities=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getPlacementTypesByComplex() {
  return httpService.get(`${ApiUrl.getApiUrl().getPlacementTypes}`, {}, {});
}

export function patchPlacement(token: string = '', id: string, data: any) {
  return httpService.patch(`${ApiUrl.getApiUrl().getPlacement}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getDisplayPlacementData() {
  return httpService.get(`${ApiUrl.getApiUrl().getDisplayPlacementData}`, {}, {});
}
