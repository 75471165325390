import {
  IFirstSpinner,
  ListOfBacklightSvg,
  IBacklightState,
  IHitsList, ISpinnerStopFrame, IWindRose, IEntityConfig,
} from 'src/typings/spinner';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface ISpinnerStateReducer {
  renderComplete: boolean | null;
  currentSpinner: IFirstSpinner | null;
  spinnerStopFrames: ISpinnerStopFrame | null;
  counter: IHitsList,
  backlightSvg: IBacklightState;
  windRose: IWindRose | null;
  entityConfig: IEntityConfig | null;
  appWindRose: IWindRose | null;
  highlightPolygons: string[];
}

export const initialState: ISpinnerStateReducer = {
  renderComplete: null,
  currentSpinner: null,
  spinnerStopFrames: null,
  counter: {},
  backlightSvg: {
    [ListOfBacklightSvg.houseId]: [],
    [ListOfBacklightSvg.placementId]: [],
  },
  windRose: null,
  entityConfig: null,
  appWindRose: null,
  highlightPolygons: [],
};

export const spinnerReducer = (
  state = initialState,
  { type, payload }: IAction,
): ISpinnerStateReducer => {
  switch (type) {
    case ActionTypes.RESET_SPINNER: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.SET_CURRENT_SPINNER: {
      return {
        ...state,
        renderComplete: false,
        currentSpinner: payload,
      };
    }

    case ActionTypes.SET_WIND_ROSE: {
      return {
        ...state,
        windRose: payload,
      };
    }

    case ActionTypes.SET_APP_WIND_ROSE: {
      return {
        ...state,
        appWindRose: payload,
      };
    }

    case ActionTypes.SET_ENTITY_CONFIG: {
      return {
        ...state,
        entityConfig: payload,
      };
    }

    case ActionTypes.SET_RENDER_SPINNER_COMPLETE: {
      return {
        ...state,
        renderComplete: true,
      };
    }

    case ActionTypes.SET_CURRENT_SPINNER_COUNTER: {
      return {
        ...state,
        counter: payload,
      };
    }

    case ActionTypes.SET_CURRENT_SPINNER_BACKLIGHT_SVG: {
      return {
        ...state,
        backlightSvg: payload,
      };
    }

    case ActionTypes.SET_HIGHLIGHT_POLYGONS: {
      return {
        ...state,
        highlightPolygons: payload,
      };
    }

    case ActionTypes.SET_SPINNER_STOP_FRAME: {
      return {
        ...state,
        spinnerStopFrames: {
          ...state.spinnerStopFrames,
          ...payload,
        },
      };
    }

    default:
      return state;
  }
};
