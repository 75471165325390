import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Tune } from '@mui/icons-material';
import { requestSelectors } from 'src/store/requests/selectors';
import {
  useIsMobile, useTranslations,
} from 'src/hooks';
import { locals } from 'src/constants/locals';
import { FilterDesktop } from './components/FilterDesktop';
import { useStyles } from './styles';

export const FilterApartments: React.FC = () => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);
  const { isMobileScreen } = useIsMobile();
  const { getTranslations } = useTranslations();

  return (
    <>
      {/* <Box
        className={`${styles.filterContentShow} ${
          isOpen ? styles.wrapperFilterClose : styles.wrapperFilterOpen} ${
          isHideFilterZone ? styles.wrapperHideFilterZone : ''
        }`}
        onClick={() => handleToggleOpenFilter(false)}
      >
        <p><b>{getTranslations(locals.appTranslations.appShowFilter)}</b></p>
        <Tune />
      </Box> */}
      {
        !isMobileScreen && <FilterDesktop />
      }
    </>
  );
};
