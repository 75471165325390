import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { getVisibilitySpinnerButton } from 'src/store/requests/app/selector';

export const useAppVisibilitySpinnerBtn = () => {
  const visibilitySpinnerButton = useSelector(getVisibilitySpinnerButton);
  const [mainSpinnerBtnViews, setMainSpinnerBtnViews] = useState<string>('');
  const [gallerySpinnerBtnViews, setGallerySpinnerBtnViews] = useState<string>('');

  useEffect(() => {
    if (visibilitySpinnerButton) {
      setMainSpinnerBtnViews(
        `
        desktop-rotation-${visibilitySpinnerButton.show_spinners_rotation_buttons_desktop}
        mobile-rotation-${visibilitySpinnerButton.show_spinners_rotation_buttons_mobile}
        desktop-zoom-${visibilitySpinnerButton.show_spinners_zoom_button_desktop}
        mobile-zoom-${visibilitySpinnerButton.show_spinners_zoom_button_mobile}
      `,
      );

      setGallerySpinnerBtnViews(
        `
        desktop-gallery-rotation-${visibilitySpinnerButton.show_placement_gallery_spinners_rotation_buttons_desktop}
        mobile-gallery-rotation-${visibilitySpinnerButton.show_placement_gallery_spinners_rotation_buttons_mobile}
        desktop-gallery-zoom-${visibilitySpinnerButton.show_placement_gallery_spinners_zoom_button_desktop}
        mobile-gallery-zoom-${visibilitySpinnerButton.show_placement_gallery_spinners_zoom_button_mobile}
      `,
      );
    }
  }, [visibilitySpinnerButton]);

  return {
    mainSpinnerBtnViews,
    gallerySpinnerBtnViews,
  };
};
