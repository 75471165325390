import React, { useRef } from 'react';
import { Close } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { useClearSelectedFilterParams, useGetFilterParams } from 'src/hooks';
import { IItemOfSelectedParams } from './types';
import { useStyles } from './styles';
import { useSelectedScrollPosition } from './useSelectedScrollPosition';

export const ItemOfSelectedParams: React.FC<IItemOfSelectedParams> = () => {
  const styles = useStyles({});
  const selectedRef = useRef<HTMLDivElement>(null);
  const { selectedParams } = useGetFilterParams();
  const { handleClearFilterParam } = useClearSelectedFilterParams();
  useSelectedScrollPosition(
    selectedParams.length,
    selectedRef,
  );

  return (
    <div
      ref={selectedRef}
      className={`${selectedParams.length ? styles.wrapperListOfItems : ''}`}
    >
      {selectedParams.map((selectItem) => {
        return (
          <Box
            key={selectItem.name}
            className={styles.wrapperSelectedItem}
            onClick={() => handleClearFilterParam(selectItem.name)}
          >
            <span>{selectItem.value}</span>
            <Close className={styles.iconStyle} />
          </Box>
        );
      })}
    </div>

  );
};
