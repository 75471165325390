import React from 'react';

import { ButtonApp } from 'src/components/Button';
import {
  useGetFilteredData,
  useGetFilterParams,
  useClearSelectedFilterParams, useTranslations,
} from 'src/hooks';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';
import { IButtonFilter } from './types';

export const ButtonFilter: React.FC<IButtonFilter> = ({
  handleSubmit = () => null,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const { resultFiltering } = useGetFilteredData();
  const { selectedParams } = useGetFilterParams();
  const { handleClearFilterParam } = useClearSelectedFilterParams();

  return (
    <div className={styles.wrapperFilterButton}>
      {Boolean(selectedParams.length) && (
        <ButtonApp
          isFullSize
          isCapitalize
          withBorder
          text={getTranslations(locals.appTranslations.appClearAll)}
          color="secondary"
          onClick={() => handleClearFilterParam()}
        />
      )}
      <ButtonApp
        isFullSize
        isCapitalize
        text={
          Boolean(resultFiltering.length)
            ? `${getTranslations(locals.appTranslations.appShow)} ${resultFiltering.length} ${getTranslations(locals.appTranslations.appUnits)}`
            : getTranslations(locals.appTranslations.appClose)
}
        variant="contained"
        onClick={() => {
          handleSubmit();
        }}
      />
    </div>
  );
};
