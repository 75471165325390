import { RefObject, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Actions } from 'src/store/filter/filter-params/actions';

export const useWritePositionScroll = () => {
  const dispatch = useDispatch();

  const handleWritePositionScroll = useCallback((parentScroll: RefObject<HTMLDivElement> | undefined) => {
    if (parentScroll) {
      dispatch(Actions.setScrollPosition(parentScroll.current?.scrollTop));
    }
  }, [dispatch]);

  return {
    handleWritePositionScroll,
  };
};
