import { Dropdown } from 'semantic-ui-react';
import React from 'react';

import { useConvertToFormat } from 'src/components/dropdowns/hooks';
import { useGetTypeOfProperty } from 'src/hooks';
import { useStyles } from './styles';

interface IDropdownInline {
  dropdownInfo: any[];
  onChange: (value: any) => void;
}

export const DropdownInline: React.FC<IDropdownInline> = ({
  dropdownInfo,
  onChange,
}) => {
  const styles = useStyles({});
  const { currentData } = useConvertToFormat(dropdownInfo);
  const { typeOfProperty } = useGetTypeOfProperty();

  return (
    <Dropdown
      inline
      options={currentData}
      className={styles.styleOfDropdown}
      value={typeOfProperty}
      onChange={(event, data) => onChange(data.value)}
    />
  );
};
