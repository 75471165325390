import { action } from 'src/store/common';

import {
  IFirstSpinner,
  IBacklightState,
  IHitsList,
} from 'src/typings/spinner';

export const ActionTypes = {
  SET_CURRENT_SPINNER: '[SPINNER_RENDER]SET_CURRENT_SPINNER',
  SET_ENTITY_CONFIG: '[SPINNER_RENDER]SET_ENTITY_CONFIG',
  SET_WIND_ROSE: '[SPINNER_RENDER]SET_WIND_ROSE',
  SET_APP_WIND_ROSE: '[SPINNER_RENDER]SET_APP_WIND_ROSE',
  SET_APP_CURRENT_SPINNER: '[SPINNER_RENDER]SET_APP_CURRENT_SPINNER',
  SET_SPINNER_STOP_FRAME: '[SPINNER_RENDER]SET_SPINNER_STOP_FRAME',
  RESET_SPINNER: '[SPINNER_RENDER]RESET_SPINNER',
  SET_RENDER_SPINNER_COMPLETE: '[SPINNER_RENDER]SET_RENDER_SPINNER_COMPLETE',
  SET_CURRENT_SPINNER_COUNTER: '[SPINNER_RENDER]SET_CURRENT_SPINNER_COUNTER',
  SET_CURRENT_SPINNER_BACKLIGHT_SVG: '[SPINNER_RENDER]SET_CURRENT_SPINNER_BACKLIGHT_SVG',
  SET_HIGHLIGHT_POLYGONS: '[SPINNER_RENDER]SET_HIGHLIGHT_POLYGONS',
};

export const Actions = {
  setCurrentSpinner: action<IFirstSpinner>(ActionTypes.SET_CURRENT_SPINNER),
  setWindRose: action(ActionTypes.SET_WIND_ROSE),
  setEntityConfig: action(ActionTypes.SET_ENTITY_CONFIG),
  setAppWindRose: action(ActionTypes.SET_APP_WIND_ROSE),
  setAppCurrentSpinner: action(ActionTypes.SET_APP_CURRENT_SPINNER),
  setSpinnerStopFrame: action(ActionTypes.SET_SPINNER_STOP_FRAME),
  resetSpinner: action(ActionTypes.RESET_SPINNER),
  setRenderCompleteSpinner: action(ActionTypes.SET_RENDER_SPINNER_COMPLETE),
  setCurrentSpinnerCounter: action<IHitsList>(ActionTypes.SET_CURRENT_SPINNER_COUNTER),
  setCurrentSpinnerBacklightSvg: action<IBacklightState>(ActionTypes.SET_CURRENT_SPINNER_BACKLIGHT_SVG),
  setHighlightPolygons: action<string[]>(ActionTypes.SET_HIGHLIGHT_POLYGONS),
};
