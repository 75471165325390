import React from 'react';

import { Section } from '../Section';
import { useStyles } from './styles';
import { ISectionsList } from './types';
import { useSplitHouseToSections } from './useSplitHouseToSections';

export const SectionsList: React.FC<ISectionsList> = React.memo(({
  floorList,
  houseName,
  houseData,
}) => {
  const styles = useStyles({});
  const { sectionKey } = useSplitHouseToSections(houseData);

  return (
    <div id="sectionListWrapper" className={styles.sectionListWrapper}>
      <>
        {sectionKey.map((section) => {
          return (
            <Section
              key={section}
              floorList={floorList}
              sectionName={`${houseName} / ${section}`}
              sectionData={houseData[section]}
            />
          );
        })}
      </>
    </div>
  );
});
