export const errors = {
  required: 'Required',
  unexpectedError: 'Unexpected error occurred',
  email: 'The email is not valid',
  server: 'Server Error',
  timeout: 'Server didn\'t respond in time.',
  connection: 'Server not available, bad dns.',
  network: 'Network not available.',
  cancelRequest: 'Request has been cancelled.',
};
