import React from 'react';

import { useBuildType } from 'src/hooks';
import { BuildEnv } from 'src/typings/env';
import { FilterMapSpinner } from './ComponentSpinner';
import { FilterMapCrossTable } from './ComponentCrosstable';

export const FilterMap: React.FC = () => {
  const { buildType } = useBuildType();

  const renderBuildType = () => {
    switch (buildType) {
      case BuildEnv.spinner: {
        return <FilterMapSpinner />;
      }
      case BuildEnv.crossTable: {
        return <FilterMapCrossTable />;
      }
      default: {
        return null;
      }
    }
  };

  return renderBuildType();
};
