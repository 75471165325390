/* eslint-disable react/no-array-index-key */
import React, {
  useEffect, useState,
} from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
// import whyDidYouRender from '@welldone-software/why-did-you-render';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MemoizedRenderSvg } from 'src/components/ViewSpinner/components/RenderSvg';
import { ButtonBackSpinner } from 'src/components/ButtonBackSpinner';
import { WindRose } from 'src/components/WindRose';
import { RenderWindowView } from 'src/components/RenderWindowView';
import { Delayed } from 'src/components/Delayed';
import { AppPopover } from 'src/components/AppPopover';
import {
  useAppVisibilityBtn,
  useDraggableMobileMenu,
  useShowInfrastructure,
  useAppVisibilitySpinnerBtn,
} from 'src/hooks/app';
import { getIsRerender } from 'src/store/view-2d/selectors';
import { ShowInfrastructure } from 'src/components/ViewSpinner/components/Infrastructure';
import { useInfrastructureIds } from 'src/hooks/infrastructure';
import { makeWindRoseData } from 'src/components/WindRose/state';
import { instanceOfPlacementWindow } from 'src/typings/spinner';
import {
  useInitSpinner,
  useGetSpinnerSvg,
} from './hooks';
import { IViewSpinner } from './types';
import { useStyles } from './styles';

// whyDidYouRender(React, {
//   // onlyLogs: true,
//   titleColor: 'green',
//   diffNameColor: 'aqua',
//   trackAllPureComponents: false,
//   include: [/^ViewSpinnerInner/],
// });

export const ViewSpinnerInner: React.FC<IViewSpinner> = React.memo(({
  isFloors,
  setFullSizeFloor,
}) => {
  const styles = useStyles({});
  const theme: Theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isRerender = useSelector(getIsRerender);
  const { isOpenDraggableMenu } = useDraggableMobileMenu();
  const { displayedSvgData } = useGetSpinnerSvg();
  const { infrastructureFilteredIds } = useInfrastructureIds();
  const { mainSpinnerBtnViews } = useAppVisibilitySpinnerBtn();
  const { isInfrastructure, showInfrastructure } = useShowInfrastructure();
  const [initWindRoseData, setInitWindRoseData] = useState<Function | null>(null);
  const {
    listOfStops,
    svgSize,
    devicePixelRatio,
    spinnerState,
  } = useInitSpinner(isFloors, setFullSizeFloor, initWindRoseData);
  const { infrastructure_button } = useAppVisibilityBtn();

  useEffect(() => {
    setInitWindRoseData(makeWindRoseData);
    return () => {
      setInitWindRoseData(null);
    };
  }, []);

  return (
    <div
      id="spinner_place"
      className={
        `${isFloors ? styles.floorsView : ''} ${
          isOpenDraggableMenu ? '' : styles.blurStyle}`
      }
    >
      {!isFloors && <ButtonBackSpinner className={styles.backButton} />}
      <canvas id="spinner_canvas" className={styles.styleCanvas} />
      <div
        className={`${styles.svgPlace} ${
          showInfrastructure ? styles.showInfrastructure : styles.hideInfrastructure}
          hide`}
        id="svg_place"
      >
        {displayedSvgData && Object.keys(displayedSvgData).map((svgFrame) => {
          return spinnerState.stopFrame == svgFrame ? (
            <Delayed key={svgFrame} waitBeforeShow={50}>
              <svg
              // here compare string and number, because I used == instead ===
              // eslint-disable-next-line
                data-frame={svgFrame}
                viewBox={`0 0 ${svgSize.w} ${svgSize.h}`}
                style={{
                  marginLeft: `${spinnerState.dx / devicePixelRatio}px`,
                  marginTop: `${spinnerState.dy / devicePixelRatio}px`,
                  width: `${spinnerState.dw / devicePixelRatio}px`,
                  height: `${spinnerState.dh / devicePixelRatio}px`,
                  shapeRendering: isMdScreen ? 'optimizeSpeed' : 'auto',
                }}
              >
                { (!isRerender && _.isArray(displayedSvgData[svgFrame]))
                && displayedSvgData[svgFrame].map((frame, index) => {
                  // @ts-ignore
                  // Need re-think logic for this ignore
                  const isAlwaysHighlight = infrastructureFilteredIds.includes(frame?.attr?.infrastructure?.toString());

                  if (instanceOfPlacementWindow(frame?.attr)) {
                    return (<RenderWindowView key={index} svg={frame} />);
                  }

                  return (
                    <MemoizedRenderSvg
                      key={index}
                      isAlwaysHighlight={isAlwaysHighlight && isInfrastructure}
                      svg={frame}
                    />
                  );
                })}
              </svg>
            </Delayed>
          ) : null;
        })}
      </div>
      <div id="spinner_control" className={`${mainSpinnerBtnViews}`}>
        {
          listOfStops.length > 1 && (
            <>
              <div id="turn_left"><i className="material-icons">arrow_back</i></div>
              <div id="turn_right"><i className="material-icons">arrow_forward</i></div>
            </>
          )
        }
        <div id="spinner_zoom"><i className="material-icons">zoom_in</i></div>
        <div id="spinner_unzoom"><i className="material-icons">zoom_out</i></div>
      </div>

      {initWindRoseData && <WindRose initWindRoseData={initWindRoseData} />}
      <div id="porgress_bar" />
      {infrastructure_button && !isFloors ? <ShowInfrastructure /> : null}
      <AppPopover />
    </div>
  );
});

ViewSpinnerInner.displayName = 'ViewSpinnerInner';
