import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  canvasStyle: {
    right: '0px',
  },
  svgPlace: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    '& svg.hide': {
      display: 'none',
    },
  },
  spinnerOnsSide: {
    height: '300px',
  },
  hideRotate: {
    display: 'none',
    '& + .spinner_zoom': {
      marginLeft: '0px',
    },
  },
}));
