import { useCallback, useEffect, useState } from 'react';

export const usePopoverState = () => {
  const [targetIsEnter, setTargetIsEnter] = useState(false);
  const [isOpenPopover, setOpenPopover] = useState(false);

  useEffect(() => {
    if (!targetIsEnter) {
      setOpenPopover(false);
    }
  }, [targetIsEnter]);

  return {
    isOpenPopover,
    setOpenPopover,
    setTargetIsEnter,
  };
};
