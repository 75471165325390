import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { isMenuOpen } from 'src/store/app/selectors';
import { Actions } from 'src/store/app/actions';

export const useAppMenuState = () => {
  const dispatch = useDispatch();
  const isAppMenuOpen = useSelector(isMenuOpen);

  const handleToggleAppMenu = useCallback(() => {
    dispatch(Actions.isMenuOpen(!isAppMenuOpen));
  }, [isAppMenuOpen, dispatch]);

  const handleToggleOnStateChange = useCallback((state: any) => {
    if (state?.isOpen === false) {
      dispatch(Actions.isMenuOpen(false));
    }
  }, [dispatch]);

  return {
    isAppMenuOpen,
    handleToggleAppMenu,
    handleToggleOnStateChange,
  };
};
