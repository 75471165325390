import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  logoUpload: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  previewUpload: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1,
  },
  managerControl: {
    padding: '10px 20px',
  },
  deleteManager: {
    border: '1px solid #E66159',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    justifyContent: 'center',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  error: {
    position: 'absolute',
    bottom: '-15px',
    fontSize: '11px',
    color: 'red',
  },
}));
