import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { localStorageConstants } from 'src/constants';
import { Actions } from 'src/store/app/actions';

export const useFavoriteListState = (id: string) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const getStorageItems = useCallback(() => localStorage.getItem(localStorageConstants.favoriteList), []);

  const checkIsActiveItem = useCallback(() => {
    const getFavList = getStorageItems();

    if (getFavList) {
      const parseFav: string[] = JSON.parse(getFavList);

      if (parseFav.includes(id)) {
        setIsActive(true);
      }

      return;
    }

    setIsActive(false);
  }, [id, getStorageItems]);

  const toggleFavoriteItem = useCallback(() => {
    const getFavList = getStorageItems();

    if (getFavList) {
      const parseFav: string[] = JSON.parse(getFavList);

      if (parseFav.includes(id)) {
        const removeItem = parseFav.filter((item) => item !== id);

        localStorage.setItem(localStorageConstants.favoriteList, JSON.stringify(Array.from(new Set(removeItem))));
        dispatch(Actions.setFavoriteCount(removeItem.length));
        setIsActive(false);
      } else {
        parseFav.push(id);

        localStorage.setItem(localStorageConstants.favoriteList, JSON.stringify(Array.from(new Set(parseFav))));
        dispatch(Actions.setFavoriteCount(parseFav.length));
        setIsActive(true);
      }
    } else {
      localStorage.setItem(localStorageConstants.favoriteList, JSON.stringify([id]));
      setIsActive(true);
    }
  }, [id, getStorageItems, dispatch]);

  const handleMouseDownUp = useCallback(() => {
    setIsMouseDown(!isMouseDown);
  }, [isMouseDown]);

  useEffect(() => {
    checkIsActiveItem();
  }, [id, getStorageItems, checkIsActiveItem]);

  return {
    isMouseDown,
    isActive,
    checkIsActiveItem,
    toggleFavoriteItem,
    handleMouseDownUp,
  };
};
