import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Field, Form } from 'react-final-form';
import { setIn } from 'final-form';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import CircularProgress from '@mui/material/CircularProgress';

import { ButtonApp } from 'src/components/Button';
import { FormTypes } from 'src/typings/forms';
import TextField from '@mui/material/TextField';
import { useTranslations } from 'src/hooks/translataions';
import { appTranslations } from 'src/constants/locals/appTranslation';
import { getFormConfig } from 'src/store/requests/app/selector';
import { useSelector } from 'react-redux';
import { IAppForm } from './types';
import { useStyles } from './styles';

export const AppForm: React.FC<IAppForm> = ({
  isLoading,
  appendClassName,
  schema,
  handleSendForm,
}) => {
  const styles = useStyles({});
  const formRef = useRef(null);
  const formConfig = useSelector(getFormConfig);

  useEffect(() => {
    if (window.afterCreateAppForm) {
      window.afterCreateAppForm(formRef.current);
    }
  }, []);

  const { getTranslations } = useTranslations();
  const validationSchema = Yup.object().shape({
    ...schema.reduce((acc, schemaItem) => {
      if (schemaItem.validation) {
        return {
          ...acc,
          [schemaItem.name]: schemaItem.validation,
        };
      }

      return acc;
    }, {}),
  });

  const validate = async (values: any) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      return null;
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      return errors;
    }
  };

  return (
    <Form
      onSubmit={(values, form) => {
        handleSendForm(values);
      }}
      validate={validate}
      render={({ handleSubmit, hasValidationErrors }) => {
        return (
          <form
            className={`${styles.formWrapper} ${appendClassName || ''}`}
            onSubmit={handleSubmit}
            data-gaid="appForm"
            ref={formRef}
          >
            {schema.map(({
              initialValue,
              type,
              fieldType,
              name,
              placeholder,
              options,
            }) => {
              switch (fieldType) {
                case FormTypes.email:
                case FormTypes.text:
                case FormTypes.hidden:
                  return (
                    <Field
                      key={`field_${name}`}
                      name={name}
                      initialValue={initialValue}
                    >
                      {(props) => (
                        <div className={`${styles.wrapperField} ${fieldType === FormTypes.hidden ? styles.isHidden : ''}`}>
                          <TextField
                            type={type}
                            label={getTranslations(placeholder)}
                            variant="outlined"
                            {...props.input}
                          />
                          {
                            props.meta?.modified
                            && props.meta?.error
                            && <span className={styles.errorText}>{getTranslations(props.meta.error)}</span>
                          }
                        </div>
                      )}
                    </Field>
                  );
                case FormTypes.phone:
                  return (
                    <Field
                      key={`field_${name}`}
                      name={name}
                    >
                      {(props) => {
                        return (
                          <div className={styles.wrapperField}>
                            <MaterialUiPhoneNumber
                              label={getTranslations(placeholder)}
                              // onChange={(e) => {
                              //   console.log('TARET', e.target.value);
                              //   props.input.onChange(e);
                              // }}
                              inputProps={{
                                onChange: props.input.onChange,
                              }}
                              defaultCountry={formConfig?.form_default_country ? formConfig?.form_default_country.toLowerCase() : 'ua'}
                              variant="outlined"
                              // @ts-ignore
                              {...props.input}
                              value={props.input.value}
                            />
                            {
                              props.meta?.modified
                              && props.meta?.error
                              && <span className={styles.errorText}>{getTranslations(props.meta.error)}</span>
                            }
                          </div>
                        );
                      }}
                    </Field>
                  );
                case FormTypes.textarea:
                  return (
                    <Field
                      name={name}
                      key={`field_${name}`}
                    >
                      {(props) => (
                        <div className={styles.wrapperField}>
                          <TextField
                            multiline
                            rows={4}
                            type={type}
                            label={getTranslations(placeholder)}
                            variant="outlined"
                            {...props.input}
                          />
                          {
                            props.meta?.modified
                            && props.meta?.error
                            && <span className={styles.errorText}>{getTranslations(props.meta.error)}</span>
                          }
                        </div>
                      )}
                    </Field>
                  );

                default:
                  return null;
              }
            })}
            <ButtonApp
              disabled={hasValidationErrors || isLoading}
              variant="contained"
              type="submit"
              customStyles={styles.sendButton}
              text={
                isLoading
                  ? <CircularProgress style={{ color: 'white' }} size="24px" />
                  : getTranslations(appTranslations.appSend)
              }
            />
          </form>
        );
      }}
    />
  );
};
