import { IAppMenuRequest } from 'src/typings/app';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface IAppMenuRequestReducer {
  isLoading: boolean;
  error: Error | null;
  data: IAppMenuRequest[] | [];
}

export const initialState: IAppMenuRequestReducer = {
  isLoading: true,
  error: null,
  data: [],
};

export const appMenuRequestReducer = (
  state = initialState,
  { type, payload }: IAction,
): IAppMenuRequestReducer => {
  switch (type) {
    case ActionTypes.GET_APP_CLIENT_MENU_MODULE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionTypes.GET_APP_CLIENT_MENU_MODULE_RESET: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.GET_APP_CLIENT_MENU_MODULE_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    case ActionTypes.GET_APP_CLIENT_MENU_MODULE_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    default:
      return state;
  }
};
