import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperModal: {
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
  },
  modalWindow: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end',
    },
  },
  wrapperContent: {
    [theme.breakpoints.down('md')]: {
      borderRadius: '20px 20px 0px 0px',
      margin: 'unset',
    },
  },
}));
