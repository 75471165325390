import React, { useCallback } from 'react';

import { ListOfDropdown } from 'src/typings/filter';
import { DropdownScale } from 'src/components/dropdowns/DropdownScale';
import { useStyles } from 'src/components/dropdowns/styles';
import { DropdownMultiCheckBoxFilter } from 'src/components/DropdownsFilter/DropdownMulticheckbox';
import { IDropdownFilterGenerator } from './types';
import { DropdownSingleFilter } from './DropdownSingle';

export const DropdownFilterGenerator: React.FC<IDropdownFilterGenerator> = ({
  isHide,
  dropdownInfo,
}) => {
  const styles = useStyles({});

  const handleDropdownGenerate = useCallback(() => {
    switch (dropdownInfo.type) {
      case ListOfDropdown.dropdownSelectList:
      case ListOfDropdown.dataList:
      case ListOfDropdown.dropdownRadioList:
        return <DropdownSingleFilter dropdownInfo={dropdownInfo} />;
      case ListOfDropdown.dropdownCheckboxList:
      case ListOfDropdown.datalistCheckboxList:
        return <DropdownMultiCheckBoxFilter dropdownInfo={dropdownInfo} />;
      case ListOfDropdown.scaleRange:
        return <DropdownScale dropdownInfo={dropdownInfo} />;
      default:
        return null;
    }
  }, [dropdownInfo]);

  return (
    <div className={`${styles.wrapperDropdown} ${isHide ? styles.isHide : ''}`}>
      {handleDropdownGenerate()}
    </div>
  );
};
