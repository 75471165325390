import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';

export const ControlledAutocomplete = ({
  options, renderInput, getOptionLabel, onChange: ignored, control, defaultValue, name, renderOption,
}: any) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          onChange={(e, data) => {
            if (ignored) {
              ignored(data);
            }
            onChange(data);
          }}
          {...props}
        />
      )}
      // @ts-ignore
      onChange={([, data]) => data}
      defaultValue={defaultValue || null}
      name={name}
      control={control}
    />
  );
};
