import { useState, useEffect } from 'react';
import { IUsers } from 'src/typings/user';
import { useGetAuthData } from 'src/hooks';

import { getUsersIdData } from 'src/api';

export const useGetUsers = (id: string|undefined) => {
  const [user, setUser] = useState<IUsers|null>(null);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  useEffect(() => {
    let cleanupFunction = false;
    if (id) {
      getUsersIdData(jwt, id)
        .then((res: any) => {
          if (!cleanupFunction) {
            setUser(res.data);
          }
        });
    }

    return () => {
      cleanupFunction = true;
    };
  }, [jwt, id]);

  return user;
};
