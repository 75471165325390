import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';
import { ISpinnerSvgData } from 'src/typings/spinner';
import { getCurrentSpinner } from 'src/store/spinner/selectors';

export const useGetSpinnerSvg = () => {
  const [displayedSvgData, setDisplayedSvgData] = useState<ISpinnerSvgData>();
  const spinnerSvgCurrentData = useSelector(requestSelectors.spinner.getSpinnerViewData);
  const currentSpinner = useSelector(getCurrentSpinner);

  useEffect(() => {
    if (spinnerSvgCurrentData && currentSpinner) {
      const spinnerSvg = spinnerSvgCurrentData[currentSpinner.name][currentSpinner['name-id']].additional_data.spinner_svg;
      setDisplayedSvgData(spinnerSvg);
    }
  }, [displayedSvgData, spinnerSvgCurrentData, currentSpinner]);

  return {
    displayedSvgData,
  };
};
