import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperButton: (props: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    color: props.filterBackgroundColor,
    backgroundColor: `${props.primaryColor}e6`,
    fontSize: '13px',
    textTransform: 'capitalize',
    padding: '10px',
    whiteSpace: 'nowrap',
    '& .appFillStyles': {
      fill: props.filterBackgroundColor,
    },
    '& .appStrokeStyles': {
      stroke: props.filterBackgroundColor,
    },
  }),
  wrapperButtonHouse: {
    padding: '0px !important',
  },
  activeBtn: {
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '4px 0px 0px 4px',
    '&:hover': {
      background: 'rgba(44, 59, 70)',
    },
  },
}));
