/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Loader } from 'src/components/Loader';
import { ButtonApp } from 'src/components/Button';
import { IComplex } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { patchUsersIdData, getUsersByDev } from 'src/api';
import { translations } from 'src/constants/translations';
import { IUsers } from 'src/typings/user';

interface IAddExistingManager {
  complex: IComplex;
}

export const AddExistingManager: React.FC<IAddExistingManager> = ({
  complex,
}) => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [currentValue, setCurrentValue] = useState<string[]>([]);
  const [users, setUsers] = useState<{[key: string]: IUsers} | null>(null);

  const funcFetch = async () => {
    if (complex?.developers[0]) {
      const { data } = await getUsersByDev(jwt, complex?.developers[0].id);
      const filteredUsers: {[key: string]: IUsers} = {};
      data['hydra:member'].forEach((user: IUsers) => {
        const findInd = user.complexes.findIndex((comp) => comp.id === complex.id);
        if (findInd === -1) {
          filteredUsers[user['@id']] = user;
        }
      });
      setUsers(filteredUsers);
    }
  };

  useEffect(() => {
    funcFetch();
  }, [jwt, complex]);

  const onSubmit = () => {
    if (users) {
      currentValue.forEach((userId) => {
        const userComplexes = users[userId].complexes.map((comp) => comp['@id']);
        userComplexes.push(complex['@id']);
        patchUsersIdData(jwt, users[userId].id, {
          complexes: userComplexes,
        });
      });
      setCurrentValue([]);
      setTimeout(async () => {
        await funcFetch();
        setSuccess(true);
      }, 200);
    }
  };

  const handleChange = (e:any) => {
    const { value } = e.target;
    setCurrentValue(typeof value === 'string' ? value.split(',') : value);
  };

  if (!users) {
    return (
      <Box
        width="400px"
        height="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box fontSize="18px" width="400px">
      <Box mb={6} alignItems="center">
        <Box width="100%" mb={2}>
          {translations.selectManagers}
        </Box>
        <Box width="100%">
          <FormControl variant="outlined">
            <Select
              variant="outlined"
              id="addExistingManagerLabel"
              multiple
              value={currentValue}
              onChange={handleChange}
              input={<OutlinedInput id="addExistingManagerSelect" />}
              renderValue={(selected) => (
                <Box display="flex" flexWrap="wrap">
                  {(selected as string[]).map((value) => (
                    <Box key={value} m="2px">
                      <Chip label={users[value].username} />
                    </Box>
                  ))}
                </Box>
              )}
            >
              {
                  Object.keys(users).length ? Object.keys(users).map((userId) => (
                    <MenuItem key={userId} value={userId}>
                      {users[userId].username}
                    </MenuItem>
                  )) : (
                    <Box
                      px={2}
                      style={{
                        pointerEvents: 'none',
                      }}
                    >
                      {translations.noManagersFound}
                    </Box>
                  )
                }
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box mb={6} display="flex" alignItems="center">
        <Box width="256px">
          <ButtonApp
            type="submit"
            color="primary"
            isCapitalize
            variant="contained"
            disabled={!currentValue.length}
            onClick={onSubmit}
            text={translations.add}
          />
        </Box>
      </Box>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} elevation={6} variant="filled" severity="success">
          Менеджеры добавлены
        </Alert>
      </Snackbar>
    </Box>
  );
};
