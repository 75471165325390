import { IFloorsSelectedSection } from 'src/typings/floors';
import { IFirstSpinner } from 'src/typings/spinner';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface IFloorsStateReducer {
  selectedHouse: IFirstSpinner | null;
  selectedSection: IFloorsSelectedSection;
  selectedFloor: string | null;
  selectedFloorNumber: string | null;
  filteredIds: string[];
  filteredSections: string[];
  selectedApartmentId: string;
  fromComplex: boolean;
}

export const initialState: IFloorsStateReducer = {
  selectedHouse: null,
  selectedSection: {
    markup: null,
    'markup-id': null,
  },
  selectedFloor: null,
  selectedFloorNumber: null,
  filteredIds: [],
  filteredSections: [],
  selectedApartmentId: '',
  fromComplex: false,
};

export const floorsReducer = (
  state = initialState,
  { type, payload }: IAction,
): IFloorsStateReducer => {
  switch (type) {
    case ActionTypes.SET_SELECTED_SECTION: {
      return {
        ...state,
        selectedSection: payload,
      };
    }

    case ActionTypes.SET_SELECTED_HOUSE: {
      return {
        ...state,
        selectedHouse: payload,
      };
    }

    case ActionTypes.SET_SELECTED_FLOOR: {
      return {
        ...state,
        selectedFloor: payload,
      };
    }

    case ActionTypes.SET_SELECTED_FLOOR_NUMBER: {
      return {
        ...state,
        selectedFloorNumber: payload,
        selectedApartmentId: '',
      };
    }

    case ActionTypes.SET_FLOOR_REDUCER: {
      return {
        ...state,
        ...payload,
      };
    }

    case ActionTypes.SET_FILTERED_FLOORS_IDS: {
      return {
        ...state,
        filteredIds: payload,
      };
    }
    case ActionTypes.SET_FILTERED_SECTIONS: {
      return {
        ...state,
        filteredSections: payload,
      };
    }

    case ActionTypes.SET_SELECTED_APARTMENT_ID: {
      return {
        ...state,
        selectedApartmentId: payload,
      };
    }
    case ActionTypes.SET_FROM_COMPLEX: {
      return {
        ...state,
        fromComplex: payload,
      };
    }

    case ActionTypes.SET_INITIAL_REDUCER: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
