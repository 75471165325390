import React from 'react';

import { SpinnerMenuMobile } from 'src/components/FilterSpinner/components/SpinnerMenuMobile';
import { useStyles } from './styles';

export const AppBottomMenu: React.FC = () => {
  const styles = useStyles({});

  return (
    <div className={styles.wrapperBottomNavigator}>
      <SpinnerMenuMobile />
    </div>
  );
};
