import React from 'react';

import { IPlacementInfo } from 'src/components/ListOfLayoutApartments/types';
import { IFilterHeaderInfo, ListOfKeysFilterAllData } from 'src/typings/filter';
import { useTranslations, useBuildType } from 'src/hooks';
import { shadeColor } from 'src/helpers';
import { useGenerateShowingInfo } from '../../hooks';
import { useStyles } from './styles';

export const InfoHeader: React.FC<IPlacementInfo> = ({
  itemData,
  filterConfigurator,
  displayedType,
}) => {
  const styles = useStyles({});
  const { isCrossTable } = useBuildType();
  const { getTranslations } = useTranslations();
  const renderItems: { [key: string]: IFilterHeaderInfo | any } = useGenerateShowingInfo(filterConfigurator[displayedType]);
  // console.log('filterConfigurator[displayedType]', filterConfigurator[displayedType], renderItems);

  const bgColor = isCrossTable
    ? (itemData?.[ListOfKeysFilterAllData.placementStatusCrossTableColor]?.toString()
  || itemData?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '')
    : (itemData?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '');
  return (
    <div className={styles.apartmentHeaderInfo}>
      {Object.keys(renderItems).map((renderItem, index) => {
        const renderData = renderItems[renderItem];
        const placementData = itemData[renderItem];

        if (itemData[renderItem]) {
          let { color } = renderData;
          if (renderItem === 'placement_status.module_name') {
            color = renderData.color || itemData[ListOfKeysFilterAllData.placementStatusColor];
          }
          return (
            <div
              key={renderItem}
              style={{ background: color }}
              className={styles.headerInfo}
            >
              {`${renderData['value-prefix'] || ''}`}
              {renderData['use-name'] && `${getTranslations(renderItem)}`}
              {` ${getTranslations(placementData)} `}
              {renderData['value-sufix'] || ''}
            </div>
          );
        }

        return null;
      })}
      {itemData[ListOfKeysFilterAllData.placementAdditionalCondition] && (
        <div
          className={styles.headerInfo}
          style={{
            background: shadeColor(bgColor, 60),
            color: 'black',
          }}
        >
          {itemData[ListOfKeysFilterAllData.placementAdditionalCondition]}
        </div>
      )}
    </div>
  );
};
