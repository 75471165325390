import { action } from 'src/store/common';
import { IFloorsSelectedSection } from 'src/typings/floors';
import { IFirstSpinner } from 'src/typings/spinner';

export const ActionTypes = {
  SET_SELECTED_SECTION: '[FLOORS]SET_SELECTED_SECTION',
  SET_SELECTED_HOUSE: '[FLOORS]SET_SELECTED_HOUSE',
  SET_SELECTED_FLOOR: '[FLOORS]SET_SELECTED_FLOOR',
  SET_SELECTED_FLOOR_NUMBER: '[FLOORS]SET_SELECTED_FLOOR_NUMBER',
  SET_FLOOR_REDUCER: '[FLOORS]SET_FLOOR_REDUCER',
  SET_INITIAL_REDUCER: '[FLOORS]SET_INITIAL_REDUCER',
  SET_FILTERED_FLOORS_IDS: '[FLOORS]SET_FILTERED_FLOORS_IDS',
  SET_FILTERED_SECTIONS: '[FLOORS]SET_FILTERED_SECTIONS',
  SET_SELECTED_APARTMENT_ID: '[FLOORS]SET_SELECTED_APARTMENT_ID',
  SET_FROM_COMPLEX: '[FLOORS]SET_FROM_COMPLEX',
};

export const Actions = {
  setSelectedSection: action<IFloorsSelectedSection>(ActionTypes.SET_SELECTED_SECTION),
  setSelectedHouse: action<IFirstSpinner>(ActionTypes.SET_SELECTED_HOUSE),
  setSelectedFloor: action<string>(ActionTypes.SET_SELECTED_FLOOR),
  setSelectedFloorNumber: action<string>(ActionTypes.SET_SELECTED_FLOOR_NUMBER),
  setFloorReducer: action<string>(ActionTypes.SET_FLOOR_REDUCER),
  setInitialReducer: action(ActionTypes.SET_INITIAL_REDUCER),
  setFilteredIdsReducer: action<string[]>(ActionTypes.SET_FILTERED_FLOORS_IDS),
  setFilteredSections: action<string[]>(ActionTypes.SET_FILTERED_SECTIONS),
  setSelectedApartmentId: action<string>(ActionTypes.SET_SELECTED_APARTMENT_ID),
  setFromComplex: action<boolean>(ActionTypes.SET_FROM_COMPLEX),
};
