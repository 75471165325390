import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { isMobileFilterOpen } from 'src/store/app/selectors';
import { Actions } from 'src/store/app/actions';

export const useToggleMobileFilter = () => {
  const dispatch = useDispatch();
  const isMobileMenuOpen = useSelector(isMobileFilterOpen);

  const handleToggleOpenState = useCallback(() => {
    dispatch(Actions.isMobileFilterOpen(!isMobileMenuOpen));
  }, [isMobileMenuOpen, dispatch]);

  return {
    isMobileMenuOpen,
    handleToggleOpenState,
  };
};
