import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { IGlobalPolygonConfig } from 'src/typings/app';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  selectedDeal: (props: any) => ({
    fill: props.hover_body_color,
    fillOpacity: props.hover_body_opacity,
    stroke: props.hover_border_color,
  }),
  iconWrapper: {
    cursor: 'pointer',
  },
  textTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  hoverText: {
    transform: 'translateX(-50%)',
    display: 'none',
  },
}));
