import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { requestSelectors } from 'src/store/requests/selectors';
import {
  ConfiguratorDisplayedTypeInner,
  ListOfKeysFilterAllData,
  PlacementDisplayedType,
} from 'src/typings/filter';
import { getComplexId, getCrmData } from 'src/store/app/selectors';

import {
  useFilterConfigurator,
  useTranslations,
  useBuildType,
} from 'src/hooks';
import { appIcon } from 'src/assets';
import { ApartmentConnectDeal } from 'src/components/ApartmentConnectDeal';
import { ButtonApp } from 'src/components/Button';
import { locals } from 'src/constants/locals';
import { Actions } from 'src/store/app/actions';
import { pathList } from 'src/route-list';
import { useWritePositionScroll } from './useWritePositionScroll';
import { useStyles } from './styles';
import { ILayoutApartmentItem } from './types';
import {
  EyeIcon,
  FavIcon,
  InfoAdditional,
  InfoHeader,
  InfoIcon,
  InfoKey,
  InfoPlacing,
} from './components';

export const LayoutApartmentItem: React.FC<ILayoutApartmentItem> = React.memo(({
  isShowEye = false,
  withCloseButton = false,
  isDisabledLink = false,
  isLayoutTab = false,
  isShowFavIcon = true,
  withMoreButton = false,
  placementDisplayedType = PlacementDisplayedType.filter,
  withSizeLimits = true,
  property,
  parentScroll,
  wrapperStyle,
  wrapperImageStyle,
  onClosePopover,
}) => {
  const dispatch = useDispatch();
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);

  const { getTranslations } = useTranslations();
  const { handleWritePositionScroll } = useWritePositionScroll();
  const { isCrossTable } = useBuildType();
  const complexId = useSelector(getComplexId);
  const crmData = useSelector(getCrmData);
  const { filterConfigurator, paddingTileSize } = useFilterConfigurator(placementDisplayedType, property[ListOfKeysFilterAllData.id]);
  const mainImage = property.main_filter_img && property.main_filter_img.replace('desktop', 'mobile');
  const mainImageFromArray = property[ListOfKeysFilterAllData.placementGallery]?.length !== 0
    && property[ListOfKeysFilterAllData.placementGallery]?.[0].replace('desktop', 'mobile');
  const status = property?.[isCrossTable ? ListOfKeysFilterAllData.placementStatusCrossTableName : ListOfKeysFilterAllData.placementStatusModuleName];
  const complexPrefix = isCrossTable ? `/${complexId}` : '';

  const handleLinkClick = useCallback(() => {
    handleWritePositionScroll(parentScroll);
    dispatch(Actions.setIsOpenAppartSidebar(false));
  }, [dispatch, handleWritePositionScroll, parentScroll]);
  // console.log('PROPERTY', property);
  return (
    <>
      {withCloseButton && (
        <Box className={styles.closeButtonPopover} onClick={onClosePopover}>
          <CloseIcon />
        </Box>
      )}
      <Box
        minWidth={withCloseButton ? 210 : 'none'}
        pt={withCloseButton ? 3 : 0}
        className={`${styles.wrapperProperty} ${
          isDisabledLink ? styles.disableLink : ''} ${wrapperStyle}`}
      >
        {isShowFavIcon && !isCrossTable && <FavIcon itemId={property.id} />}
        {/* {!isLayoutTab && !isDisabledLink && <FavIcon itemId={property.id} />} */}
        {
          !isLayoutTab
          && !isDisabledLink
          && isShowEye
          && (
            <EyeIcon
              itemId={property.id}
              property={property}
            />
          )
        }
        <Link
          to={`${!isDisabledLink ? `${complexPrefix}${pathList.own}/${property.id}` : ''}`}
          onClick={handleLinkClick}
        >
          <div
            className={`${styles.wrapperImageProperty} ${
              withSizeLimits && styles.imageSizeLimits} ${
              wrapperImageStyle || ''}`}
            style={paddingTileSize ? { padding: `${paddingTileSize}px`, objectFit: 'contain' } : undefined}
          >
            <img
              alt="plan"
              className={styles.imageStyle}
              style={paddingTileSize ? { objectFit: 'contain', borderRadius: '0px' } : undefined}
              src={mainImage || mainImageFromArray || appIcon.noImg.default}
            />
          </div>
          {filterConfigurator && (
          <div className={styles.wrapperApartmentInfo}>
            <InfoHeader
              itemData={property}
              filterConfigurator={filterConfigurator}
              displayedType={ConfiguratorDisplayedTypeInner.headerInfo}
            />
            <InfoPlacing
              itemData={property}
              filterConfigurator={filterConfigurator}
              displayedType={ConfiguratorDisplayedTypeInner.placingInfo}
            />
            <InfoKey
              itemData={property}
              filterConfigurator={filterConfigurator}
              displayedType={ConfiguratorDisplayedTypeInner.keyInfo}
            />
            <InfoIcon
              itemData={property}
              filterConfigurator={filterConfigurator}
              displayedType={ConfiguratorDisplayedTypeInner.iconsAndShortInfo}
            />
            <InfoAdditional
              itemData={property}
              filterConfigurator={filterConfigurator}
              displayedType={ConfiguratorDisplayedTypeInner.additionalInfo}
            />
            {(isCrossTable && !isLayoutTab) && (
            <Box
              style={{
                backgroundColor: '#F2F2F2',
              }}
              p={2}
              fontSize="14px"
              borderRadius="10px"
              width="100%"
              mb={3}
            >
              <Box
                fontWeight="600"
                display="flex"
                alignItems="center"
                mb={1}
              >
                <Box
                  width="15px"
                  height="15px"
                  borderRadius="2px"
                  fontWeight="600"
                  mr={1}
                  style={{
                    backgroundColor: isCrossTable
                      ? (property?.[ListOfKeysFilterAllData.placementStatusCrossTableColor]?.toString()
                      || property?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '')
                      : (property?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || ''),
                  }}
                />
                {/* @ts-ignore */}
                {property?.[ListOfKeysFilterAllData.placementStatusCrossTableName]}
              </Box>
              <Box mb={1}>
                {property?.[ListOfKeysFilterAllData.userName] || ''}
              </Box>
              {property[ListOfKeysFilterAllData.updateTime] && (
              <Box
                display="flex"
                alignItems="center"
              >
                <Box color="#757575" mr={1}>
                  Дiйсний до:
                </Box>
                {property?.[ListOfKeysFilterAllData.updateTime]}
              </Box>
              )}
            </Box>
            )}
            {withMoreButton && (
              <ButtonApp
                disabled={isDisabledLink}
                fullWidth
                text={
                  `${isDisabledLink ? status : getTranslations(locals.appTranslations.appMore)}`
                }
                variant="contained"
              />
            )}
          </div>
          )}
        </Link>
        {withCloseButton && crmData && (
          <Box p={2} pt={0}>
            <ApartmentConnectDeal placeInfo={property} isSmall />
          </Box>
        )}
      </Box>
    </>
  );
});
