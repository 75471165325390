import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperInfrastructureType: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  wrapperImage: {
    width: '100%',
    marginRight: '10px',
    [theme.breakpoints.down('md')]: {
      marginRight: '0px',
      marginBottom: '10px',
    },
  },
  fullScreenImage: {
    background: 'white',
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    display: 'flex',
    color: theme.palette.primary.main,
  },
  linkNewWindow: {
    position: 'relative',
    '& > img': {
      maxWidth: '160px',
      minWidth: '160px',
    },
  },
  textTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textBody: {
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
  },
  withoutBody: {
    marginRight: '0px',
    '& img': {
      maxWidth: 'none',
      minWidth: 'none',
      width: '100%',
    },
  },
  zoomButton: {
    borderRadius: '0px',
    position: 'absolute',
    top: '0px',
    right: '0px',
    background: '#ffffff99',
    minWidth: 'auto',
    width: '48px',
    height: '48px',
    '&:hover': {
      background: 'white',
    },
  },
  zoomStyle: {
    width: '30px',
    height: '30px',
  },
  appPanoramaWrapper: {
    width: '100%',
    height: '100%',
    '& .image-gallery-content.fullscreen': {
      height: '100vh',
      top: 'unset',
      transform: 'none',
      '& .image-gallery-slide-wrapper': {
        height: '100%',
        '& .image-gallery-slides': {
          height: '100%',
          '& .image-gallery-slide': {
            height: '100%',
          },
        },
      },
    },
  },
}));
