import * as appData from './app/actions';
import * as appMenuData from './app-menu/actions';
import * as filter from './filter/actions';
import * as typeOfProperty from './type-of-property/actions';
import * as spinnerData from './spinner-views/actions';
import * as transitionsData from './translations/actions';
import * as layoutsData from './layouts/actions';

export const requestActions = {
  app: { ...appData.Actions },
  appMenu: { ...appMenuData.Actions },
  typeOfProperty: { ...typeOfProperty.Actions },
  filter: { ...filter.Actions },
  spinner: { ...spinnerData.Actions },
  layouts: { ...layoutsData.Actions },
  translations: { ...transitionsData.Actions },
};

export const requestActionTypes = {
  app: { ...appData.ActionTypes },
  appMenu: { ...appMenuData.ActionTypes },
  typeOfProperty: { ...typeOfProperty.ActionTypes },
  filter: { ...filter.ActionTypes },
  spinner: { ...spinnerData.ActionTypes },
  layouts: { ...layoutsData.ActionTypes },
  translations: { ...transitionsData.ActionTypes },
};
