import React, { useCallback } from 'react';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import FsLightbox from 'fslightbox-react';

import { InfrastructureModal } from 'src/components/modals/Infrastructure';
import { InfrastructureTypes } from 'src/typings/spinner';
import { useGetInfrastructureData } from './hooks';
import { useStyles } from './styles';
import {
  InfrastructureGallery,
  InfrastructureVideo,
  InfrastructureVideoLoop,
  InfrastructurePanorama,
} from './components';

interface IInfrastructureType {
  isOpen: boolean;
  infrastructureId: string;
  handleToggle: () => void;
}

export const InfrastructureType: React.FC<IInfrastructureType> = ({
  isOpen,
  handleToggle,
  infrastructureId,
}) => {
  const styles = useStyles({});
  const { withBody, data } = useGetInfrastructureData(infrastructureId);

  const handleRenderInfrastructureType = useCallback(() => {
    if (data) {
      switch (data['infrastructure.type']) {
        case InfrastructureTypes.gallery:
        case InfrastructureTypes.image: {
          return <InfrastructureGallery withBody={withBody} data={data} />;
        }

        case InfrastructureTypes.video: {
          return <InfrastructureVideo data={data} />;
        }

        case InfrastructureTypes.videoLoop: {
          return <InfrastructureVideoLoop data={data} />;
        }

        case InfrastructureTypes.panorama: {
          return <InfrastructurePanorama data={data} />;
        }

        default: {
          return null;
        }
      }
    }

    return null;
  }, [data, withBody]);

  return (
    <InfrastructureModal
      isOpen={isOpen}
      toggleClose={handleToggle}
      contentHeader={<p className={styles.textTitle}>{data?.['infrastructure.head_text'] || null}</p>}
    >
      <>
        {
          handleRenderInfrastructureType()
         }
      </>
    </InfrastructureModal>
  );
};
