import { action } from 'src/store/common';
import { IAppLayoutsItem } from 'src/typings/layouts';

export const ActionTypes = {
  SET_LAYOUTS_FILTERED_DATA: '[LAYOUTS]SET_LAYOUTS_FILTERED_DATA',
  CLEAR_LAYOUTS_FILTERED_DATA: '[LAYOUTS]CLEAR_LAYOUTS_FILTERED_DATA',
};

export const Actions = {
  setLayoutsFilteredData: action<IAppLayoutsItem[]>(ActionTypes.SET_LAYOUTS_FILTERED_DATA),
  clearLayoutsFilteredData: action(ActionTypes.CLEAR_LAYOUTS_FILTERED_DATA),
};
