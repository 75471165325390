import React from 'react';

import { useHoverText } from '../hooks';

interface ITagAttributeText {
  hoverId: string;
}

export const TagAttributeText: React.FC<ITagAttributeText> = ({
  hoverId,
}) => {
  const { hoverText } = useHoverText(hoverId);

  return (
    <span>{hoverText}</span>
  );
};
