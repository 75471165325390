import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from './styles';

export interface IWindRose {
  randomSpinnerId?: string;
  onTop?: boolean;
  isAppSpinner?: boolean;
  isGallery?: boolean;
  initWindRoseData?: Function | null;
}

export const WindRose: React.FC<IWindRose> = ({
  initWindRoseData,
  randomSpinnerId = '',
  onTop = false,
  isAppSpinner = false,
  isGallery = false,
}) => {
  const windRoseData = initWindRoseData ? initWindRoseData() : {};
  const isSmallScreen = useMediaQuery('(max-width:960px)');
  const styles = useStyles({});
  return (
    <div
      id={`wind_rose${randomSpinnerId}`}
      className={`${
        styles.windRose
      } ${
        onTop && styles.onTop
      } ${
        isGallery && styles.isGallery
      }`}
      style={{
        display: ((isSmallScreen) || !windRoseData?.src || windRoseData.disabled) ? 'none' : 'block',
        backgroundImage: `url(${windRoseData?.bg_src})`,
      }}
    >
      <div className={windRoseData?.bg_src ? styles.arrowWrapperWithBg : styles.arrowWrapper}>
        {windRoseData?.src
        && (
          <img
            alt="icon"
            className={windRoseData?.bg_src ? styles.arrowWithBg : styles.arrow}
            src={windRoseData?.src}
          />
        )}
      </div>
    </div>
  );
};
