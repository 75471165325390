import { IAppState } from 'src/store/types';
import { getBuildType } from '../../../helpers/buildType';
import { BuildEnv } from '../../../typings/env';

const isCrossTable = getBuildType() === BuildEnv.crossTable;
export const getAppData = (state: IAppState) => state.requests.app.data;
export const getPolygonConfig = (state: IAppState) => state.requests.app.data?.global_polygon_config;
export const getEmbeddedContactForm = (state: IAppState) => state.requests.app.data?.client_module_embedded_contact_form;
export const getLang = (state: IAppState) => (isCrossTable ? state.requests.app.data?.crosstable_language : state.requests.app.data?.module_language);
export const getAttributesContactForm = (state: IAppState) => state.requests.app.data?.client_module_by_attributes_contact_form;
export const getModuleGTM = (state: IAppState) => state.requests.app.data?.client_module_google_tag_manager;
export const getEmbeddedJS = (state: IAppState) => state.requests.app.data?.client_module_outer_embedded_js;
export const getModuleJSPath = (state: IAppState) => state.requests.app.data?.client_module_js_paths;
export const getStandartContactForm = (state: IAppState) => state.requests.app.data?.client_module_standard_contact_form;
export const getPhonesConfig = (state: IAppState) => state.requests.app.data?.client_module_phones;
export const getMainLogo = (state: IAppState) => state.requests.app.data?.main_logo;
export const getShowFlatshow = (state: IAppState) => state.requests.app.data?.show_flat_show_logo;
export const getRingoClassname = (state: IAppState) => state.requests.app.data?.ringo_class_name;
export const getRootUrl = (state: IAppState) => state.requests.app.data?.root_url;
export const getMainLogoMobile = (state: IAppState) => state.requests.app.data?.main_logo_mobile;
export const getMainColors = (state: IAppState) => state.requests.app.data?.main_colors;
export const getRequestsConfig = (state: IAppState) => state.requests.app.data?.requests;
export const getAppDataLoading = (state: IAppState) => state.requests.app.isLoading;
export const getAppDataErrors = (state: IAppState) => state.requests.app.error;
export const getGallerySpinnerPosition = (state: IAppState) => state.requests.app.data?.placement_gallery_spinners_position;
export const getGalleryPanoramaPosition = (state: IAppState) => state.requests.app.data?.placement_gallery_panorama_position;
export const getButtonVisibilityConfig = (state: IAppState) => state.requests.app.data?.buttons_visibility_config;
export const getFormConfig = (state: IAppState) => state.requests.app.data?.form;
export const getGalleryPreviews = (state: IAppState) => state.requests.app.data?.placementGallerySpecialPreviews;
export const getFavicon = (state: IAppState) => state.requests.app.data?.favicon;
export const getSpinnerDisabledFilterRequest = (state: IAppState) => state.requests.app.data?.spinnerDisableFilter;
export const getInfrastructurePanoramaFullscreen = (state: IAppState) => state.requests.app.data?.infrastructurePanoFullscreen;
export const getVisibilitySpinnerButton = (state: IAppState) => state.requests.app.data?.spinner_interactivity_buttons;
export const getTileImgParentPadding = (state: IAppState) => state.requests.app.data?.tile_img_parent_padding;
export const getPopupConfig = (state: IAppState) => state.requests.app.data?.pop_up;
