import { takeEvery, put } from 'redux-saga/effects';
import { LocationChangePayload } from 'connected-react-router';
import { Actions } from './actions';

export function* setComplexIdWatchSaga(action: {
  type: string,
  payload: LocationChangePayload,
}) {
  const winId: number|null = Number(window.complexId) || null;
  if ((winId) && action.payload.isFirstRendering) {
    yield put(Actions.setComplexId(winId));
  }
}

export function* getComplexIdWatchSaga() {
  yield takeEvery('@@router/LOCATION_CHANGE', setComplexIdWatchSaga);
}
