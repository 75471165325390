import {
  IFilterAllData, IBitrixDealData, ListOfKeysFilterAllData,
} from 'src/typings/filter';
import { View2dType } from 'src/typings/view-2d';
import { IStatus } from 'src/typings/complexes';
import { IAction } from 'src/typings/index';

import { IPlacementType } from 'src/store/type-displayed-spinner/reducer';
import { ActionTypes } from './actions';
import { ICreatioDeal, IHouse } from '../crosstable/reducer';

export enum EFloorType {
  moreInfo = 'moreInfo',
  mini = 'mini',
}

export interface SectionView2dData {
  [key: string]: IFilterAllData[];
}

export interface IView2dData {
  [key: string]: SectionView2dData
}

export interface IDisplayPlacementData {
  id: number;
  field_name: string;
  sequence: number;
  enable_for_crosstable: boolean;
  enable_for_module: boolean;
}

export interface IView2d {
  floorScrollSize: number;
  viewTypes: IDisplayPlacementData[] | null;
  selectedViewType: string;
  floorList: string[];
  selectedPlacementIds: string[];
  view2dData: IView2dData | null;
  statuses: IStatus[];
  floorItemType: EFloorType;
  isRerender: boolean;
}

export const initialState: IView2d = {
  floorScrollSize: 0,
  viewTypes: null,
  selectedViewType: View2dType.rooms,
  floorList: [],
  selectedPlacementIds: [],
  view2dData: null,
  statuses: [],
  floorItemType: EFloorType.mini,
  isRerender: false,
};

export const view2dReducer = (
  state = initialState,
  { type, payload }: IAction,
): IView2d => {
  switch (type) {
    case ActionTypes.SET_FLOOR_LIST:
      return {
        ...state,
        floorList: payload,
      };

    case ActionTypes.SET_VIEW2D_DATA:
      return {
        ...state,
        view2dData: payload,
        floorScrollSize: 0,
      };

    case ActionTypes.SET_VIEW2D_SELECTED_ID:
      return {
        ...state,
        selectedPlacementIds: payload,
      };

    case ActionTypes.SET_VIEW2D_SELECTED_TYPE:
      return {
        ...state,
        selectedViewType: payload,
      };

    case ActionTypes.SET_VIEW2D_DISPLAY_TYPES:
      return {
        ...state,
        viewTypes: payload,
      };

    case ActionTypes.SET_VIEW2D_FLOOR_SCROLL_SIZE:
      return {
        ...state,
        floorScrollSize: payload,
      };

    case ActionTypes.SET_VIEW2D_STATUSES:
      return {
        ...state,
        statuses: payload,
      };

    case ActionTypes.SET_FLOOR_ITEM_TYPE:
      return {
        ...state,
        floorItemType: payload,
      };

    case ActionTypes.SET_IS_RERENDER:
      return {
        ...state,
        isRerender: true,
      };

    case ActionTypes.CLEAR_VIEW2D:
      return {
        ...initialState,
      };

    case ActionTypes.UPDATE_PLACEMENT: {
      const { data, lang }: {
        data: {
          id: string|number;
          placementType: IPlacementType;
          placementStatus: IStatus;
          bitrixDeals: IBitrixDealData[];
          creatioOpportunities: ICreatioDeal[];
          house: IHouse;
          section: IHouse;
        },
        lang: string
      } = payload;
      const {
        id,
        placementStatus,
        bitrixDeals,
        creatioOpportunities = [],
        house,
        section,
      } = data;

      if (state.view2dData) {
        const tempData = { ...state.view2dData };
        const tempSections: IFilterAllData[] = [...tempData[house.numeration[lang]][section.numeration[lang]]];

        if (tempSections) {
          const placementIndex = tempSections.findIndex((item) => item.id === String(id));
          const placement = { ...tempSections[placementIndex] };
          if (placement) {
            placement.bitrix_deals = bitrixDeals;
            placement.creatioOpportunities = creatioOpportunities;
            placement[ListOfKeysFilterAllData.statusId] = String(placementStatus.id);
            placement[ListOfKeysFilterAllData.placementStatusColor] = placementStatus.color;
            placement[ListOfKeysFilterAllData.placementStatusCrossTableColor] = placementStatus.crosstableColor;
            placement[ListOfKeysFilterAllData.placementStatusCrossTableName] = placementStatus.crosstable_name[lang];
            placement[ListOfKeysFilterAllData.placementStatus] = placementStatus.crosstable_name[lang];
            placement[ListOfKeysFilterAllData.placementStatusModuleName] = placementStatus.module_name[lang];

            tempSections[placementIndex] = placement;
            tempData[house.numeration[lang]][section.numeration[lang]] = tempSections;

            return {
              ...state,
              view2dData: { ...tempData },
              floorScrollSize: 0,
              isRerender: false,
            };
          }
        }
      }
      return {
        ...state,
        isRerender: false,
      };
    }

    default:
      return state;
  }
};
