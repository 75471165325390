import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  headerStyle: {
    '& > div': {
      marginBottom: '15px',
    },
  },
  wrapperFilter: {
    width: '460px',
    display: 'flex',
    maxWidth: '460px',
    height: '100%',
    padding: '30px',
    overflowY: 'auto',
    opacity: 1,
    [theme.breakpoints.up('xl')]: {
      width: '30%',
      maxWidth: '30%',
    },
  },
  wrapperFilterOpen: {
    boxShadow: '0px 0px 3px 0px #d2d2d2',
    animation: '$wrapperFilterOpen 500ms forwards',
    [theme.breakpoints.up('xl')]: {
      animation: '$wrapperFilterOpenXl 500ms forwards',
    },
  },
  wrapperFilterClose: {
    animation: '$wrapperFilterClose 500ms forwards',
    [theme.breakpoints.up('xl')]: {
      animation: '$wrapperFilterCloseXl 500ms forwards',
    },
  },
  '@keyframes wrapperFilterOpen': {
    '0%': { width: 0, opacity: 0 },
    '90%': { width: 460, opacity: 0 },
    '100%': { width: 460, opacity: 1 },
  },
  '@keyframes wrapperFilterClose': {
    '0%': { width: 460, opacity: 1 },
    '10%': { width: 460, opacity: 0 },
    '100%': { width: 0, opacity: 0, padding: 0 },
  },
  '@keyframes wrapperFilterOpenXl': {
    '0%': { width: 0, opacity: 0 },
    '90%': { width: '30%', opacity: 0 },
    '100%': { width: '30%', opacity: 1 },
  },
  '@keyframes wrapperFilterCloseXl': {
    '0%': { width: '30%', opacity: 1 },
    '10%': { width: '30%', opacity: 0 },
    '100%': { width: 0, opacity: 0, padding: 0 },
  },
  filterContent: {
    width: '100%',
  },
  countOfApartment: {
    fontSize: '18px',
    marginBottom: '20px',
  },
  combineFilterParams: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  wrapperShowHideBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fullSizeDropdown: {
    '& > div, & > button': {
      width: '100%',
      flexDirection: 'column',
    },
  },
  showHideLessFilter: {
    border: '1px solid rgba(34,36,38,.15) !important',
    display: 'flex !important',
    padding: '10px !important',
    color: `${theme.palette.primary.main} !important`,
    textTransform: 'inherit',
  },
  iconColor: {
    color: theme.palette.primary.main,
    marginRight: '9px',
  },
}));
