import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperListOfPropertyMobile: {
    marginTop: '10px',
  },
  totalCount: {
    marginBottom: '10px',
  },
}));
