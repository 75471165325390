import { Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import useWindowDimensions from './use-window-dimensions';

export const useIsMobile = () => {
  const theme: Theme = useTheme();
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));
  const { windowDimensions } = useWindowDimensions();
  const [isMobileScreen, setIsMobile] = useState<boolean>(false);
  const [isXlScreen, setXlScreen] = useState<boolean>(false);

  useEffect(() => {
    if (windowDimensions.width <= theme.breakpoints.values.md && !isMobileScreen) {
      setIsMobile(true);
    }

    if (windowDimensions.width > theme.breakpoints.values.md && isMobileScreen) {
      setIsMobile(false);
    }

    setXlScreen(matchesXl);
  }, [matchesXl, isMobileScreen, windowDimensions, theme.breakpoints.values.md]);

  return {
    isXlScreen,
    isMobileScreen,
  };
};
