import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperDescription: {
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      paddingLeft: '0px',
      marginTop: '20px',
    },
  },
  wrapperButtonControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
      marginTop: '0px',
      flexDirection: 'column',
      '& button': {
        marginTop: '20px',
        padding: '17px',
      },
    },
  },
  buttonDefault: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '19px 40px',
    minWidth: '184px',
    boxShadow: 'none',
    fontSize: '14px',
    textTransform: 'initial',
    margin: '0px 5px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 'inherit',
    },
  },
  buttonBookNow: {
    color: 'white',
  },
  buttonRequestNow: {
    color: theme.palette.primary.main,
  },
}));
