import React from 'react';

import { ButtonShowMore } from 'src/components/FilterProperty/components/FilterDesktop/ButtonShowMore';
import { ListOfLayoutApartments } from 'src/components/ListOfLayoutApartments';
import { useBuildType, useScrollIntoResultFilter, useShowLessFiler } from 'src/hooks';
import { useSelectorsApartments } from 'src/components/FilterProperty/hooks';
import { DropdownFilterGenerator } from 'src/components/DropdownsFilter';
import { useStyles } from './styles';
import { IFilterPropertyPlacementsComponent } from './types';

export const FilterPropertyPlacementsComponent: React.FC<IFilterPropertyPlacementsComponent> = ({
  scrollPosition,
}) => {
  const styles = useStyles({});
  const { isSpinner, isCrossTable } = useBuildType();
  const { isShowAll, handleToggleShowAllFilter } = useShowLessFiler();
  const { filterData } = useSelectorsApartments();
  useScrollIntoResultFilter(scrollPosition);

  return (
    <>
      {Boolean(filterData) && (
        <>
          <div className={`${styles.combineFilterParams} ${
            isCrossTable ? styles.fullSizeDropdown : ''}`}
          >
            {filterData
              .map((field, index) => {
                const showMoreButton = index === 2 && filterData.length > 3;

                if (showMoreButton) {
                  return (
                    <div
                      key={field.attribute}
                      className={`${styles.wrapperShowHideBlock} ${
                        isCrossTable ? styles.fullSizeDropdown : ''}`}
                    >
                      <DropdownFilterGenerator dropdownInfo={field} />
                      {!isCrossTable && (
                      <ButtonShowMore
                        isShowAll={isShowAll}
                        handleShowLess={handleToggleShowAllFilter}
                      />
                      )}
                    </div>
                  );
                }
                return (
                  <DropdownFilterGenerator
                    key={field.attribute}
                    dropdownInfo={field}
                    isHide={(!isShowAll && !isCrossTable) && index > 2}
                  />
                );
              })}
          </div>
          {
            isSpinner && (
              <ListOfLayoutApartments
                scrollPosition={scrollPosition}
              />
            )
          }
        </>
      )}
    </>
  );
};
