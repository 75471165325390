import { useSelector } from 'react-redux';

import { currentSpinnerBacklightSvg } from 'src/store/spinner/selectors';

export const useGetFilteredIds = () => {
  const filteredIds: any = useSelector(currentSpinnerBacklightSvg);

  return {
    filteredIds,
  };
};
