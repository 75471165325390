import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PhoneIcon from '@mui/icons-material/Phone';
import { ButtonApp } from 'src/components/Button';
import { ListOfKeysFilterAllData, IBitrixDealData } from 'src/typings/filter';
import { getBitrixDeal } from 'src/store/crosstable/selectors';
import { Actions } from 'src/store/requests/filter/actions';
import { Actions as ActionsView2d } from 'src/store/view-2d/actions';
import { Actions as ActionsFilteredData } from 'src/store/filter/filtered-data/actions';
import { Actions as SpinnerActions } from 'src/store/requests/spinner-views/actions';
import { Actions as AppActions } from 'src/store/app/actions';
import { translations } from 'src/constants/translations';
import { getCrmData, getBindingAppartment, getCurrentLang } from 'src/store/app/selectors';
import { useGetAuthData } from 'src/hooks';
import { getPlacement, patchBitrixDeal } from 'src/api';
import { AlertDialog } from './components/dialog';
import { IApartmentConnectDeal } from './index';
import { dealsTheme, useStyles } from './styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const BitrixDeal: React.FC<IApartmentConnectDeal> = React.memo(({
  placeInfo,
  isSmall,
}) => {
  const styles = useStyles({});
  const dispatch = useDispatch();
  const crmData = useSelector(getCrmData);
  const bitrixDeal = useSelector(getBitrixDeal);
  const currentLang = useSelector(getCurrentLang);
  const isBindingAppartment = useSelector(getBindingAppartment);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [currentDeals, setCurrentDeals] = useState<IBitrixDealData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const refData = useRef({
    respUser: '',
    selectedDeal: 0,
  });

  useEffect(() => {
    if (placeInfo[ListOfKeysFilterAllData.bitrixDealData]) {
      setCurrentDeals(placeInfo[ListOfKeysFilterAllData.bitrixDealData]);
    } else {
      setCurrentDeals([]);
    }
    setIsLoading(false);
  }, [placeInfo]);

  useEffect(() => {
    let dealIsSelected = false;
    if (currentDeals) {
      currentDeals.forEach((item) => {
        if (item.id === crmData?.dealId) {
          dealIsSelected = true;
        }
      });
    }
    setIsSelected(dealIsSelected);
  }, [currentDeals, crmData]);

  if (!crmData || !bitrixDeal) {
    return null;
  }

  const {
    domain, crm,
  } = crmData;

  const { bitrixExternalDealId } = bitrixDeal;

  const sendPlacementData = useCallback(async (deals: IBitrixDealData[], isRemove: boolean, dealId: number) => {
    setIsLoading(true);

    const { data: deal } = await patchBitrixDeal(
      jwt,
      dealId,
      {
        placement: isRemove ? null : `/api/placements/${placeInfo[ListOfKeysFilterAllData.id]}`,
      },
    );

    const { data: newPlacementData } = await getPlacement(
      jwt,
      placeInfo[ListOfKeysFilterAllData.id],
    );

    dispatch(ActionsView2d.setIsRerender());
    if (!isRemove) {
      dispatch(AppActions.setBindingAppartment(true));
      deals.push(deal);
    } else {
      dispatch(AppActions.setBindingAppartment(false));
    }
    dispatch(Actions.updatePlacement({
      data: newPlacementData,
      lang: currentLang,
    }));
    dispatch(ActionsFilteredData.updatePlacement({
      data: newPlacementData,
      lang: currentLang,
    }));
    dispatch(ActionsView2d.updatePlacement({
      data: newPlacementData,
      lang: currentLang,
    }));
    dispatch(SpinnerActions.spinnerDataEditPlacement({
      id: placeInfo[ListOfKeysFilterAllData.id],
      crm,
      data: newPlacementData,
      lang: currentLang,
    }));
    setCurrentDeals(deals);
    setIsLoading(false);
  }, [crmData, placeInfo, jwt, currentDeals]);

  const handleAddDeal = (dealId: number) => {
    sendPlacementData(currentDeals, false, dealId);
  };

  const handleRemoveDeal = (selectedDeal: any = 0) => {
    const current = selectedDeal || refData.current.selectedDeal;
    console.log('🚀 ~ file: bitrix.tsx:133 ~ handleRemoveDeal ~ current:', current, selectedDeal, refData.current);

    const filtered: IBitrixDealData[] = currentDeals.reduce((acc: IBitrixDealData[], item: IBitrixDealData) => {
      if (item.id !== current) {
        acc.push(item);
      }
      return acc;
    }, []);

    sendPlacementData(filtered, true, current);
    setIsOpenDialog(false);
  };

  const handleConnectBtn = () => {
    // e.preventDefault();
    // e.stopPropagation();
    if (isSelected) {
      handleRemoveDeal(bitrixDeal?.id);
    } else {
      handleAddDeal(bitrixDeal?.id);
    }
  };

  const handleUnbindDeal = useCallback((e, item) => {
    e.preventDefault();
    e.stopPropagation();
    refData.current.respUser = item.responsibleUser ? item.responsibleUser.username : '';
    refData.current.selectedDeal = item.id;
    setIsOpenDialog(true);
  }, [refData.current]);

  const getBtnText = () => {
    if (!isSelected && !isLoading && isBindingAppartment) return translations.disabledAttach;
    if (isSelected && !isLoading) return `${translations.detachUnitFromDeal} №${bitrixExternalDealId}`;
    if (isSelected && isLoading) return `${translations.deleting}...`;
    if (!isSelected && !isLoading) return `${translations.attachUnitToDeal} №${bitrixExternalDealId}`;
    if (!isSelected && isLoading) return `${translations.updating}...`;
    return '';
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={dealsTheme}>
        <AlertDialog
          handleClose={() => setIsOpenDialog(false)}
          handleAccept={() => handleRemoveDeal()}
          isOpen={isOpenDialog}
          number={placeInfo[ListOfKeysFilterAllData.placementNumeration]}
          respUser={refData.current.respUser}
        />
        <Box key={placeInfo[ListOfKeysFilterAllData.id]} mt={isSmall ? 1 : 0}>
          { (Number(placeInfo[ListOfKeysFilterAllData.placementCanBeSet])
            || (isSelected && !Number(placeInfo[ListOfKeysFilterAllData.placementCanBeSet]))) && (
              <Box mb={currentDeals.length && !(currentDeals.length === 1 && isSelected) ? 6 : 0}>
                <ButtonApp
                  text={getBtnText()}
                  type="button"
                  color={isSelected ? 'secondary' : 'primary'}
                  onClick={handleConnectBtn}
                  isCapitalize
                  isFullSize
                  disabled={isLoading || (isBindingAppartment && !isSelected)}
                  customClassName={isSmall ? styles.isSmallBtn : ''}
                  variant={isSelected ? 'outlined' : 'contained'}
                />
              </Box>
          )}
          {currentDeals && currentDeals.length && !(currentDeals.length === 1 && isSelected) ? (
            <Box>
              {translations.otherAttachedDeals}
              {currentDeals.map((item: IBitrixDealData) => {
                if (!item || item.id === crmData?.dealId) return null;
                return (
                  <a
                    href={`https://${domain}/crm/deal/details/${item.bitrixExternalDealId}/`}
                    title={translations.openDealInCRM}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    key={item.id}
                  >
                    <Box
                      width="100%"
                      mt={2}
                      borderRadius="5px"
                      position="relative"
                      className={styles.dealBtn}
                    >
                      <Box fontSize="13px">
                        {`№${item.bitrixExternalDealId}`}
                      </Box>
                      {item.responsibleUser && (
                        <Box mt={1} fontSize="11px">
                          {`${translations.responsible}: ${item.responsibleUser.username}`}
                        </Box>
                      )}
                      {item.bitrixDealClient && (
                        <>
                          <Box mt={1} fontSize="11px">
                            {`${translations.client}: ${item.bitrixDealClient.name}`}
                          </Box>
                          {item.bitrixDealClient.phones && item.bitrixDealClient.phones.map((numb) => (
                            <Box key={numb} mt={1} fontSize="11px" display="flex" alignItems="center">
                              <Box fontSize="15px" lineHeight="15px" mr={1}>
                                <PhoneIcon fontSize="inherit" />
                              </Box>
                              {numb}
                            </Box>
                          ))}
                        </>
                      )}
                      <Box
                        borderRadius="0 5px 5px 0"
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        top={0}
                        right={0}
                        bottom={0}
                        className={`${styles.removeDeal} removeBtn`}
                        title={translations.detachUnitFromDeal}
                        onClick={(e) => handleUnbindDeal(e, item)}
                      >
                        <DeleteForeverIcon />
                      </Box>
                    </Box>
                  </a>
                );
              })}
            </Box>
          ) : null}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});
