import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';

import { useStyles } from './styles';
import { ITabComponent } from './types';
import { TabPanelDescriptionApartment } from './components';

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export const TabComponent: React.FC<ITabComponent> = ({
  isSingleId,
  renderTabs,
}) => {
  const styles = useStyles({});
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div>
      <AppBar
        position="static"
        color="primary"
        className={styles.headerTabStyle}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          {renderTabs.map((tab) => (
            <Tab
              key={tab.tabTitle}
              className={styles.tabStyle}
              label={tab.tabTitle}
              {...a11yProps(0)}
            />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {renderTabs.map((tab, index) => (
          <TabPanelDescriptionApartment
            isSingleId={isSingleId}
            key={tab.tabTitle}
            value={value}
            index={index}
            tabData={tab.tabData}
          />
        ))}
      </SwipeableViews>

    </div>
  );
};
