import {
  RefObject, useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';

import { resizeEmulate } from 'src/helpers';
import { getInfrastructurePanoramaFullscreen } from 'src/store/requests/app/selector';

export const useFullScreenMode = (refAppGallery: RefObject<any>) => {
  const isFullScreenPanoramaRequest = useSelector(getInfrastructurePanoramaFullscreen);
  const [isFullScreen, setFullScreen] = useState<boolean>(isFullScreenPanoramaRequest || false);

  const handleFullScreen = useCallback((state?: boolean) => {
    setFullScreen(state !== undefined ? state : !isFullScreen);
    setTimeout(resizeEmulate, 1000);
    resizeEmulate();
  }, [isFullScreen]);

  useEffect(() => {
    if (isFullScreenPanoramaRequest && refAppGallery.current) {
      handleFullScreen();
      refAppGallery.current.fullScreen();
    }
  }, [isFullScreenPanoramaRequest]);

  return {
    isFullScreen,
    handleFullScreen,
  };
};
