import { IHouses, IHouse } from 'src/store/crosstable/reducer';
import { useGetAuthData } from 'src/hooks';

import { getHouseHistory } from 'src/api';
import { useQuery } from '@tanstack/react-query';
import { IHouseHistoryItem } from '../typings/house';

export function useGetHouseHistory(id: string|undefined) {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  return useQuery({
    queryKey: ['houseHistory'],
    queryFn: async (): Promise<IHouseHistoryItem[]|null> => {
      if (!id) return null;
      const { data } = await getHouseHistory(jwt, id);
      return data['hydra:member'];
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
}
