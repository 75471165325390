import React, { RefObject, useEffect, useRef } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useFavoriteListState } from 'src/hooks';
import { useStyles } from './styles';
import { IFavIcon } from './types';
import { useStyles as useStylesIcon } from '../styles';

export const FavIcon: React.FC<IFavIcon> = React.memo(({
  itemId,
}) => {
  const favRef: RefObject<HTMLInputElement> = useRef(null);
  const styles = useStyles({});
  const stylesIcon = useStylesIcon();
  const {
    isActive,
    toggleFavoriteItem,
  } = useFavoriteListState(itemId);

  useEffect(() => {
    if (window.afterCreateFavoriteBtn) {
      window.afterCreateFavoriteBtn(favRef.current);
    }
  }, []);

  return (
    <div
      className={`${stylesIcon.wrapperIcon} ${styles.wrapperFavIcon}`}
      data-gaid="favoriteBtn"
      ref={favRef}
    >
      {
        isActive ? (
          <FavoriteIcon
            className={`${stylesIcon.imageIcon} ${styles.imageAddToFavorite}`}
            onClick={toggleFavoriteItem}
          />
        )
          : (
            <FavoriteBorderIcon
              className={`${stylesIcon.imageIcon} ${styles.imageAddToFavorite}`}
              onClick={toggleFavoriteItem}
            />
          )
      }
    </div>
  );
});
