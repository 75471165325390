import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ListOfBacklightSvg } from 'src/typings/spinner';
import { IGlobalPolygonConfig, PolygonTriggerEvent } from 'src/typings/app';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { IRenderSvg } from 'src/components/ViewSpinner/components/RenderSvg/types';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { getSpinnerDetailInfo } from 'src/store/requests/spinner-views/selector';
import { requestSelectors } from 'src/store/requests/selectors';
import { useBuildType } from 'src/hooks';

import { useStyles, useAnimationStyles } from './styles';
import { useAddCounterToSpinner, usePolygonConfig } from './hooks';
import { HouseElement, PopoverElement } from './components';
import { DefaultElement } from './components/DefaultElement';

export const RenderSvg: React.FC<IRenderSvg> = ({
  isAlwaysHighlight,
  svg,
}) => {
  const { globalPolygonConfig } = usePolygonConfig();
  const { isCrossTable } = useBuildType();
  const theme: Theme = useTheme();
  const triggerEvent: PolygonTriggerEvent = useMemo(() => globalPolygonConfig?.trigger_event
    || PolygonTriggerEvent.hover, [globalPolygonConfig]);
  const name: string = useMemo(() => svg.attr['entity-view-name'], [svg]);
  const nameId: string = useMemo(() => svg.attr['entity-view-name-id'], [svg]);
  const redirect: string = useMemo(() => svg.attr.redirect, [svg]);
  const getPopupConfig = useSelector(requestSelectors.app.getPopupConfig);
  const detailInfo = useSelector(getSpinnerDetailInfo);
  const animStyles = useAnimationStyles({
    ...globalPolygonConfig,
    static_body_color: ((getPopupConfig?.enable_polygon_status_colors || isCrossTable)
    && detailInfo
    && detailInfo?.placement
    && detailInfo?.placement[nameId]
    ) ? `${
        detailInfo.placement[nameId][isCrossTable ? (
          ListOfKeysFilterAllData.placementStatusCrossTableColor
        ) : ListOfKeysFilterAllData.placementStatusColor]
      }` : `${globalPolygonConfig?.static_body_color}`,
  } as IGlobalPolygonConfig);
  const styles = useStyles({
    ...globalPolygonConfig,
    isCrossTable,
    static_body_color: ((getPopupConfig?.enable_polygon_status_colors || isCrossTable)
    && detailInfo
    && detailInfo?.placement
    && detailInfo?.placement[nameId]
    ) ? `${
        detailInfo.placement[nameId][isCrossTable ? (
          ListOfKeysFilterAllData.placementStatusCrossTableColor
        ) : ListOfKeysFilterAllData.placementStatusColor]
      }` : `${globalPolygonConfig?.static_body_color}`,
    hover_body_color: ((getPopupConfig?.enable_polygon_status_colors || isCrossTable)
    && detailInfo
    && detailInfo?.placement
    && detailInfo?.placement[nameId]
    ) ? `${
        detailInfo.placement[nameId][isCrossTable ? (
          ListOfKeysFilterAllData.placementStatusCrossTableColor || ListOfKeysFilterAllData.placementStatusColor
        ) : ListOfKeysFilterAllData.placementStatusColor]
      } !important` : `${globalPolygonConfig?.hover_body_color} !important`,
  });
  const { showCount, countSize } = useAddCounterToSpinner(svg);
  const { getTranslations } = useTranslations();

  const renderSelectorView = () => {
    switch (name) {
      case (ListOfBacklightSvg.houseId):
        return (
          <HouseElement
            trigger={triggerEvent}
            isAlwaysHighlight={isAlwaysHighlight}
            svg={svg}
            name={name}
            nameId={nameId}
            redirect={redirect}
            styles={styles}
            animStyles={animStyles}
          />
        );
      case (ListOfBacklightSvg.placementId):
        return (
          <PopoverElement
            trigger={triggerEvent}
            isAlwaysHighlight={isAlwaysHighlight}
            svg={svg}
            name={name}
            nameId={nameId}
            redirect={redirect}
            styles={styles}
            animStyles={animStyles}
          />
        );
      default:
        return (
          <DefaultElement
            trigger={triggerEvent}
            isAlwaysHighlight={isAlwaysHighlight}
            svg={svg}
            name={name}
            nameId={nameId}
            redirect={redirect}
            styles={styles}
            animStyles={animStyles}
          />
        );
    }
  };

  const renderCounter = () => {
    // eslint-disable-next-line react/no-children-prop
    return React.createElement(
      'foreignObject',
      {
        className: 'counter',
        fill: 'white',
        height: countSize.height,
        width: countSize.width,
        x: svg.attr.x - (countSize.width / 2 - svg.attr.width / 2),
        y: svg.attr.y - (countSize.height),
        children: (
          <div className="counter-inner">
            <span style={{ fontSize: '25px' }}>
              {showCount}
              {' '}
              {getTranslations(locals.appTranslations.appUnits)}
            </span>
          </div>
        ),
      },
    );
  };

  return (
    <>
      {renderSelectorView()}
      {showCount ? renderCounter() : null}
    </>
  );
};

function renderSvgPropsAreEqual(prevSvg: IRenderSvg, nextSvg: IRenderSvg) {
  return prevSvg.svg === nextSvg.svg
    && prevSvg.isAlwaysHighlight === nextSvg.isAlwaysHighlight;
}

export const MemoizedRenderSvg = React.memo(RenderSvg, renderSvgPropsAreEqual);
