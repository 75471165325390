import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { requestSelectors } from 'src/store/requests/selectors';
import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { Actions } from 'src/store/panorama/actions';
import {
  useGetTypeOfProperty, useSetCurrentSpinner, useSetFilterParams, useSetCurrentEntity,
} from 'src/hooks';
import { ListOfDropdown, ListOfKeysFilterAllData } from 'src/typings/filter';

export const useTypeOfSpinner = () => {
  const dispatch = useDispatch();
  const { handleWriteToRedux } = useSetFilterParams();
  const [isMainSpinner, setIsMainSpinner] = useState<boolean>(true);
  const spinnerData = useSelector(requestSelectors.spinner.getSpinnerFirstSpinner);
  const { handleSetCurrentSpinner } = useSetCurrentSpinner();
  const { handleSetCurrentEntity } = useSetCurrentEntity();
  const currentSpinner = useSelector(getCurrentSpinner);
  const filterAllData = useSelector(requestSelectors.filter.getFilterInitialAllData);
  const { typeOfProperty } = useGetTypeOfProperty();

  useEffect(() => {
    if (spinnerData && currentSpinner) {
      if (_.isEqual(spinnerData, currentSpinner)) {
        setIsMainSpinner(true);
      } else {
        setIsMainSpinner(false);
      }
    }
  }, [spinnerData, currentSpinner]);

  const handleBackToMainSpinner = useCallback(() => {
    const typeOfField = typeOfProperty && filterAllData?.[typeOfProperty]?.filter_default_values
      .filter((item) => item.attribute === ListOfKeysFilterAllData.placementHouse)[0]?.type;

    handleSetCurrentSpinner(
      spinnerData.name,
      spinnerData['name-id'],
    );
    handleSetCurrentEntity(
      spinnerData.name,
      spinnerData['name-id'],
    );
    dispatch(Actions.setLookAtMyPano(false));

    if (typeOfField !== undefined) {
      if (
        typeOfField === ListOfDropdown.dropdownCheckboxList
        || typeOfField === ListOfDropdown.datalistCheckboxList
      ) {
        handleWriteToRedux(ListOfKeysFilterAllData.placementHouse, [], false);
      } else {
        handleWriteToRedux(ListOfKeysFilterAllData.placementHouse, '', false);
      }
    }
  }, [
    filterAllData,
    spinnerData,
    typeOfProperty,
    handleSetCurrentSpinner,
    handleWriteToRedux,
  ]);

  return {
    currentSpinner,
    isMainSpinner,
    handleBackToMainSpinner,
  };
};
