import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import { useSelector } from 'react-redux';

import { TabComponent } from 'src/components/TabComponent';
import { ApartmentStatus } from 'src/components/ApartmentStatus';
import { ApartmentConnectDeal } from 'src/components/ApartmentConnectDeal';
import { ButtonApp } from 'src/components/Button';
import { getCrmData } from 'src/store/app/selectors';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import {
  useAppOpenModalBookModal,
  useAppVisibilityBtn,
  useBuildType,
  useAttributesContactForm,
} from 'src/hooks';
import { IApartmentDescription } from './types';
import { useStyles } from './styles';
import { useDescriptionState } from './hooks';

export const ApartmentDescription: React.FC<IApartmentDescription> = ({
  isSingleId,
  placeInfo,
}) => {
  console.log('🚀 ~ file: index.tsx:26 ~ placeInfo', placeInfo);
  const { isSpinner, isCrossTable } = useBuildType();
  const styles = useStyles({});
  const crmData = useSelector(getCrmData);
  const { handleToggleModal } = useAppOpenModalBookModal();
  const { getTranslations } = useTranslations();
  const { transformState } = useDescriptionState(placeInfo, isSingleId);
  const { placement_request_call } = useAppVisibilityBtn();
  const { attributesForm } = useAttributesContactForm();

  return (
    <div className={styles.wrapperDescription}>
      {Boolean(transformState.length) && (
        <TabComponent isSingleId={isSingleId} renderTabs={transformState} />
      )}
      {(isCrossTable && isSingleId) && (
        <>
          {crmData ? null : <ApartmentStatus placeInfo={placeInfo} />}
          <ApartmentConnectDeal placeInfo={placeInfo} />
        </>
      )}
      {placement_request_call && isSpinner && (
        <div className={styles.wrapperButtonControl}>
          <ButtonApp
            withBorder
            isCapitalize
            color="secondary"
            text={getTranslations(locals.appTranslations.appRequestACall)}
            startIcon={<PhoneIcon />}
            className={`${styles.buttonDefault} ${styles.buttonRequestNow} ${attributesForm?.class}`}
            onClick={handleToggleModal}
          />
        </div>
      )}
    </div>
  );
};
