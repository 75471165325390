import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { requestSelectors } from 'src/store/requests/selectors';
import { Actions } from 'src/store/spinner/actions';
import { ISpinnerDataPossibilityInnerData } from 'src/typings/spinner';

export const useGetFloorsSpinner = (
  idFloor: string[],
) => {
  const dispatch = useDispatch();
  const spinnerData = useSelector(requestSelectors.spinner.getSpinnerData);
  const [spinnerFloor, setSpinnerFloor] = useState<ISpinnerDataPossibilityInnerData | undefined>(undefined);

  useEffect(() => {
    setSpinnerFloor(
      spinnerData?.spinners?.floor?.[idFloor[0]],
    );
    dispatch(Actions.setAppCurrentSpinner({ name: 'floor', 'name-id': idFloor[0] }));
  }, [idFloor, spinnerData]);

  return {
    spinnerFloor,
  };
};
