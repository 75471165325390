import { useSelector } from 'react-redux';
import _ from 'lodash';

import { getFilterInitialAllData } from 'src/store/requests/filter/selector';
import { ListOfKeysFilterAllData } from 'src/typings/filter';

export const useGetTypePlacementById = (searchId: string | string[]) => {
  const getAllData = useSelector(getFilterInitialAllData);
  const placementType: string | undefined = _.findKey(getAllData,
    (allDataKey) => _.findKey(allDataKey.filter_all_data,
      // eslint-disable-next-line consistent-return
      (filterAllDataKey) => {
        if (_.isArray(searchId)) {
          if (filterAllDataKey.id === searchId[0]) {
            return filterAllDataKey[ListOfKeysFilterAllData.placementType];
          }
        } else if (filterAllDataKey.id === searchId) {
          return filterAllDataKey[ListOfKeysFilterAllData.placementType];
        }
      }));

  return {
    placementType,
  };
};
