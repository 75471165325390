import React, { useMemo } from 'react';
import _ from 'lodash';
import Fade from 'react-reveal';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

import { ButtonApp } from 'src/components';
import { pathList } from 'src/route-list';
import { appIcon } from 'src/assets';
import { useTranslations } from 'src/hooks/translataions';
import { PlacementDisplayedType } from 'src/typings/filter';
import { useFilterConfigurator } from 'src/hooks/filters';
import { ICompareItem } from './types';
import { useStyles } from './styles';
import { CompareItemBookNow } from '../CompareItemBookNow';

export const CompareItems: React.FC<ICompareItem> = React.memo(({
  placements,
  removeItemFromCompare,
  renderButtonShowDiff,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const { filterConfigurator }: any = useFilterConfigurator(
    PlacementDisplayedType.placement,
    placements[0].id,
  );
  const availableConfig = useMemo(() => {
    if (filterConfigurator) {
      const listOfConfig = Object.keys(filterConfigurator);
      const useConfigList = listOfConfig.filter((item) => filterConfigurator[item].use);

      return useConfigList;
    }
    return [];
  }, [filterConfigurator]);

  return (
    <>
      <div className={`${styles.compareRow}`} style={{ backgroundColor: 'transparent' }}>
        <div className={`${styles.wrapperCompareType} ${styles.wrapperCompareTitle}`} />
        <div className={styles.compareRowInner} style={{ backgroundColor: 'transparent' }}>
          {placements.map((item) => {
            return (
              <div key={item.id} className={`${styles.wrapperImageProperty}`}>
                <Link to={`${pathList.own}/${item.id}`}>
                  <Fade>
                    <img
                      alt="plan"
                      className={styles.imageStyle}
                      src={
                        item.main_filter_img
                          ? item.main_filter_img.replace('desktop', 'mobile')
                          : appIcon.noImg.default
                      }
                    />
                  </Fade>
                </Link>
                <ButtonApp
                  startIcon={<CloseIcon />}
                  className={styles.closeIcon}
                  onClick={() => removeItemFromCompare(item.id)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={`${styles.compareRow}`} style={{ backgroundColor: 'transparent' }}>
        <div className={`${styles.wrapperCompareType} ${styles.wrapperCompareTitle} ${styles.desktopShowDiff}`}>
          {renderButtonShowDiff()}
        </div>
        <div className={styles.compareRowInner} style={{ backgroundColor: 'transparent' }}>
          {placements.map((item) => <CompareItemBookNow key={item.id} item={item} />)}
        </div>
      </div>
      {
        Object.keys(placements[0]).map((placementType) => {
          if (availableConfig.includes(placementType)) {
            return (
              <div key={placementType} className={`${styles.compareRow}`}>
                <div className={`${styles.wrapperCompareType} ${styles.wrapperCompareTitle}`}>{getTranslations(placementType)}</div>
                <div className={styles.compareRowInner}>
                  {placements.map((item) => {
                    // @ts-ignore
                    const dataPlacement = item[placementType];

                    return (
                      <div key={item.id} className={`${styles.compareValue} ${styles.wrapperCompareType}`}>
                        {
                          // eslint-disable-next-line no-nested-ternary
                          _.isArray(dataPlacement)
                            ? dataPlacement.length ? dataPlacement.join(', ') : '-'
                            : dataPlacement || '-'
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }

          return null;
        })
      }
    </>
  );
});
