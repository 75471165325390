import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { range } from 'lodash';
import { useGetCurrentSection } from 'src/hooks/floors';
import { requestSelectors } from 'src/store/requests/selectors';
import { getDefaultPlacementType } from 'src/store/crosstable/selectors';
import { Actions as ActionsCross } from 'src/store/crosstable/actions';
import { useSetCurrentSpinner } from 'src/hooks/spinner';
import { getSelectedFloor, getSelectedFloorNumber } from 'src/store/floors/selectors';
import { getFilterValueData } from 'src/store/filter/filter-value/selectors';
import { Actions } from 'src/store/floors/actions';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { IFloorsState } from '../types';

export const useGenerateFloorsForSection = () => {
  const paramsName = 'floor';
  const dispatch = useDispatch();
  const { handleSetCurrentSpinner } = useSetCurrentSpinner();
  const { currentSection } = useGetCurrentSection();
  const spinnerSvgCurrentData = useSelector(requestSelectors.spinner.getSpinnerViewData);
  const selectedFloorId = useSelector(getSelectedFloor);
  const defaultPlacementType = useSelector(getDefaultPlacementType);
  const selectedFloorNumber = useSelector(getSelectedFloorNumber);
  const filterValue = useSelector(getFilterValueData);
  const [renderFloors, setRenderFloors] = useState<IFloorsState[]>([]);

  useEffect(() => {
    if (spinnerSvgCurrentData && currentSection) {
      const { floor = {} } = spinnerSvgCurrentData;
      const filterFloors: IFloorsState[] = [];
      const floorFilter = filterValue.find((val: any) => {
        return val.attribute === ListOfKeysFilterAllData.placementFloor;
      });

      const floorsFromFilter = (floorFilter && floorFilter.type === 'scaleRange')
        ? range(Number(floorFilter.data.min), Number(floorFilter.data.max) + 1, 1).map((item) => String(item))
        : (floorFilter?.data || []);

      Object.keys(floor).forEach((item) => {
        // @ts-ignore
        if (floor[item].additional_data[currentSection.markup] === currentSection['markup-id']) {
          if (floor[item].additional_data['floor.numeration'] && (
            !floorsFromFilter.length || floorsFromFilter.includes(floor[item].additional_data['floor.numeration']))) {
            const floorCount = floor[item].additional_data['floor.numeration'];
            if (floorCount !== undefined) {
              filterFloors.push({
                floor: floorCount,
                floorId: item,
              });
            }
          }
        }
      });

      const reverseFloors = filterFloors
        .sort((a, b) => (a.floor > b.floor ? 1 : -1))
        .reverse();

      setRenderFloors(reverseFloors);
    }
  }, [currentSection, filterValue]);

  useEffect(() => {
    if (renderFloors.length) {
      const floorId = renderFloors[renderFloors.length - 1]?.floorId;
      const floorIdSelectedNumberFloor = renderFloors.find((item) => item.floor === selectedFloorNumber)?.floorId;

      setTimeout(() => {
        if ((!selectedFloorId || selectedFloorId !== floorId) && !defaultPlacementType) {
          handleSetCurrentSpinner(paramsName, floorIdSelectedNumberFloor || floorId);
        } else {
          handleSetCurrentSpinner(paramsName, selectedFloorId);
          dispatch(ActionsCross.setDefaultPlacementType(null));
        }
      }, 100);

      if ((!selectedFloorId || selectedFloorId !== floorId) && !defaultPlacementType) {
        dispatch(Actions.setSelectedFloor(floorIdSelectedNumberFloor || floorId));
      }
    }
  }, [renderFloors]);

  const handleChangeFloor = useCallback((
    name: string,
    nameId: any,
    floorNumber: string,
  ) => {
    if (nameId !== selectedFloorId) {
      handleSetCurrentSpinner(name, nameId);
      dispatch(Actions.setSelectedFloor(nameId));
      dispatch(Actions.setSelectedFloorNumber(floorNumber));
    }
  }, [dispatch, handleSetCurrentSpinner, selectedFloorId]);

  return {
    selectedFloorId,
    paramsName,
    renderFloors,
    handleChangeFloor,
  };
};
