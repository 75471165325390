import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Route, useRouteMatch, Switch, Redirect,
} from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { GlobalDataLoader } from 'src/components/GlobalDataLoader';
import { localStorageConstants } from 'src/constants';
import { Sidebar } from 'src/components/profileComponents/Sidebar';
import { requestSelectors } from 'src/store/requests/selectors';
import { getComplexId } from 'src/store/app/selectors';
import { useStyles, profileTheme } from './styles';
import { profileList } from './routeList';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const Profile: React.FC = () => {
  const styles = useStyles({});
  // const complexIdState = useSelector(getComplexId);
  const { path } = useRouteMatch();
  const userDataIsLoading: boolean = useSelector(requestSelectors.user.getUserLoading);
  // useEffect(() => {
  //   localStorage.setItem(`${localStorageConstants.currentLanguage}${complexIdState}`, 'uk');
  // }, []);

  if (userDataIsLoading) {
    return <GlobalDataLoader />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={profileTheme}>
        <Box display="flex" flexWrap="nowrap" height="100vh">
          <Sidebar />
          <Box className={styles.pageWrapper}>
            <Switch>
              <Route exact path={path}>
                <Redirect to={`${path}/${profileList[0].path}`} />
              </Route>
              {profileList.map((item) => (
                <Route exact key={item.path} path={`${path}/${item.path}`}>
                  {item.Component}
                </Route>
              ))}
            </Switch>
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
