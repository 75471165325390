import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  windRose: {
    left: '20px',
    top: '90px',
    borderRadius: '50%',
    padding: '5px',
    transition: '.5s opacity',
    width: '80px',
    height: '80px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 1,
    position: 'absolute',
  },
  onTop: {
    top: 'unset',
    left: 'unset',
    transform: 'none',
    position: 'relative',
    margin: '0 auto',
    marginBottom: '15px',
  },
  isGallery: {
    width: '60px',
    height: '60px',
    left: '10px',
    top: '10px',
  },
  arrowWrapper: {
    position: 'absolute',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  arrowWrapperWithBg: {
    position: 'absolute',
    top: '20px',
    left: '22px',
  },
  arrowWithBg: {
    width: '40px',
  },
  arrow: {
    width: '100%',
    height: '100%',
  },
}));
