import React, { useState, useEffect } from 'react';

type Props = {
  children: React.ReactNode;
  waitBeforeShow?: number;
  callbackFn?: Function;
};

export const Delayed = ({ children, waitBeforeShow = 500, callbackFn }: Props) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setIsShown(true);
      if (callbackFn) {
        callbackFn();
      }
    }, waitBeforeShow);
    return () => {
      clearTimeout(handler);
    };
  }, [waitBeforeShow]);

  return isShown ? <>{children}</> : null;
};
