import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  compareRow: {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  compareRowInner: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
    },
  },
  wrapperCompareTitle: {
    backgroundColor: '#F6F7F6',
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px',
    position: 'sticky',
    top: '0px',
    left: '-30px',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      left: '-20px',
      backgroundColor: 'transparent',
      justifyContent: 'flex-start',
      paddingBottom: '0px',
    },
  },
  wrapperCompareType: {
    width: '230px',
    maxWidth: '230px',
    minWidth: '230px',
    [theme.breakpoints.up('xl')]: {
      width: '15vw',
      maxWidth: '15vw',
      minWidth: '15vw',
    },
  },
  wrapperCompareValues: {
    '& > div:nth-child(2n)': {
      backgroundColor: '#F6F7F6',
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'transparent',
      },
    },
  },
  wrapperImageProperty: {
    borderRadius: '10px',
    border: '1px solid #F2F2F2',
    padding: '21px 35px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '140px',
    maxHeight: '140px',
    margin: '0px 10px 15px',
    width: '210px',
    maxWidth: '210px',
    minWidth: '210px',
    '& > a': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(15vw - 20px)',
      maxWidth: 'calc(15vw - 20px)',
      minWidth: 'calc(15vw - 20px)',
      height: '20vh',
      maxHeight: '20vh',
    },
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  compareValue: {
    fontSize: '17px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '7px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px',
    },
  },
  closeIcon: {
    top: '0px',
    right: '0px',
    position: 'absolute',
    minWidth: '44px',
    width: '44px',
    height: '44px',
    '& span[class*="startIcon"]': {
      margin: '0px',
    },
  },
  desktopShowDiff: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
