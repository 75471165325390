import { action } from 'src/store/common';

import { IRangeAndSet } from 'src/typings/filter';
import { ISetFilterParameter } from 'src/store/filter/filter-params/reducers';

export const ActionTypes = {
  SET_INFRASTRUCTURE_FILTERS: '[INFRASTRUCTURE]SET_INFRASTRUCTURE_FILTERS',
  SET_INFRASTRUCTURE_FILTERS_VALUES_INITIAL: '[INFRASTRUCTURE]SET_INFRASTRUCTURE_FILTERS_VALUES_INITIAL',
  SET_INFRASTRUCTURE_FILTER_VALUE: '[INFRASTRUCTURE]SET_INFRASTRUCTURE_FILTER_VALUE',
  SET_INFRASTRUCTURE_FILTERED_IDS: '[INFRASTRUCTURE]SET_INFRASTRUCTURE_FILTERED_IDS',
};

export const Actions = {
  setInfrastructureFilter: action<IRangeAndSet[]>(ActionTypes.SET_INFRASTRUCTURE_FILTERS),
  setInfrastructureFiltersValuesInitial: action<{[key: string]: ISetFilterParameter}>(ActionTypes.SET_INFRASTRUCTURE_FILTERS_VALUES_INITIAL),
  setInfrastructureFilterValue: action<any>(ActionTypes.SET_INFRASTRUCTURE_FILTER_VALUE),
  setInfrastructureFilteredIds: action<number[]>(ActionTypes.SET_INFRASTRUCTURE_FILTERED_IDS),
};
