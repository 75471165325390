import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GlobalDataLoader } from 'src/components/GlobalDataLoader';
import { AuthForm, RecoveryForm } from 'src/components/AuthForms';
import { requestSelectors } from 'src/store/requests/selectors';
// import { useTranslations } from 'src/hooks/translataions';
import { appIcon } from 'src/assets';
// import { locals } from 'src/constants/locals';
import { translations } from 'src/constants/translations';
import { useStyles } from './styles';

export const Authorization: React.FC = () => {
  const styles = useStyles({});
  const [isReg, setIsReg] = useState(false);
  const [isForgot, setIsForgot] = useState(false);
  // const { getTranslations } = useTranslations();
  const userDataIsLoading: boolean = useSelector(requestSelectors.user.getUserLoading);

  if (userDataIsLoading) {
    return <GlobalDataLoader />;
  }
  const toggleType = () => {
    setIsReg(!isReg);
  };

  const toggleIsForgot = () => {
    setIsForgot(!isForgot);
  };

  const moveToLogin = () => {
    setIsForgot(false);
    setIsReg(false);
  };

  return (
    <Box pl={8} pr={14} pt={4} className={styles.pageBg}>
      <Box mb={6}>
        <Link to="/">
          <img src={appIcon.bigLogo.default} alt="Flatshow logo" />
        </Link>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box width={435}>
          <Typography className={styles.infoText} component="h2">
            {translations.tagline}
          </Typography>
        </Box>
        {isForgot ? (
          <RecoveryForm moveToLogin={moveToLogin} />
        ) : (
          <AuthForm
            isReg={isReg}
            toggleType={toggleType}
            toggleIsForgot={toggleIsForgot}
          />
        )}
      </Box>
    </Box>
  );
};
