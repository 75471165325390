import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { localStorageConstants } from 'src/constants';
import { IFilterAllData } from 'src/typings/filter';
import { getFilterInitialAllData } from 'src/store/requests/filter/selector';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';
import { useGetQueryParams } from 'src/hooks';

export const useFavoriteList = () => {
  // This part we used if url has some query parameters
  const schemaQuery: string[] = ['types', 'ids'];
  const { listQueries } = useGetQueryParams(schemaQuery, true);
  const isUseQueries = listQueries ? !Object.values(listQueries).some((item) => !Boolean(item)) : false;

  const [favoriteListIds, setFavoriteListIds] = useState<string[]>([]);
  const [displayedFavoritePlacement, setDisplayedFavoritePlacement] = useState<IFilterAllData[]>([]);
  const [idsDisplayed, setIdsDisplayed] = useState<string[]>([]);

  const typeOfPropertySelector = useSelector(getSelectedPlacement);
  const typeOfProperty: any = isUseQueries && listQueries ? listQueries[schemaQuery[0]] : typeOfPropertySelector;

  const getAllData = useSelector(getFilterInitialAllData);
  const getStorageItemsSelector = useCallback(() => localStorage.getItem(localStorageConstants.favoriteList), []);
  const storageItems = isUseQueries && listQueries ? JSON.stringify(listQueries[schemaQuery[1]].split(',')) : getStorageItemsSelector();

  useEffect(() => {
    const getFavList = storageItems;

    if (getFavList) {
      const parseFav: string[] = JSON.parse(getFavList);
      setFavoriteListIds(parseFav);
    }
  }, [storageItems]);

  useEffect(() => {
    if (favoriteListIds.length && getAllData && typeOfProperty) {
      const generateDisplayedData: IFilterAllData[] = [];
      const idsItem: string[] = [];

      favoriteListIds.forEach((item) => {
        if (getAllData[typeOfProperty].filter_all_data[item]) {
          const data = getAllData[typeOfProperty].filter_all_data[item];

          generateDisplayedData.push(data);
          idsItem.push(data.id);
        }
      });

      setDisplayedFavoritePlacement(generateDisplayedData);
      setIdsDisplayed(idsItem);
    }
  }, [getAllData, favoriteListIds, typeOfProperty]);

  return {
    typeOfProperty,
    idsDisplayed,
    displayedFavoritePlacement,
  };
};
