import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperModal: {
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
  },
  modalWindow: {

    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end',
    },
  },
  wrapperContent: {
    maxWidth: '1920px',
    width: 'calc(100vw - 40px)',
    maxHeight: '1080px',
    height: 'calc((100vw - 40px) / 1.78)',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '20px 20px 0px 0px',
      margin: 'unset',
      maxWidth: 'calc(100vw - 40px)',
      height: 'calc((100vw - 40px) * 1.78)',
    },
  },
}));
