import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';

export function getUserData(token: string, params: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().getUserInfo}${params}`, {}, {
    Authorization: `Bearer ${token || ''}`,
  });
}

export function getUsersIdData(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getUsersIdInfo}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
export function getUsersByDev(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().getUsersIdInfo}?developers=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postUsersIdData(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getUsersIdInfo}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchUsersIdData(token: string = '', id: string|number, data: any) {
  return httpService.patch(`${ApiUrl.getApiUrl().getUsersIdInfo}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function deleteUsersIdData(token: string = '', id: string|number) {
  return httpService.delete(`${ApiUrl.getApiUrl().getUsersIdInfo}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getAmoToken(email: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getAmoToken}/${email}`);
}
