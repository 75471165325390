import {
  IWindRose,
} from 'src/typings/spinner';

export function makeWindRoseData() {
  let currentCount = {};

  return function (newCount?: IWindRose) {
    if (newCount !== undefined) {
      currentCount = newCount;
      return currentCount;
    }

    return currentCount;
  };
}
