import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  apartmentAdditionalInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div:not(:last-child)': {},
    '& > div': {
      marginBottom: '5px',
      overflow: 'hidden',
      overflowWrap: 'break-word',
      width: '100%',
    },
  },
  additionalInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 5px',
    '& > span': {
      width: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));
