import React from 'react';
import ImageGallery from 'react-image-gallery';

import { IAppGallery } from './types';

export const AppGallery: React.FC<IAppGallery> = React.forwardRef(({
  ...props
}, ref) => {
  return (
    <ImageGallery
      ref={ref}
      {...props}
      // @ts-ignore
      useWindowKeyDown={false}
    />
  );
});
