import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    pointerEvents: 'none',
  },
  headerLogo: {
    pointerEvents: 'all',
    '& > a': {
      color: theme.palette.primary.main,
      fontSize: '20px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      display: 'flex',
    },
  },
  headerButton: {
    pointerEvents: 'all',
    backgroundColor: '#F6F7F6',
    borderRadius: '10px',
    minWidth: '40px',
    width: '40px',
    height: '40px',
    '&:hover': {
      backgroundColor: '#F6F7F6',
    },
    '& span[class*="startIcon"]': {
      margin: '0px',
    },
  },
  headerWrapperButton: {
    '& > *': {
      marginLeft: '10px',
    },
    '&:empty': {
      display: 'none',
    },
  },
  appLogo: {
    maxHeight: '40px',
    maxWidth: '140px',
    objectFit: 'contain',
    width: '100%',
  },
  callToUs: {
    color: 'transparent',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));
