import { useSelector } from 'react-redux';

import { draggableMobileMenuState } from 'src/store/app/selectors';

export const useDraggableMobileMenu = () => {
  const isOpenDraggableMenu: boolean = useSelector(draggableMobileMenuState);

  return {
    isOpenDraggableMenu,
  };
};
