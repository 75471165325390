import { useDispatch } from 'react-redux';
import { Actions } from 'src/store/app/actions';

export const useSetCurrentEntity = () => {
  const dispatch = useDispatch();

  const handleSetCurrentEntity = (name: string, id: any) => {
    dispatch(Actions.setCurrentEntity({
      name,
      id,
    }));
  };

  return {
    handleSetCurrentEntity,
  };
};
