import { IAppLayouts } from 'src/typings/layouts';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface ILayoutsRequestReducer {
  isLoading: boolean;
  error: Error | null;
  data: IAppLayouts | undefined;
}

export const initialState: ILayoutsRequestReducer = {
  isLoading: true,
  error: null,
  data: undefined,
};

export const layoutRequestReducer = (
  state = initialState,
  { type, payload }: IAction,
): ILayoutsRequestReducer => {
  switch (type) {
    case ActionTypes.GET_LAYOUTS_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.GET_LAYOUTS_DATA_RESET: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.GET_LAYOUTS_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    case ActionTypes.GET_LAYOUTS_DATA_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    default:
      return state;
  }
};
