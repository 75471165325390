import { ApiResponse } from 'apisauce';
import { call, put } from 'redux-saga/effects';
import { IAction } from 'src/typings/index';
import { getApiErrorMessage } from 'src/helpers/';

export const action = <T>(type: string) => (payload?: T): IAction<T> => ({ type, payload });

export function* makeRequestSaga(
  successActionCreator: (data?: any, meta?: any) => any,
  failureActionCreator: (error?: any) => any,
  apiFn: any,
  apiFnParams?: any,
  meta?: any,
) {
  try {
    const response: ApiResponse<any> = yield call(apiFn, apiFnParams);
    if (response.ok) {
      yield put(successActionCreator(response.data, meta));
    } else {
      yield put(failureActionCreator(getApiErrorMessage(response)));
    }
  } catch (error) {
    yield put(failureActionCreator(error));
  }
}
