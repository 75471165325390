/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { ButtonApp } from 'src/components/Button';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetDeveloper, useGetAuthData } from 'src/hooks';
import { patchBitrixStage, postGenerateBitrixStages, postBitrixStatusOnZeroDeal } from 'src/api';
import { useGetBitrixCategory, useGetBitrixStages } from './hooks';
import { StageModal } from './components/StageModal';
import { StatusOnZeroDeal } from './components/StatusOnZeroDeal';
import { StatusConnectDeal } from './components/StatusConnectDeal';
import { checkboxes } from './constants';
import { useStyles } from './styles';

export const BitrixCategories = () => {
  const styles = useStyles({});
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const ref = useRef<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const { id, categoryId }: { id: string, categoryId: string } = useParams();
  const { isLoading, developer } = useGetDeveloper(id);
  const { category, statuses, isLoading: isLoadingCategory } = useGetBitrixCategory(`/api/bitrix_categories/${categoryId}`);
  const {
    stages, isLoading: isLoadingStages, fetchStages, setStages,
  } = useGetBitrixStages(categoryId);

  const handleChange = (stage: string, key: string, value: any) => {
    if (ref.current[stage]) {
      ref.current[stage][key] = value;
    } else {
      ref.current[stage] = {
        [key]: value,
      };
    }
  };

  const saveChanges = () => {
    if (category) {
      const { complexes } = category;
      Object.keys(ref.current).forEach((stageKey) => {
        const savedStatuses = complexes.reduce((acc: string[], comp) => {
          acc.push(ref.current[stageKey][`status${comp['@id']}`]);
          return acc;
        }, []);
        ref.current[stageKey].placementStatuses = savedStatuses;
        patchBitrixStage(jwt, stageKey, ref.current[stageKey]).then((res: any) => console.log('RES', res));
      });
    }
  };

  useEffect(() => {
    if (category && statuses && stages) {
      const { complexes } = category;
      complexes.forEach((row) => {
        stages.forEach((stage) => {
          let defaultVal = '';
          stage.placementStatuses.forEach((status) => {
            const currentSt = statuses[row['@id']].find((value) => {
              return value['@id'] === status;
            });
            if (currentSt) {
              defaultVal = currentSt?.['@id'];
              handleChange(stage['@id'], `status${row['@id']}`, defaultVal);
            }
          });
        });
      });
    }
  }, [category, statuses, stages]);

  if (isLoading || isLoadingCategory || isLoadingStages || !developer || !category || !statuses) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const handleGenerate = async () => {
    const { data } = await postGenerateBitrixStages(jwt, category.id);
    console.log('DATA', data);
    setStages(data['hydra:member']);
  };

  const { title } = developer;
  const { complexes } = category;

  const crumbs: ICrumbs[] = [
    {
      name: translations.developersLink,
      url: `/profile/${pathList.developers}`,
    },
    {
      name: `Застройщик ${title}`,
      url: `/profile/${pathList.developers}/${id}`,
    },
    {
      name: 'Интеграция Bitrix',
      url: `/profile/${pathList.developers}/${id}/integrationBitrix`,
    },
    {
      name: 'Настройка воронки Bitrix',
    },
  ];

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {`Настройка воронки Bitrix | ${title}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box>
        <Box display="flex" mb={2} justifyContent="space-between">
          <ButtonApp
            text="Добавить Stage"
            color="primary"
            variant="contained"
            type="submit"
            onClick={() => {
              setModalOpen(true);
            }}
            size="large"
          />
          <ButtonApp
            text="Generate stages"
            color="primary"
            variant="contained"
            onClick={handleGenerate}
            size="large"
          />
          <ButtonApp
            text="Сохранить изменения"
            color="primary"
            variant="contained"
            onClick={saveChanges}
            size="large"
          />
        </Box>
        <Box mb={2}>
          <TableContainer component={Paper} className={styles.container}>
            <Table className={styles.table} stickyHeader size="small">
              <TableHead className={styles.head}>
                <TableRow>
                  <TableCell className={styles.sticky}>STAGE ID из BITRIX</TableCell>
                  {stages.map((stage) => (
                    <TableCell align="center" key={stage['@id']}>
                      <InputBase
                        multiline
                        inputProps={{
                          style: {
                            textAlign: 'center',
                          },
                        }}
                        defaultValue={stage.bitrixExternalStageId}
                        onBlur={(e) => handleChange(stage['@id'], 'bitrixExternalStageId', e.target.value)}
                      />

                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={styles.sticky}>STAGE NAME из BITRIX</TableCell>
                  {stages.map((stage) => (
                    <TableCell align="center" key={stage['@id']}>
                      <InputBase
                        multiline
                        inputProps={{
                          style: {
                            textAlign: 'center',
                          },
                        }}
                        defaultValue={stage.bitrixExternalStageName}
                        onBlur={(e) => handleChange(stage['@id'], 'bitrixExternalStageName', e.target.value)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={styles.sticky}>Stage Priority</TableCell>
                  {stages.map((stage) => (
                    <TableCell align="center" key={stage['@id']}>
                      <Box display="inline-block" width={100}>
                        <TextField
                          id={`priority${stage['@id']}`}
                          type="number"
                          inputProps={{
                            style: {
                              textAlign: 'center',
                            },
                          }}
                          defaultValue={stage.priority}
                          onBlur={(e) => handleChange(stage['@id'], 'priority', Number(e.target.value))}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {complexes.map((row) => statuses[row['@id']] && (
                  <TableRow key={row['@id']}>
                    <TableCell className={styles.sticky} align="left">{row.title[row.language]}</TableCell>
                    {stages.map((stage) => {
                      let defaultVal = '';
                      stage.placementStatuses.forEach((status) => {
                        const currentSt = statuses[row['@id']].find((value) => {
                          return value['@id'] === status;
                        });
                        if (currentSt) {
                          defaultVal = currentSt?.['@id'];
                        }
                      });
                      return (
                        <TableCell align="center" key={stage['@id']}>
                          <Select
                            id={`status${stage['@id']}`}
                            defaultValue={defaultVal}
                            onChange={(e) => {
                              handleChange(stage['@id'], `status${row['@id']}`, e.target.value);
                            }}
                          >
                            {statuses[row['@id']].map((status) => (
                              <MenuItem key={status['@id']} value={status['@id']}>
                                <Box display="flex" alignItems="center">

                                  <Box
                                    width="10px"
                                    height="10px"
                                    mr={1}
                                    borderRadius="2px"
                                    title="Цвет в шахматке"
                                    style={{
                                      backgroundColor: status.crosstableColor || status.color,
                                    }}
                                  />
                                  {status.crosstable_name[row.language]}
                                </Box>

                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                {Object.keys(checkboxes).map((checkKey) => (
                  <TableRow key={checkKey}>
                    <TableCell className={styles.sticky}>
                      <div dangerouslySetInnerHTML={{ __html: checkboxes[checkKey] }} />
                    </TableCell>
                    {stages.map((stage) => (
                      <TableCell align="center" key={stage['@id']}>
                        <Checkbox
                        // @ts-ignore
                          defaultChecked={stage[checkKey]}
                          color="primary"
                          onChange={(e) => handleChange(stage['@id'], checkKey, e.target.checked)}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box mb={4}>
          <StatusOnZeroDeal
            complexes={complexes}
            statuses={statuses}
            category={category}
          />
        </Box>
        <StatusConnectDeal
          complexes={complexes}
          statuses={statuses}
        />
      </Box>
      {modalOpen && (
        <StageModal
          isOpen={modalOpen}
          handleClose={() => {
            setModalOpen(false);
          }}
          fecthStages={fetchStages}
          category={category}
        />
      )}
    </Box>
  );
};
