import React from 'react';
import { useSelector } from 'react-redux';
import { getLookAtMyPano } from 'src/store/panorama/selectors';
import { useTypeDisplayedSpinner } from 'src/hooks';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { View2d } from 'src/components/View2d';
import { ViewSpinner } from 'src/components/ViewSpinner';
import { ViewPanorama } from 'src/components/ViewPanorama';
import { ViewInMap } from 'src/components/ViewInMap';
import { ViewLayout } from 'src/components/ViewLayouts';
import { ViewFloors } from 'src/components/ViewFloors';
import { IFullSizeFloor } from 'src/components/ViewSpinner/types';

interface ISpinnerTypesRender extends IFullSizeFloor {}

export const SpinnerTypesRender: React.FC<ISpinnerTypesRender> = ({
  setFullSizeFloor,
}) => {
  const lookAtMyPano = useSelector(getLookAtMyPano);
  const { getSelectedViewId } = useTypeDisplayedSpinner();
  const isSpinner = TypesOfSpinner.viewSpinner === getSelectedViewId;
  const isFloors = TypesOfSpinner.viewFloors === getSelectedViewId;

  const renderingSelector = () => {
    switch (getSelectedViewId) {
      case TypesOfSpinner.view2d:
        return <View2d />;
      case TypesOfSpinner.viewInMap:
        return <ViewInMap />;
      case TypesOfSpinner.viewLayouts:
        return <ViewLayout />;
      case TypesOfSpinner.viewFloors:
        return (
          <>
            <ViewFloors />
            <ViewSpinner
              isFloors
              setFullSizeFloor={setFullSizeFloor}
            />
          </>
        );
      case TypesOfSpinner.viewSpinner:
        return (
          <ViewSpinner
            isFloors={false}
            setFullSizeFloor={setFullSizeFloor}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderingSelector()}
      {/* {lookAtMyPano ? <ViewPanorama />
        : (
          <ViewSpinner
            isFloors={isFloors}
            displayed={isSpinner || isFloors}
            setFullSizeFloor={setFullSizeFloor}
          />
        )} */}
    </>
  );
};
