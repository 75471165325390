import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { requestSelectors } from 'src/store/requests/selectors';
import { IFilterAllData } from 'src/typings/filter';

export const useGetPlacementDataByIdCallback = () => {
  const getAllData = useSelector(requestSelectors.spinner.getSpinnerData)?.['detail-info'].placement;

  const handleSearchingPlacement: IFilterAllData | any = useCallback((searchId: string) => {
    if (getAllData) {
      return getAllData[searchId];
    }

    return undefined;
  }, [getAllData]);

  return {
    handleSearchingPlacement,
  };
};
