import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';

export const useCheckInitialLoadDataError = () => {
  const appDataErrors = useSelector(requestSelectors.app.getAppDataErrors);
  const typeFilterDataErrors = useSelector(requestSelectors.filter.getFilterInitialDataErrors);
  const translationsRequestErrors = useSelector(requestSelectors.translations.getTranslationErrors);
  const layoutsRequestErrors = useSelector(requestSelectors.layouts.getLayoutsRequestErrors);

  return {
    appDataErrors,
    typeFilterDataErrors,
    translationsRequestErrors,
    layoutsRequestErrors,
  };
};
