import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { useIsInfrastructure } from 'src/hooks/infrastructure';
import { FilterTypeProperty } from 'src/components/FilterTypeProperty';
import { useSelectorsApartments } from 'src/components/FilterProperty/hooks';
import { useToggleMobileFilter } from 'src/hooks/app';
import { ItemOfSelectedParams } from './components/ItemOfSelectedParams';
import { IFilterApartmentsMobile } from './types';
import { useStyles } from './styles';
// import { useToggleState } from './hooks';
import { ComponentMobileFilter } from './ComponentFilter';
import { ComponentMobileInfrastructure } from './ComponentInfrastructure';

export const FilterApartmentsMobile: React.FC<IFilterApartmentsMobile> = () => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);
  const { getTranslations } = useTranslations();
  // const { isOpen, handleToggleState } = useToggleState();
  const { isInfrastructure } = useIsInfrastructure();
  const { placementState } = useSelectorsApartments();
  const { isMobileMenuOpen, handleToggleOpenState } = useToggleMobileFilter();

  // I know that leaving commented code is not good
  // but based on my work experience with Spinner project
  // I prefer leave some commented code just in case.
  // I hope whole this code in an app understandable. Not perfect, but nonetheless
  // Good luck, new developer =)
  return (
    <>
      {/* <div className={styles.buttonShowFilter} onClick={handleToggleState}> */}
      {/*  <div className={styles.wrapperSwipeAbleElement}> */}
      {/*    <span> */}
      {/*      {getTranslations(locals.appTranslations.appShowFilter)} */}
      {/*    </span> */}
      {/*    <ArrowDropDownIcon /> */}
      {/*  </div> */}
      {/* </div> */}
      <div className={`${styles.wrapperMobileFilter} ${isMobileMenuOpen ? styles.wrapperMobileFilterOpen : ''}`}>
        <Box
          className={`${styles.wrapperSwipeAbleElement} ${styles.wrapperSwipeAbleElementInner}`}
          onClick={handleToggleOpenState}
        >
          <CloseIcon className={styles.shobZarkitb} />
          <span>{`${getTranslations(locals.appTranslations.appFilter)}`}</span>
        </Box>
        <article className={styles.resultMobileFilter}>
          <div className={`${styles.wrapperHeaderMobilePart}`}>
            {Boolean(placementState.typesPlacement.length) && (
              <div className={styles.mobileFilterControl}>
                <FilterTypeProperty placementState={placementState} />
              </div>
            )}
            {!isInfrastructure && <ItemOfSelectedParams />}
          </div>
          {
            isInfrastructure
              ? (
                <ComponentMobileInfrastructure
                  handleModalOpen={handleToggleOpenState}
                />
              )
              : (
                <ComponentMobileFilter
                  handleModalOpen={handleToggleOpenState}
                />
              )
          }
        </article>
      </div>
    </>
  );
};
