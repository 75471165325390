/* eslint-disable max-len */
import React from 'react';

export const ArrowLeftIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path className="appStrokeStyles" d="M13 6L7 12L13 18" stroke="#1F2E42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
