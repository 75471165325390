import React from 'react';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { getFloorItemType } from 'src/store/view-2d/selectors';
import { EFloorType } from 'src/store/view-2d/reducer';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { Viewport } from 'src/components/Viewport';
import { useStyles } from './styles';
import { ISection } from './types';
import { useSectionGenerator } from './hooks';
import { FloorItem } from './FloorItem';
import { FloorItemBig } from './components/FloorItemBig';
import { FloorItemBigSkeleton } from './components/FloorItemBig/skeleton';

export const Section: React.FC<ISection> = React.memo(({
  floorList,
  sectionName,
  sectionData,
}) => {
  const floorItemType = useSelector(getFloorItemType);
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles({
    ...mainColor,
    isBigFloorItem: floorItemType === EFloorType.moreInfo,
  });
  const { fillFloors } = useSectionGenerator(floorList, sectionData);
  return (
    <div>
      <div className={styles.sectionTitle}>
        <span>{sectionName}</span>
      </div>
      <div className={styles.sectionContent}>
        {floorList.map((floor) => {
          return (
            <div key={floor} className={styles.sectionContentRow}>
              {fillFloors && fillFloors[floor] && (
                <>
                  {
                    fillFloors[floor].length
                      // eslint-disable-next-line no-nested-ternary
                      ? fillFloors[floor].map((fillFloorsItem, index) => (fillFloorsItem ? (
                        floorItemType === EFloorType.mini ? (
                          <FloorItem
                            key={`${fillFloorsItem[ListOfKeysFilterAllData.id]}${fillFloorsItem[ListOfKeysFilterAllData.placementStatus]}`}
                            fillFloorsItem={fillFloorsItem}
                          />
                        ) : (
                          <Viewport
                            key={`${fillFloorsItem[ListOfKeysFilterAllData.id]}${fillFloorsItem[ListOfKeysFilterAllData.placementStatus]}`}
                            // eslint-disable-next-line react/no-unused-prop-types
                            render={({ visible }: { visible: boolean}) => {
                              if (!visible) {
                                return (
                                  <FloorItemBigSkeleton fillFloorsItem={fillFloorsItem} />
                                );
                              }
                              return (
                                <FloorItemBig fillFloorsItem={fillFloorsItem} />
                              );
                            }}
                          />

                        )
                      ) : (
                        <div
                        // eslint-disable-next-line react/no-array-index-key
                          key={sectionName + floor + index}
                          className={floorItemType === EFloorType.mini ? styles.sectionContentWrapper : styles.sectionContentWrapperBig}
                        />
                      )))
                      : (
                        <div
                          className={styles.emptyItem}
                          data-floors={floor}
                        />
                      )
                  }
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
});
