import React from 'react';

import { usePopupHoverText } from '../hooks';

interface IPopUpText {
  nameId: string;
}

export const PopUpText: React.FC<IPopUpText> = React.memo(({
  nameId,
}) => {
  const { textPopupList, detailInfo } = usePopupHoverText();

  return (
    <>
      {Object.keys(textPopupList).map((renderItem) => {
        const {
          order,
        } = textPopupList[renderItem];

        return (
          <span key={order} style={{ order }}>
            {textPopupList[renderItem]['value-prefix']}
            {detailInfo[nameId][renderItem]}
            {textPopupList[renderItem]['value-sufix']}
          </span>
        );
      })}
    </>
  );
});
