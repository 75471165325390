import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    borderRight: '1px solid #E9E9E9',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
  },
  avatar: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    position: 'relative',
    overflow: 'hidden',
    marginRight: '10px',
    background: ' #F2F2F2',
  },
  avatarImg: {
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  sidebarLink: {
    display: 'block',
    padding: '20px 15px',
    borderTop: '1px solid #E9E9E9',
    fontSize: '17px',
    lineHeight: '20px',
    color: '#201E2A',
    '&:last-child': {
      borderBottom: '1px solid #E9E9E9',
    },
    '&:hover': {
      color: '#6C59E6',
    },
    '&.active': {
      background: '#6C59E6',
      borderTop: '1px solid #6C59E6',
      pointerEvents: 'none',
      fontWeight: 'bold',
      color: '#fff',
    },
  },
}));
