import { useSelector } from 'react-redux';

import { getSelectedApartmentId } from 'src/store/floors/selectors';

export const useGetSelectedApartment = () => {
  const selectedApartmentId = useSelector(getSelectedApartmentId);

  return {
    selectedApartmentId,
  };
};
