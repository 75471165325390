import { useSelector } from 'react-redux';

import { isHideSpinnerFilterZone } from 'src/store/app/selectors';

export const useHideFilterZoneState = () => {
  const isHideFilterZone = useSelector(isHideSpinnerFilterZone);

  return {
    isHideFilterZone,
  };
};
