import React from 'react';
import { useParams } from 'react-router-dom';
import { AppFullScreenContainer } from 'src/components/ContainerFullScreen';
import { useTranslations } from 'src/hooks/translataions';
import { useSetDocumentTitle } from 'src/hooks';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';

export const ErrorPage: React.FC = () => {
  const styles = useStyles({});
  let text = '';
  const { type }: { type: string } = useParams();
  const { getTranslations } = useTranslations();
  useSetDocumentTitle(getTranslations(locals.appTranslations.appTitle404));

  switch (type) {
    case 'zeroDeal':
      text = 'Вы пытаетесь зайти в Flatshow с несохраненной сделки. Пожалуйста, сохраните сделку.';
      break;
    case 'wrongComplexAccess':
      text = 'Извините, у вас нет доступа к  комплексу привязанному к данной сделке.';
      break;
    case 'unknownPipeline':
      text = 'Эта воронка не привязана к комплексу в системе Flatshow. Пожалуйста, обратитесь к администратору Flatshow.';
      break;
    default:
      break;
  }

  return (
    <div className={styles.wrapperError}>
      <div className={styles.errorText}>
        {text}
      </div>
    </div>
  );
};
