import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { greyColor, fontBlackColor } from 'src/constants/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
  },
  greyButton: {
    borderColor: greyColor,
    color: fontBlackColor,
    textTransform: 'none',
    '&:hover': {
      // background: 'white',
      borderColor: greyColor,
    },
  },
  button: {
    fontSize: '18px',
  },

}));
