import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  fabMainStyleBtn: {
    color: theme.palette.primary.main,
  },
  fabMainStyleBtnWrapper: {
    boxShadow: `0 0 4px ${theme.palette.primary.main}, 0 4px 8px rgba(0, 0, 0, 0.28)`,
  },
  fabPhoneStyleBtn: {
    color: '#8E24AA',
  },
  fabPhoneStyleBtnWrapper: {
    boxShadow: '0 0 4px #8E24AA, 0 4px 8px rgba(0, 0, 0, 0.28)',
  },
  fabTelegramStyleBtn: {
    color: '#039BE5',
  },
  fabTelegramStyleBtnWrapper: {
    boxShadow: '0 0 4px #039BE5, 0 4px 8px rgba(0, 0, 0, 0.28)',
  },
  fabFacebookStyleBtn: {
    color: '#405DC5',
  },
  fabFacebookBtnWrapper: {
    boxShadow: '0 0 4px #405DC5, 0 4px 8px rgba(0, 0, 0, 0.28)',
  },
  actionStyles: {
    boxShadow: `0 0 4px ${theme.palette.primary.main}, 0 4px 8px rgba(0, 0, 0, 0.28)`,
  },
}));
