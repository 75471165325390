import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { greyColor, fontBlackColor } from 'src/constants/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  table: {
    minWidth: '650px',
  },
  container: {
    maxHeight: '100%',
  },
  modalWindow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    outline: '0',
  },
  btn: {
    padding: '15px 30px',
    fontSize: '18px',
  },
  formControl: {
    width: 260,
    marginBottom: theme.spacing(4),
  },

}));
