import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperFloorControls: {
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row',
      padding: '20px',
      marginTop: '60px',
      '& > div': {
        width: '100%',
      },
      '& > div:not(:first-child)': {
        marginLeft: '10px',
      },
    },
  },
}));
