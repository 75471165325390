import React, {
  useState, RefObject, useRef, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { requestSelectors } from 'src/store/requests/selectors';
import { useClickOutside } from 'src/hooks';
import { useStyles } from './styles';

interface IDropdown {
  button: React.ReactNode;
  children(onClose: Function): React.ReactNode|React.ReactElement;
  arrow?: boolean;
  arrowLeft?: boolean;
  isSmall?: boolean;
  closeOnChild?: boolean;
  isFuncChild?: boolean;
}

export const CustomDropdown: React.FC<IDropdown> = ({
  button,
  children,
  arrow = true,
  arrowLeft = false,
  isSmall = false,
  isFuncChild = false,
  closeOnChild = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropRef: RefObject<HTMLDivElement> = useRef(null);
  const mainColor = useSelector(requestSelectors.app.getMainColors);

  const styles = useStyles(mainColor);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useClickOutside(() => setIsOpen(false), dropRef);

  const handleClickDropItem = useCallback(() => {
    if (closeOnChild) {
      setIsOpen(false);
    }
  }, [closeOnChild]);

  const arrowComponent = isOpen
    ? <ArrowDropUpIcon className={`${styles.arrowIcon} ${arrowLeft && styles.arrowLeft}`} />
    : <ArrowDropDownIcon className={`${styles.arrowIcon} ${arrowLeft && styles.arrowLeft}`} />;

  return (
    <div
      ref={dropRef}
      className={styles.dropWrapper}
    >
      <Box
        onClick={toggleOpen}
        className={`${styles.dropdown} ${isOpen && styles.dropdownOpen}`}
        py={isSmall ? 2 : 3}
        pl={arrowLeft ? 8 : 4}
        pr={arrowLeft ? 4 : 8}
      >
        {arrow ? arrowComponent : null}
        {button}
      </Box>
      {isOpen ? (
        <Box
          className={`${styles.dropItem}`}
          onClick={handleClickDropItem}
        >
          {
            (isFuncChild && children) ? children(() => setIsOpen(false)) : children
          }
        </Box>
      ) : null}
    </div>
  );
};
