import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { ButtonBackSpinner } from 'src/components/ButtonBackSpinner';
import { AppPopover } from 'src/components/AppPopover';
import { ICurrentPano } from 'src/store/panorama/reducer';
import { useInitPano } from './hooks/useInitPano';
import { useStyles } from './styles';

export const ViewPanoramaBody: React.FC<{
  currentPano?: ICurrentPano,
  displayIds?: string[];
}> = ({ currentPano, displayIds }) => {
  const { panoRef, isNight, toggleNightMode } = useInitPano(currentPano, displayIds);
  const styles = useStyles({});

  return (
    <Box className={styles.containerWrapper}>
      <ButtonBackSpinner className={styles.backButton} />
      <Box
        position="absolute"
        top="160px"
        left="50px"
        zIndex={99999}
        width="30px"
        height="30px"
        style={{
          background: isNight ? 'aqua' : 'red',
          cursor: 'pointer',
        }}
        onClick={toggleNightMode}
      />
      <Box
        {...{ ref: panoRef }}
        className={styles.container}
      />
      <AppPopover />
    </Box>
  );
};
