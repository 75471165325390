import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { localStorageConstants } from 'src/constants';
import { favoriteCountState } from 'src/store/app/selectors';

export const useFavoriteStorage = () => {
  const storeFavCount = useSelector(favoriteCountState);
  const [isUp, setIsUp] = useState<boolean | null>(null);
  const getStorageItemsSelector: string | null = localStorage.getItem(localStorageConstants.favoriteList);
  const favIdsList = getStorageItemsSelector ? JSON.parse(getStorageItemsSelector) : [];
  const [componentCount, setCount] = useState<number>(favIdsList.length);

  useEffect(() => {
    if (storeFavCount !== componentCount) {
      if (storeFavCount > componentCount) {
        setIsUp(null);
        setTimeout(() => setIsUp(true), 10);
      } else {
        setIsUp(null);
        setTimeout(() => setIsUp(false), 10);
      }

      setCount(storeFavCount);
    }
  }, [storeFavCount, componentCount]);

  return {
    isUp,
    favIdsList,
    storeFavCount,
  };
};
