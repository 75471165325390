import React, { useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getIsRerender } from 'src/store/view-2d/selectors';
import { MemoizedRenderSvg } from 'src/components/ViewSpinner/components/RenderSvg';
import { Delayed } from 'src/components/Delayed';
import { WindRose } from 'src/components/WindRose';
import { randomHash } from 'src/helpers';
import { useAppVisibilitySpinnerBtn } from 'src/hooks/app';
import { makeWindRoseData } from 'src/components/WindRose/state';
import { PopupProvider } from 'src/components/ViewSpinner/context';
import { AppPopover } from 'src/components/AppPopover/index';
import { IAppSpinner } from './types';
import { useInitSpinner, useGetSpinnerSvg } from './hooks';
import { useStyles } from './styles';

export const AppSpinner: React.FC<IAppSpinner> = React.memo(({
  displayIds,
  hideControls,
  spinnerData,
  roseInTop,
  type,
  spinnerHeight,
  hideWindRose = false,
}) => {
  const randomSpinnerId: string = useMemo(randomHash, []);
  const theme: Theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isNotLarge = useMediaQuery('(max-width:1280px)');
  const styles = useStyles({});
  const isRerender = useSelector(getIsRerender);
  const [initWindRoseData, setInitWindRoseData] = useState<Function | null>(null);
  const {
    displayedSvgData,
  } = useGetSpinnerSvg(spinnerData);
  const {
    listOfStops,
    svgSize,
    spinnerState,
  } = useInitSpinner(
    displayIds,
    spinnerData,
    randomSpinnerId,
    hideWindRose,
    type,
    initWindRoseData,
  );

  const { gallerySpinnerBtnViews } = useAppVisibilitySpinnerBtn();

  useEffect(() => {
    setInitWindRoseData(makeWindRoseData);
  }, []);

  return (
    <PopupProvider>
      <Box
        width="100%"
        height="100%"
        position="relative"
        alignItems="center"
        flexDirection="column"
        display={isNotLarge ? 'flex' : 'block'}
      >
        {hideWindRose || (
        <WindRose
          randomSpinnerId={randomSpinnerId}
          initWindRoseData={initWindRoseData}
          onTop={roseInTop}
          isAppSpinner
        />
        )}
        <Box
          id={`spinner_place${randomSpinnerId}`}
          className="spinner_place"
          height={spinnerHeight ? `${spinnerHeight}px !important` : 'dontknow'}
          data-appspinner-id={randomSpinnerId}
        >
          <canvas
            id={`spinner_app_canvas${randomSpinnerId}`}
            className={`spinner_app_canvas ${styles.canvasStyle}`}
          />
          <div id={`svg_place${randomSpinnerId}`} className={`spinner_app_canvas ${styles.svgPlace}`}>
            {
          displayedSvgData && !isRerender && Object.keys(displayedSvgData).map((svgFrame, indexSvg) => {
            return spinnerState.stopFrame == svgFrame ? (
              <Delayed key={svgFrame} waitBeforeShow={300}>
                <svg
                // here compare string and number, because I used == instead ===
                  data-frame={svgFrame}
                  viewBox={`0 0 ${svgSize.w} ${svgSize.h}`}
                  style={{
                    marginLeft: `${spinnerState.dx / devicePixelRatio}px`,
                    marginTop: `${spinnerState.dy / devicePixelRatio}px`,
                    width: `${spinnerState.dw / devicePixelRatio}px`,
                    height: `${spinnerState.dh / devicePixelRatio}px`,
                    shapeRendering: isMdScreen ? 'optimizeSpeed' : 'auto',
                  }}
                >
                  {_.isArray(displayedSvgData[svgFrame])
                  && displayedSvgData[svgFrame].map((frame: any, index) => {
                    const nameId: string = frame.attr['entity-view-name-id'];
                    if (displayIds) {
                      if (displayIds.includes(nameId)) {
                        return <MemoizedRenderSvg isAlwaysHighlight key={`${frame.attr['data-is-terrace'] && 'terrace'} nameId`} svg={frame} />;
                      }
                      return null;
                    }
                    return <MemoizedRenderSvg key={`${frame.attr['data-is-terrace'] && 'terrace'} nameId`} svg={frame} />;
                  })}
                </svg>
              </Delayed>
            ) : null;
          })
        }
          </div>
          {
        !hideControls && (
        <div id={`spinner_control${randomSpinnerId}`} className={`spinner_control ${gallerySpinnerBtnViews}`}>
          <div
            className={`turn_left ${listOfStops.length <= 1 ? styles.hideRotate : ''}`}
            id={`turn_left${randomSpinnerId}`}
          >
            <i className="material-icons">arrow_back</i>
          </div>
          <div
            className={`turn_right ${listOfStops.length <= 1 ? styles.hideRotate : ''}`}
            id={`turn_right${randomSpinnerId}`}
          >
            <i className="material-icons">arrow_forward</i>
          </div>
          <div className="spinner_zoom" id={`spinner_zoom${randomSpinnerId}`}><i className="material-icons">zoom_in</i></div>
          <div className="spinner_unzoom" id={`spinner_unzoom${randomSpinnerId}`}><i className="material-icons">zoom_out</i></div>
        </div>
        )
      }

          <div id={`porgress_bar${randomSpinnerId}`} className="porgress_bar" />
        </Box>
      </Box>
      <AppPopover />
    </PopupProvider>
  );
});
