import { IFilterData } from 'src/typings/filter';
import { action } from 'src/store/common';

export const ActionTypes = {
  GET_APP_DATA_RESET: '[REQUEST_APP]GET_APP_DATA_RESET',
  GET_APP_DATA_REQUEST: '[REQUEST_APP]GET_APP_DATA_REQUEST',
  GET_APP_DATA_REQUEST_SUCCESS: '[REQUEST_APP]GET_APP_DATA_REQUEST_SUCCESS',
  GET_APP_DATA_REQUEST_FAIL: '[REQUEST_APP]GET_APP_DATA_REQUEST_FAIL',
};

export const Actions = {
  getAppDataReset: action(ActionTypes.GET_APP_DATA_RESET),
  getAppDataRequest: action(ActionTypes.GET_APP_DATA_REQUEST),
  getAppDataSuccess: action<IFilterData>(ActionTypes.GET_APP_DATA_REQUEST_SUCCESS),
  getAppDataFail: action<Error>(ActionTypes.GET_APP_DATA_REQUEST_FAIL),
};
