import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { configureLogger } from 'src/store/logger';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './root-saga';

import { createRootReducer } from './reducers';

export const history = createHashHistory({
  hashType: 'slash',
  getUserConfirmation: (message, callback) => callback(window.confirm(message)),
});

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [routerMiddleware(history), sagaMiddleware];
  // if (process.env.NODE_ENV === 'development') {
  //   const loggerMiddleware = configureLogger();

  //   middleware = [...middleware, loggerMiddleware];
  // } else {
  //   middleware = [...middleware];
  // }

  const store = createStore(
    createRootReducer(history),
    composeWithDevTools(applyMiddleware(...middleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore();
