import React from 'react';
import { Tune } from '@mui/icons-material';

import { ButtonApp } from 'src/components/Button';
import { useStyles as useStylesDropdown } from 'src/components/dropdowns/styles';
import { useGetFilterParams, useTranslations } from 'src/hooks';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';
import { IButtonShowMore } from './types';

export const ButtonShowMore: React.FC<IButtonShowMore> = ({
  isShowAll,
  handleShowLess,
}) => {
  const styles = useStyles({});
  const { selectedHideParams } = useGetFilterParams(3);
  const { getTranslations } = useTranslations();
  const stylesDropdown = useStylesDropdown();

  return (
    <ButtonApp
      withBorder
      isCapitalize
      text={`
        ${isShowAll ? getTranslations(locals.appTranslations.appLessFilter) : getTranslations(locals.appTranslations.appMoreFilter)}
        (${selectedHideParams.length})
      `}
      color="secondary"
      className={`${stylesDropdown.wrapperDropdown} ${styles.showHideLessFilter}`}
      onClick={() => handleShowLess()}
    >
      <Tune className={styles.iconColor} />
    </ButtonApp>
  );
};
