import React from 'react';
import { PopupProvider } from 'src/components/ViewSpinner/context';
import { ICurrentPano } from 'src/store/panorama/reducer';
import { ViewPanoramaBody } from './ViewPanoramaBody';

export const ViewPanorama: React.FC<{
  currentPano?: ICurrentPano,
  displayIds?: string[];
}> = ({ currentPano, displayIds }) => {
  return (
    <PopupProvider>
      <ViewPanoramaBody currentPano={currentPano} displayIds={displayIds} />
    </PopupProvider>
  );
};
