import {
  takeEvery, put, call,
} from 'redux-saga/effects';
import { getDisplayPlacementData } from 'src/api';
import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';
import { ActionTypes, Actions } from './actions';
import { IDisplayPlacementData } from './reducer';

export function* setDisplayPlacementData() {
  const buildType = getBuildType();
  const isCrossTable = buildType === BuildEnv.crossTable;
  let firstType: IDisplayPlacementData|undefined;
  const { data }: { data: IDisplayPlacementData[] } = yield call(getDisplayPlacementData);
  if (data) {
    yield put(Actions.setView2dDisplayTypes(data));
    if (isCrossTable) {
      firstType = data.find((item) => {
        return item.enable_for_crosstable;
      });
    } else {
      firstType = data.find((item) => {
        return item.enable_for_module;
      });
    }

    if (firstType) {
      yield put(Actions.setView2dSelectedType(firstType.field_name));
    }
  }
}

export function* getView2dWatchSaga() {
  yield takeEvery(ActionTypes.SET_VIEW2D_DATA, setDisplayPlacementData);
}
