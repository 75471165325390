import React, { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
// import { IRenderSvg } from './components/RenderSvg/types';
import { ISpinnerSvgBlock } from 'src/typings/spinner';
import { IFilterAllData } from 'src/typings/filter';

type Action = {
  type: 'open' | 'close' | 'recordOpen' | 'recordClose' | 'resetTimer';
  payload: any;
}
type Dispatch = (action: Action) => void
type State = {
  isOpen: boolean;
  svg: ISpinnerSvgBlock|null;
  placement: IFilterAllData|null;
  target: HTMLElement|null;
  cursorPoint: {
    x: number;
    y: number;
  } | null;
  debounceOpen: any;
  debounceClose: any;
  resetTimer: any;
}
type PopupProviderProps = {children: React.ReactNode}

const PopupStateContext = React.createContext<
  {state: State; dispatch: Dispatch} | undefined
>(undefined);

function popupReducer(state: State, action: Action) {
  switch (action.type) {
    case 'open': {
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    }
    case 'close': {
      return {
        ...state,
        svg: null,
        placement: null,
        target: null,
        isOpen: false,
      };
    }
    case 'recordOpen': {
      return {
        ...state,
        debounceOpen: action.payload,
      };
    }
    case 'recordClose': {
      return {
        ...state,
        debounceClose: action.payload,
      };
    }
    case 'resetTimer': {
      return {
        ...state,
        resetTimer: action.payload,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function PopupProvider({ children }: PopupProviderProps) {
  const [state, dispatch] = React.useReducer(popupReducer, {
    isOpen: false,
    svg: null,
    placement: null,
    target: null,
    debounceOpen: null,
    debounceClose: null,
  });
  // eslint-disable-next-line no-undef
  const mouseDelay = useRef<NodeJS.Timeout|null>(null);
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  useEffect(() => {
    const abortController = new AbortController();
    let cleanupFunction = false;
    dispatch({
      type: 'recordOpen',
      payload: (payload: {
        svg?: ISpinnerSvgBlock;
        palcement?: IFilterAllData;
        target?: Element;
        cursorPoint?: {
          x: number;
          y: number;
        };
      }) => {
        if (mouseDelay.current) {
          window.clearTimeout(mouseDelay.current);
        }
        const timeId = setTimeout(() => {
          if (!cleanupFunction) {
            dispatch({ type: 'open', payload });
          }
        }, 250);
        mouseDelay.current = timeId;
      },
    });
    dispatch({
      type: 'resetTimer',
      payload: () => {
        setTimeout(() => {
          if (mouseDelay.current) {
            window.clearTimeout(mouseDelay.current);
            mouseDelay.current = null;
          }
        }, 50);
      },
    });
    dispatch({
      type: 'recordClose',
      payload: () => {
        if (mouseDelay.current) {
          window.clearTimeout(mouseDelay.current);
        }
        const timeId = setTimeout(() => {
          if (!cleanupFunction) {
            dispatch({ type: 'close', payload: null });
          }
        }, 300);
        mouseDelay.current = timeId;
      },
    });
    return () => {
      abortController.abort();
      if (mouseDelay.current) {
        window.clearTimeout(mouseDelay.current);
      }
      cleanupFunction = true;
    };
  }, []);

  const value = { state, dispatch };
  return (
    <PopupStateContext.Provider value={value}>
      {children}
    </PopupStateContext.Provider>
  );
}

function usePopup() {
  const context = React.useContext(PopupStateContext);
  if (context === undefined) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
}

export { PopupProvider, usePopup };
