import { action } from 'src/store/common';

export const ActionTypes = {
  GET_TRANSLATION_REQUEST: '[REQUEST_APP]GET_TRANSLATION_REQUEST',
  GET_TRANSLATION_RESET: '[REQUEST_APP]GET_TRANSLATION_RESET',
  GET_TRANSLATION_REQUEST_SUCCESS: '[REQUEST_APP]GET_TRANSLATION_REQUEST_SUCCESS',
  GET_TRANSLATION_REQUEST_FAIL: '[REQUEST_APP]GET_TRANSLATION_REQUEST_FAIL',
};

export const Actions = {
  getTranslationRequest: action(ActionTypes.GET_TRANSLATION_REQUEST),
  getTranslationReset: action(ActionTypes.GET_TRANSLATION_RESET),
  getTranslationSuccess: action(ActionTypes.GET_TRANSLATION_REQUEST_SUCCESS),
  getTranslationFail: action<Error>(ActionTypes.GET_TRANSLATION_REQUEST_FAIL),
};
