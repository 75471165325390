import React from 'react';

import {
  ApartmentGallery,
  AppBreadcrumbs,
  AppFullScreenContainer,
  AppHeader,
  ApartmentDescription,
} from 'src/components';
import { useBuildType, useSetDocumentTitle, useTranslations } from 'src/hooks';
import { locals } from 'src/constants/locals';
import { ListOfKeysFilterAllData, PlacementShowModule } from 'src/typings/filter';
import { useStyles } from './styles';
import { useGetParamsData } from './hooks';
import { ComponentAdditionalInfo } from './ComponentAdditionalInfo';

export const MainPageApartments: React.FC = () => {
  const styles = useStyles({});
  const {
    isSingleId,
    combineIds,
    spinnerApartments,
    panoApartments,
    chooseData,
  } = useGetParamsData();
  const { getTranslations } = useTranslations();
  const { isCrossTable } = useBuildType();
  useSetDocumentTitle(getTranslations(locals.appTranslations.appTitlePlacement));

  return (
    <AppFullScreenContainer>
      <div className={styles.apartmentWrapper}>
        <AppHeader />
        <div className={styles.headerBlock}>
          <AppBreadcrumbs />
        </div>
        {chooseData && (
          <>
            {
              (isCrossTable || chooseData[ListOfKeysFilterAllData.placementStatusShowInModule] === PlacementShowModule.show || !isSingleId)
                ? (
                  <div className={styles.apartmentContainer}>
                    <ApartmentGallery
                      combineIds={combineIds}
                      spinnerApartments={spinnerApartments}
                      panoApartments={panoApartments}
                      gallery={chooseData[ListOfKeysFilterAllData.placementGallery] || []}
                      chooseData={chooseData}
                    />
                    <ApartmentDescription isSingleId={isSingleId} placeInfo={chooseData} />
                    {
                      isSingleId
                      && <ComponentAdditionalInfo chooseData={chooseData} />
                    }
                  </div>
                )
                : (
                  <div>
                    <p className={styles.textNotAvailable}>{getTranslations(locals.appTranslations.appApartmentNotAvailable)}</p>
                  </div>
                )
            }
          </>
        )}
      </div>
    </AppFullScreenContainer>
  );
};
