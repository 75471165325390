import { ApiUrl, crosstableUrl } from 'src/constants';
import { httpService } from 'src/services';

export function getCreatioProjects(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().creatioProjects}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioProjectById(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioProjects}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export interface PostCreatioProject {
  domain: string;
  login: string;
  password: string;
  developer: string;
}

export function postCreatioProject(token: string = '', data: PostCreatioProject) {
  return httpService.post(`${ApiUrl.getApiUrl().creatioProjects}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function postCreatioProjectByStageId(token: string = '', id: string, data: PostCreatioProject) {
  return httpService.post(`${ApiUrl.getApiUrl().creatioProjects}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioStages(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().creatioStages}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioStageById(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioStages}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export interface PostCreatioStage {
  externalId: string;
  externalName: string;
  priority: number;
  sortSequence: number;
  unlinkPlacementAtStageWithLowerPriority: boolean;
  unlinkPlacementAtStageWithHigherPriority: boolean;
  unlinkPlacementAtStageWithSamePriority: boolean;
  unlinkAllPlacementsAtAllOtherStages: boolean;
  unlinkPlacementAtThisStage: boolean;
  blockMoveAndLinkDealsWithPlacementAtOtherStages: boolean;
  blockMoveNdLinkDealsWithPlacementAtOrAftrCurrentStage: boolean;
  placementStatuses: string[];
}

export function patchCreatioStageById(token: string = '', id: string, data: PostCreatioStage) {
  return httpService.patch(`${ApiUrl.getApiUrl().creatioStages}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function postCreatioCreateAutoStage(token: string = '', projectId: string) {
  return httpService.post(`${ApiUrl.getApiUrl().creatioAutomatically}`, {
    creatioProjectId: projectId,
  }, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioPlacementStatusOnZeroDeals(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().creatioPlacementStatusOnZeroDeals}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioPlacementStatusOnZeroDealsById(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioPlacementStatusOnZeroDeals}?${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export interface CreatioPlacementStatusOnZeroDeal {
  complex: string;
  placementStatus: string;
}

export function patchCreatioPlacementStatusOnZeroDeal(token: string = '', id: string, data: CreatioPlacementStatusOnZeroDeal) {
  return httpService.patch(`${ApiUrl.getApiUrl().creatioStages}?${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioDeals(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().creatioOpportunities}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioDeal(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioOpportunities}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getCreatioResponsibleUsers(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().creatioPlacementOpporunities}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export interface PatchCreatioDeal {
  externalId?: string;
  creatioProject?: string;
  placements: string[] | null;
  creatioStage?: string;
}

export function patchCreatioDeal(token: string = '', id: string | number, data: PatchCreatioDeal) {
  return httpService.patch(`${ApiUrl.getApiUrl().creatioOpportunities}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function getComplexesByCreatioDeveloper(token: string = '', projectId: string|number, email: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}?developers.creatioProject=${projectId}&users.email=${email}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
