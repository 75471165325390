import { put, select, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

import { ActionTypes, Actions } from 'src/store/filter/filtered-data/actions';
import { Actions as ActionsView2d } from 'src/store/view-2d/actions';
import { getFilteredData } from 'src/store/filter/filtered-data/selectors';
import { IFilterAllData, IFilterData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { getFilterInitialAllData } from 'src/store/requests/filter/selector';
import { compareNumbers } from 'src/helpers';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';

export function* setFilteredDataSaga(actions: {
  type: string,
  payload: {
    selectedPlacement: string,
    data: IFilterAllData[],
  }
}): any {
  const selectedPlacementIds = actions.payload.data.map((item) => item.id);
  const selectedTypePlacements = yield select(getSelectedPlacement);

  if (actions.payload.data.length) {
    const filterAllData: IFilterData = yield select(getFilterInitialAllData);
    const listOfFilteredHouses = Array.from(new Set(actions.payload.data.map((item) => item[ListOfKeysFilterAllData.placementHouse])));
    let listOfFloors: string[] = [];

    const listOfIncludesHousesWithoutSplitToSection: IFilterAllData[] = _.filter(filterAllData[selectedTypePlacements].filter_all_data, (item) => {
      return listOfFilteredHouses.includes(item[ListOfKeysFilterAllData.placementHouse]);
    });

    const listOfIncludesHousesWithSplitToSection = listOfIncludesHousesWithoutSplitToSection
      .reduce((acc, cur: IFilterAllData | any) => {
        if (cur[ListOfKeysFilterAllData.placementFloor] !== null) listOfFloors = listOfFloors.concat(cur[ListOfKeysFilterAllData.placementFloor]);

        if (cur[ListOfKeysFilterAllData.placementHouse] && cur[ListOfKeysFilterAllData.placementSection]) {
          if (acc[cur[ListOfKeysFilterAllData.placementHouse]]) {
            if (acc[cur[ListOfKeysFilterAllData.placementHouse]][cur[ListOfKeysFilterAllData.placementSection]]) {
              return {
                ...acc,
                [cur[ListOfKeysFilterAllData.placementHouse]]: {
                  ...acc[cur[ListOfKeysFilterAllData.placementHouse]],
                  [cur[ListOfKeysFilterAllData.placementSection]]: [
                    ...acc[cur[ListOfKeysFilterAllData.placementHouse]][cur[ListOfKeysFilterAllData.placementSection]],
                    cur,
                  ],
                },
              };
            }

            return {
              ...acc,
              [cur[ListOfKeysFilterAllData.placementHouse]]: {
                ...acc[cur[ListOfKeysFilterAllData.placementHouse]],
                [cur[ListOfKeysFilterAllData.placementSection]]: [cur],
              },
            };
          }

          return {
            ...acc,
            [cur[ListOfKeysFilterAllData.placementHouse]]: {
              [cur[ListOfKeysFilterAllData.placementSection]]: [cur],
            },
          };
        }

        return acc;
      }, {} as { [key: string]: { [key: string]: any } });

    yield put(ActionsView2d.setFloorList(Array.from(new Set(listOfFloors)).sort(compareNumbers).reverse()));
    yield put(ActionsView2d.setView2dData(listOfIncludesHousesWithSplitToSection));
  } else {
    yield put(ActionsView2d.clearView2d());
  }

  yield put(ActionsView2d.setView2dSelectedId(selectedPlacementIds));
  // }
}

export function* clearFilteredDataSaga(): any {
  const filterAllData = yield select(getFilteredData);
  const keysFilterAllData = Object.keys(filterAllData);

  const result = keysFilterAllData.reduce((accu, cur) => {
    return {
      ...accu,
      [cur]: [],
    };
  }, {});

  yield put(Actions.setClearFilteredData(result));
}

export function* setFilteredDataWatchSaga() {
  yield takeEvery(ActionTypes.SET_FILTERED_DATA, setFilteredDataSaga);
  yield takeEvery(ActionTypes.CLEAR_FILTERED_DATA, clearFilteredDataSaga);
}
