import { takeEvery, select, put } from 'redux-saga/effects';

import { ActionTypes } from 'src/store/requests/infrastructure/actions';
import { Actions as ActionsTypePlacementParams } from 'src/store/requests/type-of-property/actions';
import { getListOfTypeDisplayedSpinner } from 'src/store/type-displayed-spinner/selectors';
import { Actions as ActionsTypeDisplayed } from 'src/store/type-displayed-spinner/actions';
import { Actions as ActionsInfrastructure } from 'src/store/infrastructure/actions';
import { IInfrastructureRequest } from 'src/typings/infrastructure';
import { getTypesPlacementState } from 'src/store/requests/type-of-property/selector';
import { ITypeOfPropertyReducer } from 'src/store/requests/type-of-property/reducer';
import { locals } from 'src/constants/locals';
import { ListOfDropdown } from 'src/typings/filter';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { IPlacementType } from 'src/store/type-displayed-spinner/reducer';

export function* setAppInfrastructureParams(action: {
  type: string,
  payload: {
    [key: string]: IInfrastructureRequest
  },
}) {
  const typeOfProperty: ITypeOfPropertyReducer = yield select(getTypesPlacementState);
  const listOfTypes: IPlacementType[] = yield select(getListOfTypeDisplayedSpinner);
  const extendTypeOfProperty = [...typeOfProperty.typesPlacement, locals.appTranslations.appInfrastructure];
  const keysInfrastructure: string[] = Object.keys(action.payload);

  if (keysInfrastructure.length) {
    const listOfSpinner = action.payload[keysInfrastructure[0]];
    const nameOfSpinners = Object.keys(listOfSpinner);
    const initialInfrastructureState = nameOfSpinners.reduce((acc, cur) => {
      const listIds = Object.keys(listOfSpinner[cur]);
      const dropdownsData = listIds.reduce((accDropdowns, curDropdowns) => {
        const sortedInfrastructureArray = listOfSpinner[cur][curDropdowns].filters
          .sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));

        return {
          ...accDropdowns,
          [curDropdowns]: {
            infrastructureFilteredIds: [],
            infrastructureFiltersValues: sortedInfrastructureArray.reduce((accFilter, curFilter) => {
              if (
                curFilter.filter_type === ListOfDropdown.dropdownRadioList
                  || curFilter.filter_type === ListOfDropdown.dropdownSelectList
                  || curFilter.filter_type === ListOfDropdown.dataList
              ) {
                return {
                  ...accFilter,
                  [curFilter.filter_name]:
                    {
                      typeParam: curFilter.filter_type,
                      data: '',
                      isDisabled: false,
                    },
                };
              }

              if (
                curFilter.filter_type === ListOfDropdown.datalistCheckboxList
                  || curFilter.filter_type === ListOfDropdown.dropdownCheckboxList
              ) {
                return {
                  ...accFilter,
                  [curFilter.filter_name]:
                    {
                      typeParam: curFilter.filter_type,
                      data: [],
                      isDisabled: false,
                    },
                };
              }

              return accFilter;
            }, {} as any),
            infrastructureFilters: sortedInfrastructureArray.map((item) => {
              return {
                attribute: item.filter_name,
                type: item.filter_type,
                data: item.childs.map((childItem) => {
                  return {
                    key: childItem.ids,
                    text: childItem.name,
                    value: childItem.name,
                  };
                }),
              };
            }),
          },
        };
      }, {} as any);

      return {
        ...acc,
        [cur]: dropdownsData,
      };
    }, {});

    yield put(ActionsTypePlacementParams.setFilterTypePlacement(extendTypeOfProperty));
    if (!listOfTypes.length) {
      yield put(ActionsTypeDisplayed.setTypeDisplayedSpinner(TypesOfSpinner.viewSpinner));
    }
    if (!typeOfProperty.selectedType) yield put(ActionsTypePlacementParams.setSelectedTypePlacement(extendTypeOfProperty[0]));
    yield put(ActionsInfrastructure.setInfrastructureFiltersValuesInitial(initialInfrastructureState));
  }
}

export function* getInfrastructureWatchSaga() {
  yield takeEvery(ActionTypes.GET_INFRASTRUCTURE_FILTER_DATA_REQUEST_SUCCESS, setAppInfrastructureParams);
}
