import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    position: 'relative',
    overflow: 'hidden',
    marginRight: '10px',
    background: ' #F2F2F2',
  },
  avatarImg: {
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
