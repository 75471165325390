/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Loader } from 'src/components/Loader';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { IDeveloper, IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { borderColor } from 'src/constants/styles';
import { postBitrixProject, patchBitrixProject } from 'src/api';

import { useStyles } from '../styles';

interface IMainDataForm {
  developer: IDeveloper;
  project: IProject|null;
  fetchProject: Function;
}

const schema = yup.object().shape({
  bitrixGetStagesListEndpoint: yup.string(),
  bitrixGetDealEndpoint: yup.string(),
  domain: yup.string(),
});

export const MainDataForm: React.FC<IMainDataForm> = ({
  developer,
  project,
  fetchProject,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const {
    register, handleSubmit, formState, watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (data:any) => {
    let res;
    if (project) {
      res = await patchBitrixProject(jwt, project['@id'], data);
    } else {
    // eslint-disable-next-line no-param-reassign
      data.developer = developer['@id'];
      res = await postBitrixProject(jwt, data);
    }
    if (res.ok) {
      fetchProject();
    } else {
      setError(res.data['hydra:description']);
    }
  };

  return (

    <Box>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        Основные данные
      </Box>
      <Box fontSize="14px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={4} display="flex" alignItems="center">
            <Box width="260px" mr={3}>
              Домен аккаунта BITRIX
            </Box>
            <Box width="260px">
              <TextField
                id="domain"
                size="small"
                variant="outlined"
                name="domain"
                inputRef={register}
                defaultValue={project && (project.domain)}
              />
            </Box>
          </Box>
          <Box mb={4} display="flex" alignItems="center">
            <Box width="260px" mr={3}>
              Webhook URL
            </Box>
            <Box width="260px">
              <TextField
                id="webhookUrl"
                size="small"
                variant="outlined"
                name="webhookUrl"
                inputRef={register}
                defaultValue={project && (project.webhookUrl)}
              />
            </Box>
          </Box>

          <Box width="260px">
            <ButtonApp
              text={translations.safeChanges}
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              className={styles.btn}
              disabled={!formState.isValid && !developer}
            />
          </Box>
        </form>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};
