import { useSelector } from 'react-redux';

import { getCounters } from 'src/store/spinner/selectors';
import { IHitsList } from 'src/typings/spinner';

export const useGetCounters = () => {
  const counters: IHitsList = useSelector(getCounters);

  return {
    counters,
  };
};
