import { IAppState } from 'src/store/types';

export const getCrumbs = (state: IAppState) => state.app.crumbs;
export const getLanguagesList = (state: IAppState) => state.app.languages;
export const getCurrentLang = (state: IAppState) => state.app.currentLang;
export const isBookModalOpen = (state: IAppState) => state.app.isBookModalOpen;
export const isMenuOpen = (state: IAppState) => state.app.isMenuOpen;
export const jwtToken = (state: IAppState) => state.app.jwtToken;
export const getComplexId = (state: IAppState) => state.app.complexId;
export const isFilterOpen = (state: IAppState) => state.app.isFilterOpen;
export const isMobileFilterOpen = (state: IAppState) => state.app.isMobileFilterOpen;
export const isShowNestedFilter = (state: IAppState) => state.app.isShowNestedFilter;
export const draggableMobileMenuState = (state: IAppState) => state.app.isEndDraggableMobileMenu;
export const showInfrastructureState = (state: IAppState) => state.app.showInfrastructure;
export const favoriteCountState = (state: IAppState) => state.app.favoriteCount;
export const isHideSpinnerFilterZone = (state: IAppState) => state.app.spinnerDisableFilter;
export const getCrmData = (state: IAppState) => state.app.crmData;
export const getSelectedAppartment = (state: IAppState) => state.app.selectedAppartment;
export const getBindingAppartment = (state: IAppState) => state.app.bindingAppartment;
export const getIsOpenAppartSidebar = (state: IAppState) => state.app.isOpenAppartSidebar;
export const getCurrentEntity = (state: IAppState) => state.app.currentEntity;
