import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';

export const useGetTypeOfProperty = () => {
  const typeOfProperty: string | undefined = useSelector(requestSelectors.typeOfProperty.getSelectedPlacement);

  return {
    typeOfProperty,
  };
};
