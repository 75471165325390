import React from 'react';
import { useSelector } from 'react-redux';
import { ReactImageGalleryItem } from 'react-image-gallery';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { AppGallery, ButtonApp } from 'src/components';
import { useGenerateGalleryFormat } from 'src/components/ApartmentGallery/hooks';
import {
  IApartmentGallery,
  ReactImageGalleryItemWithSpinnerData,
} from 'src/components/ApartmentGallery/types';
import { resizeEmulate } from 'src/helpers';
import { AroundImage } from 'src/components/FilterSpinner/components/TypeDisplayedSpinner/components/360Around';
import { FavIcon } from 'src/components/LayoutApartmentItem/components/FavIcon';
import { requestSelectors } from 'src/store/requests/selectors';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { useBuildType } from 'src/hooks';
import { useStyles } from './styles';
import './styles.scss';
import { ComponentGalleryItem } from './ComponentGalleryItem';

function checkURL(url: string) {
  return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

export const ApartmentGallery: React.FC<IApartmentGallery> = ({
  combineIds,
  spinnerApartments,
  panoApartments,
  gallery,
  chooseData,
}) => {
  const styles = useStyles({});
  const { isCrossTable } = useBuildType();
  const galleryPreviews = useSelector(requestSelectors.app.getGalleryPreviews);
  const { galleryState } = useGenerateGalleryFormat(
    spinnerApartments,
    panoApartments,
    gallery,
    combineIds,
  );

  const _renderItem = (item: ReactImageGalleryItem) => {
    return (
      <ComponentGalleryItem
        galleryItem={item}
        galleryImagesTransform={chooseData[ListOfKeysFilterAllData.placementGalleryImagesTransform]}
        floorIds={chooseData[ListOfKeysFilterAllData.floorIds]}
        placementId={chooseData[ListOfKeysFilterAllData.id]}
        combineIds={combineIds}
      />
    );
  };

  const _renderThumbInner = ({
    spinnerData,
    original, icon,
    thumbnail,
  }: ReactImageGalleryItemWithSpinnerData) => {
    if (spinnerData || original === 'panorama') {
      return (
        <div className={`${styles.thumbnailInner} image-gallery-thumbnail-inner`}>
          {
            icon
              ? <img alt="preview" src={icon} className={styles.previewIcon} />
              : <AroundImage />
          }
        </div>
      );
    }

    return (
      <div className="image-gallery-thumbnail-inner">
        {
          thumbnail?.indexOf('playcanvas') !== -1 || checkURL(thumbnail || '')
            ? <img alt="thumb" className="image-gallery-thumbnail-image" src={thumbnail} />
            : <img alt="preview" src={galleryPreviews?.placement_gallery_playcanvas_preview_url || ''} className={styles.previewIcon} />
        }
      </div>
    );
  };

  if (galleryState.length === 0) return null;

  return (
    <div className={styles.wrapperGallery}>
      {(combineIds.length === 1 && !isCrossTable) && <FavIcon itemId={combineIds[0]} />}
      {Boolean(galleryState) && (
        <AppGallery
          lazyLoad
          showFullscreenButton
          disableSwipe={Boolean(spinnerApartments)}
          additionalClass="app-slide-wrapper"
          showBullets={false}
          showPlayButton={false}
          showNav={false}
          items={galleryState}
          renderFullscreenButton={(onClick, isFullscreen) => {
            return (
              <ButtonApp
                style={{ position: 'absolute' }}
                className={`image-gallery-fullscreen-button ${styles.zoomButton}`}
                onClick={(event) => {
                  onClick(event);
                  setTimeout(resizeEmulate, 250);
                }}
              >
                {
                  isFullscreen
                    ? <FullscreenExitIcon className={styles.zoomStyle} />
                    : <FullscreenIcon className={styles.zoomStyle} />
                }
              </ButtonApp>
            );
          }}
          renderItem={_renderItem}
          renderThumbInner={_renderThumbInner}
        />
      )}
    </div>
  );
};
