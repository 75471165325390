import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  fullSizeContainer: (props: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: `${props.filterBackgroundColor}e6`,
    '& > svg': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: props.filterBackgroundColor,
    },
  }),
  fullSizeContainerClose: {
    flexDirection: 'column',
  },
  showFilterText: {
    transform: 'rotate(180deg)',
    writingMode: 'vertical-lr',
    margin: '4px 0',
    fontSize: '13px',
  },
}));
