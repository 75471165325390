import React from 'react';

import { FloorMarkup, FloorsList } from './components';
import { useStyles } from './styles';

export const ViewFloors: React.FC = () => {
  const styles = useStyles({});

  return (
    <div className={styles.wrapperFloorControls}>
      <FloorMarkup />
      <FloorsList />
    </div>
  );
};
