import React, { useState } from 'react';

import {
  useIsMobile,
  useOpenFilter,
  useTypeDisplayedSpinner,
} from 'src/hooks';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { useStyles as useStylesSpinner } from './styles-spinner';
import { useStyles as useStylesCrossTable } from './styles-crosstable';
import {
  CrossTableTypesRender,
  TypeDisplayedSpinner,
} from './components';

export const FilterMapCrossTable: React.FC = () => {
  const stylesSpinner = useStylesSpinner();
  const stylesCrossTable = useStylesCrossTable();
  const { isOpen } = useOpenFilter();
  const { isMobileScreen } = useIsMobile();
  const { getSelectedViewId } = useTypeDisplayedSpinner();
  const isFloors = TypesOfSpinner.viewFloors === getSelectedViewId;
  const [isFullSizeFloorSpinner, setFullSizeFloorSpinner] = useState(false);

  return (
    <div
      className={`${stylesCrossTable.mapWrapper} ${
        isOpen ? stylesSpinner.filterOpen : stylesSpinner.filterClose} ${
        (isFloors && !isFullSizeFloorSpinner) ? stylesSpinner.floorWrapper : ''}`}
    >
      {!isMobileScreen && (
        <div className={stylesCrossTable.wrapperMapHeader}>
          <div className={stylesCrossTable.wrapperDesktopTypeSelector}>
            <TypeDisplayedSpinner isActive />
          </div>
        </div>
      )}
      <CrossTableTypesRender setFullSizeFloor={setFullSizeFloorSpinner} />
    </div>
  );
};
