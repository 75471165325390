import { action } from 'src/store/common';

export const ActionTypes = {
  SET_FILTER_VALUE: '[FILTER_VALUE]SET_FILTER_VALUE',
  CHANGE_FILTER_VALUE: '[FILTER_VALUE]CHANGE_FILTER_VALUE',
  GET_FILTER_VALUE: '[FILTER_VALUE]GET_FILTER_VALUE',
};

export const Actions = {
  setFilterValue: action(ActionTypes.SET_FILTER_VALUE),
  changeFilterValue: action(ActionTypes.CHANGE_FILTER_VALUE),
  getFilterValue: action(ActionTypes.GET_FILTER_VALUE),
};
