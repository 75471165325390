/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { requestSelectors } from 'src/store/requests/selectors';
import { getAvailableComplexes } from 'src/store/crosstable/selectors';
import { CRMNames } from 'src/typings/app';
import { getCrmData } from 'src/store/app/selectors';
import { translations } from 'src/constants/translations';
import { useSetDocumentTitle } from 'src/hooks';
import { IComplex } from 'src/typings/complexes';
import { SearchField } from 'src/components/SearchField';
import { ComplexItem } from './components/ComplexItem';
import { useStyles } from './styles';

export const Complexes = () => {
  const styles = useStyles({});
  const crmData = useSelector(getCrmData);
  const user = useSelector(requestSelectors.user.getUserData);
  const availableComplexes = useSelector(getAvailableComplexes);
  const isLargeScreen = useMediaQuery('(min-width:1200px)');

  const [filteredComplexes, setFilteredComplexes] = useState<IComplex[]>([]);

  useSetDocumentTitle(translations.complexesLink);

  useEffect(() => {
    if (crmData?.crm === CRMNames.bitrix || crmData?.crm === CRMNames.creatio) {
      setFilteredComplexes(availableComplexes || []);
    } else {
      setFilteredComplexes(availableComplexes || user?.complexes || []);
    }
  }, [availableComplexes, user, crmData]);

  const handleChangeComplexes = (searchTerm: string) => {
    const formatSearchTerm = searchTerm.toLowerCase();
    if (user) {
      const { complexes } = user;
      const workList = availableComplexes || complexes;
      setFilteredComplexes(workList.filter((comp: IComplex) => {
        const lang = comp.language;
        const res = comp.title[lang].toLowerCase().indexOf(formatSearchTerm);
        return res !== -1;
      }));
    }
  };
  return (
    <Box py={8} px={4} height="100%" className={styles.wrapper}>
      <Box mb={8} display="flex" flexWrap="nowrap">
        <Box width={340} mr="auto">
          <SearchField handleChangeComplexes={handleChangeComplexes} />
        </Box>
      </Box>
      <Box>
        {filteredComplexes && filteredComplexes.length ? (
          <Grid container spacing={6}>
            {filteredComplexes.map((complex: IComplex) => {
              return complex.showInCrosstable ? (
                <Grid item xs={isLargeScreen ? 3 : 4} key={`complex${complex.id}`}>
                  <ComplexItem complex={complex} />
                </Grid>
              ) : null;
            })}
          </Grid>
        ) : (
          <Box display="flex" justifyContent="center">
            {translations.notFoundComplexes}
          </Box>
        )}
      </Box>
    </Box>
  );
};
