import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import Popover, { PopoverProps, PopoverOrigin } from '@mui/material/Popover';
import { Actions } from 'src/store/floors/actions';
import { PopoverInfo } from 'src/components/ViewSpinner/components/RenderSvg/PopoverInfo';
import { usePopup } from 'src/components/ViewSpinner/context';
import { setHoveredPoly } from 'src/components/ViewPanorama/helpers';
import { PolygonTriggerEvent } from 'src/typings/app';
import { useStyles } from './styles';
import { usePolygonConfig } from '../ViewSpinner/components/RenderSvg/hooks/usePolygonConfig';

const POPOVER_WIDTH = 184;
const POPOVER_HEIGHT = 320;

export const AppPopover = () => {
  const styles = useStyles({});
  const [position, setPosition] = useState<{
    anchor: PopoverOrigin;
    transform: PopoverOrigin;
  }>({
    anchor: {
      vertical: 'top',
      horizontal: 'right',
    },
    transform: {
      vertical: 'top',
      horizontal: 'left',
    },
  });
  const dispatch = useDispatch();
  const { globalPolygonConfig } = usePolygonConfig();
  const { state: popupState, dispatch: dispatchPopup } = usePopup();
  const triggerEvent: PolygonTriggerEvent = useMemo(() => globalPolygonConfig?.trigger_event
    || PolygonTriggerEvent.hover, [globalPolygonConfig]);

  useEffect(() => {
    if (popupState.target || popupState.cursorPoint) {
      let rightEl = 0;
      let bottEl = 0;
      if (popupState.target) {
        rightEl = popupState.target.getBoundingClientRect().right;
        bottEl = popupState.target.getBoundingClientRect().bottom;
      }

      if (popupState.cursorPoint) {
        rightEl = popupState.cursorPoint.x + 20;
        bottEl = popupState.cursorPoint.y;
      }

      if (rightEl + POPOVER_WIDTH < window.innerWidth) {
        if (bottEl + POPOVER_HEIGHT < window.innerHeight) {
          setPosition({
            anchor: {
              vertical: 'top',
              horizontal: 'right',
            },
            transform: {
              vertical: 'top',
              horizontal: 'left',
            },
          });
        } else {
          setPosition({
            anchor: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transform: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          });
        }
      } else if (bottEl + POPOVER_HEIGHT < window.innerHeight) {
        setPosition({
          anchor: {
            vertical: 'top',
            horizontal: 'left',
          },
          transform: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        setPosition({
          anchor: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transform: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    }
  }, [popupState]);

  const handleBackdropClick = useCallback(() => {
    dispatch(Actions.setSelectedApartmentId(''));
    dispatchPopup({
      type: 'close',
      payload: null,
    });
    if (popupState.placement) {
      setHoveredPoly(popupState.placement.id);
    }
  }, [dispatch, popupState]);

  const handleTouchEnd = useCallback(() => {
    setTimeout(() => {
      if (popupState.debounceClose) {
        popupState.debounceClose();
      }
      if (popupState.placement) {
        setHoveredPoly(popupState.placement.id);
      }
    }, 500);
  }, [popupState]);

  const handleMouseEnter = useCallback(() => {
    if (popupState.resetTimer) {
      popupState.resetTimer();
    }
    if (popupState.placement) {
      setHoveredPoly(popupState.placement.id);
    }
  }, [popupState]);
  const handleMouseLeave = useCallback(() => {
    if (popupState.debounceClose) {
      popupState.debounceClose();
    }
    if (popupState.placement) {
      setHoveredPoly(popupState.placement.id);
    }
  }, [popupState]);

  const handlePopoverClose = useCallback(() => {
    dispatchPopup({
      type: 'close',
      payload: null,
    });
  }, [dispatchPopup]);

  return (
    <Popover
      id="mouse-over-popover"
      open={popupState.isOpen}
      anchorReference={popupState.target ? 'anchorEl' : 'anchorPosition'}
      anchorEl={popupState.target}
      anchorPosition={popupState.cursorPoint ? { top: popupState.cursorPoint.y, left: popupState.cursorPoint.x + 20 } : undefined}
      className={`${triggerEvent === PolygonTriggerEvent.hover ? styles.rootPopOver : ''}`}
      onBackdropClick={handleBackdropClick}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      anchorOrigin={position.anchor}
      transformOrigin={position.transform}
    >
      {(popupState.svg || popupState.placement) && (
      <PopoverInfo
        attr={popupState.svg && popupState.svg.attr}
        placement={popupState.placement || undefined}
        handleClose={handlePopoverClose}
      />
      )}
    </Popover>
  );
};
