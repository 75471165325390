import React from 'react';

import { useGetFilteredData, useTranslations, useTypeDisplayedSpinner } from 'src/hooks';
import { locals } from 'src/constants/locals';
import { ComponentHouseSpinner } from 'src/components/FilterProperty/components/FilterDesktop/components';
import { ComponentListOfLayoutApartments } from './component';
import { useStyles } from './styles';

interface IListOfLayoutApartments {
  scrollPosition: React.RefObject<HTMLDivElement>;
}

export const ListOfLayoutApartments: React.FC<IListOfLayoutApartments> = ({
  scrollPosition,
}) => {
  const styles = useStyles({});
  const { resultFiltering } = useGetFilteredData();
  const { isLayoutView, isFloorsView, is2dView } = useTypeDisplayedSpinner();
  const { getTranslations } = useTranslations();
  if (isLayoutView) return null;
  if (isFloorsView || is2dView) return <ComponentHouseSpinner />;

  return (
    <div>
      <>
        {
            Boolean(resultFiltering.length)
              ? (
                <ComponentListOfLayoutApartments
                  properties={resultFiltering}
                  parentScroll={scrollPosition}
                />
              )
              : <p className={styles.titleTotalCount}>{getTranslations(locals.appTranslations.appNoDataToDisplay)}</p>
          }
      </>
    </div>
  );
};
