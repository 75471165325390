import React from 'react';
import Box from '@mui/material/Box';
import { useStyles as useStylesSpinner } from 'src/components/FilterSpinner/components/TypeDisplayedSpinner/styles-spinner';
import { useStyles as useStylesCrossTable } from 'src/components/FilterSpinner/components/TypeDisplayedSpinner/styles-crosstable';
import { useBuildType } from 'src/hooks';
import { IWrapperMobileItem } from './types';

export const WrapperMobileItem: React.FC<IWrapperMobileItem> = ({
  isActive,
  iconItem,
  text,
  handleClick,
}) => {
  const { isSpinner } = useBuildType();
  const stylesSpinner = useStylesSpinner({});
  const stylesCrossTable = useStylesCrossTable();
  const styles = isSpinner ? stylesSpinner : stylesCrossTable;

  return (
    <Box
      className={`${styles.wrapperTypeItem} ${isActive ? styles.selectedStyle : ''}`}
      onClick={handleClick}
    >
      <div className={styles.iconTypeStyles}>
        {iconItem}
      </div>
      <span>{text}</span>
    </Box>
  );
};
