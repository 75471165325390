import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isShowNestedFilter } from 'src/store/app/selectors';
import { Actions } from 'src/store/app/actions';

export const useShowLessFiler = () => {
  const dispatch = useDispatch();
  const isShowAll = useSelector(isShowNestedFilter);

  const handleToggleShowAllFilter = useCallback((state?: boolean) => {
    dispatch(Actions.isShowNestedFilter(state || !isShowAll));
  }, [dispatch, isShowAll]);

  return {
    isShowAll,
    handleToggleShowAllFilter,
  };
};
