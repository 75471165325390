import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperFavIcon: {
    top: '5px',
    right: '5px',
  },
  imageAddToFavorite: {
    animation: '$heartAnimation 500ms forwards',
  },
  '@keyframes heartAnimation': {
    '0%': { transform: 'scale(1.5)' },
    '100%': { transform: 'scale(1)' },
  },
}));
