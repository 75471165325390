import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  tooltipPortal: (props: any) => ({
    borderRadius: '10px',
    fontSize: '13px',
    padding: '5px',
    position: 'absolute',
    background: 'white',
    zIndex: 22222,
    maxWidth: '200px',
    transform: props.isOnlyText ? 'translate(0px, -140%)' : 'translate(0px, -200%)',
    textAlign: 'center',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    '&:empty': {
      display: 'none',
    },
  }),
});
