import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperModal: {
    overflowY: 'auto',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      padding: '20px 10px',
    },
    '&:before, &after': {
      content: '""',
      flex: 1,
    },
  },
  modalWindow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    outline: 'none',
  },
  wrapperContent: {
    fontSize: '14px',
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '21px 30px',
    margin: 'auto',
    maxWidth: '600px',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '21px 10px',
    },
  },
  wrapperContentHeader: {
    minHeight: '40px',
    padding: '10px 50px',
    marginBottom: '20px',
  },
  closeButton: {
    backgroundColor: '#F6F7F6',
    color: theme.palette.primary.main,
    borderRadius: '10px',
    minWidth: '44px',
    width: '44px',
    height: '44px',
    position: 'absolute',
    top: '20px',
    right: '20px',
    '& span[class*="startIcon"]': {
      margin: '0px',
    },
  },
}));
