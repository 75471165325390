import { action } from 'src/store/common';

export const ActionTypes = {
  RESET_FILTER_TYPE_PLACEMENT: 'RESET_FILTER_TYPE_PLACEMENT',
  SET_FILTER_TYPE_PLACEMENT: 'SET_FILTER_TYPE_PLACEMENT',
  SET_SELECTED_TYPE_PLACEMENT: 'SET_SELECTED_TYPE_PLACEMENT',
  CHANGE_SELECTED_TYPE_PLACEMENT: 'CHANGE_SELECTED_TYPE_PLACEMENT',
};

export const Actions = {
  resetFilterTypePlacement: action<string[]>(ActionTypes.RESET_FILTER_TYPE_PLACEMENT),
  setFilterTypePlacement: action<string[]>(ActionTypes.SET_FILTER_TYPE_PLACEMENT),
  setSelectedTypePlacement: action(ActionTypes.SET_SELECTED_TYPE_PLACEMENT),
  changeSelectedTypePlacement: action(ActionTypes.CHANGE_SELECTED_TYPE_PLACEMENT),
};
