import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { isBookModalOpen } from 'src/store/app/selectors';
import { Actions } from 'src/store/app/actions';

export const useAppOpenModalBookModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isBookModalOpen);

  const handleToggleModal = useCallback(() => {
    dispatch(Actions.isBookModalOpen(!isOpen));
  }, [dispatch, isOpen]);

  return {
    isOpen,
    handleToggleModal,
  };
};
