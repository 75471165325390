import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperListOfProperty: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0px -5px',
    paddingBottom: '40px',
  },
  propertyWrapper: {
    border: '1px solid #d6d2d2',
    width: 'calc(50% - 10px)',
    margin: '10px 5px',
  },
  titleTotalCount: {
    fontSize: '18px',
  },
  loadMoreButton: {
    marginBottom: '30px',
  },
}));
