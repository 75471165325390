import { ActionTypes } from 'src/store/requests/type-of-property/actions';
import { IAction } from 'src/typings/index';

export interface ITypeOfPropertyReducer {
  isLoading: boolean;
  error: Error | null;
  typesPlacement: string[];
  selectedType: string | undefined;
}

export const initialState: ITypeOfPropertyReducer = {
  isLoading: false,
  error: null,
  typesPlacement: [],
  selectedType: undefined,
};

export const typeOfPropertyReducer = (
  state = initialState,
  { type, payload }: IAction,
): ITypeOfPropertyReducer => {
  switch (type) {
    case ActionTypes.RESET_FILTER_TYPE_PLACEMENT: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.SET_FILTER_TYPE_PLACEMENT: {
      return {
        ...state,
        typesPlacement: payload,
      };
    }

    case ActionTypes.SET_SELECTED_TYPE_PLACEMENT:
    case ActionTypes.CHANGE_SELECTED_TYPE_PLACEMENT: {
      return {
        ...state,
        selectedType: payload,
      };
    }

    default:
      return state;
  }
};
