import React, { useRef } from 'react';

import { AppPanorama } from 'src/components/AppPanorama';
import { ButtonApp } from 'src/components/Button';
import { resizeEmulate } from 'src/helpers';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { AppGallery } from 'src/components/AppGallery';
import { useStyles } from '../../styles';
import { IInfrastructureTypePanorama } from '../../types';
import { useFullScreenMode } from './use-fullscreen-mode';

export const InfrastructurePanorama: React.FC<IInfrastructureTypePanorama> = ({
  data,
}) => {
  const refAppGallery = useRef();
  const styles = useStyles({});
  const {
    isFullScreen,
    handleFullScreen,
  } = useFullScreenMode(refAppGallery);

  const _renderItem = () => {
    return (
      <AppPanorama
        isFullScreen={isFullScreen}
        srcId={data['infrastructure.src']}
      />
    );
  };

  return (
    <div className={styles.wrapperInfrastructureType}>
      <AppGallery
        ref={refAppGallery}
        lazyLoad
        showFullscreenButton
        disableSwipe
        additionalClass={`${styles.appPanoramaWrapper}`}
        showBullets={false}
        showPlayButton={false}
        showNav={false}
        showThumbnails={false}
        items={[{
          original: '',
          thumbnail: '',
          originalClass: 'apartment-gallery-slide-item',
          thumbnailClass: 'apartment-gallery-thumbnail',
        }]}
        onScreenChange={() => handleFullScreen()}
        renderFullscreenButton={(onClick, isFullscreen) => {
          return (
            <ButtonApp
              className={`${styles.zoomButton}`}
              onClick={(event) => {
                onClick(event);
                setTimeout(resizeEmulate, 250);
              }}
            >
              {
                isFullscreen
                  ? <FullscreenExitIcon className={styles.zoomStyle} />
                  : <FullscreenIcon className={styles.zoomStyle} />
              }
            </ButtonApp>
          );
        }}
        renderItem={_renderItem}
        renderThumbInner={() => null}
      />
    </div>
  );
};
