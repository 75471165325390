import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: '15px',
    },
  },
  wrapperField: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  sendButton: {
    padding: '20px 10px',
  },
  isHidden: {
    display: 'none',
  },
}));
