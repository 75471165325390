import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  infrastructureSlideWrapper: {
    '& .image-gallery-svg': {
      height: '60px',
      width: '20px',
    },
  },
  zoomButton: {
    background: 'rgba(255,255,255,0.4)',
    minWidth: 'auto',
    width: '30px',
    height: '30px',
    zIndex: 11,
    '&:hover': {
      background: 'rgba(213,213,213,0.78)',
    },
  },
  zoomStyle: {
    width: '20px',
    height: '20px',
  },
}));
