import React, { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';
import { DropdownInline } from 'src/components/dropdowns/DropdownInline';
import { IFilterTypeProperty } from 'src/components/FilterTypeProperty/types';
import {
  useChangeValue,
} from 'src/components/FilterTypeProperty/hooks';
import { getInfrastructureFilters } from 'src/store/infrastructure/selectors';
import { getListOfTypeDisplayedSpinner } from 'src/store/type-displayed-spinner/selectors';
import { useBuildType, useLanguageState } from 'src/hooks';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { useGetRequestAppData } from 'src/hooks/app';

export const FilterTypeProperty: React.FC<IFilterTypeProperty> = ({
  placementState,
}) => {
  const { getTranslations } = useTranslations();
  const infrastructureFilter = useSelector(getInfrastructureFilters);
  const listOfType = useSelector(getListOfTypeDisplayedSpinner);
  const { handleChangeType } = useChangeValue(listOfType);
  const { appRequestData } = useGetRequestAppData();
  const { isCrossTable } = useBuildType();
  const { currentAppLanguage } = useLanguageState();
  const [sortedList, setSortedList] = useState<string[]>([]);

  useEffect(() => {
    const temp: string[] = [];
    listOfType.forEach((type) => {
      if (isCrossTable) {
        if (type.crosstableViewTypes && type.crosstableViewTypes.length) {
          temp.push(type.title[currentAppLanguage]);
        }
      } else if (type.moduleViewTypes && type.moduleViewTypes.length) {
        temp.push(type.title[currentAppLanguage]);
      }
    });
    if (infrastructureFilter && !!Object.keys(infrastructureFilter).length) {
      temp.push(locals.appTranslations.appInfrastructure);
    }
    setSortedList(temp);
  }, [listOfType, infrastructureFilter]);

  return (
    <div>
      {getTranslations(locals.appTranslations.appShow)}
      {' '}
      <DropdownInline
        dropdownInfo={sortedList}
        onChange={handleChangeType}
      />
      {' '}
      {getTranslations(locals.appTranslations.appFromIn)}
      {' '}
      {isCrossTable ? appRequestData?.crosstable_title : appRequestData?.module_title}
    </div>
  );
};
