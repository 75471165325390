import React from 'react';

import { ButtonApp } from 'src/components/Button';
import { locals } from 'src/constants/locals';
import { ModalBookNow } from 'src/components/modals/BookNow';
import { useTranslations } from 'src/hooks/translataions';
import { useBookNowState } from 'src/components/modals/BookNow/hooks';
import { IFilterAllData } from 'src/typings/filter';
import { useStyles } from '../CompareItems/styles';

interface ICompareItemBookNow {
  item: IFilterAllData;
}

export const CompareItemBookNow: React.FC<ICompareItemBookNow> = ({
  item,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const { isOpen, handleToggleState } = useBookNowState();

  return (
    <div key={item.id} className={`${styles.compareValue} ${styles.wrapperCompareType}`}>
      <ButtonApp
        style={{ color: 'black', marginBottom: '10px', padding: '20px 10px' }}
        isFullSize
        isCapitalize
        withBorder
        text={getTranslations(locals.appTranslations.appBookNow)}
        color="secondary"
        onClick={handleToggleState}
      />
      {isOpen && (
        <ModalBookNow
          isOpen={isOpen}
          onClose={handleToggleState}
        />
      )}
    </div>
  );
};
