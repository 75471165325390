import React from 'react';

import { AppFullScreenContainer } from 'src/components/ContainerFullScreen';
import { useTranslations } from 'src/hooks/translataions';
import { useSetDocumentTitle } from 'src/hooks';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';

export const NoMatch404: React.FC = () => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  useSetDocumentTitle(getTranslations(locals.appTranslations.appTitle404));

  return (
    <AppFullScreenContainer>
      <div className={styles.wrapperError}>
        <img alt="404" src="https://cdn.dribbble.com/users/612566/screenshots/5508671/artboard.png" />
      </div>
    </AppFullScreenContainer>
  );
};
