import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperTypeItem: {
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center',
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      height: 'calc(100% - 4px)',
      position: 'absolute',
      backgroundColor: 'white',
      left: '0px',
      top: '0px',
      borderRadius: '8px',
      margin: '2px 2px',
      animation: '$unselectedAnimate 150ms forwards',
    },
    '& > *': {
      color: 'white',
    },
    '& .appFillStyles': {
      fill: 'white',
    },
    '& .appStrokeStyles': {
      stroke: 'white',
    },
    [theme.breakpoints.down('md')]: {
      '&:before': {
        display: 'none',
      },
      '& > *': {
        color: 'black',
      },
      '& > span': {
        fontSize: '11px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
        textAlign: 'center',
      },
      '& .appFillStyles': {
        fill: theme.palette.primary.main,
      },
      '& .appStrokeStyles': {
        stroke: theme.palette.primary.main,
      },
    },
  },
  iconTypeStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    filter: 'grayscale(1)',
    marginRight: '10px',
  },
  selectedStyle: {
    fontWeight: 'bold',
    '&:before': {
      width: 'calc(100% - 4px)',
      animation: '$selectedAnimate 150ms forwards',
    },
    '& > img': {
      filter: 'grayscale(0)',
      opacity: 1,
    },
    '& .appFillStyles': {
      fill: theme.palette.primary.main,
    },
    '& .appStrokeStyles': {
      stroke: theme.palette.primary.main,
    },
    '& > *': {
      color: theme.palette.primary.main,
      position: 'relative',
      zIndex: 1,
    },
  },
  '@keyframes selectedAnimate': {
    from: { width: '0px' },
    to: { width: 'calc(100% - 4px)' },
  },
  '@keyframes unselectedAnimate': {
    from: { width: 'calc(100% - 4px)' },
    to: { width: '0px' },
  },
}));
