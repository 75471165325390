import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';

export function getComplexData(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getComplexByDev(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}?developers=${id}&pagination=false`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getComplexWithoutDev(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}?exists[developers]=false&pagination=false&showInCrosstable=true`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getComplexByAmoPipeline(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}?amo_pipeline.amo_id=${id}&pagination=false`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchComplexData(token: string = '', id: string|number, data: any) {
  return httpService.patch(`${ApiUrl.getApiUrl().getComplexes}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}
