import { Dropdown, StrictDropdownProps } from 'semantic-ui-react';
import React from 'react';

interface IDropdownSingle extends StrictDropdownProps {}

export const DropdownSingle: React.FC<IDropdownSingle> = ({
  ...props
}) => {
  return (
    <Dropdown
      fluid
      selection
      clearable
      pointing="top"
      upward={false}
      selectOnBlur={false}
      {...props}
    />
  );
};
