import { action } from 'src/store/common';

export const ActionTypes = {
  GET_USER_DATA_REQUEST: '[REQUEST_APP]GET_USER_DATA_REQUEST',
  GET_USER_DATA_REQUEST_SUCCESS: '[REQUEST_APP]GET_USER_DATA_REQUEST_SUCCESS',
  GET_USER_DATA_REQUEST_FAIL: '[REQUEST_APP]GET_USER_DATA_REQUEST_FAIL',
};

export const Actions = {
  getUserData: action(ActionTypes.GET_USER_DATA_REQUEST),
  getUserDataSuccess: action(ActionTypes.GET_USER_DATA_REQUEST_SUCCESS),
  getUserDataFail: action<Error>(ActionTypes.GET_USER_DATA_REQUEST_FAIL),
};
