import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { translations } from '../../../constants/translations';

interface IAlertDialog {
  handleClose: any;
  handleAccept: any;
  isOpen: boolean;
  number: string | null;
  respUser?: string;
}

const Transition = React.forwardRef((props, ref) => {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AlertDialog: React.FC<IAlertDialog> = ({
  handleClose,
  handleAccept,
  isOpen,
  number,
  respUser = '',
}) => {
  return (
    <Dialog
      open={isOpen}
        // @ts-ignore
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{`${translations.dialogTitle} №${number}`}</DialogTitle>
      {respUser && (
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {translations.dialogContent}
          <b>
            &nbsp;
            {respUser}
          </b>
        </DialogContentText>
      </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          {translations.cancel}
        </Button>
        <Button onClick={handleAccept} variant="outlined" color="primary">
          {translations.detach}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  respUser: '',
};
