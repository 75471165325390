import { useState, useEffect } from 'react';
import { IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';

import { getBitrixProject } from 'src/api';

export const useGetBitrixProject = (id: string|null) => {
  const [project, setProject] = useState<IProject|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchProject = () => {
    setIsLoading(true);
    if (id) {
      getBitrixProject(jwt, id)
        .then((res: any) => {
          const { data } = res;
          setProject(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    let cleanupFunction = false;
    if (id) {
      setIsLoading(true);
      getBitrixProject(jwt, id)
        .then((res: any) => {
          if (!cleanupFunction) {
            const { data } = res;
            setProject(data);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    return () => {
      cleanupFunction = true;
    };
  }, [jwt, id]);

  return {
    project,
    isLoading,
    fetchProject,
    setProject,
  };
};
