/* eslint-disable no-mixed-operators */
import React, {
  useState, useRef, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { instanceOfPlacementWindow } from 'src/typings/spinner';
import { getEntityConfig } from 'src/store/spinner/selectors';
import {
  IRenderSvg, IMouseEvent,
} from 'src/components/ViewSpinner/components/RenderSvg/types';
import { getPanoramaData } from 'src/api';
import { WindowView } from 'src/components/modals';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { RenderHoverText } from 'src/components/ViewSpinner/components/RenderSvg/components/HoverText';

import { useStyles } from './styles';

interface IMouseTrackState {
  x: number;
  y: number;
}

export const RenderWindowView: React.FC<IRenderSvg> = React.memo(({
  svg,
  galleryImagesTransform,
  floorId,
  imgUrl,
}) => {
  const { getTranslations } = useTranslations();
  const styles = useStyles({});
  const refParent = useRef<HTMLDivElement>();
  const entityConfig = useSelector(getEntityConfig);
  const [showModal, setShowModal] = useState(false);
  const [pano, setPano] = useState(null);
  const [isShowHoverText, setShowHoverText] = useState(false);
  const [trackMouse, setTrackMouse] = useState<IMouseTrackState>({
    x: 0,
    y: 0,
  });

  if (!entityConfig || !svg || !instanceOfPlacementWindow(svg.attr)) {
    return null;
  }
  const {
    x, y, cx, cy,
  } = svg.attr;
  const { floor_window_image: windowImage, floor_window_size: imageSize } = entityConfig;
  let newcx = Number(cx || x);
  let newcy = Number(cy || y);

  const handleSvgClick = useCallback(async () => {
    if (svg.attr['data-panorama2-id']) {
      setShowModal(true);
      const { data } = await getPanoramaData(svg.attr['data-panorama2-id']);
      const { config } = data;
      // @ts-ignore
      const parsed = JSON.parse(JSON.stringify(config).replace(/\\"\/files\//, '"https://dev1.flat.show/files/'));

      const azm = parseFloat(svg.attr['data-panorama2-center-azm-agl']);
      // @ts-ignore
      const { degToRad } = window.THREE.MathUtils;

      parsed.controls = Object.assign(parsed.controls || {}, {
        azimuthAngle: azm,
        minAzimuthAngle: azm - degToRad(40),
        maxAzimuthAngle: azm + degToRad(40),

        minPolarAngle: degToRad(45),
        maxPolarAngle: degToRad(135),

        zoomEnabled: false,
      });
      const panorama = new window.Panorama2(refParent.current, parsed);
      setPano(panorama);
    }
  }, [svg, refParent.current]);

  const handleSvgMove = useCallback((event: IMouseEvent) => {
    if (isShowHoverText && event.clientX && event.clientY) {
      setTrackMouse({ x: event.clientX, y: event.clientY });
    }
  }, [isShowHoverText]);

  const handleWindowClose = useCallback(() => {
    setShowModal(false);
    if (pano) {
      // @ts-ignore
      pano.dispose();
    }
  }, [pano]);

  const renderSvgElement = () => {
    if (!floorId) {
      if (galleryImagesTransform && imgUrl && galleryImagesTransform[imgUrl]) {
        const {
          scale, dx, dy, angle, shiftx, shifty,
        } = galleryImagesTransform[imgUrl];
        newcx = newcx * scale + dx;
        newcy = newcy * scale + dy;
        const cos = Math.cos(angle * Math.PI / 180);
        const sin = Math.sin(angle * Math.PI / 180);
        newcx = newcx * cos - newcy * sin;
        newcy = newcx * sin + newcy * cos;
        newcx += shiftx;
        newcy += shifty;
      }
    } else if (galleryImagesTransform && imgUrl && galleryImagesTransform[imgUrl] && galleryImagesTransform[imgUrl][floorId]) {
      const {
        scale, dx, dy, angle, shiftx, shifty,
      } = galleryImagesTransform[imgUrl][floorId];
      newcx = newcx * scale + dx;
      newcy = newcy * scale + dy;
      const cos = Math.cos(angle * Math.PI / 180);
      const sin = Math.sin(angle * Math.PI / 180);
      newcx = newcx * cos - newcy * sin;
      newcy = newcx * sin + newcy * cos;
      newcx += shiftx;
      newcy += shifty;
    }

    return React.createElement(
      'image',
      {
        ...svg.attr,
        x: newcx - imageSize / 2,
        y: newcy - imageSize / 2,
        height: imageSize,
        width: imageSize,
        href: windowImage,
        className: styles.iconWrapper,

        onClick: handleSvgClick,
        onMouseEnter: (event: any) => {
          setShowHoverText(true);
        },
        onMouseLeave: (event: any) => {
          setShowHoverText(false);
        },
        onMouseMove: handleSvgMove,
      },
    );
  };

  return (
    <>
      { renderSvgElement() }
      {
        (isShowHoverText)
        && (
          <RenderHoverText
            isEnableHoverPopup
            isOnlyText
            nameId={getTranslations(locals.appTranslations.appWindowViewHover)}
            hoverId={getTranslations(locals.appTranslations.appWindowViewHover)}
            trackMouse={trackMouse}
            handleHover={setShowHoverText}
          />
        )
      }
      <WindowView
        isOpen={showModal}
        toggleClose={handleWindowClose}
        contentHeader={<p className={styles.textTitle}>{getTranslations(locals.appTranslations.appWindowViewTitle)}</p>}
      >
        <Box {...{ ref: refParent }} position="relative" flex="1" />
      </WindowView>
    </>
  );
});
