import React from 'react';

import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { Box } from '@mui/material';
import { IPlacementInserts } from '../../typings/house';

export const InsertsBlock = ({ inserts }: {inserts: {
  [key: string]: IPlacementInserts[];
}}) => {
  return (
    <Box mt={3} py={5} px={4} bgcolor="#5B93DE1A" borderRadius="4px">
      <Box display="flex" alignItems="center" mb={5}>
        <TaskAltIcon />
        <Box
          ml={2}
          fontWeight={300}
          fontSize="18px"
          lineHeight="24px"
        >
          List of newly created properties
        </Box>
      </Box>
      {Object.entries(inserts).map(([key, values], index) => {
        const valuesStr = values.reduce((acc, item) => (acc
          ? `${acc}; ${item.property.clientTranslatedName || item.property} was changed to ${item.newValue}`
          : `${item.property.clientTranslatedName || item.property} was changed to ${item.newValue}`), '');
        return (
          <Box
            key={key}
            fontWeight={300}
            fontSize="16px"
            lineHeight="16px"
            mt={!index ? 0 : 4}
          >
            {`${key}: ${valuesStr}`}
          </Box>
        );
      })}
    </Box>
  );
};
