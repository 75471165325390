import React from 'react';

import { useSelectorsApartments } from 'src/components/FilterProperty/hooks';
import { ButtonFilter } from 'src/components/FilterControls';
import { IComponentMobileInfrastructure } from './types';
import { useStyles as useFilterStyles } from './components/ButtonFilter/styles';
import { SelectorGeneratorInfrastructure } from './components/SelectorGeneratorInfrastructure';

export const ComponentMobileInfrastructure: React.FC<IComponentMobileInfrastructure> = ({
  handleModalOpen,
}) => {
  const stylesFilter = useFilterStyles({});
  const {
    infrastructureFilters,
  } = useSelectorsApartments();

  return (
    <>
      <div className={stylesFilter.modalFilterWrapper}>
        <div className={stylesFilter.modalFilterSelectors}>
          {infrastructureFilters.map((field, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <SelectorGeneratorInfrastructure key={index} dropdownField={field} />
            );
          })}
        </div>
      </div>
      <ButtonFilter
        handleSubmit={handleModalOpen}
      />
    </>
  );
};
