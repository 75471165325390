import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  'cls-1': (props: any) => ({
    fill: props.filterBackgroundColor || '#fff',
  }),
  'cls-2': {
    fill: 'url(#Градієнт_без_назви_10)',
  },
  'cls-3': (props: any) => ({
    fill: props.preloaderColor || '#fff',
  }),
  'cls-3-1': {
    animation: '$widthLabel1 500ms infinite alternate',
  },
  'cls-3-2': {
    animation: '$widthLabel2 700ms infinite alternate',
  },
  'cls-3-3': {
    animation: '$widthLabel3 800ms infinite alternate',
  },
  '@keyframes widthLabel1': {
    from: { width: 0 },
    to: { width: 25.5 },
  },
  '@keyframes widthLabel2': {
    from: { width: 0 },
    to: { width: 25.5 },
  },
  '@keyframes widthLabel3': {
    from: { width: 0 },
    to: { width: 25.5 },
  },
  wrapperLoader: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
  },
  fullSize: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: '100%',
  },
}));
