import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperCheckboxes: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  checkboxItem: {
    width: '50%',
    margin: '5px 0px',
    alignItems: 'flex-start',
  },
}));
