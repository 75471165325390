import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { Actions } from 'src/store/filter/filter-params/actions';

export const useClearSelectedFilterParams = () => {
  const dispatch = useDispatch();

  const handleClearFilterParam = useCallback((tag?: string) => {
    dispatch(Actions.clearFilterParameterCall(tag));
  }, [dispatch]);

  return {
    handleClearFilterParam,
  };
};
