import { useSelector } from 'react-redux';

import { IEmbeddedContactForm, IStandartContactForm, IAttributesContactForm } from 'src/typings/app';
import { requestSelectors } from 'src/store/requests/selectors';

export const useEmbeddedContactForm = () => {
  const contactForm: IEmbeddedContactForm | null | undefined = useSelector(requestSelectors.app.getEmbeddedContactForm);

  return {
    contactForm,
  };
};

export const useStandartContactForm = () => {
  const standartForm: IStandartContactForm | null | undefined = useSelector(requestSelectors.app.getStandartContactForm);

  return {
    standartForm,
  };
};

export const useAttributesContactForm = () => {
  const attributesForm: IAttributesContactForm | null | undefined = useSelector(requestSelectors.app.getAttributesContactForm);

  return {
    attributesForm,
  };
};
