import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';

import { isFilterOpen } from 'src/store/app/selectors';
import { Actions } from 'src/store/app/actions';
import { resizeEmulate } from 'src/helpers';

export const useOpenFilter = () => {
  const dispatch = useDispatch();
  const isOpen: boolean = useSelector(isFilterOpen);
  const [isAnimationStart, setAnimation] = useState<boolean>(false);

  const handleToggleOpenFilter = useCallback((openState?: boolean) => {
    if (!isAnimationStart) {
      setAnimation(true);
      dispatch(Actions.isFilterOpen(openState || !isOpen));
      setTimeout(() => {
        resizeEmulate();
        setAnimation(false);
      }, 400);
    }
  }, [dispatch, isOpen, isAnimationStart]);

  return {
    isOpen,
    setAnimation,
    isAnimationStart,
    handleToggleOpenFilter,
  };
};
