export const appIcon = {
  iconEdit: require('./icon_edit.svg'),
  iconSortArrow: require('./icon_sortArrow.svg'),
  iconDelete: require('./icon_delete.svg'),
  iconLetter: require('./icon_letter.svg'),
  iconUserDisable: require('./icon_userDisable.svg'),
  iconUpload: require('./icon_upload.svg'),
  iconSearch: require('./icon_search.svg'),
  iconStar: require('./icon_star.svg'),
  icon360: require('./icon_360.svg'),
  iconFB: require('./icon_fb.svg'),
  iconGoogle: require('./icon_google.svg'),
  iconAddFavoriteIcon: require('./icon_add_to_fav.svg'),
  iconFloors: require('./icon_floors.svg'),
  iconShowInMap: require('./icon_in_map.svg'),
  iconLoader: require('./icon_loader.svg'),
  iconMoreFilter: require('./icon_more_filter.svg'),
  iconShowLayouts: require('./icon_show_layouts.svg'),
  iconSwipeLine: require('./icon_swipe_line.svg'),
  noImg: require('./no-img.jpg'),
  siteLogo: require('./site-logo.svg'),
  favicon: require('./favicon.png'),
  mainLogo: require('./main_logo.svg'),
  bigLogo: require('./big_logo.svg'),
  authBG: require('./authBG.png'),
  avatar: require('./avatar.svg'),
};
