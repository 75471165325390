import React from 'react';

// import { ButtonLegend } from 'src/components/ButtonLegend';
import { CrossTableContainer } from 'src/components/CrosstableContainer';
import { AppHeader } from 'src/components/AppHeader';
import {
  useBuildType,
} from 'src/hooks';
import { useStyles } from './styles';

export const View2d: React.FC = () => {
  const { isCrossTable } = useBuildType();
  const style = useStyles({ isCrossTable });

  return (
    <div className={style.containerWrapper}>
      <AppHeader wrapperStyles={style.mobileHeader} />
      <CrossTableContainer />
    </div>
  );
};
