import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { requestSelectors } from 'src/store/requests/selectors';
import { IFilterData, IHoverPopupInfoConfig } from 'src/typings/filter';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';
import { getSpinnerDetailInfo } from 'src/store/requests/spinner-views/selector';

export const usePopupHoverText = () => {
  const filterAllData: IFilterData | undefined = useSelector(requestSelectors.filter.getFilterInitialAllData);
  const typeOfPropertySelector = useSelector(getSelectedPlacement);
  const detailInfo: any = useSelector(getSpinnerDetailInfo);
  const [textPopupList, setTextPopupList] = useState<IHoverPopupInfoConfig>({});

  useEffect(() => {
    if (filterAllData && typeOfPropertySelector) {
      const hoverPopUpShortInfo = filterAllData[typeOfPropertySelector]['placement-info-config'].hover_pop_up_short_info;

      if (!hoverPopUpShortInfo) return;

      setTextPopupList(
        Object.keys(hoverPopUpShortInfo).reduce((acc: IHoverPopupInfoConfig, cur: string) => {
          if (Boolean(hoverPopUpShortInfo[cur].use)) {
            return {
              ...acc,
              [cur]: hoverPopUpShortInfo[cur],
            };
          }
          return acc;
        }, {}),
      );
    }
  }, [filterAllData, typeOfPropertySelector]);

  return {
    textPopupList,
    detailInfo: detailInfo?.placement || {},
  };
};
