import React from 'react';

import { ApartmentFloor } from 'src/components/ApartmentFloor';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { FloorMarkup } from 'src/components/ViewFloors/components/Markups';
import { locals } from 'src/constants/locals';
import { useAppVisibilityBtn } from 'src/hooks/app';
import { useTranslations } from 'src/hooks/translataions';
import { useStyles } from './styles';
import { IComponentAdditionalInfo } from './types';

export const ComponentAdditionalInfo: React.FC<IComponentAdditionalInfo> = ({
  chooseData,
}) => {
  const styles = useStyles({});
  const {
    placement_section_markup_placing,
    placement_floor_placing,
  } = useAppVisibilityBtn();
  const { getTranslations } = useTranslations();

  return (
    <div className={styles.apartmentAdditionalInfo}>
      {
        placement_floor_placing && (
          <ApartmentFloor
            idApartment={chooseData[ListOfKeysFilterAllData.id]}
            idFloor={chooseData[ListOfKeysFilterAllData.floorIds]}
          />
        )
      }
      {
        placement_section_markup_placing && (
          <FloorMarkup
            houseData={{
              name: 'house',
              id: chooseData[ListOfKeysFilterAllData.houseId],
            }}
            displaySectionId={chooseData[ListOfKeysFilterAllData.section]}
            textTitle={getTranslations(locals.appTranslations.appSection)}
            isPlacementPage
          />
        )
      }
    </div>
  );
};
