import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { View2dType } from 'src/typings/view-2d';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { Actions } from 'src/store/app/actions';
import { getBitrixDeal, getCreatioDeal } from 'src/store/crosstable/selectors';
import { getCrmData, getSelectedAppartment, getIsOpenAppartSidebar } from 'src/store/app/selectors';
import { getView2dSelectedTypes } from 'src/store/view-2d/selectors';
import { Actions as FloorsActions } from 'src/store/floors/actions';
import { CRMNames } from 'src/typings/app';
import { useBuildType } from 'src/hooks';
import { shadeColor } from 'src/helpers';

import { useSelectedIds } from './hooks';
import { useStyles } from './styles';

interface IFloorItem {
  fillFloorsItem: IFilterAllData;
}

function getFirstLetters(str: string) {
  const firstLetters = str
    .split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
}

export const FloorItem: React.FC<IFloorItem> = React.memo(({
  fillFloorsItem,
}) => {
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const [isSelectedDeal, setIsSelectedDeal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { isCrossTable } = useBuildType();
  const { selectedIds } = useSelectedIds();
  const isSelected = selectedIds.includes(fillFloorsItem.id);

  const styles = useStyles({
    floors: fillFloorsItem[ListOfKeysFilterAllData.placementFloor].length,
  });
  const bitrixDeal = useSelector(getBitrixDeal);
  const creatioDeal = useSelector(getCreatioDeal);
  const crmData = useSelector(getCrmData);
  const view2dSelectedType = useSelector(getView2dSelectedTypes);
  const selectedAppartment = useSelector(getSelectedAppartment);
  const isOpenAppartSidebar = useSelector(getIsOpenAppartSidebar);

  const id = (isOpen)
    ? 'floorItemPopover' : undefined;
  let floorItemText: String | null = '';

  const bgColor = isCrossTable
    ? (fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusCrossTableColor]?.toString()
  || fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '')
    : (fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '');

  switch (view2dSelectedType) {
    case View2dType.area:
      floorItemText = fillFloorsItem[ListOfKeysFilterAllData.placementTotalArea];
      break;
    case View2dType.rooms:
      floorItemText = fillFloorsItem[ListOfKeysFilterAllData.placementRooms];
      break;
    case View2dType.numeration:
      floorItemText = fillFloorsItem[ListOfKeysFilterAllData.placementNumeration];
      break;
    case View2dType.price:
      floorItemText = `${Math.round(Number(fillFloorsItem[ListOfKeysFilterAllData.placementTotalPrice]) / 1000)}k`;
      break;
    case View2dType.pricePM:
      floorItemText = fillFloorsItem[ListOfKeysFilterAllData.placementPricePerSqM];
      break;

    default:
      break;
  }

  useEffect(() => {
    if (isOpenAppartSidebar && selectedAppartment == fillFloorsItem[ListOfKeysFilterAllData.id]) {
      setIsOpen(true);
    } else if (isOpen) {
      setIsOpen(false);
    }
  }, [selectedAppartment, isOpenAppartSidebar]);

  useEffect(() => {
    if (crmData?.crm === CRMNames.amocrm) {
      if (fillFloorsItem[ListOfKeysFilterAllData.placementAmoDeals]) {
        fillFloorsItem[ListOfKeysFilterAllData.placementAmoDeals].forEach((item) => {
          if (crmData && item.includes(String(crmData?.dealId))) {
            setIsSelectedDeal(true);
          }
        });
      }
    } else if (crmData?.crm === CRMNames.bitrix && fillFloorsItem[ListOfKeysFilterAllData.bitrixDealData]) {
      fillFloorsItem[ListOfKeysFilterAllData.bitrixDealData].forEach((item) => {
        if (bitrixDeal && item.id == bitrixDeal.id) {
          setIsSelectedDeal(true);
        }
      });
    } else if (crmData?.crm === CRMNames.creatio && fillFloorsItem[ListOfKeysFilterAllData.creatioOpportunities]) {
      fillFloorsItem[ListOfKeysFilterAllData.creatioOpportunities].forEach((item) => {
        if (creatioDeal && item.id == creatioDeal.id) {
          setIsSelectedDeal(true);
        }
      });
    }
  }, [fillFloorsItem, crmData, creatioDeal]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isSelected && !isCrossTable) return;

    if (isOpen) {
      dispatch(Actions.setIsOpenAppartSidebar(false));
      dispatch(FloorsActions.setSelectedApartmentId(undefined));
    } else {
      dispatch(FloorsActions.setSelectedApartmentId(fillFloorsItem[ListOfKeysFilterAllData.id]));
      dispatch(Actions.selectAppartment(fillFloorsItem[ListOfKeysFilterAllData.id]));
      dispatch(Actions.setIsOpenAppartSidebar(true));
    }
  };

  return (
    <div className={styles.sectionContentWrapper}>
      <Box
        aria-describedby={id}
        className={`${styles.sectionContentItem} ${(!isSelected) && 'blockedItem'} ${isSelectedDeal && styles.selectedDeal}`}
        data-floors={ListOfKeysFilterAllData.placementFloor}
        position="relative"
        style={{
          backgroundColor: bgColor,
        }}
        onClick={handleClick}
      >
        {fillFloorsItem[ListOfKeysFilterAllData.placementAdditionalCondition] && (
        <Tooltip
          placement="top"
          TransitionComponent={Zoom}
          arrow
          title={fillFloorsItem[ListOfKeysFilterAllData.placementAdditionalCondition] || ''}
        >
          <Box
            className={styles.additionalCondition}
            top="-1px"
            right="-1px"
            bgcolor={bgColor && shadeColor(bgColor, 60)}
          >
            {getFirstLetters(fillFloorsItem[ListOfKeysFilterAllData.placementAdditionalCondition] || '')}
          </Box>
        </Tooltip>
        )}
        <span
          className={styles.sectionContentText}
        // @ts-ignore
          style={{ fontSize: `calc(${floorItemText?.length >= 4 ? '10px' : '17px'} * var(--crosstable-size))` }}
        >
          {floorItemText}
        </span>
      </Box>
    </div>
  );
});
