import React from 'react';
import { useSelector } from 'react-redux';
import { rootUrl as devUrl } from 'src/constants';
import { requestSelectors } from 'src/store/requests/selectors';
import { useIsMobile, useLanguageState } from 'src/hooks';
import { useLocation } from 'react-router';
import { pathList } from 'src/route-list';
import { useStyles } from './styles';

interface IAppHeaderLogo {
  isVisible?: boolean;
  customWrapperStyle?: string;
}

export const AppHeaderLogo: React.FC<IAppHeaderLogo> = React.memo(({
  isVisible = true,
  customWrapperStyle,
}) => {
  const styles = useStyles({});
  const location = useLocation();
  const mainLogo: any = useSelector(requestSelectors.app.getMainLogo);
  const rootUrl: string | null | undefined = useSelector(requestSelectors.app.getRootUrl);
  const mainLogoMobile: any = useSelector(requestSelectors.app.getMainLogoMobile);
  const defaultLang: any = useSelector(requestSelectors.app.getLang);
  const { isMobileScreen } = useIsMobile();
  const { currentAppLanguage } = useLanguageState();
  const sourceSrc = isMobileScreen ? mainLogoMobile : mainLogo;
  const spinnerSourceUrl = location.pathname.indexOf(pathList.home) !== -1 && sourceSrc;
  const isDev = document.location.origin.indexOf(devUrl) !== -1;
  const urlFromDocument = isDev ? document.location.href : document.location.origin;

  if (!isVisible) return null;

  const langSuffix = defaultLang === currentAppLanguage ? '' : currentAppLanguage;

  return (
    <div className={`${styles.headerLogo} ${customWrapperStyle || ''}`}>
      <a href={`${rootUrl || urlFromDocument}/${langSuffix}`}>
        <img
          alt="logo"
          className={styles.appLogo}
          src={spinnerSourceUrl || mainLogo}
        />
      </a>
    </div>
  );
});
