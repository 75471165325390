import React from 'react';
import { Complexes } from 'src/components/profileComponents/Complexes';
import { Developers } from 'src/components/profileComponents/Developers';
import { DeveloperPage } from 'src/components/profileComponents/DeveloperPage';
import { IntegrationBitrix } from 'src/components/profileComponents/IntegrationBitrix';
import { BitrixCategories } from 'src/components/profileComponents/BitrixCategories';
import { EditComplex } from 'src/components/profileComponents/EditComplex';
import { Storage } from 'src/components/profileComponents/Storage';
import { Manager, TypeOfPage } from 'src/components/profileComponents/Manager';
import Box from '@mui/material/Box';
import { IRouteList } from 'src/route-list';
import { translations } from 'src/constants/translations';
import { HouseStorage } from '../../components/profileComponents/HouseStorage';

export const pathList = {
  complexes: 'complexes',
  // managers: 'managers',
  developers: 'developers',
  settings: 'admin',
};

export const linkList: IRouteList[] = [
  {
    path: pathList.complexes,
    name: translations.complexesLink,
  },
  // {
  //   path: pathList.managers,
  //   name: translations.managersLink,
  //   disabledForManager: true,
  // },
  {
    path: pathList.developers,
    name: translations.developersLink,
    disabledForManager: true,
    disabledForSupervisor: true,
  },
  {
    path: pathList.settings,
    name: translations.settingsLink,
    disabledForManager: true,
    disabledForSupervisor: false,
    isAbsoluteLink: true,
  },
];

export const profileList: IRouteList[] = [
  {
    path: 'complexes',
    name: translations.complexesLink,
    Component: <Complexes />,
  },
  {
    path: 'complexes/:id',
    name: translations.complexesLink,
    Component: (<EditComplex />),
  },
  {
    path: 'complexes/:id/storage',
    name: translations.goToStorage,
    Component: (<Storage />),
  },
  {
    path: 'complexes/:id/storage/:houseId',
    name: translations.goToStorage,
    Component: (<HouseStorage />),
  },
  {
    path: 'complexes/:id/manager',
    name: translations.complexesLink,
    Component: (<Manager type={TypeOfPage.addWithComplex} />),
  },
  {
    path: 'complexes/:id/manager/:userId',
    name: translations.complexesLink,
    Component: (<Manager type={TypeOfPage.editWithComplex} />),
  },
  {
    path: 'managers',
    name: translations.managersLink,
    Component: (<Box>managers</Box>),
  },
  {
    path: 'developers',
    name: translations.developersLink,
    Component: (<Developers />),
  },
  {
    path: 'developers/:id',
    name: translations.developersLink,
    Component: (<DeveloperPage />),
  },
  {
    path: 'developers/:id/integrationBitrix',
    name: translations.developersLink,
    Component: (<IntegrationBitrix />),
  },
  {
    path: 'developers/:id/category/:categoryId',
    name: translations.developersLink,
    Component: (<BitrixCategories />),
  },
  {
    path: 'settings',
    name: translations.settingsLink,
    Component: (<Box>Settings</Box>),
  },
];
