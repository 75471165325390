import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getMarkups } from 'src/store/requests/spinner-views/selector';
import { IFirstSpinner, IMarkUps, IMarkUpsInner } from 'src/typings/spinner';
import { Actions } from 'src/store/floors/actions';
import { getSelectedHouse, getSelectedSection, getFromComplex } from 'src/store/floors/selectors';
import { IFloorsSelectedSection } from 'src/typings/floors';
import { IFloorMarkupHouseData } from 'src/components/ViewFloors/components/Markups/types';
import { isArray } from 'lodash';

export const useGetCurrentMarkup = (
  houseId: IFloorMarkupHouseData | undefined,
  isLargeScreen: boolean,
) => {
  const dispatch = useDispatch();
  const houseMarkups: IMarkUps | any = useSelector(getMarkups);
  const selectedHouse: IFirstSpinner | null = useSelector(getSelectedHouse);
  const fromComplex: boolean = useSelector(getFromComplex);
  const selectedSection: IFloorsSelectedSection = useSelector(getSelectedSection);
  const [renderMarkup, setRenderMarkUp] = useState<IMarkUpsInner | undefined>();

  const handleSelectSection = useCallback((markup: string, markupId: string, dropSelectedFloor?: boolean) => {
    dispatch(Actions.setSelectedSection({
      markup,
      'markup-id': markupId,
    }));
    if (dropSelectedFloor) {
      dispatch(Actions.setSelectedFloor(undefined));
    }
  }, [dispatch]);

  useEffect(() => {
    if (houseMarkups && houseMarkups.complex && !isArray(houseMarkups.complex)) {
      setRenderMarkUp(houseMarkups.complex);
    } else if (houseId) {
      setRenderMarkUp(
        houseMarkups
            && houseMarkups[houseId.name]?.[houseId.id],
      );
    } else if (selectedHouse) {
      setRenderMarkUp(
        fromComplex && isLargeScreen ? (
          houseMarkups.complex
        ) : (houseMarkups[selectedHouse.name]?.[selectedHouse['name-id']]),
      );
    }
  }, [houseId, houseMarkups, selectedHouse, isLargeScreen]);

  useEffect(() => {
    if (renderMarkup) {
      if (renderMarkup.svg.length) {
        if (!selectedSection.markup) {
          handleSelectSection(
            renderMarkup.svg[0].attr.markup,
            renderMarkup.svg[0].attr['markup-id'],
          );
        } else {
          handleSelectSection(
            selectedSection.markup,
            selectedSection['markup-id'] || '',
          );
        }
      }
    }
  }, [dispatch, handleSelectSection, renderMarkup]);

  return {
    renderMarkup,
    handleSelectSection,
  };
};
