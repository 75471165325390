import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperIcon: {
    backgroundColor: 'rgba(12,18,28,.38)',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute',
    width: '30px',
    height: '30px',
    top: '5px',
    right: '5px',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.5s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  imageIcon: {
    width: '20px',
    height: '20px',
    boxSizing: 'content-box',
    color: 'white',
  },
}));
