import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { borderColor } from 'src/constants/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    border: `1px solid ${borderColor}`,
    borderRadius: '50%',
    background: 'rgb(253 253 253 / 90%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '11px',
    cursor: 'pointer',
    transition: '0.2s all',
    '&:hover': {
      background: 'rgb(253 253 253)',
      borderColor: '#c7c7c7',
    },
  },
  withoutBorder: {
    border: 'none',
  },
  isBig: {
    padding: '30px',
  },
  icon: {
    width: '18px',
    height: '18px',
  },
}));
