import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';

export function getHousesByComplex(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getHouses}?complex=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getHouseById(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getHouses}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getHouseHistory(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getHouseHistory}${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
