import { all } from 'redux-saga/effects';

import { getAppWatchSaga } from './app/saga';
import { getFilterWatchSaga } from './filter/saga';
import { getTypeOfPropertyWatchSaga } from './type-of-property/saga';
import { getSpinnerWatchSaga } from './spinner-views/saga';
import { getInfrastructureWatchSaga } from './infrastructure/saga';
import { getUserWatchSaga } from './user/saga';

export function* requestsWatchSaga() {
  yield all([
    getUserWatchSaga(),
    getAppWatchSaga(),
    getFilterWatchSaga(),
    getTypeOfPropertyWatchSaga(),
    getSpinnerWatchSaga(),
    getInfrastructureWatchSaga(),
  ]);
}
