import React, { useCallback } from 'react';

import {
  AppCheckboxMultiBoxFilter,
  AppCheckboxSingleFilter,
} from 'src/components/AppCheckboxFilter';
import {
  IRangeAndSet,
  ListOfDropdown,
} from 'src/typings/filter';
import { AppScaleFilter } from 'src/components/AppScaleFilter';
import { useStyles } from './styles';

interface ISelectorGenerator {
  dropdownField: IRangeAndSet;
}

export const SelectorGeneratorFilter: React.FC<ISelectorGenerator> = ({
  dropdownField,
}) => {
  const styles = useStyles({});

  const handleSelectorGenerate = useCallback(() => {
    switch (dropdownField.type) {
      case ListOfDropdown.dropdownSelectList:
      case ListOfDropdown.dataList:
        return <AppCheckboxSingleFilter dropdownInfo={dropdownField} />;
      case ListOfDropdown.dropdownCheckboxList:
      case ListOfDropdown.datalistCheckboxList:
        return <AppCheckboxMultiBoxFilter dropdownInfo={dropdownField} />;
      case ListOfDropdown.scaleRange:
        return <AppScaleFilter dropdownInfo={dropdownField} />;
      default:
        return null;
    }
  }, [dropdownField]);

  if (handleSelectorGenerate()) {
    return (
      <div className={styles.wrapperCheckbox}>
        {handleSelectorGenerate()}
      </div>
    );
  }

  return null;
};
