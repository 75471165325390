import { useState, useEffect } from 'react';
import { IDeveloper } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';

import { getDevelopers, getCreatioProjects } from 'src/api';
import { getDeveloperData } from '../api/developer';

export const useGetDevelopers = () => {
  const [developers, setDevelopers] = useState<IDeveloper[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchDevelopers = () => {
    setIsLoading(true);
    getDevelopers(jwt)
      .then((res: any) => {
        const { data } = res;
        setDevelopers(data['hydra:member']);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let cleanupFunction = false;
    setIsLoading(true);
    getCreatioProjects(jwt);
    getDevelopers(jwt)
      .then((res: any) => {
        if (!cleanupFunction) {
          const { data } = res;
          setDevelopers(data['hydra:member']);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });

    return () => {
      cleanupFunction = true;
    };
  }, [jwt]);

  return {
    developers,
    isLoading,
    fetchDevelopers,
  };
};

export const useGetDeveloper = (id: string | number) => {
  const [developer, setDeveloper] = useState<IDeveloper|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchDeveloper = () => {
    if (id) {
      setIsLoading(true);
      getDeveloperData(jwt, id)
        .then((res: any) => {
          const { data } = res;
          setDeveloper(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    let cleanupFunction = false;
    if (id) {
      setIsLoading(true);
      getDeveloperData(jwt, id)
        .then((res: any) => {
          if (!cleanupFunction) {
            const { data } = res;
            setDeveloper(data);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    return () => {
      cleanupFunction = true;
    };
  }, [jwt]);

  return {
    developer,
    isLoading,
    fetchDeveloper,
    setDeveloper,
  };
};
