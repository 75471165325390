/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ButtonApp } from 'src/components/Button';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { borderColor, fontBlackColor } from 'src/constants/styles';
import { useGetDeveloper } from 'src/hooks';
import { ChooseComplexModal } from './components/ChooseComplexModal';
import { useStyles } from './styles';

export const DeveloperPage = () => {
  const { id }: { id: string } = useParams();
  const styles = useStyles({});
  const [modalOpen, setModalOpen] = useState(false);
  const { isLoading, developer, setDeveloper } = useGetDeveloper(id);

  if (isLoading || !developer) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const { title, complexes } = developer;

  const crumbs: ICrumbs[] = [
    {
      name: translations.developersLink,
      url: `/profile/${pathList.developers}`,
    },
    {
      name: `${translations.settings} ${title}`,
    },
  ];

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {`Застройщик ${title}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box mb={4}>
        <Link to={`/profile/${pathList.developers}/${developer.id}/integrationBitrix`}>
          <ButtonApp
            text="Интеграция Bitrix"
            color="primary"
            variant="contained"
            className={styles.btn}
          />
        </Link>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box
            className={styles.developerHover}
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            onClick={() => setModalOpen(true)}
            height="100%"
          >
            <Box
              width="100px"
              height="84px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              <AddRoundedIcon style={{ fontSize: '70px' }} />
            </Box>
            Добавить
            <br />
            комплекс
          </Box>
        </Grid>
        {complexes.map((complex) => (
          <Grid item xs={2} key={complex['@id']}>
            <Link to={`/profile/complexes/${complex.id}`}>
              <Box
                border={`1px solid ${borderColor}`}
                borderRadius="3px"
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                height="100%"
                color={fontBlackColor}
                className={styles.developerHover}
              >
                <Box
                  width="100px"
                  height="100px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  mb={2}
                >
                  <img
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                    }}
                    src={complex.logoUri}
                    alt={complex.title[complex.language]}
                  />
                </Box>
                {complex.title[complex.language]}
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
      {modalOpen && (
        <ChooseComplexModal
          isOpen={modalOpen}
          handleChangeOpen={setModalOpen}
          setDeveloper={setDeveloper}
          complexes={developer.complexes}
          id={developer.id}
        />
      )}
    </Box>
  );
};
