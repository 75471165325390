/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { ICategoryChild, IComplex, IProject } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { postBitrixCategory, patchBitrixCategory } from 'src/api';
import { useStyles } from '../styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ICategoryModal {
  isOpen: boolean;
  handleClose: Function;
  fetchProject: Function;
  project: IProject;
  category: ICategoryChild|null;
  complexes: Map<string, IComplex>;
}

const schema = yup.object().shape({
  bitrixExternalCategoryName: yup.string().required('Это обязательное поле'),
  bitrixExternalCategoryId: yup.string().required('Это обязательное поле'),
});

export const CategoryModal: React.FC<ICategoryModal> = ({
  isOpen, handleClose, fetchProject, category, project, complexes,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const [selectedComplexes, setSelectedComplexes] = React.useState<string[]>(category ? category.complexes : []);

  const {
    register, handleSubmit, formState, trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedComplexes(event.target.value as string[]);
    trigger();
  };

  const onSubmit = async (data:any) => {
    // eslint-disable-next-line no-param-reassign
    data.complexes = selectedComplexes;
    // eslint-disable-next-line no-param-reassign
    data.bitrixProject = project['@id'];
    let res;
    if (category) {
      res = await patchBitrixCategory(jwt, category['@id'], data);
    } else {
      res = await postBitrixCategory(jwt, data);
    }
    if (res.ok) {
      handleClose();
      fetchProject();
    } else {
      setError(res.data['hydra:description']);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      onClose={() => handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
          }}
        >
          <Box width="600px" maxHeight="90vh" overflow="auto" py={10} px={11}>
            <Box mb={6} fontSize="24px" fontWeight={600}>
              {category ? 'Редактирование воронки' : 'Добавление новой воронки'}
            </Box>
            <Box fontSize="18px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={4} display="flex" flexDirection="column">
                  Название из BITRIX
                  <Box width="260px" mt={2}>
                    <TextField
                      id="bitrixExternalCategoryName"
                      variant="outlined"
                      name="bitrixExternalCategoryName"
                      inputRef={register}
                      defaultValue={category && (category.bitrixExternalCategoryName)}
                    />
                  </Box>
                </Box>
                <Box mb={4} display="flex" flexDirection="column">
                  ID из BITRIX
                  <Box width="260px" mt={2}>
                    <TextField
                      id="bitrixExternalCategoryId"
                      variant="outlined"
                      name="bitrixExternalCategoryId"
                      inputRef={register}
                      defaultValue={category && (category.bitrixExternalCategoryId)}
                    />
                  </Box>
                </Box>

                <FormControl className={styles.formControl}>
                  <InputLabel id="complexes-label">Комлексы</InputLabel>
                  <Select
                    labelId="complexes-label"
                    id="complexes"
                    multiple
                    value={selectedComplexes}
                    onChange={handleChange}
                    input={(<Input />)}
                    renderValue={(selected) => (selected as string[]).map((item) => {
                      const comp = complexes.get(item);
                      return comp ? comp.title[comp.language] : null;
                    }).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {Array.from(complexes.values()).map((comp) => (
                      <MenuItem key={comp['@id']} value={comp['@id']}>
                        <Checkbox checked={selectedComplexes.indexOf(comp['@id']) > -1} />
                        <ListItemText primary={comp.title[comp.language]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box width="260px">
                  <ButtonApp
                    text={translations.safeChanges}
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={styles.btn}
                    disabled={!formState.isValid || !selectedComplexes.length}
                  />
                </Box>
              </form>
              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                  {error}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
