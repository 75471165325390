import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';

export function getDevelopers(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().getDevelopInfo}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getDeveloperData(token: string = '', id: string|number) {
  return httpService.get(`${ApiUrl.getApiUrl().getDevelopInfo}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
export function postDeveloperData(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getDevelopInfo}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchDeveloperData(token: string = '', id: string|number, data: any) {
  return httpService.patch(`${ApiUrl.getApiUrl().getDevelopInfo}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}
export function deleteDeveloperData(token: string = '', id: string|number) {
  return httpService.delete(`${ApiUrl.getApiUrl().getDevelopInfo}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
