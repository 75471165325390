import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';

export const useAppVisibilityBtn = () => {
  const buttonConfig = useSelector(requestSelectors.app.getButtonVisibilityConfig);

  return {
    buttonConfig,
    filter_call: buttonConfig?.filter_call,
    filter_favorites: buttonConfig?.filter_favorites,
    filter_menu: buttonConfig?.filter_menu,
    filter_share: buttonConfig?.filter_share,
    infrastructure_active: buttonConfig?.infrastructure_active,
    infrastructure_button: buttonConfig?.infrastructure_button,
    mode_floors: buttonConfig?.mode_floors,
    mode_layout: buttonConfig?.mode_layout,
    mode_crosstable: buttonConfig?.mode_crosstable,
    mode_map: buttonConfig?.mode_map,
    mode_panorama: buttonConfig?.mode_panorama,
    mode_spinner: buttonConfig?.mode_spinner,
    placement_floor_placing: buttonConfig?.placement_floor_placing,
    placement_request_call: buttonConfig?.placement_request_call,
    placement_section_markup_placing: buttonConfig?.placement_section_markup_placing,
    show_client_logo: buttonConfig?.show_client_logo,
    show_filter_counter: buttonConfig?.show_filter_counter,
    show_prefilter_placement_type_selector: buttonConfig?.show_prefilter_placement_type_selector,
    start_view_full_screen: buttonConfig?.start_view_full_screen,
  };
};
