import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperMobileFilter: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    // minHeight: '53px',
    // maxHeight: '53px',
    background: 'white',
    overflow: 'hidden',
    transform: 'translateY(100%)',
    transition: 'all 0.5s cubic-bezier(.25,.1,.25,1)',
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperMobileFilterOpen: {
    transform: 'translateY(calc(-100% + 53px))',
  },
  buttonShowFilter: {
    position: 'relative',
  },
  resultMobileFilter: (props: any) => ({
    background: props.filterBackgroundColor,
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }),
  wrapperSwipeAbleElement: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F8F8F8',
    borderRadius: '30px 30px 0px 0px',
    maxHeight: '40px',
    padding: '7px 0px',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  wrapperSwipeAbleElementInner: {
    borderRadius: '0px',
  },
  swipeAbleFilter: {
    cursor: 'pointer',
    background: '#EDEDED',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: '10px 16px 16px 16px',
    top: '0px',
    minHeight: '30px',
    '&:focus, &:active': {
      background: '#EDEDED',
    },
  },
  filterMobileContent: {
    height: '100%',
    padding: '20px 20px',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperMobileFilterParams: {
    marginTop: '10px',
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    padding: '0px 20px',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  mobileFilterControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px 5px',
    fontSize: '14px',
  },
  wrapperListOfSelectedItem: {
    padding: '10px 0px',
  },
  wrapperCounter: {
    textAlign: 'right',
    marginLeft: '10px',
  },
  shobZarkitb: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    position: 'absolute',
    right: '20px',
  },
  wrapperHeaderMobilePart: {
    position: 'relative',
    boxShadow: `0px 0px 6px 0px ${theme.palette.grey['300']}`,
  },
}));
