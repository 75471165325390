import React from 'react';

import {
  AppFavFAB,
  AppFullScreenContainer,
  AppHeader,
  FavoritesList,
} from 'src/components';
import { useTranslations } from 'src/hooks/translataions';
import { useSetDocumentTitle } from 'src/hooks';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';

export const Favorites: React.FC = () => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  useSetDocumentTitle(getTranslations(locals.appTranslations.appTitleFavorites));

  return (
    <AppFullScreenContainer>
      <div className={styles.favoritesWrapper}>
        <AppHeader />
        <FavoritesList />
        <AppFavFAB />
      </div>
    </AppFullScreenContainer>
  );
};
