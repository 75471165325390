/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import { ButtonApp } from 'src/components/Button';
import { pathList } from 'src/containers/Profile/routeList';
import {
  IDeveloper, IProject, IComplex, ICategoryChild, ICategory,
} from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { postGenerateBitrixCategories } from 'src/api';
import { CategoryModal } from './CategoryModal';
import { useStyles } from '../styles';

interface ICategoriesTable {
  developer: IDeveloper;
  project: IProject;
  fetchProject: Function;
}

export const CategoriesTable: React.FC<ICategoriesTable> = ({
  developer,
  project,
  fetchProject,
}) => {
  const { bitrixCategories } = project;
  const styles = useStyles({});
  const [error, setError] = useState('');
  const [categories, setCategories] = useState<ICategoryChild[]>(bitrixCategories);
  const [modalOpen, setModalOpen] = useState(false);
  const [editCategory, setEditCategory] = useState<ICategoryChild|null>(null);
  const [complexes, setComplexes] = useState<any>(null);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  useEffect(() => {
    if (developer.complexes) {
      const tempMap = new Map();
      developer.complexes.forEach((complex) => {
        tempMap.set(complex['@id'], complex);
      });
      setComplexes(tempMap);
    }
  }, [developer]);

  const handleGenerate = async () => {
    const { data } = await postGenerateBitrixCategories(jwt, project.id);
    console.log('DATA', data);
    setCategories(data['hydra:member'].map((item: ICategory) => {
      return {
        ...item,
        complexes: item.complexes.map((comp) => comp['@id']),
      };
    }));
  };

  return (
    <Box>
      <Box mb={4} fontSize="24px" fontWeight={600}>
        Добавление воронки (категории)
      </Box>
      <Box fontSize="14px">
        <Box display="flex" justifyContent="space-between">
          <Box width="260px" mb={2}>
            <ButtonApp
              text="Добавить воронку"
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => {
                setEditCategory(null);
                setModalOpen(true);
              }}
              className={styles.btn}
            />
          </Box>
          <Box mb={2}>
            <ButtonApp
              text="Сгенерировать воронки"
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleGenerate}
              className={styles.btn}
            />
          </Box>
        </Box>
        <TableContainer component={Paper} className={styles.container}>
          <Table className={styles.table} stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">&nbsp;</TableCell>
                <TableCell align="left">Название из BITRIX</TableCell>
                <TableCell align="left">ID из BITRIX</TableCell>
                <TableCell>Комплексы воронки</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((row) => (
                <TableRow key={row['@id']}>
                  <TableCell align="left">
                    <IconButton
                      onClick={() => {
                        console.log('ROW', row);
                        setEditCategory(row);
                        setModalOpen(true);
                      }}
                      title="Редактировать"
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">{row.bitrixExternalCategoryName}</TableCell>
                  <TableCell align="left">{row.bitrixExternalCategoryId}</TableCell>
                  <TableCell>
                    {complexes && row.complexes.map((item) => {
                      if (!complexes) return null;
                      const comp: IComplex = complexes.get(item);
                      return comp ? `${comp.title[comp.language]}, ` : null;
                    })}

                  </TableCell>
                  <TableCell align="right">
                    <Link to={`/profile/${pathList.developers}/${developer.id}/category/${row['@id'].replace('/api/bitrix_categories/', '')}`}>
                      <IconButton title="Настройки воронки" size="large">
                        <SettingsIcon />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
        {modalOpen && (
        <CategoryModal
          isOpen={modalOpen}
          handleClose={() => {
            setModalOpen(false);
          }}
          fetchProject={fetchProject}
          complexes={complexes}
          project={project}
          category={editCategory}
        />
        )}
      </Box>
    </Box>
  );
};
