import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperAirbnbGraph: {
    padding: '0px 15px',
  },
  rangeInput: (props: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    marginTop: '10px',
    '& > div': {
      '&:first-child': {
        'margin-right': '5px',
      },
      '&:last-child': {
        'margin-left': '5px',
        '& input': {
          textAlign: 'right',
        },
      },
      '& .ui.input>input': {
        background: props.filterBackgroundColor,
      },
    },
  }),
  applyWrapperFilter: {
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& span': {
      fontSize: '12px',
    },
    [theme.breakpoints.up('xl')]: {
      '& span': {
        fontSize: 'inherit',
      },
    },
  },
}));
