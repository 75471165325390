import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from 'src/store/view-2d/actions';
import { getComplexId } from 'src/store/app/selectors';
import { getView2dStatuses } from 'src/store/view-2d/selectors';
import { IStatus } from 'src/typings/complexes';
import { useGetAuthData, useBuildType } from 'src/hooks';

import { getPlacementStatusByComplex } from 'src/api';

export const useGetComplexStatuses = (id?: string|undefined) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filteredStatuses, setFilteredStatuses] = useState<IStatus[]>([]);
  const { isCrossTable } = useBuildType();
  const statuses:IStatus[] = useSelector(getView2dStatuses);
  const complexId = useSelector(getComplexId);
  const dispatch = useDispatch();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchStatuses = () => {
    if (id || complexId) {
      setIsLoading(true);
      // @ts-ignore
      getPlacementStatusByComplex(jwt, id || complexId)
        .then((res: any) => {
          dispatch(Actions.setView2dStatuses(res.data['hydra:member']));
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    let cleanupFunction = false;
    if ((id || complexId) && !statuses.length) {
      setIsLoading(true);
      // @ts-ignore
      getPlacementStatusByComplex(jwt, id || complexId)
        .then((res: any) => {
          if (!cleanupFunction) {
            dispatch(Actions.setView2dStatuses(res.data['hydra:member']));
            setIsLoading(false);
          }
        });
    }

    return () => {
      cleanupFunction = true;
    };
  }, [jwt, id, statuses]);

  useEffect(() => {
    const colors: string[] = [];
    if (statuses) {
      const filtered = statuses.reduce((acc: IStatus[], item) => {
        const color = isCrossTable ? (item.crosstableColor || item.color) : item.color;
        if (!colors.includes(color)) {
          colors.push(color);
          acc.push(item);
        }
        return acc;
      }, []);
      setFilteredStatuses(filtered);
      setIsLoading(false);
    }
  }, [statuses]);

  return {
    statuses: filteredStatuses,
    fetchStatuses,
    isLoading,
  };
};
