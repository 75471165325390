import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperEyeIcon: {
    top: '40px',
    right: '5px',
  },
  highlightPolygon: {
    animation: '$highlightPolygon 1000ms infinite',
  },
  '@keyframes highlightPolygon': {
    '0%': { fill: 'transparent' },
    '100%': { fill: theme.palette.grey['100'] },
  },
}));
