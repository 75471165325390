import { all } from 'redux-saga/effects';
import { requestsWatchSaga } from './requests/saga';
import { setFilterParamsWatchSaga } from './filter/filter-params/saga';
import { setFilteredDataWatchSaga } from './filter/filtered-data/saga';
import { setFilterValueWatchSaga } from './filter/filter-value/saga';
import { spinnerWatchSaga } from './spinner/saga';
import { getComplexIdWatchSaga } from './app/saga';
import { setFloorsDataWatchSaga } from './floors/saga';
import { getView2dWatchSaga } from './view-2d/saga';
import { getCrosstableWatchSaga } from './crosstable/saga';
import { getPanoramaWatchSaga } from './panorama/saga';
import { getTypeDisplayedWatchSaga } from './type-displayed-spinner/saga';

export function* rootSaga() {
  yield all([
    requestsWatchSaga(),
    getComplexIdWatchSaga(),
    setFilterParamsWatchSaga(),
    setFilteredDataWatchSaga(),
    setFilterValueWatchSaga(),
    spinnerWatchSaga(),
    setFloorsDataWatchSaga(),
    getView2dWatchSaga(),
    getCrosstableWatchSaga(),
    getTypeDisplayedWatchSaga(),
    getPanoramaWatchSaga(),
  ]);
}
