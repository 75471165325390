import { Dropdown } from 'semantic-ui-react';
import React from 'react';

import { useTranslations } from 'src/hooks';
import { appTranslations } from 'src/constants/locals/appTranslation';
import { AppComponentCheckbox } from 'src/components/AppCheckbox';
import { IDropdownGenerator } from 'src/components/dropdowns/types';
import { DropdownMultiCheckBox } from 'src/components/dropdowns/DropdownMulticheckbox';
import { useConvertToFormat } from 'src/components/dropdowns/hooks';
import { useCheckboxState } from './useCheckboxState';

interface IDropdownMultiCheckBox extends IDropdownGenerator {}

export const DropdownMultiCheckBoxFilter: React.FC<IDropdownMultiCheckBox> = ({
  dropdownInfo,
}) => {
  const { getFixedTranslations, getTranslations } = useTranslations(dropdownInfo.attribute);
  const { currentData } = useConvertToFormat(dropdownInfo.data);
  const {
    isDisabled,
    selectedCheckbox,
    formatPlaceholder,
    handleOnClearCheckBox,
    handleChangeSelectedCheckbox,
    handleSelectAll,
  } = useCheckboxState(
    dropdownInfo.attribute,
    getFixedTranslations(),
    currentData,
  );

  const handleSelectItem = (e: React.MouseEvent<HTMLDivElement>, value: any) => {
    e.stopPropagation();
    handleChangeSelectedCheckbox(value);
  };

  const handleClearField = (e: React.KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (e.target.classList.contains('clear')) {
      handleOnClearCheckBox();
    }
  };

  return (
    <DropdownMultiCheckBox
      clearable
      disabled={!Boolean(currentData) || currentData.length === 0 || isDisabled}
      value={formatPlaceholder}
      pointing="top"
      upward={false}
      text={formatPlaceholder || getFixedTranslations()}
      className={`selection fluid ${!formatPlaceholder && 'emptyValue'}`}
      onClick={(event) => handleClearField(event)}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          /* eslint-disable-next-line react/no-array-index-key */
          key={`selectAll${dropdownInfo.attribute}`}
          onClick={handleSelectAll}
        >
          {getTranslations(appTranslations.appSelectAll)}
        </Dropdown.Item>
        {currentData?.map((item, index) => {
          return (
            <Dropdown.Item
              /* eslint-disable-next-line react/no-array-index-key */
              key={item.value + index}
              onClick={(e) => handleSelectItem(e, item.value)}
            >
              <AppComponentCheckbox
                isChecked={selectedCheckbox?.includes(item.value)}
                label={item.text}
              />
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </DropdownMultiCheckBox>
  );
};
