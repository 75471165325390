import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperPanorama: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  panoramaViewWrapper: {
    width: '100%',
    height: '100%',
  },
  panoramaView: {
    width: '100%',
    height: '50vh',
  },
  panoramaViewFullSize: {
    width: '100%',
    height: '100%',
  },
}));
