import React, { useCallback, useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Portal from '@mui/material/Portal';

import { PopUpText, TagAttributeText } from './components';
import { useStyles } from './styles';

interface IRenderHoverText {
  isOnlyText?: boolean;
  isEnableHoverPopup: boolean;
  nameId: string;
  hoverId: string;
  trackMouse: {
    x: number,
    y: number,
  }
  handleHover: (val: boolean) => void;
}

export const RenderHoverText: React.FC<IRenderHoverText> = React.memo(({
  isEnableHoverPopup,
  isOnlyText,
  nameId,
  hoverId,
  trackMouse,
  handleHover,
}) => {
  const refWrapperText = useRef<HTMLDivElement>(null);
  const currentWrapperTextWidth: number = refWrapperText.current?.offsetWidth
    ? refWrapperText.current?.offsetWidth / 2
    : 0;
  const styles = useStyles({
    isOnlyText,
  });
  const [isSecond, setSecond] = useState(false);

  const renderText = useCallback(() => {
    if (isOnlyText) {
      return (
        <div>
          {nameId}
        </div>
      );
    }

    if (hoverId) {
      return <TagAttributeText hoverId={hoverId} />;
    }

    if (isEnableHoverPopup) {
      return <PopUpText nameId={nameId} />;
    }

    return null;
  }, [isEnableHoverPopup, hoverId, nameId]);

  if (!hoverId && !isEnableHoverPopup) return null;

  return (
    <Portal>
      <ClickAwayListener
        onClickAway={() => {
          setSecond(true);
          if (isSecond) handleHover(false);
        }}
        touchEvent="onTouchStart"
      >
        <div
          ref={refWrapperText}
          className={styles.tooltipPortal}
          style={{
            top: trackMouse.y,
            left: trackMouse.x - currentWrapperTextWidth,
          }}
        >
          {
            renderText()
          }
        </div>
      </ClickAwayListener>
    </Portal>
  );
});
