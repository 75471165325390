import { useSelector } from 'react-redux';
import { getView2dSelectedTypes } from 'src/store/view-2d/selectors';

export const useGetTypeView2d = () => {
  const getTypeView2d = useSelector(getView2dSelectedTypes);

  return {
    getTypeView2d,
  };
};
