import { ApiUrl, crosstableUrl } from 'src/constants';
import { httpService } from 'src/services';

export function getPanoramaData(id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getPanoramaInfo}/${id}`, {}, {});
}

export function getPanoramaByEntity(id: number | string, entityName: string) {
  return httpService.get(`${crosstableUrl}/${entityName}_panorama_v2_views?${entityName}=${id}&pagination=false`, {}, {});
}
