import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';
import { pathList } from 'src/route-list';
import { useFavoriteStorage } from 'src/hooks/app';

export const useGenerateFavoriteLink = () => {
  const history = useHistory();
  const [showFAB, setShowFAB] = useState<boolean>(false);
  const typeOfPropertySelector = useSelector(getSelectedPlacement);
  const { favIdsList } = useFavoriteStorage();

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    if (params.toString().trim().length) {
      setShowFAB(false);
    } else {
      const favLength = favIdsList.length;

      if (favLength) {
        setShowFAB(true);
      } else {
        setShowFAB(false);
      }
    }
  }, [favIdsList, history.location.search]);

  return {
    showFAB,
    favShareLink: `${document.location.origin}${document.location.pathname}#${pathList.favorites}/?types=${typeOfPropertySelector}&ids=${favIdsList}`,
  };
};
