import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { requestSelectors } from 'src/store/requests/selectors';
import { ISpinnerData } from 'src/typings/spinner';
import { IFilterAllData } from 'src/typings/filter';

export const useGetPlacementPopoverData = (attr: any, placement?: IFilterAllData | undefined) => {
  const entityView: any = useMemo(() => (attr ? attr['entity-view'] : null), [attr]);
  const entityViewName: any = useMemo(() => (attr ? attr['entity-view-name'] : null), [attr]);
  const entityViewId: any = useMemo(() => (attr ? attr['entity-view-name-id'] : null), [attr]);
  const spinnerData: ISpinnerData | any = useSelector(requestSelectors.spinner.getSpinnerData);

  if (placement) {
    return {
      property: placement,
    };
  }

  return {
    property: spinnerData && spinnerData[entityView][entityViewName][entityViewId],
  };
};
