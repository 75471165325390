import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperLanguageMenu: {
    '& .MuiPaper-root': {
      boxShadow: `0px 0px 5px -1px ${theme.palette.primary.main}`,
    },
  },
  currentLanguage: {
    backgroundColor: '#F6F7F6 !important',
  },
  languageMenuItem: {
    textTransform: 'uppercase',
  },
}));
