import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { requestSelectors } from 'src/store/requests/selectors';
import { getCurrentSpinner, getStopFrames } from 'src/store/spinner/selectors';
import { ISpinnerDataScheme } from 'src/typings/spinner';
import { Actions } from 'src/store/spinner/actions';
import { resizeEmulate } from 'src/helpers';
import { ISpinnerState } from '../types';

export const useInitSpinner = (
  isFloors: boolean,
  setFullSizeFloor: (val: boolean) => void,
  initWindRoseData: Function | null,
) => {
  const { devicePixelRatio } = window;
  const theme: Theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const [spinner, setSpinner] = useState<any>();
  const [spinnerLoadEnd, setSpinnerLoadEnd] = useState<boolean>(false);
  const [spinnerState, setSpinnerState] = useState<ISpinnerState>({
    stopFrame: '0',
    dw: 0,
    dh: 0,
    dx: 0,
    dy: 0,
  });
  const [svgSize, setSvgSize] = useState<{w: number, h: number}>({ w: 0, h: 0 });
  const spinnerData = useSelector(requestSelectors.spinner.getSpinnerViewData);
  const stopFrames = useSelector(getStopFrames);
  const currentSpinner = useSelector(getCurrentSpinner);

  const spinnerCanvas: HTMLCanvasElement | null = document.querySelector('#spinner_canvas');
  const spinnerPlace: HTMLElement | null = document.querySelector('#spinner_place');
  const spinnerControl: HTMLElement | null = document.querySelector('#spinner_control');
  const progressBar: HTMLElement | null = document.querySelector('#porgress_bar');
  const turnLeft: HTMLElement | null = document.querySelector('#turn_left');
  const turnRight: HTMLElement | null = document.querySelector('#turn_right');
  const spinnerZoom: HTMLElement | null = document.querySelector('#spinner_zoom');
  const spinnerUnZoom: HTMLElement | null = document.querySelector('#spinner_unzoom');
  const windRose: HTMLElement | null = document.querySelector('#wind_rose');

  const handleSetStopFrame = useCallback((isHide?: boolean) => {
    if (isHide) {
      setSpinnerState({
        ...spinnerState,
        stopFrame: '-1',
      });
    }
  }, [spinnerState]);
  const handleResizeForSvg = useCallback(() => {
    if (
      spinnerState.dh !== spinner?._size?.dh
      || spinnerState.dw !== spinner?._size?.dw
      || spinnerState.dx !== spinner?._size?.dx
      || spinnerState.dy !== spinner?._size?.dy
      || spinnerState.stopFrame !== String(spinner._frames.i)
    ) {
      setSpinnerState({
        stopFrame: spinner?._frames?.i || '0',
        dw: spinner?._size?.dw || 0,
        dh: spinner?._size?.dh || 0,
        dx: spinner?._size?.dx || 0,
        dy: spinner?._size?.dy || 0,
      });
    }
  }, [spinner]);

  const handleCanvasMouseMove = useCallback((e) => {
    if (e.buttons !== 1) return;

    spinner.moveFrame(e.movementX, e.movementY);
    handleResizeForSvg();
  }, [spinner]);

  const handleOnProgressFSSpinner = (e: any) => {
    if (progressBar) {
      // @ts-ignore
      const totalLoad = window.Spinner.map(e.loaded, 0, e.total);

      progressBar.style.width = `${totalLoad}%`;
      if (totalLoad >= 100) {
        setSpinnerLoadEnd(true);
      }
    }
  };

  const handleOnLoadEndFSSpinner = () => {
    if (progressBar) {
      progressBar.style.width = '0';
    }
  };

  function control(this: any, e: any) {
    if (this.classList.contains('disabled')) return;
    if (this === turnLeft) spinner.turn(-1);
    else if (this === turnRight) spinner.turn(1);
    else if (spinnerCanvas) {
      if (this === spinnerZoom) spinner.zoomFrame(0.1);
      else spinner.zoomFrame(-0.1);
      handleResizeForSvg();
    }
  }

  // todo fix this function
  function controlActivity(this: any) {
    const { zoom } = this._size;
    const rotate = this._rotate?.loop;

    if (zoom > 1 || rotate) {
      if (!rotate) {
        // if (spinnerUnZoom) spinnerUnZoom.classList.remove('disabled');
        // if (turnLeft) turnLeft.classList.add('disabled');
        // if (turnRight) turnRight.classList.add('disabled');
      }
    } else if (currentSpinner && stopFrames?.[currentSpinner.name]?.[currentSpinner['name-id']]) {
      dispatch(Actions.setSpinnerStopFrame({
        [currentSpinner.name]: {
          [currentSpinner['name-id']]: this._frames.i,
        },
      }));
    }
    if (spinnerZoom) {
      // if (zoom < 5 && !rotate) spinnerZoom.classList.remove('disabled');
      // else spinnerZoom.classList.add('disabled');
    }
  }

  function windRoseRotate(this: any) {
    const windRoseImg = windRose?.querySelector('div');
    if (initWindRoseData) {
      const windRoseData = initWindRoseData();
      if (windRoseImg && windRoseData && this && this._frames) {
        // @ts-ignore
        windRoseImg.style.transform = `rotateZ(${windRoseData.degree + window.Spinner.map(this._frames.i, 0, this._frames.length, 0, 359)}deg)`;
      }
    }
  }

  const canvasResize = () => {
    if (spinnerCanvas && spinnerPlace) {
      spinnerCanvas.style.width = `${spinnerPlace.clientWidth}px`;
      spinnerCanvas.style.height = `${spinnerPlace.clientHeight}px`;

      spinner.resize(true);
    }
  };

  const handleWindowResize = () => {
    canvasResize();
    handleResizeForSvg();
  };

  useEffect(() => {
    if (spinnerData && currentSpinner && spinnerCanvas && !spinner
      && !(isFloors && currentSpinner.name !== 'floor')) {
      // Write to redux initial stopFrame
      if (
        !stopFrames?.[currentSpinner.name]?.[currentSpinner['name-id']]
        && spinnerData[currentSpinner.name][currentSpinner['name-id']].startFrame !== undefined
      ) {
        dispatch(Actions.setSpinnerStopFrame({
          [currentSpinner.name]: {
            [currentSpinner['name-id']]: spinnerData[currentSpinner.name][currentSpinner['name-id']].startFrame,
          },
        }));
      }

      if (spinnerData[currentSpinner.name][currentSpinner['name-id']].objectFit === 'cover' && isFloors) {
        setFullSizeFloor(true);
      } else {
        setFullSizeFloor(false);
      }
      let config = spinnerData[currentSpinner.name][currentSpinner['name-id']];

      if (isMdScreen) {
        config = JSON.parse(JSON.stringify(config));
        // @ts-ignore
        config.data = [config.data.pop()];
      }

      // @ts-ignore
      setSpinner(new window.Spinner(
        spinnerCanvas,
        {
          stops: config.stops,
          maxZoom: 5,
          turnSpeed: 30,
          ...config,
          startFrame:
            stopFrames?.[currentSpinner.name]?.[currentSpinner['name-id']] !== undefined
              ? stopFrames?.[currentSpinner.name]?.[currentSpinner['name-id']]
              : config.startFrame,
        },
      ));
    }
  }, [spinner, spinnerData, currentSpinner, spinnerCanvas, setFullSizeFloor]);

  useEffect(() => {
    if (spinner) {
      // @ts-ignore
      document.mainSpinner = spinner;
      let mx: any;
      let my: any;
      let touchstart = false;
      let lastZoom = 1;

      const touchend = () => {
        touchstart = false;
        lastZoom = 1;
      };

      canvasResize();
      window.addEventListener('resize', debounce(handleWindowResize, 300));
      spinner.addEventListener('loadfirst', () => {
        if (spinnerZoom) spinnerZoom.classList.remove('disabled');

        canvasResize();
        setTimeout(resizeEmulate, 100);
        setTimeout(resizeEmulate, 1000);
      });

      spinner.addEventListener('rotateready', function (this: any) {
        controlActivity.call(this);
        this.addEventListener('rotationend', controlActivity);
        handleResizeForSvg();
      });

      spinner.on('framezoom', function (this: any) {
        // eslint-disable-next-line no-unused-expressions
        if (this._size.zoom >= this.maxZoom) spinnerZoom?.classList.add('disabled');
        // eslint-disable-next-line no-unused-expressions
        else spinnerZoom?.classList.remove('disabled');

        // eslint-disable-next-line no-unused-expressions
        if (this._size.zoom <= 1) spinnerUnZoom?.classList.add('disabled');
        // eslint-disable-next-line no-unused-expressions
        else spinnerUnZoom?.classList.remove('disabled');
      });

      if (spinnerCanvas) {
        spinnerCanvas.addEventListener('mousemove', handleCanvasMouseMove);
      }

      if (turnLeft) { turnLeft.addEventListener('click', control); }
      if (turnRight) { turnRight.addEventListener('click', control); }
      if (spinnerZoom) { spinnerZoom.addEventListener('click', control); }
      if (spinnerUnZoom) { spinnerUnZoom.addEventListener('click', control); }

      if (spinnerPlace) {
        spinnerPlace.style.touchAction = 'none';

        spinnerPlace.addEventListener('touchend', touchend);
        spinnerPlace.addEventListener('touchleave', touchend);

        spinnerPlace.addEventListener('touchstart', (e) => {
          if (touchstart) return;

          mx = e.touches[0].pageX;
          my = e.touches[0].pageY;

          touchstart = true;
        });

        spinnerPlace.addEventListener('touchmove', (e) => {
          if (!touchstart) return;

          const x = e.touches[0].pageX;
          const y = e.touches[0].pageY;

          spinner.moveFrame(x - mx, y - my);

          mx = x;
          my = y;

          // @ts-ignore
          spinner.zoomFrame((e.scale - lastZoom));
          // @ts-ignore
          lastZoom = e.scale;

          handleResizeForSvg();
        });
      }

      spinner.addEventListener('progress', handleOnProgressFSSpinner);
      spinner.addEventListener('loadend', handleOnLoadEndFSSpinner);
      spinner.addEventListener('loadfirst', (e: { img: { w: number, h: number } }) => {
        if (spinnerControl) {
          spinnerControl.classList.remove('hide');
        }
        // @ts-ignore
        windRoseRotate.call(spinner);
        if (windRose) {
          windRose.classList.remove('hide');
        }
        handleSetStopFrame();
        setSvgSize({ w: e.img.w, h: e.img.h });
        setTimeout(resizeEmulate, 500);
      });
      spinner.addEventListener('framechange', windRoseRotate);

      // SVG
      spinner.addEventListener('rotationstart', () => {
        handleSetStopFrame(true);
      });
      spinner.addEventListener('rotationend', () => {
        handleResizeForSvg();
      });
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      if (spinner) {
        spinner.dispose();
        // @ts-ignore
        document.mainSpinner = null;
      }
      if (spinnerCanvas) {
        spinnerCanvas.removeEventListener('mousemove', handleCanvasMouseMove);
      }
      if (turnLeft) { turnLeft.removeEventListener('click', control); }
      if (turnRight) { turnRight.removeEventListener('click', control); }
      if (spinnerZoom) { spinnerZoom.removeEventListener('click', control); }
      if (spinnerUnZoom) { spinnerUnZoom.removeEventListener('click', control); }
    };
  }, [spinner]);

  useEffect(() => {
    let intervalTimes: any;

    if (spinner && svgSize.w === 0 && svgSize.h === 0) {
      intervalTimes = setInterval(() => {
        setSvgSize({ w: spinner._frames[0]?.w, h: spinner._frames[0]?.h });
        resizeEmulate();
      }, 1000);
    }

    return () => {
      clearInterval(intervalTimes);
    };
  }, [spinner, svgSize]);

  const fetchConfig = async () => {
    if (spinnerData && currentSpinner) {
      // @ts-ignore
      const config = await window.Spinner.margeConfig(
        spinnerData[currentSpinner.name][currentSpinner['name-id']], currentSpinner.name, currentSpinner['name-id'],
      );

      if (config && spinner && initWindRoseData) {
        spinner.setData(config);
        initWindRoseData(config._windRose);
      }
    }
  };

  useEffect(() => {
    fetchConfig();
  }, [spinner]);

  return {
    spinnerLoadEnd,
    listOfStops: (currentSpinner && spinnerData?.[currentSpinner.name]?.[currentSpinner?.['name-id']].stops) || [],
    svgSize,
    devicePixelRatio,
    spinnerState,
  };
};
