import { IFilterData, IFilterAllData } from 'src/typings/filter';
import { action } from 'src/store/common';

export const ActionTypes = {
  SET_FILTER_TYPE_PLACEMENT_TO_INITIAL_STATE: '[REQUEST_FILTER]SET_FILTER_TYPE_PLACEMENT_TO_INITIAL_STATE',
  GET_FILTER_DATA_REQUEST: '[REQUEST_FILTER]GET_FILTER_DATA_REQUEST',
  GET_FILTER_DATA_RESET: '[REQUEST_FILTER]GET_FILTER_DATA_RESET',
  GET_FILTER_DATA_UPDATE: '[REQUEST_FILTER]GET_FILTER_DATA_UPDATE',
  UPDATE_PLACEMENT: '[REQUEST_FILTER]UPDATE_PLACEMENT',
  GET_FILTER_DATA_REQUEST_SUCCESS: '[REQUEST_FILTER]GET_FILTER_DATA_REQUEST_SUCCESS',
  GET_FILTER_DATA_REQUEST_FAIL: '[REQUEST_FILTER]GET_FILTER_DATA_REQUEST_FAIL',
};

export const Actions = {
  setInitialState: action(ActionTypes.SET_FILTER_TYPE_PLACEMENT_TO_INITIAL_STATE),
  getDataFilterRequest: action(ActionTypes.GET_FILTER_DATA_REQUEST),
  getDataFilterReset: action(ActionTypes.GET_FILTER_DATA_RESET),
  getDataFilterUpdate: action(ActionTypes.GET_FILTER_DATA_UPDATE),
  updatePlacement: action(ActionTypes.UPDATE_PLACEMENT),
  getDataFilterSuccess: action<IFilterData>(ActionTypes.GET_FILTER_DATA_REQUEST_SUCCESS),
  getDataFilterFail: action<Error>(ActionTypes.GET_FILTER_DATA_REQUEST_FAIL),
};
