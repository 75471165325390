import React from 'react';

import { AppGallery } from 'src/components/AppGallery';
import { ButtonApp } from 'src/components/Button';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { IInfrastructureTypeGallery } from '../../types';
import { useStyles } from '../../styles';
import { useStyles as useStylesGallery } from './styles';
import { useGalleryState } from './hooks';

export const InfrastructureGallery: React.FC<IInfrastructureTypeGallery> = ({
  withBody,
  data,
}) => {
  const styles = useStyles({});
  const stylesGallery = useStylesGallery();
  const { gallery } = useGalleryState(data);

  return (
    <div className={styles.wrapperInfrastructureType}>
      {Boolean(gallery.length) && (
        <div className={`${styles.wrapperImage} ${withBody ? '' : styles.withoutBody}`}>
          <AppGallery
            lazyLoad
            showFullscreenButton
            additionalClass={stylesGallery.infrastructureSlideWrapper}
            items={gallery}
            showPlayButton={false}
            showThumbnails={false}
            renderFullscreenButton={(onClick, isFullscreen) => {
              return (
                <ButtonApp
                  style={{ position: 'absolute' }}
                  className={`image-gallery-fullscreen-button ${stylesGallery.zoomButton}`}
                  onClick={onClick}
                >
                  {
                    isFullscreen
                      ? <FullscreenExitIcon className={stylesGallery.zoomStyle} />
                      : <FullscreenIcon className={stylesGallery.zoomStyle} />
                  }
                </ButtonApp>
              );
            }}
          />
        </div>
      )}
      <div>
        <p className={styles.textBody}>{data['infrastructure.body_text']}</p>
        {
          data['infrastructure.links'] && data['infrastructure.links'].map((link) => {
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                type={link['infrastructure_links.type']}
                href={link['infrastructure_links.link_data']}
              >
                {link['infrastructure_links.link_text']}
              </a>
            );
          })
        }
      </div>
    </div>
  );
};
