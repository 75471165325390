import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getHouses } from 'src/store/crosstable/selectors';
import { IHouses, IHouse } from 'src/store/crosstable/reducer';
import { Actions } from 'src/store/crosstable/actions';
import { useGetAuthData } from 'src/hooks';

import { getHouseById, getHousesByComplex } from 'src/api';
import { useQuery } from '@tanstack/react-query';

export const useGetHouses = (id: string|undefined) => {
  const [currentHouses, setCurrentHouses] = useState<IHouse[]|[]>([]);
  const savedHouses: IHouses|null = useSelector(getHouses);
  const dispatch = useDispatch();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  useEffect(() => {
    let cleanupFunction = false;
    if (id && (!savedHouses || Number(id) !== Number(savedHouses.complexId))) {
      getHousesByComplex(jwt, id)
        .then((res: any) => {
          if (!cleanupFunction) {
            setCurrentHouses(res.data['hydra:member']);
            dispatch(Actions.setHouses({
              complexId: id,
              data: res.data['hydra:member'],
            }));
          }
        });
    } else if (savedHouses) {
      setCurrentHouses(savedHouses.data);
    }

    return () => {
      cleanupFunction = true;
    };
  }, [jwt, id]);

  return currentHouses;
};

export function useGetHouseById(id: string|undefined) {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  return useQuery({
    queryKey: ['house'],
    queryFn: async (): Promise<IHouse|null> => {
      if (!id) return null;
      const { data } = await getHouseById(jwt, id);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
}
