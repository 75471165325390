import { ActionTypes } from 'src/store/filter/filter-params/actions';
import { countShowPropertyOnPage } from 'src/constants';
import { IFirstSpinner } from 'src/typings/spinner';
import { IAction } from 'src/typings/index';

export interface IFilterParamsReducer {
  [key: string]: any;
  countShowDesktopResult: number;
  countShowMobileResult: number;
  scrollPosition: number | null;
  leavedSlide: number | null;
}

export interface ISetFilterParameter {
  typeParam: string;
  data: any;
  isDisabled: boolean;
}

export interface ISetFilterParameterInfrastructure extends ISetFilterParameter {
  currentSpinner: IFirstSpinner;
  ids: string[];
}

const additionalFilterParams = {
  countShowDesktopResult: countShowPropertyOnPage,
  countShowMobileResult: countShowPropertyOnPage,
  scrollPosition: null,
  leavedSlide: null,
};

export const initialState: IFilterParamsReducer = {
  ...additionalFilterParams,
};

export const filterParamsReducer = (
  state = initialState,
  { type, payload }: IAction,
): IFilterParamsReducer => {
  switch (type) {
    case ActionTypes.SET_INITIAL_FILTER_PARAMETER: {
      return {
        ...state,
        ...payload as any,
      };
    }

    case ActionTypes.SET_FILTER_PARAMETER: {
      if (!payload) {
        return {
          ...state,
        };
      }

      // @ts-ignore
      const payloadConvert: ISetFilterParameter = payload;

      return {
        ...state,
        [payloadConvert?.typeParam]: {
          ...state[payloadConvert?.typeParam],
          data: payloadConvert?.data,
          isDisabled: payloadConvert?.isDisabled,
        },
      };
    }

    case ActionTypes.SET_FILTER_DESKTOP_COUNT: {
      return {
        ...state,
        countShowDesktopResult: payload,
      };
    }

    case ActionTypes.SET_FILTER_MOBILE_COUNT: {
      return {
        ...state,
        countShowMobileResult: payload,
      };
    }

    case ActionTypes.SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: payload,
      };
    }

    case ActionTypes.SET_LEAVED_SLIDE: {
      return {
        ...state,
        leavedSlide: payload,
      };
    }

    case ActionTypes.CLEAR_FILTER_PARAMETER: {
      return {
        ...initialState,
        ...payload,
      };
    }

    default:
      return state;
  }
};
