import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';

import { IOSSwitch } from 'src/components/SwitchIos';
import { useShowInfrastructure } from 'src/hooks/app';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { useIsInfrastructure } from 'src/hooks/infrastructure';
import { useStyles } from './styles';

export const ShowInfrastructure: React.FC = () => {
  const styles = useStyles({});
  const {
    showInfrastructure,
    infrastructureList,
    handleChangeShowInfrastructure,
  } = useShowInfrastructure();
  const { isInfrastructure } = useIsInfrastructure();
  const { getTranslations } = useTranslations();

  if (
    infrastructureList.length === 0
    || isInfrastructure
  ) return null;

  return (
    <div className={styles.infrastructureWrapper}>
      <FormControlLabel
        style={{ marginRight: '0px' }}
        control={(
          <IOSSwitch
            checked={showInfrastructure}
            onChange={handleChangeShowInfrastructure}
            name="checkedInfrastructure"
          />
        )}
        label={(
          <div
            className={styles.wrapperLabelStyle}
          >
            <span className={styles.labelStyle}>{getTranslations(locals.appTranslations.appShowInfrastructure)}</span>
          </div>
        )}
        labelPlacement="start"
      />
    </div>
  );
};
