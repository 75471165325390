import { useSelector } from 'react-redux';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Favicon from 'react-favicon';
import React from 'react';
// import 'semantic-ui-css/semantic.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { theme } from 'src/constants';
import { AppRoute } from 'src/route';
import { requestSelectors } from 'src/store/requests/selectors';
import { appIcon } from 'src/assets';
import { useBuildType } from './hooks';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const AppTheme: React.FC = () => {
  const { isCrossTable } = useBuildType();
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const globalPolygonConfig = useSelector(requestSelectors.app.getPolygonConfig);
  const favicon = useSelector(requestSelectors.app.getFavicon);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(mainColor, globalPolygonConfig, isCrossTable)}>
        <Favicon url={favicon || appIcon.favicon} />
        <CssBaseline />
        <AppRoute />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
