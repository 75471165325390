import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IComplex } from 'src/typings/complexes';
import { getComplex } from 'src/store/crosstable/selectors';
import { Actions } from 'src/store/crosstable/actions';
import { useGetAuthData } from 'src/hooks';

import { getComplexData } from 'src/api';

export const useGetComplex = (id: string|undefined) => {
  const [currentComplex, setCurrentComplex] = useState<IComplex|null>(null);
  const savedComplex: IComplex|null = useSelector(getComplex);
  const dispatch = useDispatch();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  useEffect(() => {
    let cleanupFunction = false;
    if (id && (!savedComplex || Number(id) !== Number(savedComplex.id))) {
      getComplexData(jwt, id)
        .then((res: any) => {
          if (!cleanupFunction) {
            setCurrentComplex(res.data);
            dispatch(Actions.setComplex(res.data));
          }
        });
    } else if (savedComplex) {
      setCurrentComplex(savedComplex);
    }

    return () => {
      cleanupFunction = true;
    };
  }, [jwt, id]);

  return currentComplex;
};
