import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  dropItem: {
    transition: '0.3s all',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(158, 158, 158, 0.04)',
    },
  },
  applyBtn: {
    padding: '12px 30px',
    color: 'white',
  },
}));
