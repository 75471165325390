import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { ButtonApp } from 'src/components/Button';
import { useTranslations } from 'src/hooks/translataions';
import { translations } from 'src/constants/translations';
import { useSetDocumentTitle } from 'src/hooks';
// import { locals } from 'src/constants/locals';
import { useRecoveryForms } from './requests';
import { useStyles } from './styles';
import { IRecovForm } from './types';

const schemas = [
  yup.object().shape({
    email: yup.string().email(translations.errorInvalidEmail).required(translations.errorRequired),
  }),
  yup.object().shape({
    code: yup.string().required(translations.errorRequired),
  }),
  yup.object().shape({
    password: yup.string()
      .required(translations.errorRequired)
      .min(8, translations.errorMin8)
      .matches(/[a-zA-Z]/, translations.errorOnlyLatin),
    passwordConfirmation: yup.string()
      .oneOf([yup.ref('password'), null], translations.errorMatch),
  }),
];

export const RecoveryForm: React.FC<IRecovForm> = ({ moveToLogin }) => {
  const styles = useStyles({});
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [step, setStep] = useState(0);
  const [timer, setTimer] = useState(50);
  const {
    isSending,
    isError,
    cleanError,
    handleSendEmail,
    handleSendCode,
    handleSendPass,
  } = useRecoveryForms(setStep);
  const {
    register, handleSubmit, errors, formState,
  } = useForm<any>({
    resolver: yupResolver(schemas[step]),
    mode: 'onBlur',
  });
  const { getTranslations } = useTranslations();
  useSetDocumentTitle(getTranslations(translations.restorePass));

  const runTimer = () => {
    let tempTimer = 50;
    const interval = setInterval(() => {
      if (tempTimer > 0) {
        setTimer(tempTimer - 1);
        tempTimer -= 1;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };
  const onSubmit = (data: any) => {
    if (step === 0) {
      setEmail(data.email);
      handleSendEmail(data);
      runTimer();
    } else if (step === 1) {
      handleSendCode(data.code);
      setCode(data.code);
    } else if (step === 2) {
      handleSendPass(data.password, code);
    }
  };

  const repeatSendTokenToEmail = () => {
    if (timer <= 0) {
      handleSendEmail({ email });
      runTimer();
    }
  };

  return (
    <form
      className={styles.wrapper}
      onSubmit={handleSubmit(onSubmit)}
      key={`step${step}`}
    >
      {
          (step === 0
            && (
              <Box mb={6}>
                <Typography variant="h4" component="h1">
                  {translations.restorePass}
                </Typography>
              </Box>
            ))
          || (step === 1
            && (
              <>
                <Box mb={2}>
                  <Typography variant="h4" component="h1">
                    {translations.varifyEmail}
                  </Typography>
                </Box>
                <Box fontSize={17} mb={4}>
                  {`${translations.confirmationCodeSentTo} ${email}`}
                </Box>
              </>
            ))
          || (step === 2
            && (
              <Box mb={6}>
                <Typography variant="h4" component="h1">
                  {translations.changePass}
                </Typography>
              </Box>
            ))
          }

      {
        (step === 0
          && (
          <Box mb={4} position="relative">
            <Box fontSize={18} mb={2}>{translations.email}</Box>
            <TextField
              className={styles.input}
              variant="outlined"
              name="email"
              inputRef={register}
            />
            {errors.email && (
              <Box className={styles.error}>
                {errors.email.message}
              </Box>
            )}
          </Box>
          ))
        || (step === 1
          && (
            <Box mb={4} position="relative">
              <Box fontSize={18} mb={2}>{translations.code}</Box>
              <TextField
                className={styles.input}
                variant="outlined"
                name="code"
                inputRef={register}
              />
              {errors.code && (
              <Box className={styles.error}>
                {errors.code.message}
              </Box>
              )}
            </Box>
          ))
        || (step === 2
          && (
            <>
              <Box mb={4} position="relative">
                <Box fontSize={18} mb={2}>{translations.newPass}</Box>
                <TextField
                  className={styles.input}
                  variant="outlined"
                  name="password"
                  type="password"
                  inputRef={register}
                />
                {errors.password && (
                <Box className={styles.error}>
                  {errors.password.message}
                </Box>
                )}
              </Box>
              <Box mb={4} position="relative">
                <Box fontSize={18} mb={2}>{translations.repeatNewPass}</Box>
                <TextField
                  className={styles.input}
                  variant="outlined"
                  type="password"
                  name="passwordConfirmation"
                  inputRef={register}
                />
                {errors.passwordConfirmation && (
                <Box className={styles.error}>
                  {errors.passwordConfirmation.message}
                </Box>
                )}
              </Box>
            </>
          ))
        }

      <Box mb={3}>
        <ButtonApp
          text={
          (isSending
            && <CircularProgress style={{ color: 'white' }} size="24px" />
          ) || (step === 0
              && translations.sendCode)
          || (step === 1
            && translations.resetPass)
          || (step === 2
            && translations.changePass)
          }
          disabled={!formState.isValid}
          variant="contained"
          className={styles.submit}
          type="submit"
        />
      </Box>
      <Box display="flex" justifyContent="center">
        {
            (step === 0
              && (
                <>
                  {translations.regFooter}
                  <Box
                    className={styles.link}
                    display="inline-block"
                    ml={2}
                    onClick={moveToLogin}
                  >
                    {translations.logSubmit}
                  </Box>
                </>
              ))
          || (step === 1
            && (
              <>
                <Box
                  className={`${styles.link} ${timer > 0 && styles.linkDisabled}`}
                  display="inline-block"
                  ml={2}
                  mr={1}
                  onClick={repeatSendTokenToEmail}
                >
                  {translations.resendCode}
                </Box>
                {timer > 0 && ` in ${timer}`}
              </>
            ))
        }
      </Box>
      <Snackbar open={!!isError} autoHideDuration={6000} onClose={cleanError}>
        <Alert onClose={cleanError} elevation={6} variant="filled" severity="error">
          {isError}
        </Alert>
      </Snackbar>
    </form>
  );
};
