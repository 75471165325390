import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  crossTableContent: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    overflowX: 'auto',
    flex: 1,
    // scrollbarWidth: 'none',
    // '&::-webkit-scrollbar': {
    //   width: '0px',
    //   height: '0px',
    // },
    // '&.appRooms': {
    //   '& span.appRooms': {
    //     display: 'inline-block',
    //   },
    // },
    // '&.appArea': {
    //   '& span.appArea': {
    //     display: 'inline-block',
    //   },
    // },
  },

}));
