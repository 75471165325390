import { Provider } from 'react-redux';
import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import 'semantic-ui-css/semantic.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { store } from 'src/store';
import { AppTheme } from 'src/App-theme';

const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppTheme />
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </Provider>
  );
};
