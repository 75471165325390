/* eslint-disable no-unused-expressions */
import { useCallback, useEffect, useState } from 'react';
import {
  ISpinnerDataScheme,
  ISpinnerDataPossibilityInnerData,
} from 'src/typings/spinner';
import { useGetPlacementDataById } from 'src/hooks/filters';
import { ISpinnerElements, ISpinnerState } from '../types';

export const useInitSpinner = (
  displayIds: string[] | undefined,
  spinnerData: ISpinnerDataPossibilityInnerData,
  randomSpinnerId: string,
  hideWindRose: boolean,
  type: string | undefined,
  initWindRoseData: Function | null,
) => {
  const { placementData } = useGetPlacementDataById(displayIds || ['0']);
  const { devicePixelRatio } = window;
  const [listOfStops, setListOfStops] = useState<any[]>([]);
  const [spinner, setSpinner] = useState<any>();
  const [spinnerState, setSpinnerState] = useState<ISpinnerState>({
    stopFrame: '0',
    dw: 0,
    dh: 0,
    dx: 0,
    dy: 0,
  });
  const [svgSize, setSvgSize] = useState<{w: number, h: number}>({ w: 0, h: 0 });

  const [spinnerElements, setSpinnerElements] = useState<ISpinnerElements>({
    spinnerCanvas: null,
    spinnerPlace: null,
    spinnerControl: null,
    progressBar: null,
    turnLeft: null,
    turnRight: null,
    spinnerZoom: null,
    spinnerUnZoom: null,
    windRose: null,
  });

  const handleSetStopFrame = useCallback((isHide?: boolean) => {
    if (isHide) {
      setSpinnerState({
        ...spinnerState,
        stopFrame: '-1',
      });
    }
  }, [spinnerState]);
  const handleResizeForSvg = useCallback(() => {
    if (
      spinnerState.dh !== spinner?._size?.dh
      || spinnerState.dw !== spinner?._size?.dw
      || spinnerState.dx !== spinner?._size?.dx
      || spinnerState.dy !== spinner?._size?.dy
      || spinnerState.stopFrame !== String(spinner._frames.i)
    ) {
      setSpinnerState({
        stopFrame: spinner?._frames?.i || '0',
        dw: spinner?._size?.dw || 0,
        dh: spinner?._size?.dh || 0,
        dx: spinner?._size?.dx || 0,
        dy: spinner?._size?.dy || 0,
      });
    }
  }, [spinner]);

  const detectBestStopFrame = useCallback(() => {
    const stops: any = {};
    const spinnerAdditionalId = spinnerData.additional_data.id;
    placementData?.forEach((item) => {
      const placementBestSpinnerStops = item?.['placement.best_spinner_stops']
        ?.filter((el) => el['entity-view-name-id'] === spinnerAdditionalId);
      if (Object.keys(stops).includes(placementBestSpinnerStops?.[0]?.stopNumber)) {
        stops[placementBestSpinnerStops?.[0]?.stopNumber] += 1;
      } else {
        stops[placementBestSpinnerStops?.[0]?.stopNumber] = 1;
      }
    });
    const res = Object.keys(stops).reduce((acc, item) => {
      if (stops[item] > stops[acc] || !acc) {
        return item;
      }
      return acc;
    }, '');
    return res;
  }, [placementData, spinnerData]);

  const handleOnProgressFSSpinner = (e: any) => {
    if (spinnerElements.progressBar) {
      // @ts-ignore
      spinnerElements.progressBar.style.width = `${window.Spinner.map(e.loaded, 0, e.total)}%`;
    }
  };

  const handleOnLoadEndFSSpinner = () => {
    if (spinnerElements.progressBar) {
      spinnerElements.progressBar.style.width = '0';
    }
  };

  function control(this: any, e: any) {
    if (this.classList.contains('disabled')) return;

    if (this === spinnerElements.turnLeft) spinner.turn(-1);
    else if (this === spinnerElements.turnRight) spinner.turn(1);
    else if (spinnerElements.spinnerCanvas) {
      if (this === spinnerElements.spinnerZoom) spinner.zoomFrame(0.1);
      else spinner.zoomFrame(-0.1);

      handleResizeForSvg();
    }
  }

  const windRoseRotate = useCallback(function (this: any) {
    const windRoseImg = spinnerElements.windRose?.querySelector('div');

    if (initWindRoseData) {
      const windRoseData = initWindRoseData();
      if (windRoseImg && windRoseData) {
        // @ts-ignore
        windRoseImg.style.transform = `rotateZ(${windRoseData.degree + window.Spinner.map(this._frames.i, 0, this._frames.length, 0, 359)}deg)`;
      }
    }
  }, [spinner, initWindRoseData]);

  const canvasResize = () => {
    if (spinnerElements.spinnerCanvas && spinnerElements.spinnerPlace) {
      spinnerElements.spinnerCanvas.style.width = `${spinnerElements.spinnerPlace.clientWidth}px`;
      spinnerElements.spinnerCanvas.style.height = `${spinnerElements.spinnerPlace.clientHeight}px`;

      spinner.resize(true);
    }
  };

  const handleWindowResize = () => {
    canvasResize();
    handleResizeForSvg();
  };

  useEffect(() => {
    if (spinnerElements.spinnerCanvas === null) {
      setSpinnerElements({
        spinnerCanvas: document.querySelector(`#spinner_app_canvas${randomSpinnerId}`),
        spinnerPlace: document.querySelector(`#spinner_place${randomSpinnerId}`),
        spinnerControl: document.querySelector(`#spinner_control${randomSpinnerId}`),
        progressBar: document.querySelector(`#porgress_bar${randomSpinnerId}`),
        turnLeft: document.querySelector(`#turn_left${randomSpinnerId}`),
        turnRight: document.querySelector(`#turn_right${randomSpinnerId}`),
        spinnerZoom: document.querySelector(`#spinner_zoom${randomSpinnerId}`),
        spinnerUnZoom: document.querySelector(`#spinner_unzoom${randomSpinnerId}`),
        windRose: document.querySelector(`#wind_rose${randomSpinnerId}`),
      });
    }
  }, [spinnerElements, randomSpinnerId]);

  useEffect(() => {
    if (spinnerData && spinnerElements.spinnerCanvas) {
      // @ts-ignore
      const currentSpinnerInit = new window.Spinner(
        spinnerElements.spinnerCanvas,
        {
          stops: spinnerData.stops,
          maxZoom: 5,
          turnSpeed: 30,
          ...spinnerData,
          count: spinnerData.count,
        },
      );
      if (spinner) {
        spinner.dispose();
      }

      setSpinner(currentSpinnerInit);
      // @ts-ignore
      document[randomSpinnerId] = currentSpinnerInit;

      setListOfStops(spinnerData.stops || []);
    }
  }, [spinnerElements, spinnerData, randomSpinnerId]);

  useEffect(() => {
    if (spinner && placementData?.length) {
      const placementStopFrame = detectBestStopFrame();
      if (placementStopFrame !== undefined && placementStopFrame !== 'undefined') {
        spinner.goto(placementStopFrame);
      }
    }
  }, [placementData, spinner]);

  useEffect(() => {
    if (spinner) {
      let mx: any;
      let my: any;
      let touchstart = false;
      let lastZoom = 1;

      const {
        spinnerCanvas,
        spinnerPlace,
        spinnerControl,
        turnLeft,
        turnRight,
        spinnerZoom,
        spinnerUnZoom,
        windRose,
      } = spinnerElements;

      const touchend = () => {
        touchstart = false;
        lastZoom = 1;
      };

      canvasResize();
      window.addEventListener('resize', handleWindowResize);
      spinner.addEventListener('loadfirst', () => {
        if (spinnerZoom) spinnerZoom.classList.remove('disabled');

        canvasResize();
      });

      spinner.addEventListener('rotateready', () => {
        handleResizeForSvg();
      });

      spinner.on('framezoom', function (this: any) {
        // eslint-disable-next-line no-unused-expressions
        if (this._size.zoom >= this.maxZoom) spinnerZoom?.classList.add('disabled');
        // eslint-disable-next-line no-unused-expressions
        else spinnerZoom?.classList.remove('disabled');

        // eslint-disable-next-line no-unused-expressions
        if (this._size.zoom <= 1) spinnerUnZoom?.classList.add('disabled');
        // eslint-disable-next-line no-unused-expressions
        else spinnerUnZoom?.classList.remove('disabled');
      });

      if (spinnerCanvas) {
        spinnerCanvas.addEventListener('mousemove', (e) => {
          if (e.buttons !== 1) return;

          spinner.moveFrame(e.movementX, e.movementY);
          handleResizeForSvg();
        });
      }

      if (turnLeft) { turnLeft.addEventListener('click', control); }
      if (turnRight) { turnRight.addEventListener('click', control); }
      if (spinnerZoom) { spinnerZoom.addEventListener('click', control); }
      if (spinnerUnZoom) { spinnerUnZoom.addEventListener('click', control); }

      // document.addEventListener('keydown', ({ key }: any) => handleKeyRotate(key));

      if (spinnerPlace) {
        spinnerPlace.style.touchAction = 'none';

        spinnerPlace.addEventListener('touchend', touchend);
        spinnerPlace.addEventListener('touchleave', touchend);

        spinnerPlace.addEventListener('touchstart', (e) => {
          if (touchstart) return;

          mx = e.touches[0].pageX;
          my = e.touches[0].pageY;

          touchstart = true;
        });

        spinnerPlace.addEventListener('touchmove', (e) => {
          if (!touchstart) return;

          const x = e.touches[0].pageX;
          const y = e.touches[0].pageY;

          spinner.moveFrame(x - mx, y - my);

          mx = x;
          my = y;

          // @ts-ignore
          spinner.zoomFrame((e.scale - lastZoom));
          // @ts-ignore
          lastZoom = e.scale;

          handleResizeForSvg();
        });
      }

      spinner.addEventListener('progress', handleOnProgressFSSpinner);
      spinner.addEventListener('loadend', handleOnLoadEndFSSpinner);
      spinner.addEventListener('loadfirst', (e: { img: { w: number, h: number } }) => {
        if (spinnerControl) {
          spinnerControl.classList.remove('hide');
        }
        // @ts-ignore
        if (!hideWindRose) {
          windRoseRotate.call(spinner);
          // @ts-ignores
          if (windRose) {
            windRose.classList.remove('hide');
          }
        }
        handleSetStopFrame();
        setSvgSize({ w: e.img.w, h: e.img.h });
      });
      if (!hideWindRose) {
        spinner.addEventListener('framechange', windRoseRotate);
      }

      // SVG
      spinner.addEventListener('rotationstart', () => {
        handleSetStopFrame(true);
      });
      spinner.addEventListener('rotationend', () => {
        handleResizeForSvg();
      });
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      if (spinner) {
        spinner.dispose && spinner.dispose();
        // @ts-ignore
        document[randomSpinnerId] = null;
        const {
          spinnerCanvas,
          spinnerPlace,
          spinnerControl,
          turnLeft,
          turnRight,
          spinnerZoom,
          spinnerUnZoom,
        } = spinnerElements;
        if (turnLeft) { turnLeft.removeEventListener('click', control); }
        if (turnRight) { turnRight.removeEventListener('click', control); }
        if (spinnerZoom) { spinnerZoom.removeEventListener('click', control); }
        if (spinnerUnZoom) { spinnerUnZoom.removeEventListener('click', control); }
      }
    };
  }, [spinner]);

  const fetchConfig = async () => {
    if (spinnerData) {
      const placementStopFrame = detectBestStopFrame();
      const startFrame = spinnerData.stops?.includes(parseInt(placementStopFrame, 10)) ? placementStopFrame : 0;
      const tempData = { ...spinnerData };
      tempData.startFrame = Number(startFrame);
      // @ts-ignore
      const config = await window.Spinner.margeConfig(tempData, type, spinnerData.additional_data.id);

      if (config && spinner && initWindRoseData) {
        spinner.setData(config);
        initWindRoseData(config._windRose);
      }
    }
  };

  useEffect(() => {
    fetchConfig();
  }, [spinner, spinnerData]);

  return {
    listOfStops,
    svgSize,
    devicePixelRatio,
    spinnerState,
  };
};
