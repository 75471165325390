import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import { IOSSwitch } from 'src/components/SwitchIos';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { AppBreadcrumbs } from 'src/components/AppBreadcrumbs';
import { useStyles } from './styles';
import {
  useGetInitialData,
  useShowDifferences,
} from './hooks';
import { CompareItems } from './components';

export const CompareList: React.FC = () => {
  const styles = useStyles({});
  const {
    removeItemFromCompare,
    listOfComparePlacement,
  } = useGetInitialData();
  const {
    listOfComparePlacementFiltered,
    showDifferences,
    handleToggleShowDifferences,
  } = useShowDifferences(listOfComparePlacement);
  const { getTranslations } = useTranslations();

  const renderButtonShowDiff = () => {
    return (
      <FormControlLabel
        style={{ marginRight: '0px' }}
        control={<IOSSwitch checked={showDifferences} onChange={handleToggleShowDifferences} name="checkedB" />}
        label={getTranslations(locals.appTranslations.appOnlyDifferences)}
        labelPlacement="start"
      />
    );
  };

  return (
    <div>
      <div className={styles.headerBlock}>
        <AppBreadcrumbs />
        <h2 className={styles.titleBlock}>{getTranslations(locals.appTranslations.appCompareApartments)}</h2>
      </div>
      {listOfComparePlacementFiltered.length >= 2 && (
        <div className={styles.mobileShowDiff}>
          {renderButtonShowDiff()}
        </div>
      )}
      <div className={styles.wrapperOutputCompareData}>
        {
          listOfComparePlacementFiltered.length >= 2
            ? (
              <CompareItems
                renderButtonShowDiff={renderButtonShowDiff}
                placements={listOfComparePlacementFiltered}
                removeItemFromCompare={removeItemFromCompare}
              />
            ) : <p>{getTranslations(locals.appTranslations.appEmptyCompares)}</p>
        }
      </div>
    </div>
  );
};
