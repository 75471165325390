import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  circ: {
    opacity: 0,
    stroke: theme.palette.primary.main,
    'stroke-dasharray': 130,
    'stroke-dashoffset': 130,
    '-webkit-transition': 'all 1s',
    '-moz-transition': 'all 1s',
    '-ms-transition': 'all 1s',
    '-o-transition': 'all 1s',
    transition: 'all 1s',
  },
  tick: {
    stroke: theme.palette.primary.main,
    'stroke-dasharray': 50,
    'stroke-dashoffset': 50,
    '-webkit-transition': 'stroke-dashoffset 1s 0.5s ease-out',
    '-moz-transition': 'stroke-dashoffset 1s 0.5s ease-out',
    '-ms-transition': 'stroke-dashoffset 1s 0.5s ease-out',
    '-o-transition': 'stroke-dashoffset 1s 0.5s ease-out',
    transition: 'stroke-dashoffset 1s 0.5s ease-out',
  },
  drawn: {
    '& svg .path': {
      opacity: 1,
      'stroke-dashoffset': 0,
    },
  },
}));
