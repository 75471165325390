import React, { useState } from 'react';

import {
  useIsMobile,
  useOpenFilter,
  useTypeDisplayedSpinner,
} from 'src/hooks';
import { AppHeader } from 'src/components/AppHeader';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { useStyles } from './styles-spinner';
import {
  TypeDisplayedSpinner,
  SpinnerTypesRender,
  SpinnerFullSize,
} from './components';

export const FilterMapSpinner: React.FC = () => {
  const styles = useStyles({});
  const { isOpen } = useOpenFilter();
  const { isMobileScreen } = useIsMobile();
  const { getSelectedViewId } = useTypeDisplayedSpinner();
  const isFloors = TypesOfSpinner.viewFloors === getSelectedViewId;
  const [isFullSizeFloorSpinner, setFullSizeFloorSpinner] = useState(false);

  return (
    <div
      className={`${styles.mapWrapper} ${
        isOpen ? styles.filterOpen : styles.filterClose} ${
        (isFloors && !isFullSizeFloorSpinner) ? styles.floorWrapper : ''}`}
    >
      {isMobileScreen && <AppHeader wrapperStyles={styles.mapHeader} />}
      {!isMobileScreen && (
        <>
          <div className={styles.wrapperDesktopTypeSelector}>
            <div className={styles.typesSelectorView}>
              <TypeDisplayedSpinner isActive />
            </div>
          </div>
          <div className={`${styles.fullscreenView} ${!isOpen && styles.fullscreenViewClose}`}>
            <SpinnerFullSize />
          </div>
        </>
      )}
      <SpinnerTypesRender setFullSizeFloor={setFullSizeFloorSpinner} />
    </div>
  );
};
