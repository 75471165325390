import { useSelector } from 'react-redux';

import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { getInfrastructureFilters } from 'src/store/infrastructure/selectors';
import { IInfrastructureReducer } from 'src/store/infrastructure/reducer';
import { IFirstSpinner } from 'src/typings/spinner';

export const useSelectorsInfrastructure = () => {
  const currentSpinner: IFirstSpinner | null = useSelector(getCurrentSpinner);
  const filterInfrastructureData: IInfrastructureReducer = useSelector(getInfrastructureFilters);

  return {
    filterInfrastructureData:
      currentSpinner
      && filterInfrastructureData
      && filterInfrastructureData[currentSpinner.name]?.[currentSpinner['name-id']],
  };
};
