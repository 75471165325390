import { useCallback, useState } from 'react';

export const useBookNowState = () => {
  const [isOpen, setOpen] = useState(false);

  const handleToggleState = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return {
    isOpen,
    handleToggleState,
  };
};
