import { useGesture } from '@use-gesture/react';
import {
  RefObject,
  useCallback, useEffect,
} from 'react';

export const useFloorScrollbar = (refScrollBar: RefObject<HTMLDivElement>) => {
  const handleScroll = useCallback((first, axis, direction, distance) => {
    if (refScrollBar.current) {
      if (axis === 'y') {
        if (direction[1] === 1) {
          refScrollBar.current.scroll(0, refScrollBar.current.scrollTop - distance[1] / 7);
        } else if (direction[1] === -1) {
          refScrollBar.current.scroll(0, refScrollBar.current.scrollTop + distance[1] / 7);
        }
      }
    }
  }, [refScrollBar.current]);

  const handleBindGesture = useGesture({
    onDrag: ({
      first, axis, direction, distance,
    }) => handleScroll(first, axis, direction, distance),
    onWheel: ({
      first, axis, direction, distance,
    }) => handleScroll(first, axis, direction, distance),
  });

  const handleScrollToActiveElement = useCallback(() => {
    setTimeout(() => {
      const activeFloor = document.querySelector('div[class*="activeFloor"]');

      if (activeFloor) {
        // @ts-ignore
        activeFloor.scrollIntoViewIfNeeded({ block: 'center', behavior: 'smooth' });
      }
    }, 100);
  }, []);

  useEffect(() => {
    handleScrollToActiveElement();
  }, [handleScrollToActiveElement]);

  return {
    handleBindGesture,
    handleScrollToActiveElement,
  };
};
