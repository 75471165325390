import React from 'react';

import { useStyles } from './styles';

export const ViewInMap: React.FC = () => {
  const style = useStyles({});

  return (
    <div className={style.containerWrapper}>
      View in map
    </div>
  );
};
