import { Dropdown, StrictDropdownProps } from 'semantic-ui-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { useStyles } from './styles';

interface IDropdownMultiCheckBox extends StrictDropdownProps {}

export const DropdownMultiCheckBox: React.FC<IDropdownMultiCheckBox> = ({
  children,
  ...props
}) => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);
  return (
    <Dropdown
      clearable
      pointing="top"
      upward={false}
      {...props}
      // eslint-disable-next-line react/destructuring-assignment
      className={`${props.className} ${styles.styleOfDropdown}`}
    >
      {children}
    </Dropdown>
  );
};
