import { useEffect, useState } from 'react';
import { usePanoramaData } from 'src/hooks/spinner';

export const useInitPanorama = (
  randomPanoramaId: string,
  srcId: any,
  panoramaName: string = 'Panorama',
) => {
  const [panorama, setPanorama] = useState<any>(null);
  const { panoramaListData } = usePanoramaData();
  const [isLoaded, setLoaded] = useState<boolean>(true);

  useEffect(() => {
    if (!panorama && panoramaListData) {
      const panoramaId = document.querySelector(`#${randomPanoramaId}`);
      const { complexId } = window;
      const {
        config,
      } = panoramaListData[srcId];
      const configPanorama = {
        config,
        id: srcId,
        complex_id: complexId,
      };

      if (panoramaId) {
        // @ts-ignore
        const initPanorama = new window.Panorama(panoramaId, configPanorama);

        setPanorama(initPanorama);
      }
    }
  }, [panorama, randomPanoramaId, srcId, panoramaName, panoramaListData]);

  useEffect(() => {
    if (panorama !== null) {
      window.addEventListener('resize', () => {
        panorama.resize();
      });
      panorama.on('loadend', () => setLoaded(false));

      return () => {
        window.removeEventListener('resize', panorama.resize);
      };
    }
    return () => {};
  }, [panorama]);

  return {
    isLoaded,
  };
};
