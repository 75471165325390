import React from 'react';

import { Loader } from 'src/components/Loader';
import { useCheckInitialLoadDataError } from 'src/hooks';
import { useStyles } from './styles';

export const GlobalDataLoader: React.FC = () => {
  const styles = useStyles({});
  const { appDataErrors, typeFilterDataErrors } = useCheckInitialLoadDataError();

  return (
    <div className={styles.wrapperGlobalLoader}>
      {
        appDataErrors || typeFilterDataErrors
          ? <p>{appDataErrors || typeFilterDataErrors}</p>
          : <Loader />
      }
    </div>
  );
};
