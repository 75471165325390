import { useEffect, useState } from 'react';

import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { IFillFloorsData } from '../types';

export const useSectionGenerator = (
  floorList: string[],
  sectionData: IFilterAllData[],
) => {
  const [isSetNewData, setNewData] = useState<boolean>(false);
  const [fillFloors, setFillFloors] = useState<IFillFloorsData | null>(null);

  useEffect(() => {
    setFillFloors(floorList.reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: [],
      };
    }, {} as IFillFloorsData));
    setNewData(true);
  }, [floorList]);

  useEffect(() => {
    if (isSetNewData && sectionData) {
      const combineData: IFillFloorsData = sectionData.reduce((acc, cur) => {
        const data = { ...acc };
        if (cur[ListOfKeysFilterAllData.placementFloor]) {
          cur[ListOfKeysFilterAllData.placementFloor].forEach((floor, index) => {
            if (data[floor]) {
              if (index) {
                data[floor] = [...data[floor], null];
              } else {
                data[floor] = [...data[floor], cur];
              }
            }
          });
          // const firstFloor = cur[ListOfKeysFilterAllData.placementFloor][0];
          // data[firstFloor] = [...data[firstFloor], cur];
        }

        return data;
      }, fillFloors as IFillFloorsData);

      setFillFloors(combineData);
      setNewData(false);
    }
  }, [fillFloors, sectionData, isSetNewData]);

  return {
    fillFloors,
  };
};
