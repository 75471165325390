import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  headerTabStyle: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  tabStyle: {
    color: theme.palette.secondary.main,
    minWidth: 'auto',
    fontSize: '17px',
    textTransform: 'capitalize',
    padding: '0px',
    marginRight: '20px',
    '&[aria-selected="true"]': {
      color: '#000000',
      fontWeight: 'bold',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '20px',
    },
  },
  wrapperTabInfoData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #F2F2F2',
    padding: '10px 0px',
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    '&.note > span:last-child': {
      fontSize: '13px',
      fontWeight: 'normal',
    },
  },
  infoTitle: {
    color: theme.palette.secondary.main,
  },
  infoTextData: {
    fontWeight: 'bold',
    fontSize: '17px',
    marginLeft: '20px',
    textAlign: 'right',
  },
  explicationHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  explicationSequence: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '50%',
    marginRight: '10px',
  },
  pdfStyle: {
    '& > a': {
      padding: '0px 10px',
    },
  },
  pdfIcon: {
    height: '30px',
    width: '30px',
  },
  wrapperOrderList: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
