import { useCheckLoadedData } from './_use-check-loaded-data';
import { useCheckInitialLoadDataError } from './_use-check-initial-load-data-error';

export const useCheckInitialLoadData = () => {
  const {
    appDataErrors,
    typeFilterDataErrors,
    translationsRequestErrors,
    layoutsRequestErrors,
  } = useCheckInitialLoadDataError();
  const allLLoads = useCheckLoadedData();

  const {
    appDataIsLoading,
    typeFilterDataIsLoading,
    translationsDataIsLoading,
    layoutsDataIsLoading,
    spinnerDataIsLoading,
  } = allLLoads;

  return {
    isErrors: appDataErrors || typeFilterDataErrors || translationsRequestErrors || layoutsRequestErrors,
    isLoading: appDataIsLoading
    || typeFilterDataIsLoading
    || translationsDataIsLoading
    || layoutsDataIsLoading
    || spinnerDataIsLoading,
  };
};
