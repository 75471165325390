import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  infrastructureWrapper: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    bottom: '50px',
    right: '30px',
    marginRight: '0px',
    [theme.breakpoints.down('lg')]: {
      right: '5px',
    },
    [theme.breakpoints.down('md')]: {
      bottom: '4.5vw',
      right: '5px',
    },
  },
  wrapperLabelStyle: {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  labelStyle: {
    fontWeight: 'bold',
  },
}));
