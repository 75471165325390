import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBuildType } from 'src/hooks';
import { IEmbeddedJS } from 'src/typings/app';
import { requestSelectors } from 'src/store/requests/selectors';

export const useInitEmbeddedJS = () => {
  const embeddedJS: IEmbeddedJS[] | null | undefined = useSelector(requestSelectors.app.getEmbeddedJS);
  const { isCrossTable } = useBuildType();
  const innerScript = (obj: IEmbeddedJS) => {
    const nodeHead = document.querySelector('head');
    const nodeBody = document.querySelector('body');

    if (nodeHead && nodeBody) {
      const script = document.createElement('script');
      script.async = obj.load_type === 'async';
      script.defer = obj.load_type === 'defer';
      if (obj.embedding_type === 'text_script') {
        script.innerHTML = obj.script ? obj.script : '';
      } else if (obj.embedding_type === 'resource_link') {
        script.src = obj.src ? obj.src : '';
      }
      if (obj.html_placing === 'head') {
        nodeHead.appendChild(script);
      } else if (obj.html_placing === 'body_start') {
        nodeBody.prepend(script);
      } else if (obj.html_placing === 'body_end') {
        nodeBody.appendChild(script);
      }
    }
  };

  const handleOnFormSubmit = () => {
    if (embeddedJS) {
      embeddedJS.forEach((obj) => {
        if (obj.name === 'ON_FORM_SUBMITION') {
          innerScript(obj);
        }
      });
    }
  };

  useEffect(() => {
    if (embeddedJS && !isCrossTable) {
      embeddedJS.forEach((obj) => {
        if (obj.name !== 'ON_FORM_SUBMITION') {
          innerScript(obj);
        }
      });
    }
  }, [embeddedJS]);

  return {
    handleOnFormSubmit,
  };
};
