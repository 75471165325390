import { IFilterData } from 'src/typings/filter';
import { action } from 'src/store/common';

export const ActionTypes = {
  GET_LAYOUTS_DATA_REQUEST: '[REQUEST_APP]GET_LAYOUTS_DATA_REQUEST',
  GET_LAYOUTS_DATA_RESET: '[REQUEST_APP]GET_LAYOUTS_DATA_RESET',
  GET_LAYOUTS_DATA_REQUEST_SUCCESS: '[REQUEST_APP]GET_LAYOUTS_DATA_REQUEST_SUCCESS',
  GET_LAYOUTS_DATA_REQUEST_FAIL: '[REQUEST_APP]GET_LAYOUTS_DATA_REQUEST_FAIL',
};

export const Actions = {
  getLayoutsRequest: action(ActionTypes.GET_LAYOUTS_DATA_REQUEST),
  getLayoutsReset: action(ActionTypes.GET_LAYOUTS_DATA_RESET),
  getLayoutsSuccess: action<IFilterData>(ActionTypes.GET_LAYOUTS_DATA_REQUEST_SUCCESS),
  getLayoutsFail: action<Error>(ActionTypes.GET_LAYOUTS_DATA_REQUEST_FAIL),
};
