import { action } from 'src/store/common';
import { ISpinnerData } from 'src/typings/spinner';

export const ActionTypes = {
  SPINNER_DATA_EDIT_PLACEMENT: '[REQUEST_APP]SPINNER_DATA_EDIT_PLACEMENT',
  GET_SPINNER_DATA_REQUEST: '[REQUEST_APP]GET_SPINNER_DATA_REQUEST',
  GET_SPINNER_DATA_RESET: '[REQUEST_APP]GET_SPINNER_DATA_RESET',
  GET_SPINNER_DATA_REQUEST_SUCCESS: '[REQUEST_APP]GET_SPINNER_DATA_REQUEST_SUCCESS',
  GET_SPINNER_DATA_REQUEST_FAIL: '[REQUEST_APP]GET_SPINNER_DATA_REQUEST_FAIL',
};

export const Actions = {
  getSpinnerDataRequest: action(ActionTypes.GET_SPINNER_DATA_REQUEST),
  getSpinnerDataReset: action(ActionTypes.GET_SPINNER_DATA_RESET),
  getSpinnerDataSuccess: action<ISpinnerData>(ActionTypes.GET_SPINNER_DATA_REQUEST_SUCCESS),
  getSpinnerDataFail: action<Error>(ActionTypes.GET_SPINNER_DATA_REQUEST_FAIL),
  spinnerDataEditPlacement: action(ActionTypes.SPINNER_DATA_EDIT_PLACEMENT),
};
