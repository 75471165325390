import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  containerWrapper: {
    padding: '90px 30px 30px 30px',
    height: '100%',
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
      padding: '80px 20px 0px 20px',
    },
  },
  itemLayoutStyles: {
    border: '1px solid #d6d2d2',
    width: 'calc(33% - 10px)',
    [theme.breakpoints.down('lg')]: {
      width: 'calc(50% - 10px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  itemLayoutWrapperImageStyles: {
  },
  textNoData: {
    fontSize: '18px',
  },
}));
