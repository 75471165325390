/* eslint-disable no-lonely-if */
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { requestSelectors } from 'src/store/requests/selectors';
import { IAppMenuRequest } from 'src/typings/app';
import { useGetListOfPhones, useIsMobile } from 'src/hooks';

export const useLinkState = () => {
  const { isMobileScreen, isXlScreen } = useIsMobile();
  const { listOfPhones } = useGetListOfPhones();
  const listOfLinks: (IAppMenuRequest) [] = useSelector(requestSelectors.appMenu.getAppClientMenuData);
  const listOfLinksIsLoading: boolean = useSelector(requestSelectors.appMenu.getAppClientMenuDataLoading);
  const [countColumns, setCountColumns] = useState<undefined | string>(undefined);

  useEffect(() => {
    if (isMobileScreen) {
      setCountColumns('100%');
    } else {
      //
      if (listOfLinks.length === 1 || listOfLinks.length === 0) {
        setCountColumns(isXlScreen ? '30%' : undefined);
      } else if (listOfLinks.length === 2) {
        setCountColumns('50%');
      } else if (listOfLinks.length >= 3) {
        setCountColumns('100%');
      }
    }
  }, [isMobileScreen, isXlScreen, listOfLinks]);

  return {
    listOfLinksIsLoading,
    countColumns,
    listOfPhones,
    listOfLinks,
  };
};
