import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { fontBlackColor } from 'src/constants/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  topWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  userButton: {
    border: '1px solid #F2F2F2',
    borderRadius: '5px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    maxWidth: '170px',
    wordBreak: 'break-word',
  },
  complexButton: {
    color: fontBlackColor,
    fontSize: '12px',
    lineHeight: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  complexLogo: {
    maxHeight: '35px',
    display: 'block',
    marginLeft: '10px',
    maxWidth: '150px',
  },
  dropItem: {
    transition: '0.3s all',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(158, 158, 158, 0.04)',
    },
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    position: 'relative',
    overflow: 'hidden',
    marginRight: '10px',
    background: ' #F2F2F2',
  },
  avatarImg: {
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  arrowIcon: {
    margin: '0 15px',
    display: 'block',
    fontSize: '15px',
  },

}));
