import { ListOfDropdown } from 'src/typings/filter';

export const generateFilterInitialValue = (fieldType: ListOfDropdown) => {
  switch (fieldType) {
    case ListOfDropdown.dataList:
    case ListOfDropdown.dropdownSelectList:
      return '';
    case ListOfDropdown.dropdownCheckboxList:
    case ListOfDropdown.datalistCheckboxList:
    case ListOfDropdown.scaleRange:
      return [];
    default:
      return undefined;
  }
};
