import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  containerWrapper: (props: any) => ({
    padding: props.isCrossTable ? '25px 0px 0px 0px' : '115px 0px 0px 0px',
    height: '100%',
    overflowY: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
      padding: '20px 0px 0px 20px',
    },
  }),
  control2dView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '100%',
    padding: '30px',
    height: '115px',
    // [theme.breakpoints.down('md')]: {
    //   display: 'none',
    // },
    [theme.breakpoints.down('md')]: {
      marginBottom: '20px',
      padding: '0px 20px 0px 0px',
      position: 'relative',
      height: 'auto',
    },
  },
  mobileHeader: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginBottom: '20px',
      paddingRight: '20px',
    },
  },
}));
