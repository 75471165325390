import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  apartmentHeaderInfo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > div:not(:last-child)': {
      borderRight: '2px solid',
    },
    '& > div': {
      marginBottom: '5px',
      overflow: 'hidden',
      overflowWrap: 'break-word',
    },
  },
  placingInfo: {
    padding: '0px 5px',
    fontWeight: 'bold',
  },
}));
