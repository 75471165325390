import React from 'react';
import { Link } from 'react-router-dom';

import {
  AppBreadcrumbs,
  ButtonApp,
  LayoutApartmentItem,
} from 'src/components';
import { pathList } from 'src/route-list';
import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { useStyles } from './styles';
import { useFavoriteList } from './hooks';

export const FavoritesList: React.FC = () => {
  const styles = useStyles({});
  const {
    typeOfProperty,
    idsDisplayed,
    displayedFavoritePlacement,
  } = useFavoriteList();
  const { getTranslations } = useTranslations();

  return (
    <div className={styles.wrapperContainer}>
      <div className={styles.headerBlock}>
        <div className={styles.headerBlockFavCrumbs}>
          <AppBreadcrumbs />
          <h2 className={styles.titleBlock}>{getTranslations(locals.appTranslations.appFavorites)}</h2>
        </div>
        {idsDisplayed.length >= 2 && (
          <Link to={`${pathList.compare}?types=${typeOfProperty}&listIds=${idsDisplayed.join(',')}`}>
            <ButtonApp
              isCapitalize
              className={styles.compareButton}
              text={getTranslations(locals.appTranslations.appCompareApartments)}
              variant="contained"
            />
          </Link>
        )}
      </div>
      {idsDisplayed.length === 0 && (
        <p className={styles.emptyFavorites}>{getTranslations(locals.appTranslations.appEmptyFavorites)}</p>
      )}
      <div className={styles.wrapperFavoriteList}>
        {displayedFavoritePlacement.map((favItem) => {
          return (
            <LayoutApartmentItem
              key={favItem.id}
              withSizeLimits
              wrapperStyle={styles.favoritePlacement}
              wrapperImageStyle={styles.imageApartmentStyleXl}
              property={favItem}
            />
          );
        })}
      </div>
    </div>
  );
};
