import React from 'react';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { useStyles } from './styles';

interface ILoader {
  fullSize?: boolean;
}

export const Loader: React.FC<ILoader> = ({
  fullSize = false,
}) => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);

  return (
    <div className={`${fullSize && styles.fullSize}`}>
      <div className={`${styles.wrapperLoader}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.25 76.25">
          <defs>
            <linearGradient
              id="Градієнт_без_назви_10"
              x1="69.23"
              y1="60.51"
              x2="22.64"
              y2="33.61"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#fff" stopOpacity="0" />
              <stop offset="1" stopColor="#9e9e9e" />
            </linearGradient>
          </defs>
          <title>Flat show</title>
          <g id="Шар_2" data-name="Шар 2">
            <path
              className={styles['cls-1']}
              d="M76.25,38.12A38.08,38.08,0,0,1,46.84,75.25a38.51,38.51,0,0,1-8.71,1A38.13,38.13,0,1,1,76.25,38.12Z"
            />
            <path
              className={styles['cls-2']}
              d="M75.12,47.38A38.2,38.2,0,0,1,46.84,75.25L25.37,54l5.5-6.62-5.5-5.94,6.13-6.63-6.13-5.93,25.5-6.63Z"
            />
            <rect className={`${styles['cls-3']} ${styles['cls-3-1']}`} x="25.38" y="22.25" width="25.5" height="6.63" />
            <rect className={`${styles['cls-3']} ${styles['cls-3-2']}`} x="25.38" y="34.81" width="15.8" height="6.63" />
            <rect className={`${styles['cls-3']} ${styles['cls-3-3']}`} x="25.38" y="47.38" width="9.63" height="6.63" />
          </g>
        </svg>
      </div>
    </div>
  );
};

Loader.defaultProps = {
  fullSize: false,
};
