import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppHeader, AppHeaderCrosstable } from 'src/components';
import {
  useAppVisibilityBtn,
  useHideFilterZoneState,
  useOpenFilter,
  useBuildType,
} from 'src/hooks';
import { requestSelectors } from 'src/store/requests/selectors';
import { useIsInfrastructure } from 'src/hooks/infrastructure';
import { FilterTypeProperty } from 'src/components/FilterTypeProperty';
import { useSelectorsApartments } from 'src/components/FilterProperty/hooks';
import { ComponentMobileInfrastructure } from 'src/components/FilterProperty/components/FilterMobile/ComponentInfrastructure';
import { IFilterApartmentsDesktop } from './types';
import { useStyles } from './styles';
import { FilterPropertyPlacementsComponent } from './ComponentPlacements';

export const FilterDesktop: React.FC<IFilterApartmentsDesktop> = () => {
  const styles = useStyles({});
  const scrollPosition = useRef(null);
  const { isCrossTable } = useBuildType();
  const user = useSelector(requestSelectors.user.getUserData);
  const { isOpen } = useOpenFilter();
  const { isInfrastructure } = useIsInfrastructure();
  const { placementState } = useSelectorsApartments();
  const { isHideFilterZone } = useHideFilterZoneState();
  const { show_prefilter_placement_type_selector: showPrefilterPlacementTypeSelector } = useAppVisibilityBtn();

  if (isHideFilterZone) return null;

  return (
    <div
      className={`${styles.wrapperFilter} ${!isOpen ? styles.wrapperFilterClose : styles.wrapperFilterOpen}`}
      ref={scrollPosition}
    >
      <div className={styles.filterContent}>
        {isCrossTable ? <AppHeaderCrosstable user={user} /> : (
          <AppHeader wrapperStyles={styles.headerStyle} />
        )}
        {showPrefilterPlacementTypeSelector && (
          <div className={styles.countOfApartment}>
            {Boolean(placementState.typesPlacement.length) && (
              <FilterTypeProperty placementState={placementState} />
            )}
          </div>
        )}
        {
          isInfrastructure
            ? (
              <ComponentMobileInfrastructure
                handleModalOpen={() => null}
              />
            )
            : (
              <FilterPropertyPlacementsComponent
                scrollPosition={scrollPosition}
              />
            )
        }
      </div>
    </div>
  );
};
