import React, { useCallback } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { useFavoriteStorage, useTranslations } from 'src/hooks';
import { locals } from 'src/constants/locals';
import { useHeaderRoute } from 'src/components/AppHeader/useHeaderRoute';
import { WrapperMobileItem } from '../_WrapperMobileItem';
import { useStyles } from '../../styles';

interface IFavoritesBottom {
  isActive: boolean;
}

export const FavoritesBottom: React.FC<IFavoritesBottom> = React.memo(({
  isActive,
}) => {
  const styles = useStyles({});
  const { handleOpenFavorite } = useHeaderRoute();
  const { getTranslations } = useTranslations();
  const { storeFavCount } = useFavoriteStorage();

  const RenderFavoriteIconWithCount = useCallback(() => {
    return (
      <>
        <FavoriteIcon color="primary" />
        <div className={styles.wrapperFavoriteCount}>
          {storeFavCount !== 0 && (
            <span>{`${storeFavCount > 9 ? '9+' : storeFavCount}`}</span>
          )}
        </div>
      </>
    );
  }, [styles, storeFavCount]);

  return (
    <WrapperMobileItem
      isActive={isActive}
      iconItem={<RenderFavoriteIconWithCount />}
      text={getTranslations(locals.appTranslations.appFavorites)}
      handleClick={handleOpenFavorite}
    />
  );
});
