import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { useHideFilterZoneState, useOpenFilter } from 'src/hooks/app';
import { locals } from 'src/constants/locals';
import { useTranslations } from 'src/hooks';
import { useStyles } from './styles';
import { ArrowLeftIcon } from './icons/ArrowLeftIcon';
import { ArrowRightIcon } from './icons/ArrowRightIcon';
import { FilterIcon } from './icons/FilterIcon';

export const SpinnerFullSize = () => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);
  const { isOpen, handleToggleOpenFilter } = useOpenFilter();
  const { isHideFilterZone } = useHideFilterZoneState();
  const { getTranslations } = useTranslations();
  const [isHover, setIsHover] = useState(false);

  if (isHideFilterZone) return null;

  return (
    <Tooltip
      title={getTranslations(locals.appTranslations.appHideFilter)}
      placement="top"
      arrow
      open={isHover && isOpen}
      onClose={() => setIsHover(false)}
    >
      <Box
        className={`${styles.fullSizeContainer} ${!isOpen && styles.fullSizeContainerClose}`}
        onClick={() => handleToggleOpenFilter()}
        onMouseEnter={() => setIsHover(true)}
      >
        {isOpen ? <ArrowLeftIcon /> : (
          <>
            <ArrowRightIcon />
            <div className={styles.showFilterText}>{getTranslations(locals.appTranslations.appShowFilter)}</div>
            <FilterIcon />
          </>
        )}
      </Box>
    </Tooltip>
  );
};
