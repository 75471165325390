import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperFilterButton: {
    boxShadow: `0px 0px 6px 0px ${theme.palette.grey['300']}`,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '45px',
    padding: '20px',
    '& > :nth-child(2)': {
      marginLeft: '20px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
