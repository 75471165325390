import { useSelector } from 'react-redux';

import { getPolygonConfig } from 'src/store/requests/app/selector';
import { IGlobalPolygonConfig } from 'src/typings/app';

export const usePolygonConfig = () => {
  const globalPolygonConfig: IGlobalPolygonConfig | undefined = useSelector(getPolygonConfig);

  return {
    globalPolygonConfig,
  };
};
