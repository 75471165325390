import { RefObject, useEffect, useState } from 'react';

export const useSelectedScrollPosition = (
  selectedParamsLength: number,
  scrollRef: RefObject<HTMLDivElement>,
) => {
  const [lastSelectedCount, setCount] = useState(selectedParamsLength);

  useEffect(() => {
    if (selectedParamsLength > lastSelectedCount) {
      setCount(selectedParamsLength);

      if (scrollRef.current) {
        scrollRef.current.scrollTo(10000, 0);
      }
    } else {
      setCount(selectedParamsLength);
    }
  }, [lastSelectedCount, selectedParamsLength, scrollRef]);
};
