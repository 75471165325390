import {
  RefObject,
  useCallback,
  useState,
} from 'react';

export const useSectionImage = (sectionImage: RefObject<HTMLImageElement>) => {
  const aspectHeight = 150;
  const aspectWidth = 210;
  const [naturalWidth, setNaturalWidth] = useState<number>(0);
  const [naturalHeight, setNaturalHeight] = useState<number>(0);
  // const [aspectWidth, setAspectWidth] = useState<number>(0);

  const handleLoadImage = useCallback((data: any) => {
    if (sectionImage.current) {
      const nw = sectionImage.current?.naturalWidth;
      const nh = sectionImage.current?.naturalHeight;

      setNaturalWidth(nw);
      setNaturalHeight(nh);
      // setAspectWidth((nw * aspectHeight) / nh || 0);
    }
  }, [sectionImage]);

  return {
    aspectHeight,
    aspectWidth,
    naturalHeight,
    naturalWidth,
    handleLoadImage,
  };
};
