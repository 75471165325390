import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';

export const Index = () => {
  const styles = useStyles({});
  const [animateStart, setAnimateStart] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setAnimateStart(true), 100);
  }, []);

  return (
    <div className={`${animateStart ? styles.drawn : ''}`} style={{ width: '100px', height: '100px' }}>
      <svg
        version="1.1"
        id="tick"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 37 37"
        // @ts-ignore
        style={{ enableBackground: 'new 0 0 37 37' }}
      >
        <path
          className={`${styles.circ} path`}
          style={{
            fill: 'none', strokeWidth: 3, strokeLinejoin: 'round', strokeMiterlimit: 10,
          }}
          d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
        />
        <polyline
          className={`${styles.tick} path`}
          style={{
            fill: 'none', strokeWidth: 3, strokeLinejoin: 'round', strokeMiterlimit: 10,
          }}
          points="11.6,20 15.9,24.2 26.4,13.8"
        />
      </svg>
    </div>
  );
};
