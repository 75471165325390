import { ICreatioDeal } from 'src/store/crosstable/reducer';

export interface IFilterData {
  [key: string]: {
    filter_all_data: {
      [key: string]: IFilterAllData
    };
    filter_attributes: string[],
    filter_default_values: IRangeAndSet[];
    ['placement-info-config']: {
      ['filter_info_config']: IFilterConfiguratorTypePlace;
      ['hover_pop_up_short_info']: IHoverPopupInfoConfig;
      ['placement_grouped_info_config']: IFilterPlacementInfoConfig;
      ['placement_grouped_tile_config']: IFilterConfiguratorTypePlace;
      ['placement_info_config']: IFilterPlacementInfoConfig;
      ['pop_up_info_config']: IFilterConfiguratorTypePlace;
    }
  }
}

export interface IRangeAndSet {
  attribute: string;
  type: ListOfDropdown;
  data: IRangeAndSetData[] | any;
  precision?: number;
  step_type?: ScaleStepType;
}

export enum ScaleStepType {
  float = 'float',
  integer = 'integer',
}

export interface IRangeAndSetData {
  key: string;
  text: string;
  value: string;
}

export interface IBitrixDealClient {
  id: number;
  bitrix_complex_data_id: number;
  name: string;
  phones: string[];
}

export interface IBitrixUser {
  email: string;
  icon_uri: string | null;
  id: number;
  username: string;
}

export interface IBitrixDealData {
  bitrixExternalDealId: string;
  bitrix_complex_data_id: number;
  bitrixDealClient: IBitrixDealClient;
  id: number;
  linked_with_placement_by_user_id: number;
  linked_with_placement_by_user_time: string;
  responsibleUser: IBitrixUser;
  unlinked_from_placement_by_user_id: number;
  unlinked_from_placement_by_user_time: string|null;
}

export interface ICreatioUser {
  creatioId: string;
  name: string;
  email: string;
}

export interface ICreatioClient {
  creatioId: string;
  name: string;
  email: string;
  phone: string;
  mobilePhone: string;
  homePhone: string;
}

// "creatioId": "a583e598-ca47-4d1c-920c-4ec627758959",
// "responsibleUser": {
//     "creatioId": "69318eac-610a-4111-aa56-2a3915183934",
//     "name": "Zverkhovskyi Slavik ",
//     "email": "szverhov@gmail.com"
// },
// "contact": {
//     "creatioId": "787aaa22-12cc-4ee3-8c70-51c051e096e6",
//     "name": "test",
//     "email": "",
//     "phone": "",
//     "mobilePhone": "+380848588558",
//     "homePhone": ""
// }

export interface ICreatioDealData {
  creatioProject: string;
  creatioStage: string;
  externalId: string;
  placements: string[];
  id: string;
}

export enum ListOfKeysFilterAllData {
  id = 'id',
  houseId = 'house',
  sectionId = 'section_id',
  creatioOpportunities = 'creatioOpportunities',
  bitrixDealData = 'bitrix_deals',
  floorIds = 'floor_ids',
  placementType = 'placement.type',
  placementNumeration = 'placement.numeration',
  placementAmoDeals = 'placement.amo_deals',
  placementHouse = 'placement.house',
  placementSection = 'placement.section',
  placementFloor = 'placement.floor',
  placementTotalArea = 'placement.total_area',
  placementLivingArea = 'placement.living_area',
  placementBalconyArea = 'placement.balcony_area',
  placementTotalPrice = 'placement.total_price',
  placementTotalPriceAlternative = 'placement.total_price_alternative',
  placementPricePerSqM = 'placement.price_per_sq_m',
  placementPricePerSqMAlternative = 'placement.price_per_sq_m_alternative',
  placementRooms = 'placement.rooms',
  placementBedrooms = 'placement.bedrooms',
  placementBathrooms = 'placement.bathrooms',
  placementStatus = 'placement.status',
  placementTag = 'placement.tag',
  placementNote = 'placement.note',
  placementPlanningType = 'placement.planning_type',
  placementGallery = 'placement.gallery',
  placementAdditionalCondition = 'placement.additional_condition',
  placementAdditionalAdvantages = 'placement.additional_advantages',
  placementHouseSequence = 'placement.house.crosstable_sequence',
  placementBestSpinnerStops = 'placement.best_spinner_stops',
  placementGalleryImagesTransform = 'placement.gallery_images_transform',
  placementExplications = 'placement.explications',
  placementStatusShowInModule = 'placement_status.show_in_module',
  placementPdf = 'placement.pdfs',
  placementStatusColor = 'placement_status.color',
  placementStatusCrossTableName = 'placement_status.crosstable_name',
  placementStatusCrossTableColor = 'placement_status.crosstable_color',
  placementCanBeSet = 'placement_status.amo_can_be_set_for_deal',
  placementOnlyOneDeal = 'placement_status.amo_only_one_deal_placement',
  placementStatusName = 'placement_status.name',
  placementStatusModuleName = 'placement_status.module_name',
  mainFilterImg = 'main_filter_img',
  section = 'section',
  userIconUri = 'status_updated_by_user_icon_uri',
  userName = 'status_updated_by_user_username',
  updateTime = 'placement.status_update_time',
  statusId = 'placement_status.id'
}

export interface IFilterAllData {
  [ListOfKeysFilterAllData.id]: string;
  [ListOfKeysFilterAllData.houseId]: string;
  [ListOfKeysFilterAllData.bitrixDealData]: IBitrixDealData[];
  [ListOfKeysFilterAllData.creatioOpportunities]: ICreatioDeal[];
  [ListOfKeysFilterAllData.sectionId]: string;
  [ListOfKeysFilterAllData.floorIds]: string[];
  [ListOfKeysFilterAllData.placementAmoDeals]: string[];
  [ListOfKeysFilterAllData.placementType]: string | null;
  [ListOfKeysFilterAllData.placementNumeration]: string | null;
  [ListOfKeysFilterAllData.placementHouse]: string | null;
  [ListOfKeysFilterAllData.placementSection]: string | null;
  [ListOfKeysFilterAllData.placementFloor]: string[];
  [ListOfKeysFilterAllData.placementTotalArea]: string | null;
  [ListOfKeysFilterAllData.placementLivingArea]: string | null;
  [ListOfKeysFilterAllData.placementBalconyArea]: string | null;
  [ListOfKeysFilterAllData.placementTotalPrice]: string | null;
  [ListOfKeysFilterAllData.placementTotalPriceAlternative]: string | null;
  [ListOfKeysFilterAllData.placementPricePerSqM]: string | null;
  [ListOfKeysFilterAllData.placementPricePerSqMAlternative]: string | null;
  [ListOfKeysFilterAllData.placementRooms]: string | null;
  [ListOfKeysFilterAllData.placementBedrooms]: string | null;
  [ListOfKeysFilterAllData.placementBathrooms]: string | null;
  [ListOfKeysFilterAllData.placementStatus]: string | null;
  [ListOfKeysFilterAllData.placementTag]: string | null;
  [ListOfKeysFilterAllData.placementNote]: string | null;
  [ListOfKeysFilterAllData.placementPlanningType]: string | null;
  [ListOfKeysFilterAllData.placementHouseSequence]: string | null;
  [ListOfKeysFilterAllData.placementAdditionalCondition]: string | null;
  [ListOfKeysFilterAllData.placementAdditionalAdvantages]: string[];
  [ListOfKeysFilterAllData.placementGalleryImagesTransform]: any;
  [ListOfKeysFilterAllData.placementExplications]: IPlacementExplications;
  [ListOfKeysFilterAllData.placementGallery]: string[] | null;
  [ListOfKeysFilterAllData.placementPdf]: string[] | null;
  [ListOfKeysFilterAllData.placementStatusShowInModule]: PlacementShowModule;
  [ListOfKeysFilterAllData.placementBestSpinnerStops]: IPlacementBestStops[];
  [ListOfKeysFilterAllData.placementStatusColor]: string;
  [ListOfKeysFilterAllData.placementStatusCrossTableColor]: string|null;
  [ListOfKeysFilterAllData.placementStatusCrossTableName]: string;
  [ListOfKeysFilterAllData.placementStatusName]: string[];
  [ListOfKeysFilterAllData.placementStatusModuleName]: string;
  [ListOfKeysFilterAllData.placementCanBeSet]: string | null;
  [ListOfKeysFilterAllData.placementOnlyOneDeal]: string | null;
  [ListOfKeysFilterAllData.statusId]: string;
  [ListOfKeysFilterAllData.mainFilterImg]: string | null;
  [ListOfKeysFilterAllData.section]: string;
  [ListOfKeysFilterAllData.userIconUri]: string|null;
  [ListOfKeysFilterAllData.userName]: string|null;
  [ListOfKeysFilterAllData.updateTime]: string|null;
}

export interface IPlacementBestStops {
  stopNumber: string;
  'entity-view-name': string;
  'entity-view-name-id': string;
}

export enum PlacementShowModule {
  hide = 'hide',
  show = 'show',
}

export interface IPlacementExplications {
  ['explication.title']: string;
  ['explication.area']: string;
  ['explication.sequence']: string;
  ['explication.level']: string;
}

export interface IFilterParams {
  [key: string]: {
    data: any,
    type: ListOfDropdown,
    isDisabled?: boolean,
  }
}

export enum ConfiguratorDisplayedTypeInner {
  headerInfo = 'header-info',
  placingInfo = 'placing-info',
  keyInfo = 'key-info',
  iconsAndShortInfo = 'incons-and-short-info',
  additionalInfo = 'additional-info',
}

export interface IFilterConfiguratorTypePlace {
  [ConfiguratorDisplayedTypeInner.additionalInfo]: {
    [key: string]: IFilterAdditionalInfo;
  };
  [ConfiguratorDisplayedTypeInner.headerInfo]: {
    [key: string]: IFilterHeaderInfo;
  };
  [ConfiguratorDisplayedTypeInner.iconsAndShortInfo]: {
    [key: string]: IFilterIconsAndShortInfo;
  };
  [ConfiguratorDisplayedTypeInner.keyInfo]: {
    [key: string]: IFilterKeyInfo;
  };
  [ConfiguratorDisplayedTypeInner.placingInfo]: {
    [key: string]: IFilterPlacingInfo;
  };
}

export interface IFilterHeaderInfo {
  use: boolean;
  color: string;
  ['value-prefix']: {
    [key: string]: string;
  };
  ['value-sufix']: {
    [key: string]: string;
  };
  ['name-position']: string;
  ['use-name']: boolean;
}

export interface IFilterPlacingInfo {
  order: number;
  use: boolean;
  ['use-name']: boolean;
  ['use-position']: boolean;
}

export interface IFilterKeyInfo {
  order: number;
  use: boolean;
  ['use-name']: boolean;
  ['value-prefix']: string;
  ['value-sufix']: string;
}

export interface IFilterIconsAndShortInfo {
  order: number;
  use: boolean;
  ['value-prefix']: string;
  ['value-sufix']: string;
  ['icon_url']: string;
  ['icon-position']: string;
}

export interface IFilterAdditionalInfo {
  order: number;
  use: boolean;
  ['use-name']: boolean;
  ['value-prefix']: string;
  ['value-sufix']: string;
}

export interface IFilterPlacementInfoConfig {
  [key: string]: {
    order: number;
    use: boolean;
    ['value-prefix']: string;
    ['value-sufix']: string;
  }
}

export interface IHoverPopupInfoConfig {
  [key: string]: IHoverPopupInfoConfigInner
}

export interface IHoverPopupInfoConfigInner {
  use: string;
  order: number;
  ['value-prefix']: string;
  ['value-sufix']: string;
}

export enum PlacementDisplayedType {
  filter = 'filter_info_config',
  popup = 'pop_up_info_config',
  placementGroupedInfoConfig = 'placement_grouped_info_config',
  placementGroupedTileConfig = 'placement_grouped_tile_config',
  placement = 'placement_info_config',
}

export enum ListOfDropdown {
  dropdownSelectList = 'dropdownSelectList',
  dropdownCheckboxList = 'dropdownCheckboxList',
  dropdownRadioList = 'dropdownRadioList',
  dataList = 'dataList',
  datalistCheckboxList = 'dataListCheckboxList',
  scaleRange = 'scaleRange',
}
