import { useSelectorsInfrastructure } from './use-infrastructure-filters';

export const useGetInfrastructureValues = () => {
  const { filterInfrastructureData } = useSelectorsInfrastructure();

  return {
    filterInfrastructureData,
    filterParams: filterInfrastructureData?.infrastructureFiltersValues || {},
    infrastructureFilters: filterInfrastructureData?.infrastructureFilters,
  };
};
