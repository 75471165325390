const headScripts = [
  'https://dev1.flat.show/web/js/pdfAssets/js/tmpl.min.js',
  // 'https://dev1.flat.show/web/js/pdfAssets/js/jspdf.umd.min.js',
  // 'https://dev1.flat.show/web/js/pdfAssets/js/html2canvas.min.js',
  // 'https://dev1.flat.show/web/js/pdfAssets/js/dompurify.2.2.0.min.js',
  // 'https://dev1.flat.show/web/js/pdfAssets/fonts/Arial-normal.js',

  // 'https://dev1.flat.show/web/js/pdfAssets/fonts/DejaVuSans-normal.js',
  // 'https://dev1.flat.show/web/js/pdfAssets/fonts/DejaVuSans-Bold-bold.js',
];

const bodyScript = `
<div style="background-color: {%=o.backgroundColor%};" id="pdf">
      <table id="headerStyle" class="header">
        <tr>
          <td height="80" align="center" valign="bottom" style="padding-top: 10px">
            <div id="pdfLogo"><img src={%=o.clientLogoURL%} alt=""></div>
          </td>
          <td width="20"></td>
          <td height="80" valign="bottom">
            <table>
              <tr>
                <th>{%=o.complexAddressFieldName%}</th>
                {% if (o.phones.length >= 1) { %}
                  <th>{%=o.salesDepartmentFieldName%}</th>
                {% } %}
              </tr>
              <tr>
                <td style="padding-right: 100px;" valign="bottom"><span>{%=o.complexAddress%}</span></td>
                {% if (o.phones.length >= 1) { %}
                  <td>
                    {% for (var i=0; i<o.phones.length; i++) { %}
                      <span>{%=o.phones[i]%}</span><br>
                    {% } %}
                  </td>
                {% } %}
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <div id="mainImage">
        {% if (o.firstImageURL) { %}
          <img src={%=o.firstImageURL%} alt="">
        {% } %}
      </div>
      <table id="visualization">
        <tr>
          <td valign="bottom">&nbsp;</td>
          <td width="380" align="center" valign="middle" id="secondImage">
            {% if (o.secondImageURL) { %}
              <img src={%=o.secondImageURL%} alt="">
            {% } %}
          </td>
        </tr>
      </table>
      <table id="dataBox">
        <tr>
          <td width="100" align="center" valign="middle" id="compass">
            {% if (o.thirdImageURL) { %}
              <img src={%=o.thirdImageURL%} alt="">
            {% } %}
          </td>
          <td width="20"></td>
          <td>
            <div id="dataTable">
              {% for (const [key, value] of Object.entries(o.placementData)) { %}
                  <div><span>{%=key%}</span><strong>{%=value%}</strong></div>
              {% } %}
            </div>
          </td>
        </tr>
      </table>
      <table id="footer">
        <tr>
          <td>
            {% if (o.notes) { %}
              {% for (var i=0; i<o.notes.length; i++) { %}
                <div><i>*</i>&nbsp;{%=o.notes[i]%}</div>
              {% } %}
            {% } %}
          </td>
          <td id="pageNumber" valign="top">{%=o.pageNumber%}</td>
        </tr>
      </table>
    </div>
  
`;

const shortBody = `
<div style="background-color: {%=o.backgroundColor%};" class="shortStyle" id="pdf">
<table id="headerStyle" class="header">
  <tr>
    <td height="80" align="center" valign="bottom" style="padding-top: 10px">
      <div id="pdfLogo"><img src={%=o.clientLogoURL%} alt=""></div>
    </td>
    <td width="20"></td>
    <td height="80" valign="bottom">
      <table>
        <tr>
          <th>{%=o.complexAddressFieldName%}</th>
          {% if (o.phones.length >= 1) { %}
            <th>{%=o.salesDepartmentFieldName%}</th>
          {% } %}
        </tr>
        <tr>
          <td style="padding-right: 80px;" valign="bottom"><span>{%=o.complexAddress%}</span></td>
          {% if (o.phones.length >= 1) { %}
            <td>
              {% for (var i=0; i<o.phones.length; i++) { %}
                <span>{%=o.phones[i]%}</span><br>
              {% } %}
            </td>
          {% } %}
        </tr>
      </table>
    </td>
  </tr>
</table>
<div id="mainDataGrid">
  <div style="width: 82px;">
    <div id="dataTable">
      {% for (const [key, value] of Object.entries(o.placementData)) { %}
          <div><span>{%=key%}</span><strong>{%=value%}</strong></div>
      {% } %}
    </div>
  </div>
  <div id="mainImage">
    {% if (o.apartmentLayout) { %}
      <img src={%=o.apartmentLayout%} alt="">
    {% } %}
  </div>
</div>
<div id="mainHalfGrid">
  <div>
  {% if (o.sectionDiagram) { %}
    <img src={%=o.sectionDiagram%} alt="">
  {% } %}
  </div>
  <div id="compass">
  {% if (o.compass) { %}
    <img src={%=o.compass%} alt="">
  {% } %}
  </div>
  <div>
  {% if (o.floorPlan) { %}
    <img src={%=o.floorPlan%} alt="">
  {% } %}
  </div>
</div>
<div id="mainFacadeGrid">
    {% if (o.renderFacade) { %}
      <div id="mainImage">
        <img src={%=o.renderFacade%} alt="">
      </div>
    {% } %}
  <div>
    <div id="dataTable">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<table id="footer">
  <tr>
    <td>
      {% for (var i=0; i<o.notes.length; i++) { %}
        <div><i>*</i>&nbsp;{%=o.notes[i]%}</div>
      {% } %}
    </td>
    <td id="pageNumber" valign="top">1</td>
  </tr>
</table>
</div>
`;

export const insertHtmlForPdf = () => {
  if (!document.querySelector('#pdf-template')) {
    headScripts.forEach((link, index) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = link;
      if (index > headScripts.length - 3) {
        script.async = true;
        script.defer = true;
        setTimeout(() => {
          document.head.appendChild(script);
        }, 30);
      } else {
        document.head.appendChild(script);
      }
    });

    const script = document.createElement('script');
    script.type = 'text/x-tmpl';
    script.id = 'pdf-template';
    script.innerHTML = bodyScript;
    document.body.appendChild(script);
  }

  if (!document.querySelector('#pdf-template-short')) {
    headScripts.forEach((link, index) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = link;
      if (index > headScripts.length - 3) {
        script.async = true;
        script.defer = true;
        setTimeout(() => {
          document.head.appendChild(script);
        }, 30);
      } else {
        document.head.appendChild(script);
      }
    });

    const script = document.createElement('script');
    script.type = 'text/x-tmpl';
    script.id = 'pdf-template-short';
    script.innerHTML = shortBody;
    document.body.appendChild(script);
  }
};
