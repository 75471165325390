import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { allUtms } from 'src/constants';

export const useReadUTM = () => {
  useEffect(() => {
    const UTMobj = Object.fromEntries((decodeURI(window.location.href).match(/(utm_).+?=[^&]*/g) || []).map((n) => n.split('=')));
    if (Object.keys(UTMobj).length) {
      allUtms.forEach((item) => {
        Cookies.remove(item);
      });

      Object.keys(UTMobj).forEach((item) => {
        Cookies.set(item, UTMobj[item]);
      });
    } else {
      const ringo = Cookies.get('rngst2');
      const binotel = Cookies.get('biatv-cookie');
      const tilda = Cookies.get('TILDAUTM');
      if (ringo) {
        const ringoObj = JSON.parse(ringo);
        const { utmz } = ringoObj;
        if (utmz) {
          Object.keys(utmz).forEach((item) => {
            Cookies.set(item, utmz[item]);
          });
        }
      }
      if (binotel) {
        const decodedUriStr = decodeURI(binotel);
        const binotelObj = JSON.parse(decodedUriStr);
        const { utmDataCurrent } = binotelObj;
        if (utmDataCurrent) {
          Object.keys(utmDataCurrent).forEach((item) => {
            Cookies.set(item, utmDataCurrent[item]);
          });
        }
      }
      if (tilda) {
        const decodedUriStr = decodeURI(tilda);
        const keyValuePairs = decodedUriStr.split('|||');

        keyValuePairs.forEach((keyValuePair) => {
          const keyValue = keyValuePair.split('=');
          if (keyValue.length === 2) {
            const key = keyValue[0].trim();
            const value = keyValue[1].trim();
            Cookies.set(key, value);
          }
        });
      }
    }
  }, []);
};
