import React from 'react';
import Box from '@mui/material/Box';
import { appIcon } from 'src/assets';
import { useStyles } from './styles';

interface IUploadButton {
  handleFunc?: React.MouseEventHandler<HTMLDivElement>;
  isBig?: boolean;
  withoutBorder?: boolean;
}

export const UploadButton: React.FC<IUploadButton> = ({
  handleFunc = () => {},
  isBig,
  withoutBorder,
}) => {
  const styles = useStyles({});
  return (
    <Box
      className={`${styles.button} ${isBig && styles.isBig} ${withoutBorder && styles.withoutBorder}`}
      onClick={handleFunc}
    >
      <img className={styles.icon} src={appIcon.iconUpload.default} alt="" />
    </Box>
  );
};
