import { useSelector } from 'react-redux';

import {
  getFloorList,
  getView2dData,
} from 'src/store/view-2d/selectors';

export const useCrossTableState = () => {
  const floorList = useSelector(getFloorList);
  const view2dData = useSelector(getView2dData);

  return {
    floorList,
    view2dData,
  };
};
