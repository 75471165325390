import { useCallback, useEffect, useState } from 'react';

import {
  crossTableTitle,
  crossTableTitleItemMargin,
  crossTableTitleItemSize,
  crossTableTitleSpace,
} from 'src/constants';

export const useControlCrossTable = () => {
  const [refCrossTable, setRefCrossTable] = useState<HTMLDivElement | null>(null);
  const [countSlider, setCountSlider] = useState<number>(0);
  const [crossTableHeight, setCrossTableHeight] = useState<number | null>(null);
  const [isResize, setResize] = useState<boolean>(true);
  const titleSize = crossTableTitle + crossTableTitleSpace;
  const itemSize = crossTableTitleItemSize + (2 * crossTableTitleItemMargin);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setRefCrossTable(node);
    }
  }, []);

  useEffect(() => {
    setResize(true);
    setCrossTableHeight(null);
  }, [refCrossTable?.parentElement?.offsetHeight]);

  useEffect(() => {
    if (refCrossTable && crossTableHeight === null && isResize) {
      const handle = setTimeout(() => {
        const calcCountSlider = Math.floor((refCrossTable.offsetHeight - titleSize) / itemSize);
        setCountSlider(calcCountSlider);
        setCrossTableHeight((calcCountSlider * itemSize) + titleSize);
        setResize(false);
      }, 500);

      return () => {
        clearTimeout(handle);
      };
    }
    return () => {};
  }, [refCrossTable, crossTableHeight, isResize, itemSize, titleSize]);

  return {
    crossTableHeight,
    countSlider,
    refCrossTable,
    measuredRef,
  };
};
