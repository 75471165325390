import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  apartmentHeaderInfo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > div:not(:last-child)': {
      marginRight: '5px',
    },
    '& > div': {
      backgroundColor: theme.palette.primary.main,
      marginBottom: '5px',
      overflow: 'hidden',
      overflowWrap: 'break-word',
    },
  },
  headerInfo: {
    color: 'white',
    borderRadius: '3px',
    padding: '2px 5px',
  },
}));
