import * as app from './app/selector';
import * as appMenu from './app-menu/selector';
import * as filter from './filter/selector';
import * as typeOfProperty from './type-of-property/selector';
import * as spinnerData from './spinner-views/selector';
import * as translationsData from './translations/selector';
import * as layoutsData from './layouts/selector';
import * as infrastructureData from './infrastructure/selector';
import * as userData from './user/selector';

export const requestSelectors = {
  app: { ...app },
  appMenu: { ...appMenu },
  filter: { ...filter },
  infrastructure: { ...infrastructureData },
  typeOfProperty: { ...typeOfProperty },
  spinner: { ...spinnerData },
  layouts: { ...layoutsData },
  translations: { ...translationsData },
  user: { ...userData },
};
