import { IAppState } from 'src/store/types';

export const getSpinnerData = (state: IAppState) => state.requests.spinner.data;
export const getSpinnerHoverText = (state: IAppState) => state.requests.spinner.data?.['hover-text'];
export const getSpinnerInfrastructure = (state: IAppState) => state.requests.spinner.data?.infrastructure;
export const getSpinnerViewData = (state: IAppState) => state.requests.spinner.data?.spinners;
export const getSpinnerDetailInfo = (state: IAppState) => state.requests.spinner.data?.['detail-info'];
export const getSpinnerFirstSpinner = (state: IAppState) => state.requests.spinner.data?.spinners?.firstSpinner;
export const getMarkups = (state: IAppState) => state.requests.spinner.data?.markups;
export const getPanoramaData = (state: IAppState) => state.requests.spinner.data?.panorama;
export const getSpinnerDataLoading = (state: IAppState) => state.requests.spinner.isLoading;
