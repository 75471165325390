import { action } from 'src/store/common';
import { ICurrentPano } from './reducer';

export const ActionTypes = {
  SET_CURRENT_PANORAMA: '[PANORAMA]SET_CURRENT_PANORAMA',
  SET_AVAILABLE_PANORAMAS: '[PANORAMA]SET_AVAILABLE_PANORAMAS',
  SET_LOOK_AT_PANO: '[PANORAMA]SET_LOOK_AT_PANO',
};

export const Actions = {
  setCurrentPanorama: action<ICurrentPano>(ActionTypes.SET_CURRENT_PANORAMA),
  setAvailablePanoramas: action(ActionTypes.SET_AVAILABLE_PANORAMAS),
  setLookAtMyPano: action<boolean>(ActionTypes.SET_LOOK_AT_PANO),
};
