import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  mapWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  wrapperDesktopTypeSelector: {
    background: 'rgba(0, 0, 0, 0.3)',
    backdropFilter: 'blur(5px)',
    borderRadius: '10px',
    display: 'flex',
    zIndex: 5,
    fontSize: '14px',
    '& > div': {
      padding: '10px',
    },
  },
  wrapperMapHeader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    top: '30px',
    padding: '0px 30px',
  },
  spinnerTypesRenderStyle: {
    flex: 1,
  },
}));
