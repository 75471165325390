import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';

import { useTranslations } from 'src/hooks/translataions';
import { locals } from 'src/constants/locals';
import { useToggleMobileFilter } from 'src/hooks/app';
import { WrapperMobileItem } from '../_WrapperMobileItem';

export const MobileFilterCaller: React.FC = () => {
  const { getTranslations } = useTranslations();
  const { handleToggleOpenState } = useToggleMobileFilter();

  return (
    <WrapperMobileItem
      text={getTranslations(locals.appTranslations.appFilter)}
      iconItem={<TuneIcon color="primary" />}
      handleClick={handleToggleOpenState}
    />
  );
};
