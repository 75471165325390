import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { requestSelectors } from 'src/store/requests/selectors';
import { FilterApartmentsMobile } from 'src/components/FilterProperty/components/FilterMobile';
import { TypeDisplayedSpinner } from 'src/components/FilterSpinner/components/TypeDisplayedSpinner';
import { MobileFilterCaller } from 'src/components/AppBottomMenu/components/FilterCaller';
import { pathList } from 'src/route-list';
import { FavoritesBottom } from 'src/components/AppBottomMenu/components/FavoritesBottom';
import { useHideFilterZoneState } from 'src/hooks/app';
import { useStyles } from './styles';
import { ISpinnerMenuMobile } from './types';

export const SpinnerMenuMobile: React.FC<ISpinnerMenuMobile> = () => {
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const styles = useStyles(mainColor);
  const location = useLocation();
  const { isHideFilterZone } = useHideFilterZoneState();

  return (
    <>
      <FilterApartmentsMobile />
      <div className={styles.mobileBottomMenuWrapper}>
        {!isHideFilterZone && location.pathname === pathList.home && <MobileFilterCaller />}
        <TypeDisplayedSpinner isActive={location.pathname === pathList.home} />
        <FavoritesBottom isActive={location.pathname === pathList.favorites} />
      </div>
    </>
  );
};
