import { useDispatch, useSelector } from 'react-redux';

import { jwtToken } from 'src/store/app/selectors';
import { Actions } from 'src/store/app/actions';

const JWT_KEY = 'jwt_token';

export function useGetAuthData() {
  const jwtTokenData = useSelector(jwtToken);
  const dispatch = useDispatch();

  const setJWT = (token: string) => {
    dispatch(Actions.jwtToken(token));
    localStorage.setItem(JWT_KEY, token);
  };

  const removeJWT = () => {
    dispatch(Actions.jwtToken(''));
    localStorage.removeItem(JWT_KEY);
  };

  const getJWT = () => {
    if (!!jwtTokenData) {
      return (jwtTokenData);
    }
    const localJWT = localStorage.getItem(JWT_KEY);
    if (!!localJWT) {
      dispatch(Actions.jwtToken(localJWT));
      return (localJWT);
    }

    return '';
  };

  return {
    getJWT,
    setJWT,
    removeJWT,
  };
}
