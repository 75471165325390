import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getFloorItemType } from 'src/store/view-2d/selectors';
import { Actions } from 'src/store/view-2d/actions';
import { EFloorType } from 'src/store/view-2d/reducer';
import { translations } from 'src/constants/translations';
import { useStyles } from './styles';

export const SelectFloorItemType: React.FC = () => {
  const styles = useStyles({});
  const dispatch = useDispatch();
  const floorItemType = useSelector(getFloorItemType);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const viewOptions = [
    {
      key: EFloorType.moreInfo,
      text: translations.detailedView,
      value: EFloorType.moreInfo,
    },
    {
      key: EFloorType.mini,
      text: translations.simpleView,
      value: EFloorType.mini,
    },
  ];

  const handleOnChange = (value: any) => {
    if (value !== floorItemType) {
      dispatch(Actions.setFloorItemType(value));
    }
  };

  return (
    <Box mb={isSmallScreen ? 2 : 0}>
      {translations.changeView}
      : &nbsp;
      <Dropdown
        inline
        options={viewOptions}
        className={styles.styleOfDropdown}
        value={floorItemType}
        onChange={(event, data) => handleOnChange(data.value)}
      />
    </Box>
  );
};
