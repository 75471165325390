import { useSelector } from 'react-redux';

import {
  getSpinnerHoverText,
  getSpinnerInfrastructure,
} from 'src/store/requests/spinner-views/selector';

// Todo need refactoring
export const useHoverText = (hoverId: string) => {
  const hoverData = useSelector(getSpinnerHoverText);
  const hoverDataInfrastructure = useSelector(getSpinnerInfrastructure);

  return {
    hoverText:
      (hoverData && (hoverData[hoverId]?.['polygon_hover_text.text']))
      || (hoverDataInfrastructure && hoverDataInfrastructure[hoverId]?.['infrastructure.head_text']),
  };
};
