import { IFormData } from 'src/components/AuthForms/types';

export interface IAppRequest {
  buttons_visibility_config: {
    filter_call: boolean;
    filter_favorites: boolean;
    filter_menu: boolean;
    filter_share: boolean;
    infrastructure_active: boolean;
    infrastructure_button: boolean;
    mode_crosstable: boolean;
    mode_floors: boolean;
    mode_layout: boolean;
    mode_map: boolean;
    mode_panorama: boolean;
    mode_spinner: boolean;
    placement_floor_placing: boolean;
    placement_request_call: boolean;
    placement_section_markup_placing: boolean;
    show_client_logo: boolean;
    show_filter_counter: boolean;
    show_prefilter_placement_type_selector: boolean;
    start_view_full_screen: boolean;
  };
  infrastructurePanoFullscreen: boolean;
  favicon: string;
  client_module_js_paths: string[];
  client_module_embedded_contact_form: IEmbeddedContactForm | null;
  client_module_by_attributes_contact_form: IAttributesContactForm | null;
  client_module_standard_contact_form: IStandartContactForm | null;
  client_module_google_tag_manager: IModuleGTM | null;
  client_module_outer_embedded_js: IEmbeddedJS[] | null;
  client_module_phones: IClientModulePhones[];
  default_language: string;
  crosstable_language: string;
  module_language: string;
  default_title: string;
  crosstable_title: string;
  module_title: string;
  entity_inner_data_relation: {
    house: { [key: string]: string },
    section: { [key: string]: string },
    floor: { [key: string]: string },
  };
  main_logo: string;
  main_logo_mobile: string | null;
  global_polygon_config: IGlobalPolygonConfig;
  google_id: string;
  main_colors: IMainColors;
  form: IForm;
  placementGallerySpecialPreviews: {
    placement_gallery_complex_spinner_preview_url: string | null;
    placement_gallery_floor_spinner_preview_url: string | null;
    placement_gallery_house_spinner_preview_url: string | null;
    placement_gallery_placement_spinner_preview_url: string | null;
    placement_gallery_playcanvas_preview_url: string | null;
    placement_gallery_section_spinner_preview_url: string | null;
    placement_gallery_complex_panorama_preview_url: string | null;
    placement_gallery_floor_panorama_preview_url: string | null;
    placement_gallery_house_panorama_preview_url: string | null;
    placement_gallery_placement_panorama_preview_url: string | null;
    placement_gallery_section_panorama_preview_url: string | null;
  };
  placement_gallery_spinners_position: {
    [key: string]: number | null;
  };
  placement_gallery_panorama_position: {
    [key: string]: number | null;
  };
  pop_up: {
    enable_hover_pop_up_short_info: boolean,
    enable_hover_text_polygon_light_up: boolean,
    enable_polygon_status_colors: boolean,
  };
  secondary_languages: string[];
  crosstable_secondary_languages: string[];
  module_secondary_languages: string[];
  requests: IAppRequestEntity;
  spinnerDisableFilter: {
    complex_spinner_disable_filter: boolean;
    floor_spinner_disable_filter: boolean;
    house_spinner_disable_filter: boolean;
    section_spinner_disable_filter: boolean;
  };
  spinner_interactivity_buttons: {
    show_placement_gallery_spinners_rotation_buttons_desktop: boolean;
    show_placement_gallery_spinners_rotation_buttons_mobile: boolean;
    show_placement_gallery_spinners_zoom_button_desktop: boolean;
    show_placement_gallery_spinners_zoom_button_mobile: boolean;
    show_spinners_rotation_buttons_desktop: boolean;
    show_spinners_rotation_buttons_mobile: boolean;
    show_spinners_zoom_button_desktop: boolean;
    show_spinners_zoom_button_mobile: boolean;
  };
  show_flat_show_logo: boolean;
  tile_img_parent_padding: number;
  ringo_class_name: string|null;
  root_url: string|null;
}

export interface IMainColors {
  greyColor: string;
  placeholderColor: string;
  primaryColor: string;
  primaryColorLight: string;
  preloaderColor: string;
  filterBackgroundColor: string;
}

export interface IAppRequestEntity {
  mail_clients_url: string;
}

export interface IClientModulePhones {
  phone_text: string;
  phone_href: string;
  order: number;
  default: boolean;
}

export interface IGlobalPolygonConfig {
  clicked_body_color: string;
  clicked_body_opacity: string;
  clicked_border_color: string;
  global_border_width: string;
  hover_body_color: string;
  hover_body_opacity: string;
  hover_border_color: string;
  static_body_color: string;
  static_body_opacity: string;
  static_border_color: string;
  trigger_event: PolygonTriggerEvent;
  is_pre_highlight_polygons_in_module: boolean;
}

export enum PolygonTriggerEvent {
  click = 'click',
  hover = 'hover',
}

export interface IAppMenuRequest {
  menu_column_title: string;
  rows: IAppMenuRowRequest[];
}

export interface IAppMenuRowRequest {
  link_title: string;
  link_url: string;
  order: number;
}
export interface IEmbeddedContactForm {
  html: string;
}

export interface IAttributesContactForm {
  class: string | null;
  el_id: string | null;
  event: string | null;
}

export interface IForm {
  form_default_country: string;
}

export interface IModuleGTM {
  head: string;
  body: string;
}

export interface IEmbeddedJS {
  complex_id: string;
  embedding_type: string;
  html_placing: string;
  html_placing_order: string;
  id: string;
  load_type: string;
  name: string;
  script: string | null;
  src: string | null;
}

export interface IStandartContactForm {
  additionalInfo: string;
  bio: string;
  client_end_point_url: string;
  email: string;
  phoneNumber: string;
  request_content_type: string;
  request_method: string;
  redirect_url: string
  url: string;
  static_hidden_inputs: object;
  isFormData: boolean;
}

export enum UrlConfigure {
  collapsedFilter = 'collapsedFilter',
  typePlacementUrl = 'typePlacementUrl',
  language = 'language',
  displayType = 'displayType',
  curSpinnerName = 'curSpinnerName',
  curSpinnerId = 'curSpinnerId',
  curEntityName = 'curEntityName',
  curEntityId = 'curEntityId',
  floorData = 'floorData',
}

export enum CRMNames {
  bitrix = 'bitrix',
  amocrm = 'amocrm',
  creatio = 'creatio',
}

export interface IContactPhone {
  ID: string;
  TYPE_ID: string;
  VALUE: string;
  VALUE_TYPE: string;
}
export interface IMessage {
  crm: CRMNames;
  dealId: number;
  domain?: string;
  pipelineId?: string|undefined;
  statusId?: string;
  complexId?: number;
  userEmail: string;
  projectId: number;
  categoryId: number;
  token?: string;
}
