import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { useBuildType } from 'src/hooks';
import { getListViewType, getView2dSelectedTypes } from 'src/store/view-2d/selectors';
import { Actions } from 'src/store/view-2d/actions';
import { IDisplayPlacementData } from 'src/store/view-2d/reducer';

export const useTypeView2d = () => {
  const dispatch = useDispatch();
  const { isCrossTable } = useBuildType();
  const [filteredTypes, setFilteredTypes] = useState<string[]>([]);
  const viewList: IDisplayPlacementData[] | null = useSelector(getListViewType);
  const selectedType = useSelector(getView2dSelectedTypes);

  const handleOnChange = useCallback((value: any) => {
    dispatch(Actions.setView2dSelectedType(value));
  }, [dispatch]);

  useEffect(() => {
    if (viewList) {
      const temp: string[] = viewList.reduce((acc: string[], item) => {
        if ((isCrossTable && item.enable_for_crosstable) || (!isCrossTable && item.enable_for_module)) {
          acc.push(item.field_name);
        }
        return acc;
      }, []);
      setFilteredTypes(temp);
    }
  }, [viewList]);

  return {
    viewList: filteredTypes,
    selectedType,
    handleOnChange,
  };
};
