import { ISpinnerSvgBlock } from 'src/typings/spinner';
import { PolygonTriggerEvent } from 'src/typings/app';

export interface IRenderSvg {
  isAlwaysHighlight?: boolean;
  galleryImagesTransform?: any;
  imgUrl?: string;
  svg: ISpinnerSvgBlock;
  floorId?: string;
}

export interface IMouseEvent extends MouseEvent {
  target: HTMLDivElement;
}

export interface IRenderSvgElement {
  trigger: PolygonTriggerEvent;
  isBlockedRemoveHoverLeaveClass?: boolean;
  isAlwaysHighlight?: boolean;
  className?: string;
  elementProps?: any;
  svg: IRenderSvg | any;
  name: string;
  nameId: string;
  redirect?: string;
  styles: any;
  animStyles: any;
  isHouse?: boolean;
  handleClick: (event: any) => any;
  handleMouseEnter: (event: any) => any;
  handleMouseLeave: (event: any) => any;
  handleMouseMove: (event: any) => any;
  handleOutsideClick?: (event: any) => any;
}

export enum TypeActions {
  add = 'add',
  remove = 'remove',
}
