import React from 'react';

import {
  useRerenderSpinner,
} from './hooks';
import { IViewSpinner } from './types';
import { ViewSpinnerInner } from './component';
import { PopupProvider } from './context';

export const ViewSpinner: React.FC<IViewSpinner> = ({
  isFloors,
  setFullSizeFloor,
}) => {
  const { isRender } = useRerenderSpinner();

  return isRender
    ? (
      <PopupProvider>
        <ViewSpinnerInner isFloors={isFloors} setFullSizeFloor={setFullSizeFloor} />
      </PopupProvider>
    )
    : null;
};
