import { action } from 'src/store/common';

export const ActionTypes = {
  SET_INITIAL_FILTER_PARAMETER: '[FILTER_PARAMS]SET_INITIAL_FILTER_PARAMETER',
  SET_FILTER_PARAMETER: '[FILTER_PARAMS]SET_FILTER_PARAMETER',
  SET_FILTER_DESKTOP_COUNT: '[FILTER_PARAMS]SET_FILTER_SHOW_DESKTOP_COUNT',
  SET_FILTER_MOBILE_COUNT: '[FILTER_PARAMS]SET_FILTER_SHOW_MOBILE_COUNT',
  SET_SCROLL_POSITION: '[FILTER_PARAMS]SET_SCROLL_POSITION',
  SET_LEAVED_SLIDE: '[FILTER_PARAMS]SET_LEAVED_SLIDE',
  CLEAR_FILTER_PARAMETER_CALL: '[FILTER_PARAMS]CLEAR_FILTER_PARAMETER_CALL',
  CLEAR_FILTER_PARAMETER: '[FILTER_PARAMS]CLEAR_FILTER_PARAMETER',
};

export const Actions = {
  setInitialFilterParameter: action(ActionTypes.SET_INITIAL_FILTER_PARAMETER),
  setFilterParameter: action(ActionTypes.SET_FILTER_PARAMETER),
  setScrollPosition: action(ActionTypes.SET_SCROLL_POSITION),
  setFilterDesktopCount: action(ActionTypes.SET_FILTER_DESKTOP_COUNT),
  setFilterMobileCount: action(ActionTypes.SET_FILTER_MOBILE_COUNT),
  setLeavedSlide: action(ActionTypes.SET_LEAVED_SLIDE),
  clearFilterParameterCall: action(ActionTypes.CLEAR_FILTER_PARAMETER_CALL),
  clearFilterParameter: action(ActionTypes.CLEAR_FILTER_PARAMETER),
};
