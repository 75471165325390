import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';

export const useGetComplexConfig = () => {
  const complexConfig = useSelector(requestSelectors.app.getAppData);
  const complexConfigEntityInnerDataRelation = complexConfig?.entity_inner_data_relation;

  return {
    complexConfig,
    complexConfigEntityInnerDataRelation,
  };
};
