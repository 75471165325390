import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import {
  crossTableBigItemHeight,
  crossTableBigItemWidth,
} from 'src/constants';
import { IFloorItemBig } from './index';
import { useStyles } from '../../styles';

export const FloorItemBigSkeleton: React.FC<IFloorItemBig> = ({
  fillFloorsItem,
}) => {
  const styles = useStyles({});

  return (
    <div className={styles.sectionContentWrapperBig}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={crossTableBigItemWidth}
        height={crossTableBigItemHeight * fillFloorsItem[ListOfKeysFilterAllData.placementFloor].length}
        style={{
          borderRadius: '5px',
          position: 'absolute',
          bottom: 0,
        }}
      />
    </div>
  );
};
