import React, { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { RenderWindowView } from 'src/components/RenderWindowView';
import { WindRose } from 'src/components/WindRose';
import { AppSpinner } from 'src/components/AppSpinner';
import { ViewPanorama } from 'src/components/ViewPanorama';
import { ICurrentPano } from 'src/store/panorama/reducer';
import { useGetPlacementSvgs, useGetCompassData } from './hooks';
import { useStyles } from './styles';
import { IComponentGalleryItem, SpinnerLabel } from './types';

function checkURL(url: string) {
  return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

export const ComponentGalleryItem: React.FC<IComponentGalleryItem> = React.memo(({
  combineIds,
  galleryItem,
  galleryImagesTransform,
  floorIds,
  placementId,
}) => {
  const refImage = useRef<any>(null);
  const styles = useStyles({});
  const [svgSize, setSvgSize] = useState({
    h: 0,
    w: 0,
    naturalH: 0,
    naturalW: 0,
  });
  const { views } = useGetPlacementSvgs(floorIds, placementId);
  const windRoseData = useGetCompassData(galleryImagesTransform, galleryItem && galleryItem.original, floorIds[0]);
  const onloadFunc = (e: any) => {
    setSvgSize({
      h: refImage.current.height,
      w: refImage.current.width,
      naturalH: e.target.naturalHeight,
      naturalW: e.target.naturalWidth,
    });
  };
  switch (galleryItem.original) {
    case SpinnerLabel.SPINNER:
      if (galleryItem.spinnerData) {
        return (
          <Box>
            <Box className="image-gallery-image">
              <AppSpinner
                spinnerData={galleryItem.spinnerData}
                displayIds={combineIds}
                type={galleryItem.type}
              />
            </Box>
          </Box>
        );
      }

      return null;
    case 'panorama':
      return (
        <Box py={2}>
          <Box my="-10px" className="image-gallery-image">
            <ViewPanorama
              currentPano={{
                entity: galleryItem.entity,
                entityId: galleryItem.entityId,
                id: galleryItem.id,
              } as ICurrentPano}
              displayIds={combineIds}
            />
          </Box>
        </Box>
      );
    default:
      if (galleryItem.original?.indexOf('playcanvas') !== -1 || !checkURL(galleryItem.original)) {
        return (
          <div>
            <iframe title="sometitle" src={galleryItem.original} className="image-gallery-image gallery-playcanvas" />
          </div>
        );
      }
      return (
        <Box position="relative" display="flex" justifyContent="center">
          <WindRose initWindRoseData={windRoseData} randomSpinnerId={placementId} isGallery />
          <Box
            className="image-gallery-image"
            py={2}
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {galleryImagesTransform && galleryImagesTransform[galleryItem.original] && (
            <svg
              className={styles.svg}
              viewBox={`0 0 ${svgSize.naturalW} ${svgSize.naturalH}`}
              style={{
                width: `${svgSize.w}px`,
                height: `${svgSize.h + 20}px`,
              }}
            >
              {floorIds.map((floor) => {
                if (!views[floor]) return null;
                return views[floor].map((item) => (
                  <RenderWindowView
                    key={item.attr['data-uuid']}
                    svg={item}
                    floorId={floor}
                    galleryImagesTransform={galleryImagesTransform}
                    imgUrl={galleryItem.original}
                  />
                ));
              })}
            </svg>
            )}
            <img ref={refImage} onLoad={onloadFunc} alt="img" className={styles.galleryImage} src={galleryItem.original} />
          </Box>
        </Box>
      );
  }
});
