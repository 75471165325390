import { IAppState } from 'src/store/types';

export const getFloorList = (state: IAppState) => state.view2d.floorList;
export const getView2dData = (state: IAppState) => state.view2d.view2dData;
export const getView2dSelectedIds = (state: IAppState) => state.view2d.selectedPlacementIds;
export const getListViewType = (state: IAppState) => state.view2d.viewTypes;
export const getView2dSelectedTypes = (state: IAppState) => state.view2d.selectedViewType;
export const getView2dCrossTableScroll = (state: IAppState) => state.view2d.floorScrollSize;
export const getView2dStatuses = (state: IAppState) => state.view2d.statuses;
export const getFloorItemType = (state: IAppState) => state.view2d.floorItemType;
export const getIsRerender = (state: IAppState) => state.view2d.isRerender;
