import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ISpinnerSvgBlock } from 'src/typings/spinner';
import { requestSelectors } from 'src/store/requests/selectors';

export const useGetPlacementSvgs = (
  floorIds: string[],
  placementId: string,
) => {
  const [views, setViews] = useState<{
    [key: string]: ISpinnerSvgBlock[]
  }>({});
  const spinnerData = useSelector(requestSelectors.spinner.getSpinnerData);
  useEffect(() => {
    if (spinnerData && floorIds) {
      const tempViews: {
        [key: string]: ISpinnerSvgBlock[]
      } = {};
      floorIds.forEach((floor) => {
        const floorData = spinnerData?.spinners?.floor?.[floor];
        const firstStop = floorData?.additional_data.spinner_svg['0'];
        if (firstStop) {
          const uuid = firstStop.reduce((acc: string[], item:ISpinnerSvgBlock) => {
            if (item.attr['entity-view-name-id'] === placementId) {
              acc.push(item.attr['data-uuid']);
            }
            return acc;
          }, []);
          const polygons = firstStop.reduce((acc: ISpinnerSvgBlock[], item: ISpinnerSvgBlock) => {
            if (item.attr['data-role'] === 'placement-window' && uuid.includes(item.attr['data-parent-uuid'])) {
              acc.push(item);
            }
            return acc;
          }, []);
          tempViews[floor] = polygons;
        }
      });
      setViews(tempViews);
    }
  }, [floorIds, spinnerData]);

  return {
    views,
  };
};
