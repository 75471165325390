/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { ICategory } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { postBitrixStage } from 'src/api';
import { useStyles } from '../styles';

interface ICategoryModal {
  isOpen: boolean;
  handleClose: Function;
  fecthStages: Function;
  category: ICategory;
}

const schema = yup.object().shape({
  bitrixExternalStageName: yup.string().required('Это обязательное поле'),
  bitrixExternalStageId: yup.string().required('Это обязательное поле'),
});

export const StageModal: React.FC<ICategoryModal> = ({
  isOpen, handleClose, fecthStages, category,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const {
    register, handleSubmit, formState, trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (data:any) => {
    // eslint-disable-next-line no-param-reassign
    data.bitrixCategory = category['@id'];
    const res = await postBitrixStage(jwt, data);
    if (res.ok) {
      handleClose();
      fecthStages();
    } else {
      setError(res.data['hydra:description']);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      onClose={() => handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
          }}
        >
          <Box width="600px" maxHeight="90vh" overflow="auto" py={10} px={11}>
            <Box mb={6} fontSize="24px" fontWeight={600}>
              Добавление нового Stage
            </Box>
            <Box fontSize="18px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={4} display="flex" flexDirection="column">
                  STAGE ID из BITRIX
                  <Box width="260px" mt={2}>
                    <TextField
                      id="bitrixExternalStageId"
                      variant="outlined"
                      name="bitrixExternalStageId"
                      inputRef={register}
                    />
                  </Box>
                </Box>
                <Box mb={4} display="flex" flexDirection="column">
                  STAGE  NAME из BITRIX
                  <Box width="260px" mt={2}>
                    <TextField
                      id="bitrixExternalStageName"
                      variant="outlined"
                      name="bitrixExternalStageName"
                      inputRef={register}
                    />
                  </Box>
                </Box>
                <Box width="260px">
                  <ButtonApp
                    text={translations.safeChanges}
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={styles.btn}
                    disabled={!formState.isValid}
                  />
                </Box>
              </form>
              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                  {error}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
