import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  closeButton: {
    color: theme.palette.primary.main,
    backgroundColor: '#F6F7F6 !important',
    borderRadius: '10px',
    minWidth: '40px',
    minHeight: '40px',
    width: '40px',
    height: '40px',
    '&:hover': {
      backgroundColor: '#F6F7F6 !important',
    },
    '& span[class*="startIcon"]': {
      margin: '0px',
    },
  },
  languageButton: {
    position: 'absolute',
    top: '20px',
    right: '70px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuClassName: (props: any) => ({
    backgroundColor: props.filterBackgroundColor,
    overflow: 'hidden',
    padding: '70px 0px 0px',
    '& *': {
      outline: 'none',
    },
  }),
  crossButtonClassName: {
    width: '40px !important',
    height: '40px !important',
    top: '20px !important',
    right: '20px !important',
  },
  itemListClassName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    padding: '0px',
  },
  menuColumnWrapper: {
    display: 'flex !important',
    flexDirection: 'column',
    padding: '0px 10px',
    margin: 'auto',
  },
  itemListItemClassNameTitle: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#898787',
    padding: '10px 0px',
  },
  itemListItemClassName: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: 'black',
    padding: '5px 0px',
  },
  menuBody: {
    '& #root': {
      overflow: 'hidden',
    },
  },
  headerLogo: {
    '& > a': {
      color: theme.palette.primary.main,
      fontSize: '20px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      display: 'flex',
    },
  },
  wrapperLogo: {
    position: 'absolute',
    top: '30px',
    left: '20px',
  },
  wrapperMenuContent: {
    display: 'flex !important',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  menuContent: {
    overflow: 'auto',
    padding: '20px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneContent: {
    padding: '20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& > a': {
      fontSize: '20px',
      padding: '3px 0px',
    },
  },
}));
