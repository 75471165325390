import React, { useRef, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import { ApiUrl } from 'src/constants';
import { pathList } from 'src/containers/Profile/routeList';
import {
  useGetAuthData, useGetComplex, useGetHouseById, useSetDocumentTitle, useGetHouseHistory,
} from 'src/hooks';
import { importPlacements, uploadPlacementXlsxByHouse } from 'src/api';
import { borderColor } from 'src/constants/styles';
import { IComplex } from 'src/typings/complexes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { isEmpty } from 'lodash';
import { useStyles } from './styles';
import { appIcon } from '../../../assets';
import { HouseHistoryBlock } from '../../HouseHistoryBlock';
import {
  IPlacementErrors, IPlacementInserts, IPlacementUpdates, IUpdatedHouseHistoryItem,
} from '../../../typings/house';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ISnackData {
  type: 'error' | 'success';
  text: string;
}

export const HouseStorage = () => {
  const styles = useStyles({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { id, houseId }: { id: string; houseId: string } = useParams();
  const complex: IComplex|null = useGetComplex(id);
  const { data: house } = useGetHouseById(houseId);
  const { data: houseHistory, refetch } = useGetHouseHistory(houseId);
  const sortedHouseHistory: IUpdatedHouseHistoryItem[] | null = useMemo(() => {
    if (houseHistory) {
      return houseHistory?.reverse().map((item) => {
        const { response } = item;
        const inserts: {
          [key: string]: IPlacementInserts[]
        } = {};
        const updates: {
          [key: string]: IPlacementUpdates[]
        } = {};
        const errors: {
          [key: string]: IPlacementErrors[]
        } = {};

        response.forEach((resp) => {
          if (resp.inserts && resp.placement && !isEmpty(resp.inserts)) {
            inserts[resp.placement] = resp.inserts;
          }
          if (resp.updates && resp.placement && !isEmpty(resp.updates)) {
            updates[resp.placement] = resp.updates;
          }
          if (resp.errors && !isEmpty(resp.errors)) {
            const errorsWithRowNumber = resp.errors.map((error) => ({
              ...error,
              rowNumber: resp.rowNumber,
            }));
            if (resp.placement) {
              errors[resp.placement] = errorsWithRowNumber;
            } else if (errors.unnamed) {
              errors.unnamed = errors.unnamed.concat(errorsWithRowNumber);
            } else {
              errors.unnamed = errorsWithRowNumber;
            }
          }
        });
        return {
          ...item,
          sortedResponses: {
            inserts,
            updates,
            errors,
          },
        };
      });
    }
    return null;
  }, [houseHistory]);
  const history = useHistory();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  useSetDocumentTitle(translations.storage);

  const [isImportDisabled, setIsImportDisabled] = useState<boolean>(false);
  const [snackData, setSnackData] = useState<ISnackData | null>(null);

  const handleCloseSnack = () => setSnackData(null);

  const handleImportClick = () => {
    // 👇️ open file input box on click of other element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj || !house) {
      return;
    }

    const formData = new FormData();
    formData.append('file', fileObj);
    setIsImportDisabled(true);
    importPlacements(jwt, house.id, formData).then((res: any) => {
      setIsImportDisabled(false);
      if (res.ok) {
        setSnackData({ type: 'success', text: 'Import is completed successfully' });
        refetch();
      } else {
        setSnackData({ type: 'error', text: 'There was a import error' });
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }).catch((error: any) => {
      setIsImportDisabled(false);
    });
  };

  if (!complex || !house || !sortedHouseHistory) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }
  console.log('🚀 ~ file: index.tsx:149 ~ HouseStorage ~ sortedHouseHistory:', sortedHouseHistory);

  const {
    language,
    title,
  } = complex;
  const crumbs: ICrumbs[] = [

    {
      name: translations.complexesLink,
      url: `/profile/${pathList.complexes}`,
    },
    {
      name: `${translations.settings} ${title[language]}`,
      url: `/profile/${pathList.complexes}/${id}/`,
    },
    {
      name: `${translations.storage} ${title[language]}`,
      url: `/profile/${pathList.complexes}/${id}/storage`,
    },
    {
      name: `${translations.house} ${house.numeration[language]}`,
    },
  ];

  return (
    <Box py={5} px={4}>
      <ButtonApp
        text={translations.goBack}
        onClick={() => {
          history.push(`/profile/${pathList.complexes}/${id}/`);
        }}
        type="button"
        color="inherit"
        size="small"
        isCapitalize
        variant="outlined"
        startIcon={<ArrowBackIcon />}
      />
      <Box fontSize="32px" mb={1} mt={2} fontWeight={700}>
        {`${translations.storage} ${title[language]}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box>
        <Box
          borderBottom={`1px solid ${borderColor}`}
          display="flex"
          justifyContent="space-between"
          py={2}
          px={2}
        >
          <Box fontSize="20px">
            {`${translations.house} ${house.numeration[language]}`}
          </Box>
          <Box display="flex">
            <Box mr={2}>
              <ButtonApp
                text={isImportDisabled ? 'Loading' : translations.import}
                onClick={handleImportClick}
                type="button"
                color="primary"
                size="small"
                isCapitalize
                disabled={isImportDisabled}
                variant="contained"
                startIcon={isImportDisabled ? (
                  <CircularProgress
                    size={18}
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : <PublishIcon />}
              />
            </Box>
            <Box>
              <ButtonApp
                onClick={() => uploadPlacementXlsxByHouse(house.id, house.numeration[language], title[language], jwt)}
                text={translations.export}
                type="button"
                color="primary"
                size="small"
                isCapitalize
                variant="contained"
                startIcon={<GetAppIcon />}
              />
            </Box>
          </Box>
        </Box>
        {sortedHouseHistory.map((item) => {
          return (
            <HouseHistoryBlock
              houseHistoryItem={item}
              houseNumeration={house.numeration[language]}
              complexName={title[language]}
              key={item['@id']}
            />
          );
        })}

      </Box>
      <Box display="none">
        <input
          ref={fileInputRef}
          onChange={handleFileChange}
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </Box>
      <Snackbar open={!!snackData} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={snackData?.type} sx={{ width: '100%' }}>
          {snackData?.text}
        </Alert>
      </Snackbar>
    </Box>
  );
};
