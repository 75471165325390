import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBuildType } from 'src/hooks';

import { IModuleGTM } from 'src/typings/app';
import { requestSelectors } from 'src/store/requests/selectors';

const getScriptTag = (str: string, tagOpen: string, tagClose: string) => {
  const temp = str.substring(str.lastIndexOf(tagOpen) + tagOpen.length, str.lastIndexOf(tagClose));
  return temp;
};

export const useModuleGTM = (): IModuleGTM | null | undefined => {
  const moduleGTM: IModuleGTM | null | undefined = useSelector(requestSelectors.app.getModuleGTM);

  if (!moduleGTM) return moduleGTM;

  const head = getScriptTag(moduleGTM.head, '<script>', '</script>');
  const body = getScriptTag(moduleGTM.body, '<noscript>', '</noscript>');

  return {
    head,
    body,
  };
};

export const useInitGTM = () => {
  const moduleGTM = useModuleGTM();
  const { isCrossTable } = useBuildType();

  useEffect(() => {
    if (moduleGTM && !isCrossTable) {
      const nodeHead = document.querySelector('head');
      const nodeBody = document.querySelector('body');
      if (nodeBody && nodeHead) {
        const scriptHead = document.createElement('script');
        scriptHead.innerHTML = moduleGTM.head;
        nodeHead.appendChild(scriptHead);
        const noscriptBody = document.createElement('noscript');
        noscriptBody.innerHTML = moduleGTM.head;
        nodeBody.appendChild(noscriptBody);
      }
    }
  }, [moduleGTM]);
};
