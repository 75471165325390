import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  spinnerFloor: {
    pointerEvents: 'none',
    width: '100%',
    paddingBottom: '20px',
    '& .wind_rose': {
      display: 'none',
    },
    '& .spinner_control': {
      display: 'none',
      borderRadius: '5px',
      padding: '10px',
      bottom: '5px',
      '& .turn_left, & .turn_right': {
        display: 'none',
      },
      '& .spinner_zoom': {
        marginLeft: '0px',
      },
    },
    '& .spinner_app_canvas': {
      pointerEvents: 'none',
      touchAction: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '20px 0px',
      minWidth: 'none',
      maxWidth: 'none',
      maxHeight: 'none',
    },

  },
}));
