import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';
import { ISpinnerDataPossibilityInnerData, ISpinnerSvgData } from 'src/typings/spinner';

export const useGetSpinnerSvg = (
  spinnerData: ISpinnerDataPossibilityInnerData,
) => {
  const [displayedSvgData, setDisplayedSvgData] = useState<ISpinnerSvgData | null>(null);
  const spinnerSvgCurrentData = useSelector(requestSelectors.spinner.getSpinnerViewData);

  useEffect(() => {
    if (!displayedSvgData) {
      setDisplayedSvgData(
        spinnerData.additional_data.spinner_svg,
      );
    }
  }, [displayedSvgData, spinnerSvgCurrentData, spinnerData]);

  return {
    displayedSvgData,
  };
};
