import { useCallback, useEffect, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';

import { useGetFilterParams } from 'src/hooks';
import { IAirbnbSlider } from './index';

export const useSliderState = (
  data: IAirbnbSlider,
  fieldName: string,
  currentValue: string[]|undefined,
  setPlaceholder: (range: string) => void,
) => {
  const { filterParams } = useGetFilterParams();
  const [sliderDefaultState, setSliderDefaultState] = useState<number[]>(
    [+data.range.min, +data.range.max],
  );
  const [sliderState, setSliderState] = useState<number[]>(
    currentValue ? currentValue.map((item) => Number(item)) : [+data.range.min, +data.range.max],
  );

  const handleChangeFromInput = useCallback((value: NumberFormatValues) => {
    setSliderState([+value.value, sliderState[1]]);
  }, [sliderState]);

  const handleChangeToInput = useCallback((value: NumberFormatValues) => {
    setSliderState([sliderState[0], +value.value]);
  }, [sliderState]);

  const controllerWriteValues = useCallback(() => {
    const correctedValue = [0, 0];
    if (sliderState[0] < sliderDefaultState[0] || sliderState[0] > sliderState[1]) {
      correctedValue[0] = sliderDefaultState[0];
    } else {
      correctedValue[0] = sliderState[0];
    }

    if (sliderState[1] > sliderDefaultState[1] || sliderState[1] < sliderState[0]) {
      correctedValue[1] = sliderDefaultState[1];
    } else {
      correctedValue[1] = sliderState[1];
    }

    setSliderState(correctedValue);
    setPlaceholder(correctedValue.join(' - '));
  }, [setPlaceholder, sliderDefaultState, sliderState]);

  useEffect(() => {
    if (filterParams[fieldName]?.data.length === 0) {
      setSliderState([+data.range.min, +data.range.max]);
      setSliderDefaultState([+data.range.min, +data.range.max]);
    }
  }, [filterParams, fieldName, data.range.min, data.range.max]);

  return {
    sliderState,
    setSliderState,
    handleChangeFromInput,
    handleChangeToInput,
    controllerWriteValues,
  };
};
