import {
  takeEvery, put, call, select, all,
} from 'redux-saga/effects';
import { IAppRequest } from 'src/typings/app';
import { ActionTypes } from 'src/store/requests/app/actions';
import { getComplexId } from 'src/store/app/selectors';
import { getPanoramaByEntity, getSpinnerByEntity } from 'src/api';
import { Actions as ActionsPano } from './actions';

export function* setPanoramaDataParams(action: {
  type: string,
  payload: IAppRequest,
}) {
  const complexId: number | null = yield select(getComplexId);
  if (action.payload && complexId) {
    const {
      entity_inner_data_relation,
    } = action.payload;

    const panoramas: any = {};

    // @ts-ignore
    const responsesComp: any = yield call(async () => {
      const res = await Promise.all([
        getPanoramaByEntity(complexId, 'complex'),
        getSpinnerByEntity(complexId, 'complex'),
      ]);
      const allViews = res.flatMap((views) => views.data['hydra:member']).sort((a, b) => a.sequence - b.sequence);
      return allViews;
    });
    if (responsesComp.length) {
      if (!panoramas.complex) {
        panoramas.complex = {};
      }
      panoramas.complex[complexId] = responsesComp;
    }

    if (entity_inner_data_relation.house) {
      // @ts-ignore
      const responses: any = yield all(Object.keys(entity_inner_data_relation.house).map((houseId) => {
        return call(async () => {
          const res = await Promise.all([
            getPanoramaByEntity(houseId, 'house'),
            getSpinnerByEntity(houseId, 'house'),
          ]);
          const allViews = res.flatMap((views) => views.data['hydra:member']).sort((a, b) => a.sequence - b.sequence);
          return allViews;
        });
      }));

      if (responses.length) {
        Object.keys(entity_inner_data_relation.house).forEach((houseId, index) => {
          if (responses[index].length) {
            if (!panoramas.house) {
              panoramas.house = {};
            }
            panoramas.house[houseId] = responses[index];
          }
        });
      }
    // const testPano: any = yield call(() => getPanoramaData('93'));
    // panoramas[38] = [testPano.data];
    }

    if (Object.keys(panoramas).length) {
      yield put(ActionsPano.setAvailablePanoramas(panoramas));

      const entityName = Object.keys(panoramas)[0];
      yield put(ActionsPano.setCurrentPanorama(
        {
          entity: entityName,
          entityId: Object.keys(panoramas[entityName])[0],
          id: panoramas[entityName][Object.keys(panoramas[entityName])[0]][0].id,
        },
      ));
    }
  }
}

export function* getPanoramaWatchSaga() {
  yield takeEvery(ActionTypes.GET_APP_DATA_REQUEST_SUCCESS, setPanoramaDataParams);
}
