import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperDropdown: {
    width: '49%',
    marginBottom: '10px',
    '& .menu': {
      maxHeight: '320px !important',
      '& div[class*="rangeInput"]': {
        flexDirection: 'column',
        '& > div': {
          '&:first-child': {
            marginRight: '0px',
            marginBottom: '10px',
          },
          '&:last-child': {
            marginLeft: '0px',
            '& input': {
              textAlign: 'left',
            },
          },
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  isHide: {
    display: 'none',
  },
}));
