import * as Yup from 'yup';

import { FormTypes, FormFields } from 'src/typings/forms';
import { locals, phoneRegExp } from 'src/constants';
import { appTranslations } from 'src/constants/locals/appTranslation';
import { IStandartContactForm } from 'src/typings/app';

export const getBookNowFormSchema = (standartForm: IStandartContactForm | null | undefined) => [
  {
    fieldType: FormTypes.phone,
    name: FormFields.phone,
    type: 'tel',
    initialValue: '',
    placeholder: appTranslations.appPhoneNumber,
    validation: Yup.string()
      .required(appTranslations.appErrorFieldIsRequired)
      .transform((value) => value.replace(/\s+/gi, ''))
      .transform((value) => value.replace(')', ''))
      .transform((value) => value.replace('(', ''))
      .transform((value) => value.replaceAll('-', ''))
      .matches(phoneRegExp, { message: locals.appTranslations.appErrorPhoneNumberFormat }),
    options: {
      mask: '+38 999 999 99 99',
    },
  },
  {
    fieldType: FormTypes.email,
    name: FormFields.email,
    type: 'email',
    initialValue: '',
    placeholder: appTranslations.appEmail,
    validation: Yup.string()
      .email(appTranslations.appErrorEmailFormat),
    options: {},
  },
  {
    fieldType: FormTypes.text,
    name: FormFields.bio,
    type: 'text',
    initialValue: '',
    placeholder: appTranslations.appUserName,
    options: {},
  },
  {
    fieldType: FormTypes.textarea,
    name: FormFields.additionalInfo,
    type: 'text',
    initialValue: '',
    placeholder: appTranslations.additionalInfo,
    options: {},
  },
  {
    fieldType: FormTypes.hidden,
    name: FormFields.url,
    type: 'hidden',
    initialValue: document.location.href,
    placeholder: '',
    options: {},
  },
];
