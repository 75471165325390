import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperCheckbox: {
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    padding: '20px 0px',
  },
}));
