/* eslint-disable max-len */
import React from 'react';

export const HouseIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path className="appStrokeStyles" d="M3 21H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path className="appStrokeStyles" d="M19 21V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path className="appStrokeStyles" d="M19 17C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V13C21 12.4696 20.7893 11.9609 20.4142 11.5858C20.0391 11.2107 19.5304 11 19 11C18.4696 11 17.9609 11.2107 17.5858 11.5858C17.2107 11.9609 17 12.4696 17 13V15C17 15.5304 17.2107 16.0391 17.5858 16.4142C17.9609 16.7893 18.4696 17 19 17Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path className="appStrokeStyles" d="M14 21V7C14 6.20435 13.6839 5.44129 13.1213 4.87868C12.5587 4.31607 11.7956 4 11 4H7C6.20435 4 5.44129 4.31607 4.87868 4.87868C4.31607 5.44129 4 6.20435 4 7V21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path className="appStrokeStyles" d="M9 17V21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path className="appStrokeStyles" d="M8 13H10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path className="appStrokeStyles" d="M8 9H10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
