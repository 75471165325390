import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  containerDesktopFloorsList: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    right: '20px',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  floorItem: {
    border: `1px solid ${theme.palette.grey['200']}`,
    cursor: 'pointer',
    fontSize: '17px',
    width: '48px',
    background: 'white',
    padding: '3px 5px',
    textAlign: 'center',
    transition: 'all 0.25s',
  },
  containFilteredElement: {
    background: theme.palette.primary.light,
  },
  notContainFilteredElement: {
    opacity: 0.9,
  },
  activeFloor: {
    background: `${theme.palette.primary.main}`,
    border: `1px solid ${theme.palette.primary.main}`,
    color: '#ffffff',
    fontWeight: 'bold',
  },
  arrowStyle: {
    backgroundColor: '#ecebeb',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  arrowStyleUp: {
    borderRadius: '10px 10px 0px 0px',
  },
  arrowStyleDown: {
    borderRadius: '0px 0px 10px 10px',
  },
  arrowDisable: {
    backgroundColor: '#ecebeb',
  },
  wrapperFloorList: {
    overflow: 'hidden',
    maxHeight: '320px',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
}));
