import { useSelector } from 'react-redux';

import { getSelectedSection } from 'src/store/floors/selectors';

export const useGetCurrentSection = () => {
  const currentSection = useSelector(getSelectedSection);

  return {
    currentSection,
  };
};
