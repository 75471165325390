import { useCallback } from 'react';

import { useGetFilterParams, useSetFilterParams } from 'src/hooks';

export const useCheckboxState = (
  nameOfField: string,
) => {
  const { filterParams } = useGetFilterParams();
  const { handleWriteToRedux } = useSetFilterParams();

  const handleSelectSingleCheckbox = useCallback((value: string) => {
    if (filterParams[nameOfField]?.isDisabled) {
      return;
    }

    if (value === filterParams[nameOfField]?.data) {
      handleWriteToRedux(
        nameOfField,
        '',
      );
    } else {
      handleWriteToRedux(
        nameOfField,
        value,
      );
    }
  }, [filterParams, nameOfField, handleWriteToRedux]);

  return {
    filterValue: filterParams[nameOfField]?.data || '',
    handleSelectSingleCheckbox,
  };
};
