import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface ITranslationReducer {
  isLoading: boolean;
  error: Error | null;
  data: ITranslationData | null;
}

export interface ITranslationData {
  [key: string]: string;
}

export const initialState: ITranslationReducer = {
  isLoading: true,
  error: null,
  data: null,
};

export const translationReducer = (
  state = initialState,
  { type, payload }: IAction,
): ITranslationReducer => {
  switch (type) {
    case ActionTypes.GET_TRANSLATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.GET_TRANSLATION_RESET: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.GET_TRANSLATION_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    case ActionTypes.GET_TRANSLATION_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    default:
      return state;
  }
};
