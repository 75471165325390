import { IRouteList } from 'src/route-list';
import { ApiUrl } from 'src/constants/config';
import { IMessage } from 'src/typings/app';
import { IFilterAllData } from 'src/typings/filter';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

interface ICurrentEntity {
  name: string | null;
  id: string | null;
}

export interface IAppStateReducer {
  isBookModalOpen: boolean;
  isMenuOpen: boolean;
  isFilterOpen: boolean;
  isMobileFilterOpen: boolean;
  isShowNestedFilter: boolean;
  isEndDraggableMobileMenu: boolean;
  showInfrastructure: boolean;
  spinnerDisableFilter: boolean;
  favoriteCount: number;
  crumbs: IRouteList[];
  languages: string[];
  currentLang: string;
  jwtToken: string;
  complexId: number | null;
  crmData: IMessage | null;
  selectedAppartment: string | null;
  bindingAppartment: boolean;
  isOpenAppartSidebar: boolean;
  currentEntity: ICurrentEntity;
}

export const initialState: IAppStateReducer = {
  isBookModalOpen: false,
  isMenuOpen: false,
  isFilterOpen: false,
  isMobileFilterOpen: false,
  isShowNestedFilter: false,
  isEndDraggableMobileMenu: true,
  showInfrastructure: true,
  spinnerDisableFilter: false,
  favoriteCount: 0,
  crumbs: [],
  languages: [],
  currentLang: '',
  jwtToken: '',
  complexId: null,
  crmData: null,
  selectedAppartment: null,
  bindingAppartment: false,
  isOpenAppartSidebar: false,
  currentEntity: {
    name: null,
    id: null,
  },
};

export const appReducer = (
  state = initialState,
  { type, payload }: IAction,
): IAppStateReducer => {
  switch (type) {
    case ActionTypes.SET_CRUMBS: {
      return {
        ...state,
        crumbs: payload,
      };
    }

    case ActionTypes.IS_BOOK_NOW_OPEN: {
      return {
        ...state,
        isBookModalOpen: payload,
      };
    }

    case ActionTypes.IS_FILTER_OPEN: {
      return {
        ...state,
        isFilterOpen: payload,
      };
    }

    case ActionTypes.IS_SHOW_NESTED_FILTER: {
      return {
        ...state,
        isShowNestedFilter: payload,
      };
    }

    case ActionTypes.IS_END_DRAGGABLE_MOBILE_MENU: {
      return {
        ...state,
        isEndDraggableMobileMenu: payload,
      };
    }

    case ActionTypes.SHOW_INFRASTRUCTURE: {
      return {
        ...state,
        showInfrastructure: payload,
      };
    }

    case ActionTypes.IS_MENU_OPEN: {
      return {
        ...state,
        isMenuOpen: payload,
      };
    }

    case ActionTypes.JWT_TOKEN: {
      return {
        ...state,
        jwtToken: payload,
      };
    }

    case ActionTypes.IS_MOBILE_FILTER_OPEN: {
      return {
        ...state,
        isMobileFilterOpen: payload,
      };
    }

    case ActionTypes.SET_FAVORITE_COUNT: {
      return {
        ...state,
        favoriteCount: payload,
      };
    }

    case ActionTypes.SET_AVAILABLE_LANGUAGES: {
      return {
        ...state,
        languages: payload,
      };
    }

    case ActionTypes.SET_CURRENT_LANG: {
      return {
        ...state,
        currentLang: payload,
      };
    }

    case ActionTypes.SET_SPINNER_DISABLED_FILTER: {
      return {
        ...state,
        spinnerDisableFilter: payload,
      };
    }

    case ActionTypes.SET_COMPLEX_ID: {
      ApiUrl.setComplexId(String(payload));
      return {
        ...state,
        complexId: payload,
      };
    }

    case ActionTypes.SET_AMOCRM_DATA: {
      return {
        ...state,
        crmData: payload,
      };
    }

    case ActionTypes.SET_BINDING_APPARTMENT: {
      return {
        ...state,
        bindingAppartment: payload,
      };
    }

    case ActionTypes.SELECT_APPARTMENT: {
      return {
        ...state,
        selectedAppartment: payload,
      };
    }

    case ActionTypes.IS_OPEN_APPART_SIDEBAR: {
      return {
        ...state,
        isOpenAppartSidebar: payload,
      };
    }

    case ActionTypes.SET_CURRENT_ENTITY: {
      return {
        ...state,
        currentEntity: payload,
      };
    }

    default:
      return state;
  }
};
