import { IRangeAndSet } from 'src/typings/filter';
import { ISetFilterParameterInfrastructure } from 'src/store/filter/filter-params/reducers';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface IInfrastructureReducer {
  [key: string]: {
    [key: string]: {
      infrastructureFilters: IRangeAndSet[];
      infrastructureFiltersValues: {
        [key: string]: any;
      };
      infrastructureFilteredIds: string[];
    }
  };
}

export const initialState: IInfrastructureReducer = {};

export const infrastructureReducer = (
  state = initialState,
  { type, payload }: IAction,
): IInfrastructureReducer => {
  switch (type) {
    case ActionTypes.SET_INFRASTRUCTURE_FILTERS_VALUES_INITIAL: {
      return {
        ...state,
        ...payload,
      };
    }

    case ActionTypes.SET_INFRASTRUCTURE_FILTER_VALUE: {
      if (!payload) {
        return {
          ...state,
        };
      }

      // @ts-ignore
      const payloadConvert: ISetFilterParameterInfrastructure = payload;

      return {
        ...state,
        [payloadConvert.currentSpinner.name]: {
          ...state[payloadConvert.currentSpinner.name],
          [payloadConvert.currentSpinner['name-id']]: {
            ...state[payloadConvert.currentSpinner.name][payloadConvert.currentSpinner['name-id']],
            infrastructureFiltersValues: {
              ...state[payloadConvert.currentSpinner.name][payloadConvert.currentSpinner['name-id']].infrastructureFiltersValues,
              [payloadConvert?.typeParam]: {
                typeParam: payloadConvert?.typeParam,
                data: payloadConvert?.data,
                isDisabled: payloadConvert?.isDisabled,
              },
            },
            infrastructureFilteredIds: payloadConvert.ids || [],
          },
        },
      };
    }

    default:
      return state;
  }
};
