import React from 'react';

import { useTypeDisplayedSpinner } from 'src/hooks';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { View2d } from 'src/components/View2d';
import { ViewLayout } from 'src/components/ViewLayouts';
import { ViewFloors } from 'src/components/ViewFloors';
import { ViewSpinner } from 'src/components/ViewSpinner';
import { IFullSizeFloor } from 'src/components/ViewSpinner/types';

interface ICrossTableTypesRender extends IFullSizeFloor {}

export const CrossTableTypesRender: React.FC<ICrossTableTypesRender> = ({
  setFullSizeFloor,
}) => {
  const { getSelectedViewId } = useTypeDisplayedSpinner();
  const isSpinner = TypesOfSpinner.viewSpinner === getSelectedViewId;
  const isFloors = TypesOfSpinner.viewFloors === getSelectedViewId;

  const renderingSelector = () => {
    switch (getSelectedViewId) {
      case TypesOfSpinner.view2d:
        return <View2d />;
      case TypesOfSpinner.viewLayouts:
        return <ViewLayout />;
      case TypesOfSpinner.viewFloors:
        return (
          <>
            <ViewFloors />
            <ViewSpinner
              isFloors={isFloors}
              setFullSizeFloor={setFullSizeFloor}
            />
          </>
        );
      case TypesOfSpinner.viewSpinner:
        return (
          <ViewSpinner
            isFloors={isFloors}
            setFullSizeFloor={setFullSizeFloor}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderingSelector()}
    </>
  );
};
