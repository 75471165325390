import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';
import { ITranslationData } from 'src/store/requests/translations/reducer';
import { useCallback } from 'react';

export const useTranslations = (keyNameFixed?: string) => {
  const translationsData: ITranslationData | null = useSelector(requestSelectors.translations.getTranslationData);

  const generateTranslations = useCallback((keyName: string) => {
    if (translationsData) {
      if (translationsData[keyName]) {
        return translationsData[keyName];
      }

      return keyName;
    }

    return keyName;
  }, [translationsData]);

  const getTranslations = useCallback((keyName: string) => {
    return generateTranslations(keyName);
  }, [generateTranslations]);

  const getFixedTranslations = useCallback(() => {
    if (keyNameFixed) {
      return generateTranslations(keyNameFixed);
    }

    return '';
  }, [generateTranslations, keyNameFixed]);

  return {
    getTranslations,
    getFixedTranslations,
  };
};
