import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { httpService } from 'src/services';
import { useGetAuthData } from 'src/hooks';
import { pathList } from 'src/route-list';

export const useSendFormLogin = () => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>('');
  const { setJWT } = useGetAuthData();
  const history = useHistory();

  const cleanError = () => setIsError('');
  const handleSendForm = useCallback((data: any) => {
    if (!isSending) {
      setIsSending(true);
      setIsError('');

      httpService.patch(
        'https://pro-api-dev.flat.show/api/login_check',
        data,
        {
          'Content-Type': 'application/json',
        },
      )
        .then((res: any) => {
          setIsSending(false);
          if (!res.ok) {
            setIsError(res.data.message);
          } else {
            setJWT(res.data.token);
            setTimeout(() => {
              history.push(pathList.profileComplexes);
            }, 500);
          }
        })
        .catch(() => {
          setIsSending(false);
          setIsError('Something went wrong');
        });
    }
  }, [isSending, history, setJWT]);

  return {
    isSending,
    isError,
    cleanError,
    handleSendForm,
  };
};

export const useRecoveryForms = (changeStep: Function) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>('');
  const history = useHistory();

  const cleanError = () => setIsError('');
  const handleSendEmail = useCallback((data: any) => {
    if (!isSending) {
      setIsSending(true);
      setIsError('');

      httpService.post(
        'https://pro-api-dev.flat.show/api/forgot-password',
        JSON.stringify(data),
        {
          'Content-Type': 'application/json',
        },
      )
        .then((res: any) => {
          setIsSending(false);
          if (!res.ok) {
            setIsError(res.data.message || res.data.detail);
          } else {
            changeStep(1);
          }
        })
        .catch(() => {
          setIsSending(false);
          setIsError('Something went wrong');
        });
    }
  }, [isSending, changeStep]);

  const handleSendCode = useCallback((code: string) => {
    if (!isSending) {
      setIsSending(true);
      setIsError('');

      httpService.get(
        `https://pro-api-dev.flat.show/api/forgot-password/${code}`,
      )
        .then((res: any) => {
          setIsSending(false);
          if (!res.ok) {
            setIsError(res.data.message || res.data.detail);
          } else {
            changeStep(2);
          }
        })
        .catch(() => {
          setIsSending(false);
          setIsError('Something went wrong');
        });
    }
  }, [isSending, changeStep]);

  const handleSendPass = useCallback((pass: string, code: string) => {
    if (!isSending) {
      setIsSending(true);
      setIsError('');

      httpService.patch(
        `https://pro-api-dev.flat.show/api/forgot-password/${code}`,
        {
          password: pass,
        },
        {
          'Content-Type': 'application/json',
        },
      )
        .then((res: any) => {
          setIsSending(false);
          if (!res.ok) {
            setIsError(res.data.message || res.data.detail);
          } else {
            setTimeout(() => {
              history.push(pathList.home);
            }, 500);
          }
        })
        .catch(() => {
          setIsSending(false);
          setIsError('Something went wrong');
        });
    }
  }, [isSending, history]);

  return {
    isSending,
    isError,
    cleanError,
    handleSendEmail,
    handleSendCode,
    handleSendPass,
  };
};
