/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import { IInfrastructureTypeVideo } from '../../types';
import { useStyles } from '../../styles';

export const InfrastructureVideoLoop: React.FC<IInfrastructureTypeVideo> = ({
  data,
}) => {
  const styles = useStyles({});

  return (
    <div className={styles.wrapperInfrastructureType}>
      <video autoPlay loop controls playsInline width="100%" height="400px">
        <source src={data['infrastructure.src']} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
