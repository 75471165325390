import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'src/store/spinner/actions';

import { currentSpinnerRerenderComplete } from 'src/store/spinner/selectors';

export const useRerenderSpinner = () => {
  const dispatch = useDispatch();
  const isRender = useSelector(currentSpinnerRerenderComplete);

  useEffect(() => {
    if (isRender === false) {
      // window.stop();
      dispatch(Actions.setRenderCompleteSpinner());
    }
  }, [isRender]);

  return {
    isRender,
  };
};
