import { action } from 'src/store/common';

export const ActionTypes = {
  SET_LIST_TYPES_SUCCESS: 'SET_LIST_TYPES_SUCCESS',
  SET_LIST_TYPES_FAIL: 'SET_LIST_TYPES_FAIL',
  SET_TYPE_DISPLAYED_SPINNER: 'SET_TYPE_DISPLAYED_SPINNER',
};

export const Actions = {
  setTypeDisplayedSpinner: action<string>(ActionTypes.SET_TYPE_DISPLAYED_SPINNER),
  setListTypesSuccess: action<string>(ActionTypes.SET_LIST_TYPES_SUCCESS),
  setListTypesFail: action<string>(ActionTypes.SET_LIST_TYPES_FAIL),
};
