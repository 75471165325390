import { ApiResponse } from 'apisauce';
import { locals } from 'src/constants';

/**
 * @param {(Error|string|undefined)} error
 * @return {string}
 */
export const getErrorMessage = (error: Error) => {
  if (typeof error === 'string') {
    return error;
  }

  return locals.errors.unexpectedError;
};

/**
 * @param {ApisauceResponse} response
 * @return {string}
 */
export const getApiErrorMessage = (response: ApiResponse<any>) => {
  const ApisauceProblemCodes = {
    CLIENT_ERROR: 'CLIENT_ERROR',
    SERVER_ERROR: 'SERVER_ERROR',
    TIMEOUT_ERROR: 'TIMEOUT_ERROR',
    CONNECTION_ERROR: 'CONNECTION_ERROR',
    NETWORK_ERROR: 'NETWORK_ERROR',
    CANCEL_ERROR: 'CANCEL_ERROR',
  };

  switch (response.problem) {
    case ApisauceProblemCodes.CLIENT_ERROR:
      return getErrorMessage(response.data?.detail);
    case ApisauceProblemCodes.SERVER_ERROR:
      return locals.errors.server;
    case ApisauceProblemCodes.TIMEOUT_ERROR:
      return locals.errors.timeout;
    case ApisauceProblemCodes.CONNECTION_ERROR:
      return locals.errors.connection;
    case ApisauceProblemCodes.NETWORK_ERROR:
      return locals.errors.network;
    case ApisauceProblemCodes.CANCEL_ERROR:
      return locals.errors.cancelRequest;
    default:
      return '';
  }
};
