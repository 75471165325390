/* eslint-disable no-param-reassign */

export const setHoveredPoly = (id: string) => {
  if (document.mainPano) {
    const cldrns = document.mainPano.scene.children;
    const hlghtItem = cldrns.find((child: any) => {
      // @ts-ignore
      if (child instanceof window.THREE.Mesh) {
        const dataPoly = child.dataSet.get('transition');
        return id === String(dataPoly.id);
      }
      return false;
    });
    if (hlghtItem) {
      const hovered = hlghtItem.styleList.has('hover');
      if (hovered) {
        hlghtItem.styleList.delete('hover');
      } else {
        hlghtItem.styleList.add('hover');
      }
    }
  }
};

export function blinkAnimate(target: any, styleName = 'blink') {
  if (target.styleList) {
    if (!target.aid) {
      target.aid = window.setAnimate((n: any) => {
        if (target.parent) {
          const val = (n >= 0.5 ? 1 - n : n) * 2;

          target.material.opacity = val * 0.3; // умножаем на максимально допустимое значение

          if (target.outline) {
            target.outline.material.opacity = val; // умножаем на максимально допустимое  значение
          }

          if (n === 1) {
            target.aid = 0;
            if (target.styleList.has(styleName)) blinkAnimate(target);
          }
        } else {
          window.clearAnimate(target.aid);
        }
      }, 1000); // продолжительность анимации
    }
  }
}
