import { create } from 'apisauce';

import { ApiUrl } from 'src/constants';

class HTTPService {
  private _instance: any;

  static AUTH_HEADER_KEY = 'Authorization';

  constructor() {
    this._instance = create({
      baseURL: ApiUrl.getApiUrl().base,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: '*/*',
      },
    });
  }

  /**
   * @param {string} url
   * @param {Object} [params]
   * */
  get(url: string, params = {}, headers: any = {}) {
    return this._instance.get(url, params, { headers });
  }

  /**
   * @param {string} url
   * @param {Object} data - raw data without JSON.stringify
   * */
  post(url: any, data: any, headers: any) {
    return this._instance.post(url, data, { headers });
  }

  /**
   * @param {string} url
   * @param {Object} data - raw data without JSON.stringify
   * @param {Object} [headers]
   * */
  patch(url: string, data: any, headers: any) {
    return this._instance.patch(url, JSON.stringify(data), { headers });
  }

  /**
   * @param {string} url
   * @param {Object} data - raw data without JSON.stringify
   * @param {Object} [headers]
  * */
  put(url: string, data: any, headers: any) {
    return this._instance.put(url, JSON.stringify(data), { headers });
  }

  /**
   * @param {string} url
   * @param {Object} params
   * */
  delete(url: any, params: any, headers: any) {
    return this._instance.delete(url, params, { headers });
  }

  /**
   * @param {Object} params
   * @param {string} params.method
   * @param {string} params.url
   * @param {string} params.fileApiKey
   * @param {ImageForApi} params.file
   * @param {Object} params.otherFields
   */

  // /** @param {string} token */
  // setAuthHeader(token: string) {
  //   this._instance.setHeader(HTTPService.AUTH_HEADER_KEY, `Bearer ${token}`);
  // }
  //
  // removeAuthHeader() {
  //   this._instanse.deleteHeader(HTTPService.AUTH_HEADER_KEY);
  // }
}

export const httpService = new HTTPService();

/**
 * @typedef {Object} ApisauceResponse
 * @property {Object} response
 * @property {T} data - the object originally from the server
 * @template T
 * @property {number} duration - the number of milliseconds
 * @property {Object} problem - the problem code (see ApisauceProblemCodes)
 * @property {boolean} ok - true or false
 * @property {number} status - the HTTP status code
 * @property {Object} headers - the HTTP response headers
 * @property {Object} config - the underlying axios config for the request
 */
