import { useState } from 'react';

import { IInfrastructure } from 'src/typings/spinner';
import { ReactImageGalleryItemWithVideoData } from '../../../types';

export const useGalleryState = (
  data: IInfrastructure,
) => {
  const [gallery] = useState<ReactImageGalleryItemWithVideoData[]>(
    Boolean(data['infrastructure.src'])
      ? data['infrastructure.src']
        .split(';')
        .map((galleryItem) => {
          return {
            original: galleryItem,
            thumbnail: galleryItem,
            originalClass: 'infrastructure-item-gallery',
            thumbnailClass: 'infrastructure-thumbnail',
          };
        })
      : [],
  );

  return {
    gallery,
  };
};
