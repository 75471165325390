import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  sectionListWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
    height: 'max-content',
    '& > div': {
      marginRight: '50px',
    },
  },
}));
