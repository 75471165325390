import { IAppState } from 'src/store/types';

export const getSelectedHouse = (state: IAppState) => state.floors.selectedHouse;
export const getSelectedSection = (state: IAppState) => state.floors.selectedSection;
export const getSelectedFloor = (state: IAppState) => state.floors.selectedFloor;
export const getSelectedFloorNumber = (state: IAppState) => state.floors.selectedFloorNumber;
export const getFilteredFloorsIdsData = (state: IAppState) => state.floors.filteredIds;
export const getFilteredSections = (state: IAppState) => state.floors.filteredSections;
export const getSelectedApartmentId = (state: IAppState) => state.floors.selectedApartmentId;
export const getFromComplex = (state: IAppState) => state.floors.fromComplex;
export const getAllFloorData = (state: IAppState) => state.floors;
