import { useEffect, useState } from 'react';
import _ from 'lodash';

import { useGetCounters } from 'src/hooks/spinner';
import { ISpinnerSvgDataChildren } from 'src/typings/spinner';

interface IStateCountSize {
  width: number;
  height: number;
}

export const useAddCounterToSpinner = (svg: ISpinnerSvgDataChildren | any) => {
  const { counters } = useGetCounters();
  const [showCount, setCount] = useState<number>(0);
  const [countSize, setCountSize] = useState<IStateCountSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!_.isEmpty(counters)) {
      const counterId = svg.attr?.['counter-id'];
      const placeCounter = svg.attr?.['place-counter'];

      if (svg.tag === 'image' && counterId && placeCounter) {
        if (placeCounter === 'house_id') {
          setCount(counters.house[counterId]?.count || 0);
        }

        if (placeCounter === 'section_id') {
          setCount(counters.section[counterId]?.count || 0);
        }

        setCountSize({
          width: 200,
          height: 35,
        });
      }
    } else {
      setCount(0);
    }
  }, [svg, counters, setCount]);

  return {
    showCount,
    countSize,
  };
};
