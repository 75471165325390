import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  hideSpinner: {
    display: 'none',
  },
  backButton: {
    zIndex: 2,
    top: '32px',
    left: '30px',
  },
  svgPlace: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    '& > svg': {
      pointerEvents: 'none',
      '& > *:not(.counter)': {
        cursor: 'pointer',
        pointerEvents: 'visibleFill',
        transition: '0.15s all',
      },
      '& > .counter': {
        position: 'relative',
        overflow: 'visible',
        pointerEvents: 'visibleFill',
        fillOpacity: 1,
        transition: '0.15s all',
        backgroundColor: 'white',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > .counter-inner': {
          fontWeight: '500',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '20px',
          zIndex: 22,
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-5px',
            left: 'calc(50% - 10px)',
            width: '10px',
            height: '10px',
            border: '10px solid white',
            transform: 'rotate(45deg)',
            zIndex: -1,
          },
        },
      },
    },
    '& svg.hide': {
      display: 'none',
    },
  },
  blurStyle: {
    filter: 'blur(20px)',
  },
  styleCanvas: {
    animation: '$blurMap 1500ms forwards',
  },
  '@keyframes blurMap': {
    from: { filter: 'blur(10px)' },
    to: { filter: 'blur(0px)' },
  },
  floorsView: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
    },
    '& > #spinner_control': {
      background: 'none',
      boxShadow: 'none',
      flexDirection: 'column',
      bottom: '50%',
      left: '30px',
      transform: 'translateY(50%)',
      padding: '0px',
      '& > *': {
        marginLeft: '0px',
        marginTop: '20px',
        boxShadow: '0px 0px 10px 0px rgba(128, 128, 128, 0.24)',
      },
      '& > #turn_left, & > #turn_right, & > img': {
        display: 'none',
      },
      [theme.breakpoints.down('md')]: {
        left: '10px',
      },
    },
  },
  showInfrastructure: {
    '& *[infrastructure]': {
      animation: '$showInf 250ms forwards',
      animationIterationCount: 1,
    },
  },
  hideInfrastructure: {
    '& *[infrastructure]': {
      display: 'none',
      opacity: 0,
    },
    '& *[infrastructure].displayed': {
      display: 'inherit',
      animation: '$showInf 250ms forwards',
    },
  },
  '@keyframes showInf': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  '@keyframes hideInf': {
    '0%': { opacity: 1 },
    '99%': { opacity: 0 },
    '100%': { opacity: 0, display: 'none' },
  },
}));
