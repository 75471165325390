import { takeEvery } from 'redux-saga/effects';

import { ActionTypes } from 'src/store/filter/filter-value/actions';

export function* setFilterValueSaga() {

}

export function* setFilterValueWatchSaga() {
  yield takeEvery(ActionTypes.SET_FILTER_VALUE, setFilterValueSaga);
}
