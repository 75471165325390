/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SketchPicker } from 'react-color';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { IStatus, IComplex } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { postPlacementStatus, patchPlacementStatus } from 'src/api';
import { useStyles } from '../styles';

interface IStatusModal {
  isOpen: boolean;
  handleChangeOpen: Function;
  refreshStatuses: Function;
  status?: IStatus|null;
  complex: IComplex;
}

const schema = yup.object().shape({
  name: yup.string().required(translations.errorRequired),
  crossname: yup.string().required(translations.errorRequired),
  color: yup.string().required(translations.errorRequired),
  crosstableColor: yup.string().required(translations.errorRequired),
});

export const StatusModal: React.FC<IStatusModal> = ({
  isOpen, handleChangeOpen, status, complex, refreshStatuses,
}) => {
  const styles = useStyles({});
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayCrossColorPicker, setDisplayCrossColorPicker] = useState(false);
  const [color, setColor] = useState(status ? status.color : '#000');
  const [crossColor, setCrossColor] = useState(status && status.crosstableColor ? status.crosstableColor : '#000');
  const [error, setError] = useState('');
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const {
    register, handleSubmit, formState, watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (data:any) => {
    data.complex = complex['@id'];
    data.update_time_interval = data.temporary_type === 'fixed time'
      ? Math.floor(data.update_time_interval * 3600) : null;
    data.crosstable_name = {
      [complex.language]: data.crossname,
    };
    data.module_name = {
      [complex.language]: data.name,
    };
    data.name = {
      [complex.language]: data.name,
    };
    let res;
    if (status) {
      res = await patchPlacementStatus(jwt, status.id, data);
    } else {
      res = await postPlacementStatus(jwt, data);
    }
    if (res.ok) {
      handleChangeOpen(false);
      refreshStatuses();
    } else {
      setError(res.data['hydra:description']);
    }
  };

  const onChangeColor = (value: any) => {
    setColor(value.hex);
  };
  const onChangeCrossColor = (value: any) => {
    setCrossColor(value.hex);
  };

  const typeWatch = watch('temporary_type');

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      onClose={() => handleChangeOpen(false)}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
          }}
        >
          <Box width="750px" maxHeight="90vh" overflow="auto" py={10} px={11}>
            <Box mb={6} fontSize="24px" fontWeight={600}>
              {status ? translations.editingStatus : translations.addingNewStatus}
            </Box>
            <Box fontSize="18px" width="640px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex">
                  <Box flex="1" mr={2}>
                    <Box mb={6} display="flex" flexDirection="column">
                      {translations.nameInModule}
                      <Box width="260px" mt={2}>
                        <TextField
                          id="statusName"
                          variant="outlined"
                          name="name"
                          inputRef={register}
                          defaultValue={status && (status.module_name ? status.module_name[complex.language] : status.name[complex.language])}
                        />
                      </Box>
                    </Box>
                    <Box mb={6} display="flex" flexDirection="column">
                      {translations.colorInModule}
                      <Box position="relative" width="260px" mt={2}>
                        <Box
                          width="30px"
                          height="30px"
                          borderRadius="4px"
                          position="absolute"
                          style={{
                            backgroundColor: color,
                            cursor: 'pointer',
                            top: '50%',
                            left: '10px',
                            transform: 'translateY(-50%)',
                          }}
                        />
                        { displayColorPicker ? (
                          <Box className={styles.popover}>
                            <Box className={styles.cover} onClick={() => setDisplayColorPicker(false)} />
                            <SketchPicker color={color} onChange={onChangeColor} />
                          </Box>
                        ) : null }
                        <TextField
                          id="statusColor"
                          variant="outlined"
                          name="color"
                          value={color}
                          inputRef={register}
                          onClick={() => setDisplayColorPicker(!displayColorPicker)}
                          InputProps={{
                            readOnly: true,
                            className: styles.colorPicker,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  width="30px"
                                  height="30px"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box mb={6} display="flex" flexDirection="column">
                      {translations.nameInCross}
                      <Box width="260px" mt={2}>
                        <TextField
                          id="statusCrossName"
                          variant="outlined"
                          name="crossname"
                          inputRef={register}
                          defaultValue={status && status.crosstable_name && status.crosstable_name[complex.language]}
                        />
                      </Box>
                    </Box>
                    <Box mb={6} display="flex" flexDirection="column">
                      {translations.colorInCross}
                      <Box position="relative" width="260px" mt={2}>
                        <Box
                          width="30px"
                          height="30px"
                          borderRadius="4px"
                          position="absolute"
                          style={{
                            backgroundColor: crossColor,
                            cursor: 'pointer',
                            top: '50%',
                            left: '10px',
                            transform: 'translateY(-50%)',
                          }}
                        />
                        { displayCrossColorPicker ? (
                          <Box className={styles.popover}>
                            <Box className={styles.cover} onClick={() => setDisplayCrossColorPicker(false)} />
                            <SketchPicker color={crossColor} onChange={onChangeCrossColor} />
                          </Box>
                        ) : null }
                        <TextField
                          id="crosstableColor"
                          variant="outlined"
                          name="crosstableColor"
                          value={crossColor}
                          inputRef={register}
                          onClick={() => setDisplayCrossColorPicker(!displayCrossColorPicker)}
                          InputProps={{
                            readOnly: true,
                            className: styles.colorPicker,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  width="30px"
                                  height="30px"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>

                </Box>
                <Box mb={6} display="flex" flexDirection="column">
                  {translations.type}
                  <Box fontSize="18px" width="260px" display="flex" mt={2}>
                    <input
                      ref={register}
                      style={{ display: 'none' }}
                      type="radio"
                      defaultChecked={
                        status
                          ? (status?.temporary_type === 'static' || !status?.temporary_type)
                          : true
                      }
                      id="type_static"
                      name="temporary_type"
                      value="static"
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="type_static" className="radioBtn">
                      On/Off
                    </label>
                    <input
                      ref={register}
                      style={{ display: 'none' }}
                      type="radio"
                      defaultChecked={status ? status?.temporary_type === 'fixed time' : false}
                      id="type_fixtime"
                      name="temporary_type"
                      value="fixed time"
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="type_fixtime" className="radioBtn">Fix Time</label>
                  </Box>
                </Box>
                {typeWatch === 'fixed time' && (
                  <Box mb={6} display="flex" alignItems="center">
                    {translations.period}
                    <Box width="260px" ml={4} display="flex" alignItems="center">
                      <Box width="60px" mr={2}>
                        <TextField
                          variant="outlined"
                          name="update_time_interval"
                          defaultValue={(status && status.update_time_interval) ? status.update_time_interval / 3600 : 72}
                          inputRef={register}
                          type="number"
                        />
                      </Box>
                      {translations.hours}
                    </Box>
                  </Box>
                )}
                <Box display="flex">
                  <Box>
                    <Box mb={4}>
                      <Box width="260px">
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color="primary"
                              name="can_be_changed"
                              inputRef={register}
                              defaultChecked={status ? status.can_be_changed : false}
                            />
                    )}
                          label={translations.managerCanChange}
                        />
                      </Box>
                    </Box>
                    <Box mb={6}>
                      <Box width="260px">
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color="primary"
                              name="can_be_set"
                              inputRef={register}
                              defaultChecked={status ? status.can_be_set : false}
                            />
                  )}
                          label={translations.managerCanApply}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box ml={4}>
                    <Box mb={4}>
                      <Box>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color="primary"
                              name="amo_only_one_deal_placement"
                              inputRef={register}
                              defaultChecked={status ? status.amo_only_one_deal_placement : false}
                            />
                    )}
                          label={translations.oneApartInDeal}
                        />
                      </Box>
                    </Box>
                    <Box mb={6}>
                      <Box>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color="primary"
                              name="amo_can_be_set_for_deal"
                              inputRef={register}
                              defaultChecked={status ? status.amo_can_be_set_for_deal : false}
                            />
                  )}
                          label={translations.bindingRoomsWithStatus}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box width="260px">
                  <ButtonApp
                    text={translations.safeChanges}
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={styles.btn}
                    disabled={!formState.isValid && !status}
                  />
                </Box>
              </form>
              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                  {error}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
