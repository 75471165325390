import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperTypeItem: (props: any) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    color: props.primaryColor,
    backgroundColor: `${props.filterBackgroundColor}e6`,
    '& .appFillStyles': {
      fill: props.primaryColor,
    },
    '& .appStrokeStyles': {
      stroke: props.primaryColor,
    },
    '& > span': {
      fontSize: '13px',
    },
    [theme.breakpoints.down('md')]: {
      '& > span': {
        fontSize: '11px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
        textAlign: 'center',
      },
    },
  }),
  iconTypeStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '4px',
    position: 'relative',
  },
  selectedStyle: (props: any) => ({
    fontWeight: 'bold',
    backgroundColor: `${props.primaryColor}e6`,
    color: props.filterBackgroundColor,
    '& .appFillStyles': {
      fill: props.filterBackgroundColor,
    },
    '& .appStrokeStyles': {
      stroke: props.filterBackgroundColor,
    },
    // '& > div > svg > path': {

    //   fill: props.filterBackgroundColor,
    // },
  }),
}));
