import { useState, useEffect } from 'react';
import { IStage } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';

import { getBitrixStageByCategory } from 'src/api';

export const useGetBitrixStages = (id: string|null) => {
  const [stages, setStages] = useState<IStage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchStages = () => {
    setIsLoading(true);
    if (id) {
      getBitrixStageByCategory(jwt, id)
        .then((res: any) => {
          const { data } = res;
          setStages(data['hydra:member']);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    let cleanupFunction = false;
    if (id) {
      setIsLoading(true);
      getBitrixStageByCategory(jwt, id)
        .then((res: any) => {
          if (!cleanupFunction) {
            const { data } = res;
            setStages(data['hydra:member']);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    return () => {
      cleanupFunction = true;
    };
  }, [jwt, id]);

  return {
    stages,
    isLoading,
    fetchStages,
    setStages,
  };
};
