/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { FeedbackSettings } from 'src/components/profileComponents/FeedbackSettings';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetDeveloper } from 'src/hooks';
import { MainDataForm } from './components/MainDataForm';
import { CategoriesTable } from './components/CategoriesTable';
import { BitrixFields } from './components/BitrixFields';
import { useGetBitrixProject } from './hooks/useGetBitrixProject';

export const IntegrationBitrix = () => {
  const { id }: { id: string } = useParams();
  const { isLoading, developer } = useGetDeveloper(id);
  const { project, isLoading: isLoadingProject, fetchProject } = useGetBitrixProject(developer && developer.bitrixProject);

  if (isLoading || isLoadingProject || !developer) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const { title } = developer;

  const crumbs: ICrumbs[] = [
    {
      name: translations.developersLink,
      url: `/profile/${pathList.developers}`,
    },
    {
      name: `Застройщик ${title}`,
      url: `/profile/${pathList.developers}/${id}`,
    },
    {
      name: 'Интеграция Bitrix',
    },
  ];

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {`Интеграция Bitrix | ${title}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box mb={4}>
        <MainDataForm developer={developer} project={project} fetchProject={fetchProject} />
      </Box>
      {project && (
        <>
          <Box mb={4}>
            <CategoriesTable developer={developer} project={project} fetchProject={fetchProject} />
          </Box>
          <Box mb={4}>
            <BitrixFields project={project} />
          </Box>
          <Box mb={4}>
            <FeedbackSettings project={project} />
          </Box>
        </>
      )}
    </Box>
  );
};
