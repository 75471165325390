import React, { useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PolygonTriggerEvent } from 'src/typings/app';
import { isTouch } from 'src/constants';
import { Actions } from 'src/store/spinner/actions';
import { getHighlightPolygons } from 'src/store/spinner/selectors';

import { RenderHoverText } from './components/HoverText';
import { IMouseEvent, IRenderSvgElement, TypeActions } from './types';
import {
  useDetectAdditionalParams,
  useFilledPolygons,
  useFloorSpinnerInteraction,
} from './hooks';
import { InfrastructureType } from './components/InfrastructureType';

export const RenderSvgElement: React.FC<IRenderSvgElement> = React.memo(({
  trigger,
  isBlockedRemoveHoverLeaveClass,
  isAlwaysHighlight,
  isHouse,
  svg,
  name,
  nameId,
  redirect,
  styles,
  animStyles,
  className,
  elementProps,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleMouseMove,
  handleOutsideClick,
}) => {
  const wrapperRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch();
  const detectMainSvg = 'mainSvg';
  const highlightPolygonsArray = useSelector(getHighlightPolygons);
  const { handleIsSelectedFillColor } = useFilledPolygons();

  const {
    isInfrastructureOpen,
    isEnableHoverPopup,
    isEnableHoverTextPolygonLightUp,
    isHasInfrastructureType,
    isHoverTextPolygon,
    isHasHoverText,
    trackMouse,
    setTrackMouse,
    setIsHoverPolygon,
    handleInfrastructureToggle,
  } = useDetectAdditionalParams(svg);
  const {
    isFloors,
    handleActionForFloor,
  } = useFloorSpinnerInteraction();

  const handleClickOutside = useCallback((event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (handleOutsideClick) {
        handleOutsideClick(event);
      }
    }
  }, [wrapperRef.current]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const highlightPolygons = useCallback((event: any, typeAction: TypeActions) => {
    const { parentElement } = event.target;

    if (!parentElement) return;

    if (name && nameId) {
      const listOfPolygons: any[] = parentElement.querySelectorAll(`[entity-view-name="${name}"][entity-view-name-id="${nameId}"]`);

      if (listOfPolygons) {
        listOfPolygons.forEach((itemPolygon: any) => {
          itemPolygon.classList[typeAction](styles.hoverFragment.split(' ')[1]);
        });
      }
    } else {
      const infrastructureId = event.target.getAttribute('infrastructure');
      const listOfInfrastructure: any[] = parentElement.querySelectorAll(`[infrastructure="${infrastructureId}"]`);

      if (listOfInfrastructure) {
        listOfInfrastructure.forEach((listItem: any) => {
          listItem.classList[typeAction](styles.hoverFragment.split(' ')[1]);
        });
      }
    }

    if (isEnableHoverTextPolygonLightUp) {
      event.target.classList[typeAction](styles.hoverFragment.split(' ')[1]);
    }
  }, [styles, name, nameId, isEnableHoverTextPolygonLightUp]);

  const handleSvgClick = useCallback((event: any) => {
    if (redirect) {
      window.open(redirect, '_blank');
    }

    if (isFloors) {
      handleActionForFloor(nameId);
      if (trigger === PolygonTriggerEvent.click) {
        handleMouseEnter(event);
      }
      return;
    }
    handleInfrastructureToggle();
    if (!name) return;

    if (event.target.classList.contains(detectMainSvg)) {
      event.target.classList.add(styles.clickedFragment.split(' ')[1]);
    }

    if (trigger === PolygonTriggerEvent.click || isTouch) {
      if (isHouse) {
        handleClick(event);
      } else {
        handleMouseEnter(event);
      }
    } else {
      handleClick(event);
    }
  }, [redirect, isFloors, nameId, name, detectMainSvg, isHouse, isTouch]);

  const handleSvgEnter = useCallback((event: any) => {
    if (event.target.classList.contains(detectMainSvg) && !isBlockedRemoveHoverLeaveClass) {
      highlightPolygons(event, TypeActions.add);
    }
    if (highlightPolygonsArray.includes(nameId)) {
      dispatch(Actions.setHighlightPolygons([]));
    }
    // if (trigger === PolygonTriggerEvent.hover || isEnableHoverPopup) {
    if (isHasHoverText || isEnableHoverPopup) setIsHoverPolygon(true);
    // }
    if (trigger === PolygonTriggerEvent.hover) {
      handleMouseEnter(event);
    }
  }, [dispatch, detectMainSvg, isBlockedRemoveHoverLeaveClass, highlightPolygonsArray, nameId, isHasHoverText, isEnableHoverPopup]);

  const handleSvgLeave = useCallback((event: any) => {
    if (event.target.classList.contains(detectMainSvg) && !isBlockedRemoveHoverLeaveClass) {
      highlightPolygons(event, TypeActions.remove);
    }
    if (isHasHoverText || isEnableHoverPopup) setIsHoverPolygon(false);

    if (trigger === PolygonTriggerEvent.hover) {
      handleMouseLeave(event);
    }
  }, [trigger, isBlockedRemoveHoverLeaveClass, isHasHoverText, isEnableHoverPopup, detectMainSvg]);

  const handleSvgMove = useCallback((event: IMouseEvent) => {
    if (isHasHoverText || isEnableHoverPopup) {
      setTrackMouse({ x: event.clientX, y: event.clientY });
    }

    handleMouseMove(event);
  }, [isHasHoverText, isEnableHoverPopup]);

  const renderSvgElement = () => {
    return React.createElement(
      svg.tag,
      {
        ...svg.attr,
        className: `${detectMainSvg} ${styles.defaultSvgStyle.split(' ')[1]} ${
          handleIsSelectedFillColor(name, nameId) ? styles.activeFragment : styles.transparentFragment} ${
          highlightPolygonsArray.includes(nameId) ? animStyles.highlightPolygon : ''} ${
          isAlwaysHighlight ? `${styles.activeFragment} displayed` : ''} ${
          className
        }`,
        ...elementProps,
        ref: wrapperRef,
        onClick: handleSvgClick,
        onMouseEnter: handleSvgEnter,
        onMouseLeave: handleSvgLeave,
        onMouseMove: handleSvgMove,
      },
    );
  };

  return (
    <>
      {renderSvgElement()}
      {
        (
          (isHasHoverText && isHoverTextPolygon)
          || (isEnableHoverPopup && isHoverTextPolygon)
        )
        && (
          <RenderHoverText
            isEnableHoverPopup={isEnableHoverPopup}
            nameId={nameId}
            hoverId={svg.attr['hover-text'] || svg.attr.infrastructure}
            trackMouse={trackMouse}
            handleHover={setIsHoverPolygon}
          />
        )
      }
      {
        isHasInfrastructureType
        && (
          <InfrastructureType
            isOpen={isInfrastructureOpen}
            infrastructureId={svg.attr.infrastructure}
            handleToggle={handleInfrastructureToggle}
          />
        )
      }
    </>
  );
});
