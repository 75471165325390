import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { ActionTypes as AppActionsTypes, Actions as AppActions } from 'src/store/app/actions';
import { makeRequestSaga } from 'src/store/common';
import { getUserData } from 'src/api';
import { Actions } from 'src/store/requests/user/actions';
import { ActionTypes } from './actions';

export function* setJWT(action: {
  type: string,
  payload: string | null,
}) {
  if (typeof action.payload === 'string') {
    let JWT = '';
    if (typeof action.payload === 'string') {
      JWT = action.payload;
    }

    if (!!JWT) {
      // httpService.setAuthHeader(JWT);
      yield put(Actions.getUserData());
      yield call(
        makeRequestSaga,
        Actions.getUserDataSuccess,
        Actions.getUserDataFail,
        () => getUserData(JWT),
      );
    } else {
      yield put(Actions.getUserDataFail());
    }
  }
}

export function* invalidToken(action: {
  type: string,
  payload: string | null,
}) {
  localStorage.removeItem('jwt_token');
  yield put(AppActions.jwtToken(''));
}

export function* getUserWatchSaga() {
  yield takeLatest([AppActionsTypes.JWT_TOKEN], setJWT);
  // yield takeEvery(ActionTypes.GET_USER_DATA_REQUEST_FAIL, invalidToken);
}
