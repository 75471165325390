import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Actions as filterActions } from 'src/store/filter/filter-params/actions';
import { Actions as InfrastructureActions } from 'src/store/infrastructure/actions';
import { Actions as FloorsActions } from 'src/store/floors/actions';
import { Actions } from 'src/store/spinner/actions';
import { IFirstSpinner } from 'src/typings/spinner';
import { getCurrentSpinner } from 'src/store/spinner/selectors';

export const useSetFilterParams = () => {
  const dispatch = useDispatch();
  const currentSpinner: IFirstSpinner | null = useSelector(getCurrentSpinner);

  const handleWriteToRedux = useCallback((
    typeParam: string,
    data,
    isDisabled?: boolean,
  ) => {
    dispatch(FloorsActions.setSelectedApartmentId(''));
    dispatch(Actions.setHighlightPolygons([]));
    dispatch(filterActions.setFilterParameter({ typeParam, data, isDisabled }));
  }, [dispatch]);

  const handleWriteToReduxInfrastructure = useCallback((
    typeParam: string,
    data,
    ids: string[],
    isDisabled?: boolean,
  ) => {
    if (currentSpinner && currentSpinner.name) {
      dispatch(InfrastructureActions.setInfrastructureFilterValue({
        typeParam,
        data,
        isDisabled,
        currentSpinner,
        ids,
      }));
    }
  }, [dispatch, currentSpinner]);

  return {
    handleWriteToRedux,
    handleWriteToReduxInfrastructure,
  };
};
