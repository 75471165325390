import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';

export const useGetInfrastructureData = (infrastructureId: string) => {
  const infrastructure = useSelector(requestSelectors.spinner.getSpinnerInfrastructure);

  return {
    withBody: !!(infrastructure && infrastructure[infrastructureId]?.['infrastructure.body_text']),
    data: infrastructure && infrastructure[infrastructureId],
  };
};
