import { IFilterData } from 'src/typings/filter';
import { action } from 'src/store/common';

export const ActionTypes = {
  GET_INFRASTRUCTURE_FILTER_DATA_REQUEST: '[REQUEST_APP]GET_INFRASTRUCTURE_FILTER_DATA_REQUEST',
  GET_INFRASTRUCTURE_FILTER_DATA_RESET: '[REQUEST_APP]GET_INFRASTRUCTURE_FILTER_DATA_RESET',
  GET_INFRASTRUCTURE_FILTER_DATA_REQUEST_SUCCESS: '[REQUEST_APP]GET_INFRASTRUCTURE_FILTER_DATA_REQUEST_SUCCESS',
  GET_INFRASTRUCTURE_FILTER_DATA_REQUEST_FAIL: '[REQUEST_APP]GET_INFRASTRUCTURE_FILTER_DATA_REQUEST_FAIL',
};

export const Actions = {
  getInfrastructureDataRequest: action(ActionTypes.GET_INFRASTRUCTURE_FILTER_DATA_REQUEST),
  getInfrastructureDataReset: action(ActionTypes.GET_INFRASTRUCTURE_FILTER_DATA_RESET),
  getInfrastructureDataSuccess: action<IFilterData>(ActionTypes.GET_INFRASTRUCTURE_FILTER_DATA_REQUEST_SUCCESS),
  getInfrastructureDataFail: action<Error>(ActionTypes.GET_INFRASTRUCTURE_FILTER_DATA_REQUEST_FAIL),
};
