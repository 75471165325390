import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';

export const rootUrl = 'https://dev1.flat.show';
export const serverPath = '/';
export const crosstableUrl = 'https://pro-api-dev.flat.show/api';
export const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints;
export const allUtms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_nooverride', 'utm_referrer'];

class ApiUrlClass {
  complexId: string|number;

  constructor() {
    this.complexId = '';
  }

  getApiUrl() {
    const { complexId } = this;
    const buildType = getBuildType();
    const isCrossTable = buildType === BuildEnv.crossTable;
    return {
      base: `${rootUrl}/client/module/`,
      placementFilter: `get-filter-data?id=${complexId}&view=${isCrossTable ? 'crosstable' : 'module'}`,
      getComplexDefaultConfig: `get-complex-default-config?id=${complexId}`,
      getGroupedPlacementsConfig: `get-grouped-placements-data?id=${complexId}&view=${isCrossTable ? 'crosstable' : 'module'}`,
      getClientMenuModule: `get-client-module-menu?id=${complexId}`,
      getSpinnerViews: `get-views?id=${complexId}`,
      getSpinnerSvg: `get-spinners-svg?id=${complexId}`,
      getTranslations: `get-translates?id=${complexId}`,
      getInfrastructureFilter: `get-infrastructure-filter-data?id=${complexId}`,
      getDisplayPlacementData: `get-crosstable-display-placement-data-config?id=${complexId}`,
      getUserInfo: `${crosstableUrl}/user`,
      getPanoramaInfo: `${crosstableUrl}/panorama_v2s`,
      getComplexes: `${crosstableUrl}/complexes`,
      getHouses: `${crosstableUrl}/houses`,
      getHouseHistory: `${crosstableUrl}/house_excel_import_histories?house=`,
      getPlacementStatus: `${crosstableUrl}/placement_statuses`,
      getPlacement: `${crosstableUrl}/placements`,
      getUsersIdInfo: `${crosstableUrl}/users`,
      getAmoToken: `${crosstableUrl}/exchange-amo-token`,
      getDevelopInfo: `${crosstableUrl}/developers`,
      getBitrixDeals: `${crosstableUrl}/bitrix_deals`,
      getBitrixDealData: `${crosstableUrl}/bitrix_deal_datas`,
      getBitrixProjects: `${crosstableUrl}/bitrix_projects`,
      getPlacementTypes: `${crosstableUrl}/placement_types?complex=${complexId}`,
      getBitrixCategories: `${crosstableUrl}/bitrix_categories`,
      getBitrixStages: `${crosstableUrl}/bitrix_stages`,
      bitrixDealClients: `${crosstableUrl}/bitrix_deal_clients`,
      bitrixFields: `${crosstableUrl}/bitrix_deal_placement_fields_linkings`,
      bitrixFormConfigurations: `${crosstableUrl}/bitrix_form_configurations`,
      bitrixFormConfigurationTimelines: `${crosstableUrl}/bitrix_form_configuration_timelines`,
      creatioProjects: `${crosstableUrl}/creatio_projects`,
      creatioStages: `${crosstableUrl}/creatio_stages`,
      creatioAutomatically: `${crosstableUrl}/create-automatically`,
      creatioPlacementStatusOnZeroDeals: `${crosstableUrl}/creatio_placement_status_on_zero_deals`,
      creatioOpportunities: `${crosstableUrl}/creatio_opportunities`,
      creatioPlacementOpporunities: `${crosstableUrl}/creatio/placement_opporunities`,
      importPlacements: `${crosstableUrl}/import/placements`,
      exportPlacements: `${crosstableUrl}/export/placements`,
      uploadFile: 'https://files.flat.show/file/upload',
    };
  }

  setComplexId(id: number|string) {
    this.complexId = id;
  }
}

export const ApiUrl = new ApiUrlClass();
