/* eslint-disable max-len */
import React from 'react';

export const ArrowRightIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path className="appStrokeStyles" d="M9 6.5L15 12.5L9 18.5" stroke="#1F2E42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
