import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useGetPlacementDataByIdCallback, useTypeDisplayedSpinner } from 'src/hooks';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { floorsAdditionalSpinnerId } from 'src/constants';
import { IFilterAllData } from 'src/typings/filter';
import { Actions } from 'src/store/floors/actions';

export const useFloorSpinnerInteraction = () => {
  const dispatch = useDispatch();
  const { handleSearchingPlacement } = useGetPlacementDataByIdCallback();
  const { getSelectedViewId } = useTypeDisplayedSpinner();
  const isFloors = TypesOfSpinner.viewFloors === getSelectedViewId;

  // eslint-disable-next-line no-undef
  const handleAddHighlight = useCallback((findElementOnSpinner: NodeListOf<Element> | undefined) => {
    if (findElementOnSpinner) {
      findElementOnSpinner.forEach((item) => {
        item.classList.add('highlightPolygon');
      });
    }
  }, []);

  const handleRemoveHighlight = useCallback(() => {
    document
      .querySelectorAll('.highlightPolygon')
      .forEach((item) => {
        item.classList.remove('highlightPolygon');
      });
  }, []);

  const handleActionForFloor = useCallback(async (nameId: string) => {
    await dispatch(Actions.setSelectedApartmentId(nameId));

    const floorsAdditionalSpinner: HTMLDivElement | null = document.querySelector(`#${floorsAdditionalSpinnerId}`);
    const findAdditionalSpinner: string | null | undefined = floorsAdditionalSpinner
      ?.querySelector('.spinner_place')
      ?.getAttribute('data-appspinner-id');
    if (findAdditionalSpinner) {
      const dataElement: IFilterAllData | undefined = handleSearchingPlacement(nameId);
      const findElementOnSpinner = floorsAdditionalSpinner
        ?.querySelectorAll(`[entity-view-name-id="${nameId}"]`);

      if (findElementOnSpinner?.length) {
        // @ts-ignore
        // eslint-disable-next-line no-unused-expressions
        document[findAdditionalSpinner]?.goto(dataElement?.['placement.best_spinner_stops'][0]?.stopNumber);
        handleRemoveHighlight();
        handleAddHighlight(findElementOnSpinner);
      }
    }
  }, [
    dispatch,
    handleAddHighlight,
    handleRemoveHighlight,
    handleSearchingPlacement,
  ]);

  return {
    isFloors,
    handleActionForFloor,
  };
};
