import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperContainer: {},
  wrapperFavoriteList: {
    display: 'flex',
    overflowX: 'auto',
    padding: '20px 0px',
    margin: '0px -30px 50px',
    '& > div:not(:last-child)': {
      marginRight: '20px',
    },
    '& > div:first-child': {
      marginLeft: '30px',
    },
    '& > div:last-child': {
      marginRight: '30px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0px -20px 50px',
      '& > div:first-child': {
        marginLeft: '20px',
      },
      '& > div:last-child': {
        marginRight: '20px',
      },
    },
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      '& > div:not(:last-child)': {
        marginRight: 'unset',
      },
      '& > div:first-child': {
        marginLeft: 'unset',
      },
      '& > div:last-child': {
        marginRight: 'unset',
      },
      '& > div': {
        margin: '10px 30px !important',
      },
    },
  },
  headerBlockFavCrumbs: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  emptyFavorites: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  compareButton: {
    color: 'white',
    padding: '18px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      width: '100%',
      marginTop: '10px',
    },
  },
  favoritePlacement: {
    width: '210px',
    minWidth: '210px',
    [theme.breakpoints.up('xl')]: {
      width: 'calc(25% - 60px)',
      maxWidth: 'calc(25% - 60px)',
    },
  },
  titleBlock: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
      marginBottom: '10px !important',
    },
  },
  headerBlock: {
    padding: '25px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  imageApartmentStyleXl: {
    [theme.breakpoints.up('xl')]: {
      height: '20vh',
      maxHeight: '20vh',
    },
  },
}));
