import React from 'react';
import { useCollapse } from 'react-collapsed';
import Box from '@mui/material/Box';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import { ApiUrl } from 'src/constants';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetAuthData } from 'src/hooks';
import { borderColor } from 'src/constants/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import isEmpty from 'lodash/isEmpty';
import { useStyles } from './styles';
import { appIcon } from '../../assets';
import { IUpdatedHouseHistoryItem } from '../../typings/house';
import { InsertsBlock } from './InsertsBlock';
import { UpdatesBlock } from './UpdatesBlock';
import { ErrorsBlock } from './ErrorsBlock';
import { uploadPlacementXlsxByHouseHistory } from '../../api';

function formatDateTime(dateTime: string): string {
  const date = new Date(dateTime);
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
  // @ts-ignore
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate.replace(',', ' –');
}

export const HouseHistoryBlock = ({ houseHistoryItem, houseNumeration, complexName }: {
  houseHistoryItem: IUpdatedHouseHistoryItem;
  houseNumeration: string | number,
  complexName: string,
}) => {
  const styles = useStyles({});
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const { user } = houseHistoryItem;
  const isWithError = houseHistoryItem.response.some((res) => {
    return !!res?.errors;
  });

  const hasInserts = !isEmpty(Object.keys(houseHistoryItem.sortedResponses.inserts));
  const hasUpdates = !isEmpty(Object.keys(houseHistoryItem.sortedResponses.updates));
  const hasErrors = !isEmpty(Object.keys(houseHistoryItem.sortedResponses.errors));
  const isMassiveError = houseHistoryItem.sortedResponses.errors && houseHistoryItem.sortedResponses.errors.unnamed
  && houseHistoryItem.sortedResponses.errors.unnamed.length > 100;

  const errorTag = isMassiveError ? translations.massiveError : translations.partiallyUpdated;
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  return (
    <Box
      borderBottom={`1px solid ${borderColor}`}
      py={2}
      px={2}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center">
          <Box
            bgcolor={isWithError ? '#DE7C71CC' : '#55DE45CC'}
            width="210px"
            py={3}
            display="flex"
            justifyContent="center"
            borderRadius="180px"
            fontSize="18px"
            lineHeight="20px"
            fontWeight={300}
            mr={4}
          >
            {isWithError ? errorTag : translations.successfullyUpdated}
          </Box>
          <Box
            fontSize="18px"
            lineHeight="20px"
            fontWeight={300}
            width="180px"
            mr={4}
          >
            {formatDateTime(houseHistoryItem.createdAt)}
          </Box>
          <Box display="flex" alignItems="center">
            <Box className={styles.avatar}>
              <img className={styles.avatarImg} src={user.iconUri || appIcon.avatar.default} alt="user avatar" />
            </Box>
            <Box>
              <Box
                fontSize="18px"
                lineHeight="20px"
                fontWeight={300}
                mb={2}
              >
                {user.username}
              </Box>
              <Box
                fontSize="16px"
                lineHeight="16px"
                fontWeight={300}
              >
                {user.email}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <ButtonApp
              text={translations.exportThisVersion}
              onClick={() => uploadPlacementXlsxByHouseHistory(houseHistoryItem.id, houseNumeration, complexName, jwt)}
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<FileDownloadOutlinedIcon />}
            />
          </Box>
          <ButtonApp
            {...getToggleProps()}
            text={isExpanded ? translations.rollUpLog : translations.seeLog}
            color="primary"
            variant="outlined"
            size="small"
            startIcon={isExpanded ? <RemoveIcon /> : <AddIcon />}
            style={{
              background: '#685ADE1A',
              borderColor: '#f0effd',
              width: '160px',
            }}
          />
        </Box>
      </Box>
      <Box {...getCollapseProps()}>
        <Box py={2}>
          {isWithError && (
            <Box
              color="#C71D0A"
              fontSize="18px"
              lineHeight="24px"
              fontWeight={300}
              mt={4}
            >
              Please, pay attention to the changes that caused the error(s) below.
              <br />
              These changes ARE NOT APPLIED. The export file of this version will not include changes that caused errors.
              <br />
              The list of applied and saved changes you may see in the section &quot;Updates list&quot; and &quot;List of newly created items.&quot;
            </Box>
          )}
          {!hasErrors && !hasInserts && !hasUpdates && (
            <Box
              fontSize="18px"
              lineHeight="24px"
              fontWeight={300}
              mt={4}
            >
              No changes were applied. The uploaded file has similar data to the previous version.
            </Box>
          )}
          {hasErrors && <ErrorsBlock errors={houseHistoryItem.sortedResponses.errors} />}
          {hasInserts && <InsertsBlock inserts={houseHistoryItem.sortedResponses.inserts} />}
          {hasUpdates && <UpdatesBlock updates={houseHistoryItem.sortedResponses.updates} />}
        </Box>
      </Box>
    </Box>

  );
};
