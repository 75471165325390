import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Actions } from 'src/store/requests/type-of-property/actions';
import { useBuildType, useLanguageState } from 'src/hooks';
import { IPlacementType } from 'src/store/type-displayed-spinner/reducer';
import { Actions as ActionsOfTypes } from 'src/store/type-displayed-spinner/actions';

export const useChangeValue = (listOfType: IPlacementType[]) => {
  const dispatch = useDispatch();
  const { isCrossTable } = useBuildType();
  const { currentAppLanguage } = useLanguageState();

  const handleChangeType = useCallback((value: string) => {
    const findingType = listOfType.find((type) => {
      if (type.title[currentAppLanguage] === value) {
        return true;
      }
      return false;
    });
    dispatch(Actions.changeSelectedTypePlacement(value));
    if (findingType) {
      dispatch(ActionsOfTypes.setTypeDisplayedSpinner(findingType[isCrossTable ? 'crosstableViewTypes' : 'moduleViewTypes'][0]));
    }
  }, [dispatch]);

  return {
    handleChangeType,
  };
};
