import { ISpinnerData } from 'src/typings/spinner';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { CRMNames } from 'src/typings/app';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface ISpinnerViewStateReducer {
  isLoading: boolean;
  error: Error | null;
  data: ISpinnerData | null;
}

export const initialState: ISpinnerViewStateReducer = {
  isLoading: true,
  error: null,
  data: null,
};

export const spinnerViewReducer = (
  state = initialState,
  { type, payload }: IAction,
): ISpinnerViewStateReducer => {
  switch (type) {
    case ActionTypes.GET_SPINNER_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.GET_SPINNER_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    case ActionTypes.GET_SPINNER_DATA_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    case ActionTypes.SPINNER_DATA_EDIT_PLACEMENT: {
      const {
        id,
        crm,
        data,
        lang = 'uk',
      } = payload;

      // @ts-ignore
      const tempData = window.structuredClone(state.data);
      if (tempData) {
        const placement = tempData?.['detail-info']?.placement?.[id];
        if (placement) {
          if (crm === CRMNames.amocrm) {
            placement[ListOfKeysFilterAllData.placementAmoDeals] = [...data];
          } else {
            const {
              placementStatus,
              bitrixDeals,
              creatioOpportunities,
            } = data;
            // @ts-ignore
            placement[ListOfKeysFilterAllData.creatioOpportunities] = [...creatioOpportunities];
            placement[ListOfKeysFilterAllData.bitrixDealData] = [...bitrixDeals];
            placement[ListOfKeysFilterAllData.statusId] = String(placementStatus.id);
            placement[ListOfKeysFilterAllData.placementStatusColor] = placementStatus.color;
            placement[ListOfKeysFilterAllData.placementStatusCrossTableColor] = placementStatus.crosstableColor;
            placement[ListOfKeysFilterAllData.placementStatusCrossTableName] = placementStatus.crosstable_name[lang];
            placement[ListOfKeysFilterAllData.placementStatus] = placementStatus.crosstable_name[lang];
            placement[ListOfKeysFilterAllData.placementStatusModuleName] = placementStatus.module_name[lang];
          }
        }
      }

      return {
        ...state,
        data: tempData as ISpinnerData,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
