import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { useGetFilterParams } from 'src/hooks';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { useGetFilteredIds } from './useGetFilteredIds';
import { usePolygonConfig } from './usePolygonConfig';

export const useFilledPolygons = () => {
  const { filteredIds } = useGetFilteredIds();
  const { selectedParams } = useGetFilterParams();
  const { globalPolygonConfig } = usePolygonConfig();
  const currentSpinner = useSelector(getCurrentSpinner);

  const handleIsSelectedFillColor = useCallback((name: string, nameId: string) => {
    /*
    *   if we have selected only ONE VALUE in the filter,
    *   and THIS VALUE is the HOUSE NUMBER,
    *   and we are on the house’s spinner,
    *   then nothing needs to be highlighted
    */
    const highlightAllPolygons = isEmpty(filteredIds[name]) && globalPolygonConfig?.is_pre_highlight_polygons_in_module;
    if (
      selectedParams.some((item) => item.name === ListOfKeysFilterAllData.placementHouse)
      && selectedParams.length === 1
      && currentSpinner?.name !== 'complex'
    ) {
      return false;
    }

    return filteredIds[name]?.includes(nameId) || highlightAllPolygons;
  }, [currentSpinner, selectedParams, filteredIds]);

  return {
    handleIsSelectedFillColor,
  };
};
