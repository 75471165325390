import { useDispatch } from 'react-redux';
import {
  requestActions,
} from 'src/store/requests/actions';
import {
  Actions as SpinnerActions,
} from 'src/store/spinner/actions';

export const useResetRequestData = () => {
  const {
    app,
    appMenu,
    typeOfProperty,
    filter,
    spinner,
    layouts,
    translations,
  } = requestActions;
  const dispatch = useDispatch();

  const handleReset = () => {
    dispatch(app.getAppDataReset());
    dispatch(appMenu.getAppClientMenuModuleReset());
    dispatch(typeOfProperty.resetFilterTypePlacement());
    dispatch(filter.getDataFilterReset());
    dispatch(spinner.getSpinnerDataReset());
    dispatch(layouts.getLayoutsReset());
    dispatch(translations.getTranslationReset());
    dispatch(SpinnerActions.resetSpinner());
  };

  return handleReset;
};
