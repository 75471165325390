import React from 'react';

import { PolygonTriggerEvent } from 'src/typings/app';
import { IRenderSvg } from 'src/components/ViewSpinner/components/RenderSvg/types';
import { RenderSvgElement } from '../../_RenderSvgElement';

interface IDefaultElement {
  trigger: PolygonTriggerEvent;
  isAlwaysHighlight?: boolean;
  svg: IRenderSvg | any;
  name: string;
  nameId: string;
  redirect: string;
  styles: any;
  animStyles: any;
}

export const DefaultElement: React.FC<IDefaultElement> = React.memo(({
  trigger,
  isAlwaysHighlight,
  svg,
  name,
  nameId,
  redirect,
  styles,
  animStyles,
}) => {
  return (
    <>
      <RenderSvgElement
        trigger={trigger}
        isAlwaysHighlight={isAlwaysHighlight}
        svg={svg}
        name={name}
        nameId={nameId}
        redirect={redirect}
        styles={styles}
        animStyles={animStyles}
        handleClick={(event) => null}
        handleMouseEnter={(event) => null}
        handleMouseLeave={(event) => null}
        handleMouseMove={(event) => null}
      />
    </>
  );
});
