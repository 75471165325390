import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { ButtonApp } from 'src/components/Button';
import { useTranslations } from 'src/hooks/translataions';
import { useSetDocumentTitle } from 'src/hooks';
// import { appIcon } from 'src/assets';
// import { locals } from 'src/constants/locals';
import { translations } from 'src/constants/translations';
import { useStyles } from './styles';
import { IAuthForm, IFormData } from './types';
import { useSendFormLogin } from './requests';

const schema = yup.object().shape({
  email: yup.string().email('Вы ввели неверный e-mail').required('Это обязательное поле'),
  password: yup.string()
    .required('Это обязательное поле')
    .min(8, 'Пароль должен состоять минимум из 8 символов')
    .matches(/[a-zA-Z]/, 'Пароль должен состоять только из латинских букв'),
});

export const AuthForm: React.FC<IAuthForm> = ({ isReg, toggleType, toggleIsForgot }) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  useSetDocumentTitle(getTranslations(translations.authTitle));

  const {
    register, handleSubmit, errors, formState,
  } = useForm<IFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    isSending,
    isError,
    cleanError,
    handleSendForm,
  } = useSendFormLogin();

  const onSubmit = (data: IFormData) => {
    handleSendForm(data);
  };
  const submitText = isReg ? translations.regSubmit : translations.logSubmit;
  return (
    <form
      className={styles.wrapper}
      onSubmit={handleSubmit(onSubmit)}
      key={isReg ? 'reg' : 'login'}
    >
      <Box mb={6}>
        <Typography variant="h4" component="h1">
          {isReg ? translations.regTitle : translations.logTitle}
        </Typography>
      </Box>
      <Box mb={4} position="relative">
        <Box fontSize={18} mb={2}>{translations.email}</Box>
        <TextField
          className={styles.input}
          variant="outlined"
          name="email"
          inputRef={register}
        />
        {errors.email && (
          <Box className={styles.error}>
            {errors.email.message}
          </Box>
        )}
      </Box>
      <Box mb={4} position="relative">
        <Box
          fontSize={18}
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          {translations.password}
          <Box
            className={styles.link}
            onClick={toggleIsForgot}
          >
            {translations.forgotLink}
          </Box>
        </Box>
        <TextField
          className={styles.input}
          type="password"
          variant="outlined"
          name="password"
          inputRef={register}
        />
        {errors.password && (
          <Box className={styles.error}>
            {errors.password.message}
          </Box>
        )}
      </Box>
      <Box mb={3}>
        <ButtonApp
          text={
            isSending
              ? <CircularProgress style={{ color: 'white' }} size="24px" />
              : submitText
          }
          type="submit"
          variant="contained"
          className={styles.submit}
          disabled={!formState.isValid}
        />
      </Box>
      {/* <Box mb={3} display="flex" justifyContent="center">
        Или войти с помощью
      </Box>
      <ButtonApp
        variant="outlined"
        color="primary"
        isFullSize
        isCapitalize
        customStyles={styles.socialBtn}
      >
        <img src={appIcon.iconFB.default} alt="Facebook icon" />
        <Box ml={2}>
          Facebook
        </Box>
      </ButtonApp>
      <ButtonApp
        variant="outlined"
        color="primary"
        isFullSize
        isCapitalize
        customStyles={styles.socialBtn}
      >
        <img src={appIcon.iconGoogle.default} alt="Google icon" />
        <Box ml={2}>
          Google
        </Box>
      </ButtonApp> */}
      {/* <Box display="flex" justifyContent="center">

        {isReg ? 'Уже есть аккаунт?' : 'Нет аккаунта?'}
        <Box
          className={styles.link}
          onClick={toggleType}
          display="inline-block"
          ml={2}
        >
          {isReg ? 'Войти' : 'Зарегистрироваться'}
        </Box>
      </Box> */}
      <Snackbar open={!!isError} autoHideDuration={6000} onClose={cleanError}>
        <Alert onClose={cleanError} elevation={6} variant="filled" severity="error">
          {isError}
        </Alert>
      </Snackbar>
    </form>
  );
};

export * from './RecoveryForm';
