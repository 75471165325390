import { useSelector } from 'react-redux';

import { getCrumbs } from 'src/store/app/selectors';

export const useGenerateCrumbs = () => {
  const crumbs = useSelector(getCrumbs);

  return {
    crumbs: crumbs || [],
  };
};
