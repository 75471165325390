import { useSelector } from 'react-redux';

import { IClientModulePhones } from 'src/typings/app';
import { requestSelectors } from 'src/store/requests/selectors';

export const useGetListOfPhones = () => {
  const listOfPhones: IClientModulePhones[] | undefined = useSelector(requestSelectors.app.getPhonesConfig);

  return {
    listOfPhones,
  };
};
