/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

interface IAppComponentCheckbox {
  isChecked: boolean;
  label?: string;
}

export const AppComponentCheckbox: React.FC<IAppComponentCheckbox> = ({
  isChecked,
  label,
}) => {
  return (
    <div className="ui checkbox">
      <input
        readOnly
        checked={isChecked}
        type="checkbox"
        className="hidden"
      />
      {label && <label>{label}</label>}
    </div>
  );
};

AppComponentCheckbox.defaultProps = {
  label: '',
};
