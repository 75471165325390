import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  crossTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: '30px',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 20px 0px 0px',
      height: 'calc(100% - 60px)',
    },
  },
  closedFilters: {
    marginLeft: '60px',
  },
  crossTable: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    overflow: 'hidden',
    position: 'relative',
  },
  controlTableContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '22px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  noDisplayData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > p': {
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
}));
