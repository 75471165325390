import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  apartmentIconInfo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > div:not(:last-child)': {},
    '& > div': {
      width: '50%',
      marginBottom: '5px',
      overflow: 'hidden',
      overflowWrap: 'anywhere',
      '& > img': {
        marginRight: '4px',
        width: '24px',
        height: '24px',
      },
    },
  },
  iconInfo: {
    padding: '2px 5px',
    display: 'flex',
    alignItems: 'center',
  },
}));
