import { useEffect, useState } from 'react';
import _ from 'lodash';

export const useGenerateShowingInfo = <T extends { [key: string]: { use: boolean } }>(data: T) => {
  const [renderItems, setRenderItems] = useState<{ [key: string]: T}>({});

  useEffect(() => {
    if (_.isObject(data)) {
      const willRenderItems: any = {};
      Object.keys(data).forEach((item) => {
        if (data[item].use) {
          willRenderItems[item] = data[item];
        }
      });

      setRenderItems(willRenderItems);
    }
  }, [data]);

  return renderItems;
};
