import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import _ from 'lodash';

import { getFilterInitialAllData } from 'src/store/requests/filter/selector';
import { useGetQueryParams } from 'src/hooks';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';

export const useGetInitialData = () => {
  // This part we used if url has some query parameters
  const schemaQuery: string[] = ['types', 'listIds'];
  const { listQueries } = useGetQueryParams(schemaQuery);
  const listArray = listQueries[schemaQuery[1]] ? listQueries[schemaQuery[1]].split(',') : [];

  const getAllData = useSelector(getFilterInitialAllData);
  const [listOfComparePlacement, setListOfComparePlacement] = useState<IFilterAllData[]>(listArray.reduce((acc: any, cur: any) => {
    if (getAllData?.[listQueries[schemaQuery[0]]]?.filter_all_data[cur]) {
      const currentValue: IFilterAllData = getAllData?.[listQueries[schemaQuery[0]]]?.filter_all_data[cur];
      const excludeKeysFromCurrentValue = _.pickBy(currentValue, (v, k) => {
        if (
          k !== ListOfKeysFilterAllData.placementStatusShowInModule
          && k !== ListOfKeysFilterAllData.placementPdf
        ) {
          return k;
        }
        return null;
      });

      return [...acc, excludeKeysFromCurrentValue];
    }

    return acc;
  }, []));

  const removeItemFromCompare = useCallback((id: string) => {
    setListOfComparePlacement(listOfComparePlacement.filter((item) => item.id !== id));
  }, [listOfComparePlacement]);

  return {
    listOfComparePlacement,
    removeItemFromCompare,
  };
};
