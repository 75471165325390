import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  tuneButton: {
    padding: 11,
    minWidth: 'auto',
    background: '#F6F7F6',
    marginLeft: '10px',
    position: 'relative',
  },
  countParams: {
    position: 'absolute',
    color: '#ffffff',
    top: '3px',
    right: '5px',
    '& > span': {
      background: theme.palette.primary.main,
      padding: '0px 3px',
      borderRadius: '3px',
    },
  },
  modalMain: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  modalFilterWrapper: (props: any) => ({
    backgroundColor: props.filterBackgroundColor,
    width: '100%',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    overflowY: 'auto',
  }),
  '@keyframes filterAnimation': {
    from: { maxHeight: '0%' },
    to: { maxHeight: '90%' },
  },
  modalFilterSelectors: {
    flex: 1,
    height: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      padding: '10px 20px',
    },
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
  },
  titleFiltering: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));
