import React from 'react';
import { StrictDropdownProps } from 'semantic-ui-react';

import { DropdownSingle } from 'src/components/dropdowns/DropdownSingle';
import { useConvertToFormat } from 'src/components/dropdowns/hooks';
import { IDropdownGenerator } from 'src/components/dropdowns/types';
import { useTranslations } from 'src/hooks/translataions';

interface IDropdownSingleFloors extends IDropdownGenerator, StrictDropdownProps {}

export const DropdownSingleFloors: React.FC<IDropdownSingleFloors> = ({
  dropdownInfo,
  ...props
}) => {
  const { getFixedTranslations } = useTranslations(dropdownInfo.attribute);
  const { currentData } = useConvertToFormat(dropdownInfo.data);

  return (
    <DropdownSingle
      clearable={false}
      placeholder={getFixedTranslations()}
      options={currentData}
      {...props}
    />
  );
};
