import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperListOfItems: {
    padding: '0px 20px',
    whiteSpace: 'nowrap',
    overflowY: 'auto',
  },
  wrapperSelectedItem: {
    display: 'inline-flex;',
    alignItems: 'center',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.light,
    padding: '5px',
    margin: '5px',
  },
  iconStyle: {
    color: theme.palette.primary.main,
    marginLeft: '10px',
  },
}));
