import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { countShowPropertyOnPage } from 'src/constants';
import { getFilterParamDataCountDesktopResult } from 'src/store/filter/filter-params/selectors';
import { Actions } from 'src/store/filter/filter-params/actions';

export const useStateListOfLayout = (length: number) => {
  const dispatch = useDispatch();
  const defaultCount = countShowPropertyOnPage;
  const [lengthOfList, setLengthOfList] = useState<number>(length);
  const [isShowLoadMoreBtn, setShowLoadMoreBtn] = useState<boolean>(true);
  const countShowedProperty = useSelector(getFilterParamDataCountDesktopResult);

  useEffect(() => {
    if (length !== lengthOfList) {
      dispatch(Actions.setFilterDesktopCount(defaultCount));
      setLengthOfList(length);
      setShowLoadMoreBtn(true);
    }
    if (countShowedProperty >= lengthOfList) {
      setShowLoadMoreBtn(false);
    } else {
      setShowLoadMoreBtn(true);
    }
  }, [dispatch, defaultCount, length, lengthOfList, countShowedProperty]);

  const handleShowMore = useCallback(() => {
    if (countShowedProperty < lengthOfList) {
      dispatch(Actions.setFilterDesktopCount(countShowedProperty + defaultCount));
    }
  }, [dispatch, defaultCount, countShowedProperty, lengthOfList]);

  return {
    isShowLoadMoreBtn,
    lengthOfList,
    countShowedProperty,
    handleShowMore,
  };
};
