import { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { appIcon } from 'src/assets';
import { IPanoAppartments } from 'src/containers/MainPageApartments/hooks';
import {
  IGalleryListOfSpinner,
  ReactImageGalleryItemWithSpinnerData,
  SpinnerLabel,
} from '../types';

export const useGenerateGalleryFormat = (
  spinnerApartments: IGalleryListOfSpinner[] | [],
  panoApartments: IPanoAppartments[],
  gallery: string[],
  combineIds: string[],
) => {
  const theme: Theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [galleryState, setGalleryState] = useState<ReactImageGalleryItemWithSpinnerData[]>([]);

  useEffect(() => {
    if ((!spinnerApartments || !spinnerApartments.length) && (!panoApartments || !panoApartments.length)) {
      setGalleryState(gallery.map((image) => {
        return {
          original: image,
          thumbnail: image,
          originalClass: 'apartment-gallery-slide-item',
          thumbnailClass: 'apartment-gallery-thumbnail',
        };
      }));
    } else {
      // const panoState = panoApartments.reduce((acc: ReactImageGalleryItemWithSpinnerData[], cur: IPanoAppartments) => {
      //   return [
      //     ...acc,
      //     {
      //       original: 'panorama',
      //       thumbnail: appIcon.icon360,
      //       originalClass: 'apartment-gallery-spinner',
      //       thumbnailClass: 'apartment-gallery-thumbnail-spinner',
      //       ...cur,
      //     },
      //   ];
      // }, [] as ReactImageGalleryItemWithSpinnerData[]);
      // @ts-ignore
      const initialSpinnerState = spinnerApartments.reduce((acc: any, cur: IGalleryListOfSpinner) => {
        const findedPoly = cur.data.additional_data.spinner_svg
        && Object.keys(cur.data.additional_data.spinner_svg).reduce((accPoly: boolean, stopId: string) => {
          if (cur.data && isArray(cur.data.additional_data.spinner_svg[stopId])) {
            const finded = cur.data.additional_data.spinner_svg[stopId].find((svg: any) => {
              return combineIds.includes(svg.attr['entity-view-name-id']);
            });
            if (finded) return true;
          }
          return accPoly;
        }, false);

        if (!findedPoly) return acc;

        return [
          ...acc,
          {
            original: SpinnerLabel.SPINNER,
            thumbnail: appIcon.icon360.default,
            originalClass: 'apartment-gallery-spinner',
            thumbnailClass: 'apartment-gallery-thumbnail-spinner',
            spinnerData: cur.data,
            icon: cur.icon,
            order: cur.order,
            type: cur.name,
          },
        ];
      }, [] as ReactImageGalleryItemWithSpinnerData[]);

      const combineData = gallery.reduce((acc, cur, currentIndex) => {
        return [
          ...acc,
          {
            original: isMdScreen ? cur.replace('desktop', 'mobile') : cur,
            thumbnail: isMdScreen ? cur.replace('desktop', 'mobile') : cur,
            originalClass: 'apartment-gallery-slide-item',
            thumbnailClass: 'apartment-gallery-thumbnail',
            order: currentIndex,
          },
        ];
      }, [...initialSpinnerState] as ReactImageGalleryItemWithSpinnerData[]);

      setGalleryState(combineData.sort((a: any, b: any) => a.order - b.order));
    }
  }, [spinnerApartments, gallery]);

  return {
    galleryState,
  };
};
