import { useSelector } from 'react-redux';

import { requestSelectors } from 'src/store/requests/selectors';

export const useCheckLoadedData = () => {
  const appDataIsLoading: boolean = useSelector(requestSelectors.app.getAppDataLoading);
  const typeFilterDataIsLoading: boolean = useSelector(requestSelectors.filter.getFilterInitialDataLoading);
  const translationsDataIsLoading: boolean = useSelector(requestSelectors.translations.getTranslationLoading);
  const layoutsDataIsLoading: boolean = useSelector(requestSelectors.layouts.getLayoutsRequestLoading);
  const spinnerDataIsLoading: boolean = useSelector(requestSelectors.spinner.getSpinnerDataLoading);

  return {
    appDataIsLoading,
    typeFilterDataIsLoading,
    translationsDataIsLoading,
    layoutsDataIsLoading,
    spinnerDataIsLoading,
  };
};
