import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  containerWrapper: (props: any) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    margin: 'auto',
  }),
  container: (props: any) => ({
    width: '100%',
    height: '100%',
    margin: 'auto',
  }),
  backButton: {
    zIndex: 2,
    top: '100px',
    left: '20px',
    position: 'absolute',
  },
}));
