import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  appFillStyles: {
    fill: theme.palette.primary.main,
  },
  appStrokeStyles: {
    stroke: theme.palette.primary.main,
  },
  dropBtn: {
    transition: '0.3s linear',
    '&:hover': {
      background: '#ffffff14',
    },
  },
  dropMenuBtn: {
    transition: '0.3s linear',
    cursor: 'pointer',
    '&:hover': {
      background: '#00000014',
    },
  },
  activeMenuBtn: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
}));
