import { useDispatch, useSelector } from 'react-redux';
import { RefObject, useEffect } from 'react';

import { getFilterScrollPosition } from 'src/store/filter/filter-params/selectors';
import { Actions } from 'src/store/filter/filter-params/actions';

export const useScrollIntoResultFilter = (scrollRef: RefObject<HTMLDivElement>) => {
  const dispatch = useDispatch();
  const scrollPosition = useSelector(getFilterScrollPosition);

  useEffect(() => {
    if (scrollRef.current && scrollPosition !== null) {
      scrollRef.current.scrollTo({ top: scrollPosition });
      dispatch(Actions.setScrollPosition(null));
    }
  }, [dispatch, scrollRef, scrollPosition]);

  return {
    scrollPosition,
  };
};
