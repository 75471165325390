import { useSelector } from 'react-redux';

import { getView2dSelectedIds } from 'src/store/view-2d/selectors';

export const useSelectedIds = () => {
  const selectedIds = useSelector(getView2dSelectedIds);

  return {
    selectedIds,
  };
};
