// @ts-nocheck
import React, {
  useEffect, useState, useRef, forwardRef,
} from 'react';
import 'intersection-observer';

interface IViewport {
  children?: any;
  className?: string;
  render: any;
}

const ViewportWrapper = forwardRef(({ visible, children, ...props }, ref) => {
  if (!visible) {
    return (
      <div ref={ref} {...props}>{children}</div>
    );
  }
  return children;
});

export const Viewport: React.FC<IViewport> = ({
  children, className, render,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const refNode = useRef(null);
  const content = visible ? children : null;

  useEffect(() => {
    if (refNode.current) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setVisible(true);
          observer.disconnect();
        }
      });
      observer.observe(refNode.current);

      return () => observer.disconnect();
    }
    return undefined;
  }, []);

  return (
    <ViewportWrapper
      ref={refNode}
      visible={visible}
      className={className}
    >
      {render ? render({ visible }) : content}
    </ViewportWrapper>
  );
};
