import { useMemo } from 'react';

import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';

export const useBuildType = () => {
  const buildType = useMemo(() => getBuildType(), []);
  const isSpinner = buildType === BuildEnv.spinner;
  const isCrossTable = buildType === BuildEnv.crossTable;

  return {
    isSpinner,
    isCrossTable,
    buildType,
  };
};
