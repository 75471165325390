import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  apartmentKeyInfo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > div:not(:last-child)': {},
    '& > div': {
      marginBottom: '5px',
      overflow: 'hidden',
      overflowWrap: 'break-word',
    },
  },
  keyInfo: {
    color: '#757575',
    padding: '0px 5px',
    fontSize: '12px',
  },
}));
