import React, { useCallback, useEffect, useState } from 'react';
import _, { forEach } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getComplexId, getCrmData } from 'src/store/app/selectors';
import { getBitrixDeal, getCreatioDeal } from 'src/store/crosstable/selectors';
import { Actions } from 'src/store/floors/actions';
import { AppPopover } from 'src/components/AppPopover';
import { PopoverInfo } from 'src/components/ViewSpinner/components/RenderSvg/PopoverInfo';
import { usePopoverState, useGetPlacementPopoverData } from 'src/components/ViewSpinner/components/RenderSvg/hooks';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { ListOfBacklightSvg } from 'src/typings/spinner';
import { PolygonTriggerEvent, CRMNames } from 'src/typings/app';
import { IRenderSvg } from 'src/components/ViewSpinner/components/RenderSvg/types';
import { useIsMobile, useBuildType } from 'src/hooks';
import { pathList } from 'src/route-list';
import { usePopup } from 'src/components/ViewSpinner/context';
import { RenderSvgElement } from '../../_RenderSvgElement';

interface IPopoverElement {
  trigger: PolygonTriggerEvent;
  isAlwaysHighlight?: boolean;
  svg: IRenderSvg | any;
  name: string;
  nameId: string;
  redirect: string;
  styles: any;
  animStyles: any;
}

export const PopoverElement: React.FC<IPopoverElement> = React.memo(({
  trigger,
  isAlwaysHighlight,
  svg,
  name,
  nameId,
  redirect,
  styles,
  animStyles,
}) => {
  const history = useHistory();
  const { isMobileScreen } = useIsMobile();
  const complexIdState = useSelector(getComplexId);
  const { isCrossTable } = useBuildType();
  const { state } = usePopup();
  const {
    isOpenPopover,
    setOpenPopover,
    setTargetIsEnter,
  } = usePopoverState();
  const crmData = useSelector(getCrmData);
  const bitrixDeal = useSelector(getBitrixDeal);
  const creatioDeal = useSelector(getCreatioDeal);
  const [isSelectedDeal, setIsSelectedDeal] = useState<boolean>(false);
  const { property }: { property: IFilterAllData } = useGetPlacementPopoverData(svg.attr);
  const popupPoly = state && state.svg && state.svg.attr && nameId === state.svg.attr['entity-view-name-id'];

  const handleSetPlacementId = useCallback((event: any) => {
    if (name === ListOfBacklightSvg.placementId) {
      setTargetIsEnter(true);
      setOpenPopover(true);
      if (state.debounceOpen) {
        state.debounceOpen({
          svg,
          target: event.currentTarget,
        });
      }
    }
  }, [name, setOpenPopover, setTargetIsEnter]);

  const handleClick = useCallback((event) => {
    handleSetPlacementId(event);
    if (name === 'placement' && !isMobileScreen) {
      history.push(`${isCrossTable ? `/${complexIdState}` : ''}${pathList.own}/${nameId}`);
    }
  }, [isMobileScreen, nameId, name, complexIdState, pathList, isCrossTable]);

  const handleMouseLeave = useCallback(() => {
    setTargetIsEnter(false);
    if (state.debounceClose) {
      state.debounceClose();
    }
  }, [state]);

  useEffect(() => {
    if (crmData?.crm === CRMNames.amocrm) {
      if (property && property[ListOfKeysFilterAllData.placementAmoDeals]) {
        property[ListOfKeysFilterAllData.placementAmoDeals].forEach((item) => {
          if (crmData && item.includes(String(crmData?.dealId))) {
            setIsSelectedDeal(true);
          }
        });
      }
    } else if (crmData?.crm === CRMNames.bitrix) {
      if (property && property[ListOfKeysFilterAllData.bitrixDealData]) {
        property[ListOfKeysFilterAllData.bitrixDealData].forEach((item) => {
          if (bitrixDeal && item.id === bitrixDeal.id) {
            setIsSelectedDeal(true);
          }
        });
      }
    } else if (crmData?.crm === CRMNames.creatio) {
      if (property && property[ListOfKeysFilterAllData.creatioOpportunities]) {
        property[ListOfKeysFilterAllData.creatioOpportunities].forEach((item) => {
          if (creatioDeal && item.id === creatioDeal.id) {
            setIsSelectedDeal(true);
          }
        });
      }
    }
  }, [property, creatioDeal, crmData]);

  useEffect(() => {
    if (!isOpenPopover && !popupPoly) {
      const listOfPolygons = document.querySelectorAll(`[entity-view-name="${name}"][entity-view-name-id="${nameId}"]`);

      if (listOfPolygons) {
        listOfPolygons.forEach((itemPolygon: any) => {
          styles.hoverFragment.split(' ').forEach((itemCls: string) => {
            itemPolygon.classList.remove(itemCls);
          });
          styles.clickedFragment.split(' ').forEach((itemCls: string) => {
            itemPolygon.classList.remove(itemCls);
          });
        });
      }
    }
  }, [isOpenPopover, name, nameId, styles, popupPoly]);

  return (
    <>
      <RenderSvgElement
        trigger={trigger}
        isBlockedRemoveHoverLeaveClass={isOpenPopover || popupPoly}
        isAlwaysHighlight={isAlwaysHighlight}
        svg={svg}
        name={name}
        nameId={nameId}
        redirect={redirect}
        styles={styles}
        animStyles={animStyles}
        className={`
          ${isSelectedDeal && styles.selectedDeal}
        `}
        handleClick={handleClick}
        handleMouseEnter={handleSetPlacementId}
        handleMouseLeave={handleMouseLeave}
        handleOutsideClick={() => {
          setTargetIsEnter(false);
        }}
        handleMouseMove={(event) => null}
      />
    </>
  );
});
