import { action } from 'src/store/common';
import { IFilterAllData } from 'src/typings/filter';

export const ActionTypes = {
  SET_FILTERED_KEYS: '[FILTER_DATA]SET_FILTERED_KEYS',
  SET_FILTERED_DATA: '[FILTER_DATA]SET_FILTERED_DATA',
  UPDATE_PLACEMENT: '[FILTER_DATA]UPDATE_PLACEMENT',

  CLEAR_FILTERED_DATA: '[FILTER_DATA]CLEAR_FILTERED_DATA',
  SET_CLEAR_FILTERED_DATA: '[FILTER_DATA]SET_CLEAR_FILTERED_DATA',
};

export const Actions = {
  setFilteredKeys: action(ActionTypes.SET_FILTERED_KEYS),
  setFilteredData: action(ActionTypes.SET_FILTERED_DATA),
  updatePlacement: action(ActionTypes.UPDATE_PLACEMENT),

  clearFilteredData: action(ActionTypes.CLEAR_FILTERED_DATA),
  setClearFilteredData: action(ActionTypes.SET_CLEAR_FILTERED_DATA),
};
