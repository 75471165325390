import React, { useMemo, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { useTranslations } from 'src/hooks/translataions';
import {
  IFilterPlacementInfoConfig,
  IPlacementExplications, ListOfKeysFilterAllData,
  PlacementDisplayedType,
} from 'src/typings/filter';
import { useFilterConfigurator } from 'src/hooks/filters';
import { CreatePdf } from './CreatePdf';
import { useStyles } from '../styles';

interface TabPanelProps {
  isSingleId: boolean;
  index: any;
  value: any;
  tabData: any;
}

export const TabPanelDescriptionApartment = React.memo((
  props: TabPanelProps,
) => {
  const {
    isSingleId,
    value,
    index,
    tabData,
    ...other
  } = props;
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const { filterConfigurator }: {
    filterConfigurator: IFilterPlacementInfoConfig | any
  } = useFilterConfigurator(
    isSingleId
      ? PlacementDisplayedType.placement
      : PlacementDisplayedType.placementGroupedInfoConfig,
    props.tabData.id,
  );
  const pdfRef = useRef(null);
  const availableConfig = useMemo(() => {
    if (filterConfigurator) {
      const listOfConfig = Object.keys(filterConfigurator);
      const useConfigList = listOfConfig.filter((item) => filterConfigurator[item].use);

      return useConfigList;
    }
    return [];
  }, [filterConfigurator]);

  useEffect(() => {
    if (window.afterCreateDownloadPdf) {
      window.afterCreateDownloadPdf(pdfRef.current);
    }
  }, []);

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === 0 && (
        <div className={styles.wrapperOrderList}>
          {Object.keys(tabData).map((infoData) => {
            if (
              tabData[infoData] === null
              || !availableConfig.includes(infoData)
            ) return null;

            const showData = _.isArray(tabData[infoData])
              ? tabData[infoData].join(', ')
              : tabData[infoData];
            const order = filterConfigurator?.[infoData]?.order || 0;
            const prefix = filterConfigurator?.[infoData]?.['value-prefix'] || '';
            const sufix = filterConfigurator?.[infoData]?.['value-sufix'] || '';

            if (showData === '') return null;
            return (
              <div
                style={{ order }}
                className={`${styles.wrapperTabInfoData} ${infoData.split('.')[1] || ''}`}
                key={infoData}
              >
                <span className={styles.infoTitle}>{getTranslations(infoData)}</span>
                {
                  ListOfKeysFilterAllData.placementPdf === infoData
                    ? (
                      <div className={styles.pdfStyle}>
                        {
                        tabData[infoData].map((pdf: string, i: number) => {
                          return (
                            <a
                              key={pdf}
                              href={pdf}
                              target="_blank"
                              rel="noopener noreferrer"
                              data-gaid="downloadPdf"
                              ref={pdfRef}
                            >
                              <PictureAsPdfIcon className={styles.pdfIcon} />
                            </a>
                          );
                        })
                      }
                      </div>
                    )
                    : <span className={styles.infoTextData}>{`${prefix}${showData} ${sufix}`}</span>
                }
              </div>
            );
          })}
          {
            (!Object.keys(tabData).includes(ListOfKeysFilterAllData.placementPdf)
            || !tabData[ListOfKeysFilterAllData.placementPdf]
            || !tabData[ListOfKeysFilterAllData.placementPdf].length) && (
              <div
                style={{ order: 9999 }}
                className={`${styles.wrapperTabInfoData} pdfs`}
              >
                <span className={styles.infoTitle}>{getTranslations(ListOfKeysFilterAllData.placementPdf)}</span>
                <div className={styles.pdfStyle}>
                  <CreatePdf id={props.tabData.id} />
                </div>
              </div>
            )
          }

        </div>
      )}
      {value === 1 && (
        <>
          {_.isArray(tabData) && tabData.map((infoDataObj: IPlacementExplications) => {
            return (
              <div key={infoDataObj['explication.title']} className={styles.wrapperTabInfoData}>
                <div className={styles.explicationHeader}>
                  <div className={styles.explicationSequence}><span>{infoDataObj['explication.sequence']}</span></div>
                  <span className={styles.infoTitle}>{infoDataObj['explication.title']}</span>
                </div>
                <div>
                  <span className={styles.infoTextData}>{infoDataObj['explication.area']}</span>
                </div>
              </div>
            );
          })}
        </>
      )}
    </Typography>
  );
});
