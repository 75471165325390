import { ActionTypes } from 'src/store/filter/filter-value/actions';
import { IAction } from 'src/typings/index';

export interface IFilterValueReducer {
  [key: string]: any;
}

export const initialState: IFilterValueReducer = [];

export const filterValueReducer = (
  state = initialState,
  { type, payload }: IAction,
): IFilterValueReducer => {
  switch (type) {
    case ActionTypes.SET_FILTER_VALUE: {
      return [...payload];
    }

    default:
      return state;
  }
};
