import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApartmentIcon from '@mui/icons-material/Apartment';
import {
  useTypeDisplayedSpinner,
  useTypeOfSpinner,
  useShowLessFiler,
  useTranslations,
  useBuildType,
  useHideFilterZone,
} from 'src/hooks';
import { requestSelectors } from 'src/store/requests/selectors';
import { TypeViewIconName, listOfTypes, IListOfTypes } from 'src/store/type-displayed-spinner/constant';
import { getMarkups } from 'src/store/requests/spinner-views/selector';
import { pathList } from 'src/route-list';
import { IMarkUps } from 'src/typings/spinner';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import {
  AroundImage,
  LayoutsImage,
  FloorsImage,
  CrosstableIcon,
} from './components';
import { MasterPlanDropdown } from './components/MasterPlanDropdown';
import { useStyles as useStylesSpinner } from './styles-spinner';
import { useStyles as useStylesCrossTable } from './styles-crosstable';

interface ITypeDisplayedSpinner {
  isActive?: boolean;
}

export const TypeDisplayedSpinner: React.FC<ITypeDisplayedSpinner> = React.memo(({
  isActive,
}) => {
  const history = useHistory();
  const { isSpinner } = useBuildType();
  const markups: IMarkUps | any = useSelector(getMarkups);
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const { isMainSpinner, currentSpinner } = useTypeOfSpinner();
  const styles = isSpinner ? useStylesSpinner(mainColor) : useStylesCrossTable();
  const {
    selectedViews,
    getSelectedViewId,
    handleOnChangeTypeMap,
    handleDropToPreviousView,
  } = useTypeDisplayedSpinner();
  const { getTranslations } = useTranslations();
  const { handleToggleShowAllFilter } = useShowLessFiler();
  useHideFilterZone();

  const handleMasterPlan = useCallback((id) => {
    console.log('handleMasterPlan', id);
    handleOnChangeTypeMap(id);
    handleDropToPreviousView();

    if (!isActive) history.push(pathList.home);
  }, [isActive, history, handleOnChangeTypeMap, handleDropToPreviousView]);

  const handleTypeBtn = useCallback((type) => {
    console.log('handleTypeBtn', type);
    handleOnChangeTypeMap(type.id);
    if (type.showNestedFilter) {
      handleToggleShowAllFilter(true);
    }
    if (type.isMain) {
      handleDropToPreviousView();
    }

    if (!isActive) history.push(pathList.home);
  }, [history, isActive, handleOnChangeTypeMap, handleToggleShowAllFilter, handleDropToPreviousView]);

  const renderIcon = (iconName: TypeViewIconName) => {
    switch (iconName) {
      case TypeViewIconName.svg360:
        return <AroundImage />;
      case TypeViewIconName.layouts:
        return <LayoutsImage />;
      case TypeViewIconName.floors:
        return <FloorsImage />;
      case TypeViewIconName.model2d:
        return <CrosstableIcon />;
      default:
        return null;
    }
  };

  return (
    <>
      {
        selectedViews.map((view) => {
          const type: IListOfTypes = listOfTypes[view];
          if (type.isNested
              && isMainSpinner
              && currentSpinner?.name === 'complex'
              && Array.isArray(markups.complex)
          ) return null;

          if (view === TypesOfSpinner.viewSpinner) {
            return (
              <MasterPlanDropdown
                key={type.id}
                getSelectedViewId={getSelectedViewId}
                type={type}
                isActive={!!isActive}
                handleClick={() => handleMasterPlan(type.id)}
              />
            );
          }

          return (
            <div
              key={type.id}
              className={`${styles.wrapperTypeItem} ${
                type.id === getSelectedViewId && isActive ? styles.selectedStyle : ''}`}
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => handleTypeBtn(type)}
            >
              <div className={styles.iconTypeStyles}>
                {renderIcon(type.iconName)}
              </div>
              <span>{getTranslations(type.label)}</span>
            </div>
          );
        })
      }
    </>
  );
});
