import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapperGallery: {
    width: '100%',
    maxWidth: '50%',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
    },
  },
  previewIcon: {
    width: '100%',
    height: '100%',
  },
  zoomButton: {
    bottom: '15px',
    right: '10px',
    background: 'white',
    minWidth: 'auto',
    width: '48px',
    height: '48px',
    zIndex: 11,
    '&:hover': {
      background: 'white',
    },
  },
  zoomStyle: {
    width: '30px',
    height: '30px',
  },
  svg: {
    position: 'absolute',
    left: 0,
    top: '10px',
    marginTop: '-10px',
    marginBottom: '-10px',
  },
  thumbnailInner: {
    '& .appFillStyles': {
      fill: theme.palette.primary.main,
    },
    '& .appStrokeStyles': {
      stroke: theme.palette.primary.main,
    },
  },
  galleryImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));
