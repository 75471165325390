import { IInfrastructureRequest } from 'src/typings/infrastructure';
import { IAction } from 'src/typings/index';
import { ActionTypes } from './actions';

export interface IInfrastructureRequestReducer {
  isLoading: boolean;
  error: Error | null;
  data: IInfrastructureRequest | undefined;
}

export const initialState: IInfrastructureRequestReducer = {
  isLoading: true,
  error: null,
  data: undefined,
};

export const infrastructureRequestReducer = (
  state = initialState,
  { type, payload }: IAction,
): IInfrastructureRequestReducer => {
  switch (type) {
    case ActionTypes.GET_INFRASTRUCTURE_FILTER_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.GET_INFRASTRUCTURE_FILTER_DATA_RESET: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.GET_INFRASTRUCTURE_FILTER_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    case ActionTypes.GET_INFRASTRUCTURE_FILTER_DATA_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    default:
      return state;
  }
};
